import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import axios from "axios";
import socketIOClient from "socket.io-client";
import car from "../../static/car.png";
import parking from "../../static/parking.png";
import hopLogo from "../../static/hop-logo.png";
import { ParkingDisplayStatuses } from "../../constants/parking-display-statuses";

const mapDispatchToProps = (dispatch) => {
  return {
  };
};
class ConnectedParkingDisplay extends Component {
  state = {
    licensePlateNumber: null,
    parkingPlace: null,
    deviceId: this.props.match.params.deviceId,
    textColor: 'black',
    showParking: false
  };

  formRef = React.createRef();
  componentDidMount = async () => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL + "/api";
    const socket = socketIOClient(`${process.env.REACT_APP_BASE_URL}`, {
      transports: ["websocket"],
      query: { deviceId: this.state.deviceId },
      path: "/api/socket.io",
      secure: true,
    });

    socket.on("vehicleArrived", (data) => {
      let timer = 10000;
      switch (data.status) {
        case ParkingDisplayStatuses.SUCCESSFUL_PARKING:
          timer = 10000;
          this.setState({ licensePlateNumber: data.licensePlateNumber, parkingPlace: data.parking.name, textColor: 'black', showParking: data.showParking });
          break;
        case ParkingDisplayStatuses.COULD_NOT_READ_PLATE:
          timer = 5000;
          this.setState({ licensePlateNumber: "NEM OLVASHATÓ A RENDSZÁM", textColor: 'red' });
          break;
        case ParkingDisplayStatuses.PLATE_NOT_IN_DB:
          timer = 5000;
          this.setState({ licensePlateNumber: "RENDSZÁM NEM TALÁLHATÓ A RENDSZERBEN", textColor: 'red' });
          break;
        case ParkingDisplayStatuses.PARKING_FULL:
          timer = 5000;
          this.setState({ licensePlateNumber: "MEGTELT A PARKOLÓHELY", textColor: 'red' });
          break;
        default:
          break;
      }

      setTimeout(() => {
        this.setState({ licensePlateNumber: null, parkingPlace: null, textColor: 'black' });
      }, timer);
    });
  };

  render() {
    return (
      <div>

        <Row>
          <Col span={24}>
            <div
              style={{
                padding: "20px",
                backgroundColor: "#00aea9",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={14} offset={8}>
            <div
              style={{
                color: "black",
                fontSize: "3rem",
                fontWeight: "bold",
              }}
            >
              Üdvözöljük a Hungária Office Parkban!
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "100px", paddingBottom: "50px" }} >
          <Col span={2} offset={6}>
            <div >
              <img alt="car" src={car} width={120} />
            </div>
          </Col>
          <Col span={14} offset={2}>
            <div
              style={{
                color: this.state.textColor,
                fontSize: "6rem",
                fontWeight: "bold",
              }}
            >
              {this.state.licensePlateNumber ? this.state.licensePlateNumber : " - "}
            </div>
          </Col>
        </Row>
        <Row style={{ visibility: this.state.showParking ? 'visible' : 'hidden' }}>
          <Col span={2} offset={6}>
            <div >
              <img alt="parking" src={parking} width={120} />
            </div>
          </Col>
          <Col span={14} offset={2}>
            <div
              style={{
                color: "black",
                fontSize: "6rem",
                fontWeight: "bold",
              }}
            >
              {this.state.parkingPlace ? this.state.parkingPlace : " - "}
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "100px" }}>
          <Col span={12} offset={8}>
            <img alt="hopLogo" src={hopLogo} width={400} />
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
});
const ParkingDisplay = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedParkingDisplay)
);
export default ParkingDisplay;
