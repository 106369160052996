import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Space, Button, message, Popconfirm, Switch } from "antd";
import {
  getAllCompanies,
  saveCompany,
  deleteCompany,
  modifyCompany,
  getAllAddresses,
  saveCompanyAddress,
} from "../../../actions/company";
import { getAllLanguages } from "../../../actions/language";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { getColumnSearchProps } from "../../../common/table.common";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCompanies: () => dispatch(getAllCompanies()),
    getAllLanguages: () => dispatch(getAllLanguages()),
    saveCompany: (params) => dispatch(saveCompany(params)),
    deleteCompany: (companyId) => dispatch(deleteCompany(companyId)),
    modifyCompany: (companyId, params) =>
      dispatch(modifyCompany(companyId, params)),
    getAllAddresses: (companyId, officeId) =>
      dispatch(getAllAddresses(companyId, officeId)),
    saveCompanyAddress: (params) => dispatch(saveCompanyAddress(params)),
  };
};

class ConnectedCompaniesList extends Component {
  formRef = React.createRef();

  state = {
    editable: this.props.editable || false,
  };

  componentDidMount = async () => {
    await this.props.getAllLanguages();
  };

  openEditModal = (company) => {
    this.props.openEditModal(company);
  };

  handleDelete = async (record) => {
    await this.props.deleteCompany(record.id);
  };

  handleChangeSmartlock = async (companyId) => {
    await axios
      .get("/2.0.0/company/smartlock", {
        params: {
          companyId: companyId,
        },
      })
      .then(async () => {
        await this.props.getAllCompanies();
      })
      .catch(() => {
        message.error("Hiba!");
      });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: this.props.t("companies-label-companyname"),
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["ascend", "descend"],
        ...getColumnSearchProps(
          "name",
          this.props.t("companies-label-companyname"),
          this.state.searchText,
          this.state.searchedColumn,
          this.handleSearch,
          this.handleReset,
          this.formRef,
          this.props.t
        ),
      },
      {
        title: this.props.t("companies-table-header-date"),
        key: "date",
        sorter: (a, b) => a.date.localeCompare(b.date),
        sortDirections: ["ascend", "descend"],
        render: (text, record) => (
          <Space size="middle">
            {new Date(parseInt(record.created)).toLocaleDateString()}
          </Space>
        ),
      },
      {
        title: this.props.t("companies-table-header-logo"),
        key: "logo",
        render: (text, record) => (
          <Space size="middle">
            {record.logoName !== null ? (
              <img
                alt="logo"
                src={`${
                  process.env.REACT_APP_BASE_URL
                }/api/1.0.0/company/logo/${record.logoName}?token=${
                  this.props.token.split(" ")[1]
                }`}
                width={50}
              />
            ) : (
              this.props.t("companies-table-no-logo")
            )}
          </Space>
        ),
      },
      {
        title: this.props.t("companies-table-header-smartlock"),
        key: "smartLock",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Switch
                checked={record.smartLock}
                onChange={() => this.handleChangeSmartlock(record.id)}
              />
            </Space>
          );
        },
      },
    ];

    if (this.state.editable === true) {
      columns.push(
        {
          title: this.props.t("companies-table-header-modify"),
          key: "modify",
          render: (text, record) => (
            <Space size="middle">
              <Button onClick={() => this.openEditModal(record)}>
                {this.props.t("companies-table-header-modify")}
              </Button>
            </Space>
          ),
        },
        {
          title: this.props.t("companies-table-header-delete"),
          key: "delete",
          render: (text, record) => (
            <Space size="middle">
              <Popconfirm
                onConfirm={() => this.handleDelete(record)}
                title={this.props.t("companies-table-delete-confirm")}
                cancelText={this.props.t("no-button-label")}
                okText={this.props.t("yes-button-label")}
              >
                <Button type="primary" danger>
                  {this.props.t("companies-table-header-delete")}
                </Button>
              </Popconfirm>
            </Space>
          ),
        }
      );
    }

    return (
      <>
        <Table
          columns={columns}
          rowKey="id"
          dataSource={this.props.companies}
          locale={{ emptyText: this.props.t("empty-text") }}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  saveStatus: state.companyReducer.saveStatus,
  languages: state.languageReducer.languages,
  message: state.companyReducer.message,
  addresses: state.companyReducer.addresses,
  companyId: state.companyReducer.companyId,
});

const CompaniesList = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedCompaniesList)
);
export default CompaniesList;
