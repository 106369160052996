import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Input, Select, Space, Table, Popconfirm, Tooltip } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  addAddressToRegapp,
  banRegApp,
  companyReset,
  createRegApp,
  getAllAddresses,
  getOneCompanyById,
  getUsersForRegapps,
} from "../../actions/company";
import { getOneOfficeById } from "../../actions/office";
import { PrinterOutlined } from "@ant-design/icons";

const mapDispatchToProps = (dispatch) => {
  return {
    getOneCompanyById: (companyId) => dispatch(getOneCompanyById(companyId)),
    companyReset: () => dispatch(companyReset()),
    banRegApp: (regAppId) => dispatch(banRegApp(regAppId)),
    createRegApp: (companyId, officeId) =>
      dispatch(createRegApp(companyId, officeId)),
    getAllAddresses: (companyId, officeId) =>
      dispatch(getAllAddresses(companyId, officeId)),
    addAddressToRegapp: (regAppId, addressId) =>
      dispatch(addAddressToRegapp(regAppId, addressId)),
    getOneOfficeById: (officeId) => dispatch(getOneOfficeById(officeId)),
    getUsersForRegapps: (token) => dispatch(getUsersForRegapps(token)),
  };
};

class ConnectedRegAppTokens extends Component {
  state = {
    regAppTokens: [],
    subscription: {},
    modifyName: null,
    name: "",
  };

  componentDidMount = async () => {
    await this.updateCompany();
    await this.props.getAllAddresses(
      this.props.user?.companyId,
      this.props.user?.officeId
    );
    this._loaded = true;
    this.props.socket.on("refreshUsedRegApps", async () => {
      await this.updateCompany();
    });
  };

  handleChangeAddress = async (event, regAppId) => {
    await this.props.addAddressToRegapp(regAppId, event);
    if (this.props.status) {
      await this.updateCompany();
    }
  };

  handleModifyName = (id, name) => {
    this.setState({ name: name, modifyName: id });
  };

  handleCancelModify = () => {
    this.setState({ name: "", modifyName: null });
  };

  handleSaveName = async () => {
    await axios.post("/1.0.0/regapp/name", {
      id: this.state.modifyName,
      name: this.state.name,
    });
    this.handleCancelModify();
    await this.updateCompany();
  };

  handleChangeName = (event) => {
    this.setState({ name: event.target.value });
  };

  updateCompany = async () => {
    if (!this.props.user.companyId && this.props.user.officeId !== null) {
      await this.props.getOneOfficeById(this.props.user.officeId);
      if (this.props.officeStatus) {
        this.setState({
          regAppTokens: this.props.office[0].registrationAppTokens,
        });
      }
    } else {
      await this.props.getOneCompanyById(this.props.user.companyId);
      if (this.props.status) {
        this.setState({
          regAppTokens: this.props.company[0].registrationAppTokens,
          subscription: this.props.company[0].subscription,
        });
      }
    }
  };

  handleBanRegApp = async (regAppId) => {
    await this.props.banRegApp(regAppId);
    if (this.props.status) {
      await this.updateCompany();
    }
  };

  handleGenerateNewToken = async () => {
    if (
      this.props.user.companyId !== null &&
      this.props.user.companyId !== undefined
    ) {
      if (
        (this.state.subscription.maxRegApp === undefined ||
        this.state.subscription.maxRegApp === null
          ? Number.MAX_SAFE_INTEGER
          : this.state.subscription.maxRegApp) >
        this.state.regAppTokens.filter((token) => token.banned !== true).length
      ) {
        await this.props.createRegApp(
          this.props.user.companyId,
          this.props.user.company.officeId
        );
        if (this.props.saveStatus) {
          await this.updateCompany();
        }
      }
    } else {
      await this.props.createRegApp(null, this.props.user.officeId);
      if (this.props.saveStatus) {
        await this.updateCompany();
      }
    }
  };

  render() {
    const printerColumn = {
      title: "",
      key: "printer",
      render: (text, record) => {
        return record.printer ? (
          <Tooltip title={record.printer.unique_id}>
            <PrinterOutlined />
          </Tooltip>
        ) : (
          ""
        );
      },
      ellipsis: true,
      width: "46px",
    };
    const regAppTokenColumn = {
      title: this.props.t("companyadmin-regapp-table-header-token"),
      key: "regAppToken",
      dataIndex: "token",
    };
    const nameColumn = {
      title: this.props.t("companyadmin-regapp-table-header-name"),
      key: "name",
      render: (text, record) => {
        return (
          <Space size="small" style={{ display: "flex" }}>
            <Input
              disabled={this.state.modifyName !== record.id}
              defaultValue={record.name}
              onChange={this.handleChangeName}
            />
            <div style={{ display: "flex" }}>
              {this.state.modifyName === null &&
                this.state.modifyName !== record.id && (
                  <Button
                    type="primary"
                    onClick={() =>
                      this.handleModifyName(record.id, record.name)
                    }
                  >
                    <EditOutlined />
                  </Button>
                )}
              {this.state.modifyName === record.id && (
                <>
                  <Button
                    type="primary"
                    style={{ marginRight: "0.5em" }}
                    onClick={this.handleSaveName}
                  >
                    <CheckOutlined />
                  </Button>
                  <Button onClick={this.handleCancelModify}>
                    <CloseOutlined />
                  </Button>
                </>
              )}
            </div>
          </Space>
        );
      },
    };
    const addressColumn = {
      title: this.props.t("companyadmin-regapp-table-header-address"),
      key: "address",
      render: (text, record) => (
        <Space size="middle">
          <Select
            style={{ width: "15vw" }}
            value={record.addressId}
            onChange={(event) => this.handleChangeAddress(event, record.id)}
          >
            {this.props.addresses.map((address) => {
              return (
                <Select.Option key={address.id} value={address.id}>
                  {address.name}
                </Select.Option>
              );
            })}
          </Select>
        </Space>
      ),
    };
    const inUseColumn = {
      title: this.props.t("companyadmin-regapp-table-header-inuse"),
      key: "inUse",
      render: (text, record) => (
        <Space size="middle" style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "1.1vw",
              height: "1.1vw",
              borderRadius: "50%",
              backgroundColor: record.inUse ? "green" : "gray",
            }}
          />
        </Space>
      ),
    };
    const banColumn = {
      title: this.props.t("delete-button-label"),
      key: "ban",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title={this.props.t("regapp-token-ban-popup-title")}
            okText={this.props.t("yes-button-label")}
            cancelText={this.props.t("no-button-label")}
            onConfirm={() => this.handleBanRegApp(record.id)}
          >
            <Button danger>{this.props.t("delete-button-label")}</Button>
          </Popconfirm>
        </Space>
      ),
    };

    const regAppColumns = [
      printerColumn,
      regAppTokenColumn,
      nameColumn,
      addressColumn,
      inUseColumn,
      banColumn,
    ];

    return (
      <div>
        <div>
          <div style={{ padding: "0.5em", float: "right" }}>
            <Button
              type="primary"
              onClick={this.handleGenerateNewToken}
              disabled={
                (this.state.subscription.maxRegApp === null
                  ? Number.MAX_SAFE_INTEGER
                  : this.state.subscription.maxRegApp) <=
                this.state.regAppTokens.filter((token) => token.banned !== true)
                  .length
              }
            >
              <PlusOutlined />
              {this.props.t("companyadmin-regapp-newtoken-button-label")}
            </Button>
          </div>
          <div style={{ paddingBottom: "1em", fontWeight: "bold" }}>
            {this.props.t("companyadmin-regapp-inuse-counter")}{" "}
            {
              this.state.regAppTokens.filter(
                (token) => token.banned !== true && token.inUse === true
              ).length
            }
            /
            {this.props.user.companyId === null ||
            this.props.user.company.subscription.maxRegApp === null
              ? "∞"
              : this.props.user.company.subscription.maxRegApp}
          </div>
          <Table
            columns={regAppColumns}
            rowKey="id"
            dataSource={this.state.regAppTokens.filter(
              (token) => token.banned !== true
            )}
            locale={{ emptyText: this.props.t("empty-text") }}
            pagination={
              this.state.regAppTokens.filter((token) => token.banned !== true)
                .length > 10
                ? {
                    position: ["bottomCenter"],
                    showSizeChanger: true,
                  }
                : false
            }
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  company: state.companyReducer.company,
  status: state.companyReducer.status,
  addresses: state.companyReducer.addresses,
  officeStatus: state.officeReducer.status,
  office: state.officeReducer.office,
  users: state.usersReducer.users,
  saveStatus: state.companyReducer.saveStatus
});
const RegAppTokens = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedRegAppTokens)
);
export default RegAppTokens;
