import { PROXIMITYCARD_ACTIONS } from "../constants/action-types";

const initialState = {
  cards: [],
  status: false,
  message: "",
  saveStatus: false,
  importErrors: {},
};

const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROXIMITYCARD_ACTIONS.PROXIMITYCARD_LOADING:
      return Object.assign({}, state, {
        cards: [],
        saveStatus: false,
        message: "",
      });
    case PROXIMITYCARD_ACTIONS.PROXIMITYCARD_LOADED:
      return Object.assign({}, state, {
        cards: action.payload,
        saveStatus: true,
        message: "",
      });
    case PROXIMITYCARD_ACTIONS.PROXIMITYCARD_ERROR:
      return Object.assign({}, state, {
        cards: [],
        saveStatus: false,
        message: action.payload,
      });
    case PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });

    case PROXIMITYCARD_ACTIONS.PROXIMITYCARD_IMPORT_ERRORS_ADD:
      return Object.assign({}, state, {
        importErrors: action.payload,
      });
    case PROXIMITYCARD_ACTIONS.PROXIMITYCARD_IMPORT_ERRORS_EMPTY:
      return Object.assign({}, state, {
        importErrors: {},
      });

    default:
      return state;
  }
};

export default cardReducer;
