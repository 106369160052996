import React, { Component } from "react";
import { connect } from "react-redux";
import { getOneCompanyById, modifyCompanyDesign, cropImage } from "../../actions/company";
import { Row, Col, Form, Button, message, Upload, Input, Tooltip } from "antd";
import { QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { GithubPicker } from "react-color";
import ImgCrop from "antd-img-crop";
import { refreshUser } from "../../actions/login";
import { saveCompanyText } from "../../actions/language";
import Modal from "antd/lib/modal/Modal";
import WizardBeforeImport from "./wizardBeforeImport";
import jolnezki from "../../static/jolnezki.png";
import WizardExcelImport from "./wizardExcelImport";
import AppPreview from "../Preview/appPreview";
import { withTranslation } from "react-i18next";
import WizardDownloadTemplate from "./wizardDownloadTemplate";
import { detect } from "detect-browser";
import Compress from "compress.js";

const mapDispatchToProps = (dispatch) => {
  return {
    modifyCompanyDesign: (formData) => dispatch(modifyCompanyDesign(formData)),
    saveCompanyText: (params) => dispatch(saveCompanyText(params)),
    refreshUser: (userId) => dispatch(refreshUser(userId)),
    getOneCompanyById: (companyId) => dispatch(getOneCompanyById(companyId)),
    cropImage: ( params ) => dispatch(cropImage(params))
  };
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};
const compress = new Compress();
class ConnectedWizardDesign extends Component {

  logoCropper = React.createRef();
  backgroundCropper = React.createRef();
  formRef = React.createRef();

  state = {
    primaryColor: this.props.selectedCompany.primaryColor || "#ffffff",
    background: [],
    logo: [],
    welcomeText: "",
    welcomeTitle: "",
    endModalVisible: false,
    excelImportVisible: false,
    previewVisible: false,
    downloadTemplateVisible: false,
    browser: "",
    backgroundCropData: {
      image: "",
      rotation: 0,
      cropData: {}
    },
    logoCropData: {
      image: "",
      rotation: 0,
      cropData: {}
    }
  };

  componentDidMount = async () => {
    await this.props.getOneCompanyById(this.props.user.companyId);
    if (this.props.status) {
      const welcomeTitle = this.props.company[0].companyTexts.filter(
        (text) =>
          text.languageId === this.props.company[0].defaultLanguageId &&
          text.textId === 2
      );
      const welcomeText = this.props.company[0].companyTexts.filter(
        (text) =>
          text.languageId === this.props.company[0].defaultLanguageId &&
          text.textId === 1
      );
      this.setState({
        welcomeText: welcomeText[0]?.textValue
          ? welcomeText[0]?.textValue
          : this.props.t("default-welcome-text"),
        welcomeTitle: welcomeTitle[0]?.textValue
          ? welcomeTitle[0]?.textValue
          : this.props.t("default-welcome-title"),
      });
      this.formRef.current.setFieldsValue({
        welcomeText: welcomeText[0]?.textValue
          ? welcomeText[0]?.textValue
          : this.props.t("default-welcome-text"),
        welcomeTitle: welcomeTitle[0]?.textValue
          ? welcomeTitle[0]?.textValue
          : this.props.t("default-welcome-title"),
      });
    }
    const browser = detect();
    this.setState({ browser: browser.name });
  };

  cropImage = async ( cropData ) => {
    await this.props.cropImage( cropData )
  }

  resetValues = () => {
    const welcomeTitle = this.props.company[0].companyTexts.filter(
      (text) =>
        text.languageId === this.props.company[0].defaultLanguageId &&
        text.textId === 2
    );
    const welcomeText = this.props.company[0].companyTexts.filter(
      (text) =>
        text.languageId === this.props.company[0].defaultLanguageId &&
        text.textId === 1
    );
    this.setState({
      welcomeText: welcomeText[0]?.textValue
        ? welcomeText[0]?.textValue
        : this.props.t("default-welcome-text"),
      welcomeTitle: welcomeTitle[0]?.textValue
        ? welcomeTitle[0]?.textValue
        : this.props.t("default-welcome-title"),
      primaryColor: this.props.selectedCompany.primaryColor || "#ffffff",
    });
    this.formRef.current.setFieldsValue({
      welcomeText: welcomeText[0]?.textValue
        ? welcomeText[0]?.textValue
        : this.props.t("default-welcome-text"),
      welcomeTitle: welcomeTitle[0]?.textValue
        ? welcomeTitle[0]?.textValue
        : this.props.t("default-welcome-title"),
    });
    this.removeFileBackground();
    this.removeFileLogo();
  };

  handleSubmit = async () => {
    await this.formRef.current.validateFields();
    const errors = this.formRef.current.getFieldsError();
    if (
      !errors.filter((error) => {
        return error.errors.length !== 0;
      })[0]
    ) {
      const formData = new FormData();
      formData.set("id", this.props.companyId ? this.props.companyId : this.props.user.companyId);
      formData.set("primaryColor", this.state.primaryColor);
      formData.set("secondaryColor", this.state.secondaryColor);
      formData.append("logo", this.state.logo[0]);
      formData.append("background", this.state.background[0]);
      await this.handleSaveLanguage();
      await this.props.modifyCompanyDesign(formData);
      if (this.props.saveStatus) {
        await this.props.updateCompany?.();
        this.setState({ endModalVisible: true });
      } else {
        message.error("Sikertelen mentés!", 5);
      }
    }
  };

  closeModal = async () => {
    await this.props.refreshUser(this.props.user.id);
    this.props.close();
  };

  beforeUploadBackground = (file) => {
    if (file.size / 1024 / 1024 > 5) {
      this.setState({ background: [] });
      message.error(this.props.t("companyadmin-design-toolargefile"));
    } else {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      if (image.width < 1000 || image.height < 1000) {
        // this.setState({ background: [] });
        // message.error(this.props.t("companyadmin-design-toosmallfile"));
        this.resizeTooSmallFile(file, 'background');
      } else if (image.width > 2000 || image.height > 2000) {
        // this.setState({ background: [] });
        // message.error(this.props.t("companyadmin-design-toolargefile"));
        this.resizeTooBigFile(file, 'background');
      } else {
        this.setState({ background: [file] });
      }
    }
    return false;
  };

  removeFileBackground = () => {
    this.setState({ background: [] });
  };

  resizeTooBigFile = async (file, imagetype) => {
    const resizedImage = await compress.compress([file], {
      size: 5,
      quality: 1,
      maxWidth: 2000,
      maxHeight: 2000,
      resize: true,
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
    const newState = {}
    newState[ imagetype ] = [resizedFile]
    this.setState(newState);
  };

  resizeTooSmallFile = async (file, imagetype) => {
    const resizedImage = await compress.compress([file], {
      size: 5,
      quality: 1,
      minWidth: 1000,
      minHeight: 1000,
      resize: true,
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
    const newState = {}
    newState[ imagetype ] = [resizedFile]
    this.setState(newState);
  };

  beforeUploadLogo = (file) => {
    if (file.size / 1024 / 1024 > 5) {
      this.setState({ logo: [] });
      message.error(this.props.t("companyadmin-design-toolargefile"));
    } else {
      const image = new Image();
      image.onload = () => {
        if (image.naturalWidth / image.naturalHeight === 2) {
          this.setState({ logo: [file] });
        } else {
          if (image.width < 500 || image.height < 500) {
            this.resizeTooSmallFile(file, 'logo');
          } else if (image.width > 2000 || image.height > 2000) {
            this.resizeTooBigFile(file, 'logo');
          } else {
            message.error(this.props.t("companyadmin-design-badaspect"));
            this.setState({ logo: [] });
          }
        }
      };
      image.src = URL.createObjectURL(file);
    }
    return false;
  };

  removeFileLogo = () => {
    this.setState({ logo: [] });
  };

  onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClickSecondary = () => {
    this.setState({
      displayColorPickerSecondary: !this.state.displayColorPickerSecondary,
    });
  };

  handleCloseSecondary = () => {
    this.setState({ displayColorPickerSecondary: false });
  };

  handleChangeSecondary = (color) => {
    this.setState({ secondaryColor: color.hex });
  };

  handleClickPrimary = () => {
    this.setState({
      displayColorPickerPrimary: !this.state.displayColorPickerPrimary,
    });
  };

  handleClosePrimary = () => {
    this.setState({ displayColorPickerPrimary: false });
  };

  handleChangePrimary = (color) => {
    this.setState({ primaryColor: color.hex });
    this.forceUpdate();
  };

  handleSaveLanguage = async () => {
    const defaultLanguageTexts = [
      {
        companyId: this.props.user.company.id,
        textId: 1,
        textValue: this.state.welcomeText,
        languageId: this.props.user.company.defaultLanguageId,
        isActive: true,
      },
      {
        companyId: this.props.user.company.id,
        textId: 2,
        textValue: this.state.welcomeTitle,
        languageId: this.props.user.company.defaultLanguageId,
        isActive: true,
      },
    ];
    const toSave = {
      texts: defaultLanguageTexts,
    };
    await this.props.saveCompanyText(toSave);
  };

  switchToNextPage = () => {
    this.props.next();
  };

  switchToTemplateDownload = () => {
    this.setState({
      endModalVisible: false,
      excelImportVisible: false,
      downloadTemplateVisible: true,
    });
  };

  switchToExcelImport = () => {
    this.setState({
      endModalVisible: false,
      excelImportVisible: true,
      downloadTemplateVisible: false,
    });
  };

  handleBackFromExcelImport = () => {
    this.setState({
      endModalVisible: false,
      excelImportVisible: false,
      downloadTemplateVisible: true,
    });
  };
  handleBackFromTemplate = () => {
    this.setState({
      endModalVisible: true,
      excelImportVisible: false,
      downloadTemplateVisible: false,
    });
  };

  openPreview = () => {
    this.setState({ previewVisible: true });
  };
  closePreview = () => {
    this.setState({ previewVisible: false });
  };

  closePopupBeforeImport = () => {
    this.setState({ endModalVisible: false });
  };

  getFileName = ( type ) => {
    switch ( type ) {
      case "image/jpeg":
        return "image.jpg"
      case "image/gif":
        return "image.gif"
      default:
        return "image.png"
    }
  }

  render() {
    return (
      <div>
        <div style={{ width: "100%", margin: "0 auto" }}>
          <Row>
            <Col span={6} style={{ padding: "0.5em" }}>
              <Form
                onFinish={this.handleSubmit}
                ref={this.formRef}
                {...formItemLayout}
                name="design"
                scrollToFirstError
                layout="vertical"
              >
                <Form.Item
                  label={
                    <div>
                      <strong>
                        {this.props.t("companyadmin-design-theme-label")}:{" "}
                      </strong>{" "}
                      <Tooltip
                        title="Válaszd ki, hogy milyen színben jelenjenek meg az applikációk."
                        placement="right"
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </div>
                  }
                  name="primaryColor"
                >
                  <div>
                    <div>
                      <GithubPicker
                        triangle="hide"
                        color={this.state.primaryColor}
                        colors={[
                          "#9E1C5C",
                          "#005245",
                          "#EEAC4D",
                          "#5C2A2E",
                          "#EE84B3",
                          "#187B30",
                          "#00A5E3",
                          "#74737A",
                          "#FFA23A",
                          "#0065A2",
                          "#FF5768",
                          "#00AEA9",
                        ]}
                        onChange={this.handleChangePrimary}
                      />
                    </div>
                  </div>
                </Form.Item>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <strong>
                            {this.props.t("companyadmin-design-logo-label")}:
                          </strong>
                        </div>
                      }
                      name="logo"
                      style={{ width: "100%" }}
                    >
                      <ImgCrop
                        ref={this.logoCropper}
                        cropperProps={{
                          onCropAreaChange: ( cropArea, croppedAreaPixels ) => {
                            this.setState( { logoCropData: { ...this.state.logoCropData, rotation: 0, cropData: croppedAreaPixels } } )
                          }
                        }}
                        zoom
                        shape="rect"
                        grid
                        aspect={16 / 9}
                        modalOk={this.props.t("save-button-label")}
                        modalCancel={this.props.t("cancel-button-label")}
                        modalTitle={this.props.t(
                          "companyadmin-design-cropimage-title"
                        )}
                      >
                        <Upload
                          name="logo"
                          fileList={this.state.logo}
                          showUploadList={true}
                          onRemove={this.removeFileLogo}
                          accept=".png,.jpg,.jpeg,.gif"
                          onPreview={this.onPreview}
                          beforeUpload={ () => false }
                          onChange={ async ( file ) => {
                            this.setState( { logoCropData: { ...this.state.logoCropData, image: file.file } } )
                            await this.cropImage( this.state.logoCropData )
                            this.setState( { logo: [ new File( [ this.props.image ], this.getFileName( this.props.image.type ), { type: this.props.image.type } ) ] } )
                          } }
                        >
                          <Button icon={<UploadOutlined />}>
                            {this.props.t("companyadmin-design-upload")}
                          </Button>
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      style={{ width: "100%", margin: "0 auto" }}
                      label={
                        <div>
                          <strong>
                            {this.props.t(
                              "companyadmin-design-logo-background"
                            )}
                            :
                          </strong>{" "}
                          <Tooltip
                            title={this.props.t(
                              "wizard-design-background-tooltip"
                            )}
                            placement="right"
                          >
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </div>
                      }
                      name="background"
                    >
                      <ImgCrop
                        ref={this.backgroundCropper}
                        cropperProps={{
                          onCropAreaChange: ( cropArea, croppedAreaPixels ) => {
                            this.setState( { backgroundCropData: { ...this.state.backgroundCropData, rotation: 0, cropData: croppedAreaPixels } } )
                          }
                        }}
                        zoom
                        shape="rect"
                        grid
                        aspect={16 / 9}
                        modalOk={this.props.t("save-button-label")}
                        modalCancel={this.props.t("cancel-button-label")}
                        modalTitle={this.props.t(
                          "companyadmin-design-cropimage-title"
                        )}
                      >
                        <Upload
                          name="background"
                          fileList={this.state.background}
                          showUploadList={true}
                          onRemove={this.removeFileBackground}
                          accept=".png,.jpg,.jpeg,.gif"
                          beforeUpload={ () => false }
                          onChange={ async ( file ) => {
                            this.setState( { backgroundCropData: { ...this.state.backgroundCropData, image: file.file } } )
                            await this.cropImage( this.state.backgroundCropData )
                            this.setState( { background: [ new File( [ this.props.image ], this.getFileName( this.props.image.type ), { type: this.props.image.type } ) ] } )
                          } }
                        >
                          <Button icon={<UploadOutlined />}>
                            {this.props.t("companyadmin-design-upload")}
                          </Button>
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                  </Col>
                </Row>
                <div style={{ color: "red" }}>
                  {this.props.t("companyadmin-design-maxsize")}
                </div>
                <Form.Item
                  label={
                    <div>
                      <strong>
                        {this.props.t(
                          "companyadmin-languages-welcometitle-label"
                        )}
                      </strong>{" "}
                      <Tooltip
                        title={this.props.t(
                          "wizard-design-welcometitle-tooltip"
                        )}
                        placement="right"
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </div>
                  }
                  name="welcomeTitle"
                  initialValue={this.state.welcomeTitle}
                  rules={[
                    {
                      required: true,
                      message: this.props.t("wizard-design-welcometitle-text"),
                    },
                    {
                      max: 80,
                      message: this.props.t("wizard-design-welcometitle-max"),
                    },
                  ]}
                >
                  <Input.TextArea
                    name="welcomeTitle"
                    onChange={this.handleChange}
                    value={this.state.welcomeTitle}
                    rows={2}
                    maxLength={80}
                  />
                  <div>{this.state.welcomeTitle.length}/80</div>
                </Form.Item>
                <Form.Item
                  label={
                    <div>
                      <strong>
                        {this.props.t(
                          "companyadmin-languages-welcometext-label"
                        )}
                      </strong>{" "}
                      <Tooltip
                        title={this.props.t(
                          "wizard-design-welcometext-tooltip"
                        )}
                        placement="right"
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </div>
                  }
                  name="welcomeText"
                  initialValue={this.state.welcomeText}
                  rules={[
                    {
                      required: true,
                      message: this.props.t("wizard-design-welcometext-text"),
                    },
                    {
                      max: 150,
                      message: this.props.t("wizard-design-welcometext-max"),
                    },
                  ]}
                >
                  <Input.TextArea
                    name="welcomeText"
                    onChange={this.handleChange}
                    value={this.state.welcomeText}
                    rows={5}
                    maxLength={150}
                  />
                  <div>{this.state.welcomeText.length}/150</div>
                </Form.Item>
              </Form>
            </Col>
            <Col span={18}>
              <div>
                <AppPreview
                  screenWidth="700px"
                  screenHeight="525px"
                  width="770px"
                  height="579px"
                  bottom="54px"
                  flagpaddings={
                    this.state.browser === "safari"
                      ? ["61px 5px 15px", "15px 5px 15px", "15px 5px 60px"]
                      : ["61px 5px 15px", "15px 5px 15px", "15px 5px 61px"]
                  }
                  paddingBottoms={
                    this.state.browser === "safari"
                      ? ["49px", "35px"]
                      : ["48px", "34px"]
                  }
                  background={
                    this.state.background.length > 0
                      ? URL.createObjectURL(this.state.background[0])
                      : `${process.env.REACT_APP_BASE_URL}/api/1.0.0/company/background/${this.props.background}`
                  }
                  logo={
                    this.state.logo.length > 0
                      ? URL.createObjectURL(this.state.logo[0])
                      : `${process.env.REACT_APP_BASE_URL}/api/1.0.0/company/logo/${this.props.logoName}`
                  }
                  defaultLanguageCode={
                    this.props.company[0]?.defaultLanguage.code
                  }
                  backgroundColor={this.state.primaryColor}
                  welcomeTitle={this.state.welcomeTitle}
                  welcomeText={this.state.welcomeText}
                />
              </div>
              <div style={{ textAlign: "center", marginTop: "15px" }}>
                <Button
                  type="link"
                  style={{
                    color: "#000000",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                  }}
                  onClick={this.resetValues}
                >
                  <u>{this.props.t("reset-design-button-label")}</u>
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <div>
                {this.props.wizard && (
                  <Button style={{ margin: "1em" }} onClick={this.props.prev}>
                    {this.props.t("back-button-label")}
                  </Button>
                )}
                <Button
                  onClick={this.handleSubmit}
                  type="primary"
                  style={{ margin: "1em" }}
                >
                  {this.props.t("next-button-label")}
                </Button>
              </div>
            </Col>
          </Row>
          <Modal
            maskClosable={false}
            closable={false}
            footer={null}
            visible={this.state.endModalVisible}
            centered
            bodyStyle={{
              backgroundImage: `url("${jolnezki}")`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
              height: "600px",
            }}
            width={900}
          >
            <WizardBeforeImport
              closePopup={this.closePopupBeforeImport}
              switchToExcelImport={this.switchToTemplateDownload}
              switchToNextPage={this.switchToNextPage}
            />
          </Modal>
          <Modal
            maskClosable={false}
            closable={true}
            footer={null}
            visible={this.state.downloadTemplateVisible}
            centered
            onCancel={this.handleBackFromTemplate}
          >
            <WizardDownloadTemplate
              next={this.switchToExcelImport}
              prev={this.handleBackFromTemplate}
            />
          </Modal>
          <Modal
            maskClosable={false}
            closable={true}
            footer={null}
            visible={this.state.excelImportVisible}
            centered
            onCancel={this.handleBackFromExcelImport}
          >
            <WizardExcelImport
              next={this.props.next}
              nextVisible={true}
              back={this.handleBackFromExcelImport}
            />
          </Modal>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  saveStatus: state.companyReducer.saveStatus,
  user: state.loginReducer.user,
  company: state.companyReducer.company,
  status: state.companyReducer.status,
  image: state.companyReducer.image
});
const WizardDesign = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedWizardDesign)
);
export default WizardDesign;
