import { Button, Transfer } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { SaveOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";

const mapDispatchToProps = (dispatch) => {
  return {};
};

class ConnectedUpdateSetOfUsersModal extends Component {
  formRef = React.createRef();

  state = {
    modalVisible: this.props.modalVisible || false,
    allUsers: [],
    targetKeys: [],
    selectedKeys: [],
    addressId: null,
  };

  componentDidMount = async () => {
    this.setState({
      modalVisible: this.props.modalVisible,
    });
  };

  componentDidUpdate = () => {
    if (this.props.modalVisible !== this.state.modalVisible) {
      this.setState({
        targetKeys: this.props.addeddUserIds,
        allUsers: this.props.allUsers,
        modalVisible: this.props.modalVisible,
        addressId: this.props.addressId,
      });
    }
  };

  handleClose = () => {
    this.setState({
      modalVisible: false,
      addeddUserIds: [],
      allUsers: [],
      targetKeys: [],
      selectedKeys: [],
      addressId: null,
    });
    this.props.handleCloseUpdateSetOfUsersModal();
  };

  handleSaveSelectedUsers = async () => {
    const params = {
      userIds: this.state.targetKeys,
      addressId: this.state.addressId,
    };
    await this.props.handleSaveSelectedUsers(params);
    this.handleClose();
  };

  handleChangeTarget = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  render() {
    return (
      <>
        <Modal
          maskClosable={false}
          closable={true}
          footer={[
            <Button key="handleCloseButton" onClick={this.handleClose}>
              {this.props.t("cancel-button-label")}
            </Button>,
            <Button
              key="handleSaveSelectedUsersButton"
              onClick={this.handleSaveSelectedUsers}
              type="primary"
            >
              <SaveOutlined /> {this.props.t("save-button-label")}
            </Button>,
          ]}
          visible={this.state.modalVisible}
          centered
          bodyStyle={{
            textAlign: "center",
            margin: "0 auto",
          }}
          width={1100}
          title={
            <h3 style={{ padding: 0, margin: 0 }}>
              <strong>{this.props.t("add-user-to-address-title")}</strong>
            </h3>
          }
          onCancel={this.handleClose}
          destroyOnClose
        >
          <div>
            <Transfer
              style={{ textAlign: "left" }}
              dataSource={this.state.allUsers}
              render={(item) =>
                `${item.lastName} ${item.firstName} (${item.email}) - ${item.company.name}`
              }
              targetKeys={this.state.targetKeys}
              selectedKeys={this.state.selectedKeys}
              showSearch
              onChange={this.handleChangeTarget}
              onSelectChange={this.handleSelectChange}
              rowKey={(record) => record.id}
              selectAllLabels={[<div></div>, <div></div>]}
              locale={{
                notFoundContent: this.props.t("empty-text"),
                selectAll: this.props.t("select-all-label"),
                selectInvert: this.props.t("select-invert-label"),
                searchPlaceholder: this.props.t("search-button-label"),
              }}
              listStyle={{
                width: 500,
                height: 300,
              }}
            />
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
});
const UpdateSetOfUsersModal = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedUpdateSetOfUsersModal)
);
export default UpdateSetOfUsersModal;
