export const checkIfUserHasRoles = (user, roles, notAllowedSubscriptions) => {
  let allowed = false;
  user.groups.forEach((group) => {
    if (roles.includes(group.id)) {
      allowed = true;
    }
  });
  if (allowed === true && notAllowedSubscriptions === undefined) {
    return true;
  } else {
    if (allowed === true) {
      if (this.allowedBySubscription(user, notAllowedSubscriptions)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
};

export const allowedBySubscription = (user, notAllowedSubscriptions) => {
  if (user.companyId === null) {
    return false;
  } else {
    if (
      notAllowedSubscriptions !== undefined &&
      notAllowedSubscriptions !== null
    ) {
      if (notAllowedSubscriptions.includes(user.company.subscriptionId)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
};
