import React, { Component } from "react";
import { Button, Input, message, Form } from "antd";
import { UserOutlined } from "@ant-design/icons";
import "./login.scss";
import axios from "axios";
import { withTranslation } from "react-i18next";

class ConnectedForgotPassword extends Component {
  formRef = React.createRef();

  state = {
    email: "",
    buttonDisable: false,
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleFormSubmit = async () => {
    this.setState({ buttonDisable: true });
    await axios
      .post("/1.0.0/users/forgot-password", {
        email: this.state.email,
      })
      .then((response) => {
        message.success(this.props.t("forgot-password-success"), 5);
        this.formRef.current.resetFields();
        this.setState({ buttonDisable: false, email: "" });
      })
      .catch((err) => {
        message.error(this.props.t("forgot-password-error"), 5);
        this.formRef.current.resetFields();
        this.setState({ email: "", buttonDisable: false });
      });
  };
  render() {
    return (
      <div>
        <div className="login-page-container">
          <Form
            name="normal_login"
            className="login-form"
            onFinish={this.handleFormSubmit}
            ref={this.formRef}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: this.props.t("email-empty"),
                },
                {
                  type: "email",
                  message: this.props.t("email-wrong-format"),
                },
              ]}
            >
              <Input
                prefix={
                  <UserOutlined
                    className="site-form-item-icon"
                    style={{ color: "#00b7ae" }}
                  />
                }
                placeholder={this.props.t("email-label")}
                name="email"
                autoComplete="off"
                value={this.state.email || ""}
                onChange={this.handleChange}
              />
            </Form.Item>
            <div style={{ padding: "0 1em 2em 1em", textAlign: "center" }}>
              {this.props.t("forgot-password-text")}
            </div>
            <Form.Item style={{ width: "100%" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ width: "100%" }}
                disabled={this.state.buttonDisable}
              >
                {this.props.t("forgot-password-button-label")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

const ForgotpassWord = withTranslation()(ConnectedForgotPassword);
export default ForgotpassWord;
