import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import RoomObject from "../../components/Building/RoomObject/roomObject";
import { userRoles } from "../../constants/roles-types";
import { checkIfUserHasRoles } from "../../common/auth.common";
import CreateOrUpdateRoomModal from "../../components/Rooms/CreateOrUpdateRoomModal/createOrUpdateRoomModal";
import CreateOrUpdateDoorModal from "../../components/Building/CreateOrUpdateDoorModal/createOrUpdateDoorModal";
import DoorObject from "../../components/Building/DoorObject/doorObject";
import { UncontrolledReactSVGPanZoom } from "react-svg-pan-zoom";

const svgPanZoomWidth = 1200;
const svgPanZoomHeight = 700;
const svgWidth = 5000;
const svgHeight = 5000;
const svgBoundary = 20;

const doorTypes = [
  {
    type: 1,
    name: "door",
    dValue:
      "M184.646,0v21.72H99.704v433.358h31.403V53.123h53.539V492.5l208.15-37.422v-61.235V37.5L184.646,0z M222.938,263.129 c-6.997,0-12.67-7.381-12.67-16.486c0-9.104,5.673-16.485,12.67-16.485s12.67,7.381,12.67,16.485 C235.608,255.748,229.935,263.129,222.938,263.129z",
    dScale: 0.1,
  },
  {
    type: 2,
    name: "turnstile",
    dValue:
      "M16 4C15.45 4 15 4.45 15 5L15 10C15 10.55 15.45 11 16 11L20.550781 11L29 20.380859L29 39.310547L21.650391 42.060547C21.260391 42.210547 21 42.58 21 43L21 45C21 45.55 21.45 46 22 46L39 46C39.55 46 40 45.55 40 45L40 5C40 4.45 39.55 4 39 4L16 4 z M 30 10L34 10C34.55 10 35 10.45 35 11C35 11.55 34.55 12 34 12L30 12C29.45 12 29 11.55 29 11C29 10.45 29.45 10 30 10 z M 19.169922 12.460938C18.619987 13.196661 18.242254 14.059479 18.083984 15L6 15C5.447 15 5 15.448 5 16C5 16.552 5.447 17 6 17L18.089844 17C18.243213 17.910076 18.598561 18.750247 19.113281 19.472656L11.292969 27.292969C10.901969 27.683969 10.901969 28.316031 11.292969 28.707031C11.487969 28.902031 11.744 29 12 29C12.256 29 12.512031 28.902031 12.707031 28.707031L20.527344 20.886719C21.249753 21.401439 22.089924 21.756787 23 21.910156L23 34C23 34.552 23.447 35 24 35C24.553 35 25 34.552 25 34L25 21.90625C25.726484 21.783178 26.412493 21.538392 27.019531 21.179688L19.169922 12.460938 z",
    dScale: 1.1,
  },
];
const roomMinWidth = 120;
const roomMinHeight = 80;
const roomMaxWidth = 800;
const roomMaxHeight = 800;

const mapDispatchToProps = (dispatch) => {
  return {};
};

class ConnectedBuildingLayoutPage extends Component {
  state = {
    svgPanZoomWidth: window.innerWidth > 768 ? 1200 : window.innerWidth - 50, // Example responsive logic
    svgPanZoomHeight: 700,
    floors: [
      {
        id: 1,
        label: "Floor 1",
        rooms: [
          {
            id: 1,
            x: 50,
            y: 50,
            width: 120,
            height: 80,
            name: "Room 1",
            isPublic: true,
            type: 2,
            floor: 0,
          },
          {
            id: 2,
            x: 200,
            y: 200,
            width: 120,
            height: 80,
            name: "Room 2",
            isPublic: false,
            type: 1,
            floor: 0,
          },
          {
            id: 3,
            x: 450,
            y: 200,
            width: 120,
            height: 80,
            name: "Room 3",
            isPublic: false,
            type: 1,
            floor: 0,
          },
          {
            id: 4,
            x: 450,
            y: 400,
            width: 120,
            height: 80,
            name: "Room 4",
            isPublic: false,
            type: 3,
            floor: 0,
          },
        ],
        doors: [
          {
            id: 1,
            name: "A22",
            type: 1,
            floor: 0,
            sensors: [
              {
                id: 1,
                epId: "asd123",
                roomId: 1,
                name: "Room 1 Ki",
                isSideA: true,
                rfidString: "randomstring1",
              },
              {
                id: 2,
                epId: "asd123",
                roomId: 2,
                name: "Room 1 Be",
                isSideA: false,
                rfidString: "randomstring2",
              },
            ],
            x: 75,
            y: 200,
          },
          {
            id: 2,
            name: "B30",
            type: 1,
            floor: 0,
            sensors: [
              {
                id: 3,
                epId: "asd123",
                roomId: 2,
                name: "Room 2 Ki",
                isSideA: true,
                rfidString: "randomstring3",
              },
              {
                id: 4,
                epId: "asd123",
                roomId: 3,
                name: "Room 3 Be",
                isSideA: false,
                rfidString: "randomstring4",
              },
            ],
            x: 350,
            y: 200,
          },
          {
            id: 3,
            type: 2,
            name: "B40",
            floor: 0,
            sensors: [
              {
                id: 5,
                epId: "asd123",
                roomId: 3,
                name: "Room 3 Ki",
                isSideA: true,
                rfidString: "randomstring5",
              },
              {
                id: 6,
                epId: "asd123",
                roomId: null,
                name: "Room 3 Be",
                isSideA: false,
                rfidString: "randomstring6",
              },
            ],
            x: 450,
            y: 300,
          },
        ],
      },
      {
        id: 2,
        label: "Floor 2",
        rooms: [
          {
            id: 1,
            x: 100,
            y: 50,
            width: 120,
            height: 80,
            name: "Room B1",
            isPublic: false,
            type: 2,
            floor: 1,
          },
          {
            id: 2,
            x: 200,
            y: 200,
            width: 120,
            height: 80,
            name: "Room B2",
            isPublic: true,
            type: 1,
            floor: 1,
          },
          {
            id: 3,
            x: 450,
            y: 200,
            width: 120,
            height: 80,
            name: "Room B3",
            isPublic: true,
            type: 1,
            floor: 1,
          },
        ],
        doors: [
          {
            id: 1,
            name: "A22",
            type: 1,
            floor: 1,
            sensors: [
              {
                roomId: 1,
                id: 7,
                epId: "asd123",
                name: "Room 1 Ki",
                isSideA: true,
                rfidString: "randomstring7",
              },
              {
                roomId: 2,
                id: 8,
                epId: "asd123",
                name: "Room 1 Be",
                isSideA: false,
                rfidString: "randomstring8",
              },
            ],
            x: 75,
            y: 200,
          },
          {
            id: 2,
            name: "B30",
            type: 1,
            floor: 1,
            sensors: [
              {
                roomId: 2,
                id: 10,
                epId: "asd123",
                name: "Room 2 Ki",
                isSideA: true,
                rfidString: "randomstring9",
              },
              {
                roomId: 3,
                id: 11,
                epId: "asd123",
                name: "Room 3 Be",
                isSideA: false,
                rfidString: "randomstring10",
              },
            ],
            x: 350,
            y: 200,
          },
          {
            id: 3,
            type: 2,
            name: "B40",
            floor: 1,
            sensors: [
              {
                id: 12,
                epId: "asd123",
                roomId: 3,
                name: "Room 3 Ki",
                isSideA: true,
                rfidString: "randomstring11",
              },
              {
                id: 13,
                epId: "asd123",
                roomId: null,
                name: "Room 3 Ki",
                isSideA: false,
                rfidString: "randomstring12",
              },
            ],
            x: 450,
            y: 300,
          },
        ],
      },
    ],
    selectedFloor: 1, // Initially select the first floor
    dragDelta: { x: 0, y: 0 }, // To track the delta when dragging
    resizing: false,
    resizingStartPos: { x: 0, y: 0 },
    resizingStartSize: { width: 0, height: 0 },
    resizingRoomIndex: -1,
    lastMousePos: { x: 0, y: 0 }, // Initialize last mouse position    ,
    roomModalVisible: false,
    doorModalVisible: false,
    modalMode: "create",
    selectedRoom: null,
    selectedDoor: null,
    selectedSensorIdForConnect: null,
    doorTypes: [
      { id: 1, name: "door" },
      { id: 2, name: "turnstile" },
      { id: 3, name: "gate" },
      { id: 4, name: "barrier" },
    ],
  };

  componentDidMount = async () => {
    this.setState({ resizingStartPos: { x: 0, y: 0 } });
    this.setState({ resizingStartSize: { width: 0, height: 0 } });
  };

  openCreateRoomModal = () => {
    this.setState({ roomModalVisible: true, modalMode: "create" });
  };

  handleCancelRoomModal = () => {
    this.setState({ roomModalVisible: false, selectedRoom: null });
  };

  openUpdateRoomModal = (record) => {
    if (record) {
      this.setState({
        roomModalVisible: true,
        modalMode: "update",
        selectedRoom: record,
      });
    }
  };

  openCreateDoorModal = () => {
    this.setState({ doorModalVisible: true, modalMode: "create" });
  };

  handleCancelDoorModal = () => {
    this.setState({ doorModalVisible: false, selectedDoor: null });
  };

  openUpdateDoorModal = (record) => {
    if (record) {
      this.setState({
        doorModalVisible: true,
        modalMode: "update",
        selectedDoor: record,
      });
    }
  };

  createRoom = async () => {
    //TODO
    console.log("create room");
  };

  updateRoom = async () => {
    //TODO
    console.log("update room");
  };

  createDoor = async () => {
    //TODO
    console.log("create door");
  };

  updateDoor = async () => {
    //TODO
    console.log("update door");
  };

  handleDragStart = (e, ui) => {
    // Check if the event and its target are defined
    // Calculate the delta between initial position and new position of the SVG container
    const { x, y } = this.state.dragDelta;
    this.setState({
      dragDelta: {
        x: ui.x - x,
        y: ui.y - y,
      },
    });
  };

  handleDrag = (e, ui) => {
    // Update the drag delta
    this.setState({
      dragDelta: {
        x: ui.x,
        y: ui.y,
      },
    });
  };

  handleRoomDrag = (floorIndex, roomIndex, deltaX, deltaY) => {
    const updatedFloors = [...this.state.floors];
    const updatedRooms = [...updatedFloors[floorIndex].rooms];
    updatedRooms[roomIndex] = {
      ...updatedRooms[roomIndex],
      x: updatedRooms[roomIndex].x + deltaX,
      y: updatedRooms[roomIndex].y + deltaY,
    };
    updatedFloors[floorIndex] = {
      ...updatedFloors[floorIndex],
      rooms: updatedRooms,
    };
    this.setState({ floors: updatedFloors });
  };

  handleDoorDrag = (floorIndex, doorIndex, deltaX, deltaY) => {
    const updatedFloors = [...this.state.floors];
    const updatedDoors = [...updatedFloors[floorIndex].doors];
    updatedDoors[doorIndex] = {
      ...updatedDoors[doorIndex],
      x: updatedDoors[doorIndex].x + deltaX,
      y: updatedDoors[doorIndex].y + deltaY,
    };
    updatedFloors[floorIndex] = {
      ...updatedFloors[floorIndex],
      doors: updatedDoors,
    };
    this.setState({ floors: updatedFloors });
  };

  handleAddNewRoom = () => {
    const updatedFloors = [...this.state.floors];
    const selectedFloorIndex = this.state.selectedFloor - 1;
    const updatedRooms = [...updatedFloors[selectedFloorIndex].rooms];
    updatedRooms.push({
      id: updatedRooms.length + 1, // Generate a unique ID for the new room
      x: 20,
      y: 20,
      width: roomMinWidth,
      height: roomMinHeight,
      color: "red",
      name: `Room ${updatedRooms.length + 1}`,
      isPublic: true,
      type: 1,
      floor: selectedFloorIndex,
    });
    updatedFloors[selectedFloorIndex] = {
      ...updatedFloors[selectedFloorIndex],
      rooms: updatedRooms,
    };
    this.setState({ floors: updatedFloors });
  };

  handleAddNewDoor = () => {
    const updatedFloors = [...this.state.floors];
    const selectedFloorIndex = this.state.selectedFloor - 1;
    const updatedDoors = [...updatedFloors[selectedFloorIndex].doors];

    // Creating two sample sensors for the new door
    const sampleSensors = [
      {
        id: Date.now(),
        epId: "sensor1_epId",
        roomId: null,
        name: "Sensor 1",
        isSideA: true,
        rfidString: "sensor1_rfid",
      },
      {
        id: Date.now() + 1,
        epId: "sensor2_epId",
        roomId: null,
        name: "Sensor 2",
        isSideA: false,
        rfidString: "sensor2_rfid",
      },
    ];

    const newDoor = {
      id: updatedDoors.reduce((maxId, door) => Math.max(door.id, maxId), 0) + 1,
      name: `Door ${updatedDoors.length + 1}`,
      type: 1,
      floor: selectedFloorIndex,
      x: 20,
      y: 20,
      sensors: sampleSensors,
    };

    updatedDoors.push(newDoor);
    updatedFloors[selectedFloorIndex] = {
      ...updatedFloors[selectedFloorIndex],
      doors: updatedDoors,
    };
    this.setState({ floors: updatedFloors });
  };

  handleFloorChange = (e) => {
    const selectedFloor = parseInt(e.target.value);
    this.setState({ selectedFloor });
  };

  //resize
  handleRoomResizeStart = (event, index) => {
    event.stopPropagation();

    const updatedFloors = [...this.state.floors];
    const selectedFloorIndex = this.state.selectedFloor - 1;
    const updatedRooms = [...updatedFloors[selectedFloorIndex].rooms];
    const room = updatedRooms[index];

    this.setState({
      resizing: true,
      resizingStartPos: {
        x: event.clientX,
        y: event.clientY,
      },
      resizingStartSize: {
        width: room.width,
        height: room.height,
      },
      resizingRoomIndex: index,
    });

    window.addEventListener("mousemove", this.handleRoomResize);
    window.addEventListener("mouseup", this.handleRoomResizeEnd);
  };

  handleRoomResizeEnd = () => {
    this.setState({
      resizing: false,
      resizingRoomIndex: -1,
    });

    window.removeEventListener("mousemove", this.handleRoomResize);
    window.removeEventListener("mouseup", this.handleRoomResizeEnd);
  };

  handleRoomResize = (event) => {
    const { resizingStartPos, resizingRoomIndex, lastMousePos } = this.state;
    if (resizingRoomIndex !== -1) {
      const { clientX, clientY } = event;
      const deltaX = clientX - resizingStartPos.x;
      const deltaY = clientY - resizingStartPos.y;
      const mouseMoved =
        lastMousePos.x !== clientX || lastMousePos.y !== clientY;

      if (mouseMoved) {
        const updatedFloors = [...this.state.floors];
        const selectedFloorIndex = this.state.selectedFloor - 1;
        const updatedRooms = [...updatedFloors[selectedFloorIndex].rooms];
        const room = updatedRooms[resizingRoomIndex];

        // Calculate the change in width and height based on mouse movement
        const newWidth = Math.min(
          Math.max(this.state.resizingStartSize.width + deltaX, roomMinWidth),
          roomMaxWidth
        );
        const newHeight = Math.min(
          Math.max(this.state.resizingStartSize.height + deltaY, roomMinHeight),
          roomMaxHeight
        );

        // Update the room's width and height
        updatedRooms[resizingRoomIndex] = {
          ...room,
          width: newWidth,
          height: newHeight,
        };
        updatedFloors[selectedFloorIndex] = {
          ...updatedFloors[selectedFloorIndex],
          rooms: updatedRooms,
        };
        this.setState({
          floors: updatedFloors,
          lastMousePos: { x: clientX, y: clientY }, // Update last mouse position
        });
      }
    }
  };

  //room connect
  handleStartConnectSensor = (sensorId) => {
    this.setState({ selectedSensorIdForConnect: sensorId });
  };

  handleCancelConnectSensor = () => {
    this.setState({ selectedSensorIdForConnect: null });
  };

  handleFinishConnectSensor = (selectedRoomId) => {
    if (this.state.selectedSensorIdForConnect) {
      const updatedFloors = [...this.state.floors];

      updatedFloors.forEach((floor) => {
        floor.doors.forEach((door) => {
          const sensor = door.sensors.find(
            (sensor) => sensor.id === this.state.selectedSensorIdForConnect
          );
          if (sensor) {
            sensor.roomId = selectedRoomId;
          }
        });
      });

      this.setState({
        floors: updatedFloors,
        selectedSensorIdForConnect: null,
      });
    }
  };

  render() {
    const { dragDelta, floors, selectedFloor, resizing } = this.state;
    const selectedFloorIndex = selectedFloor - 1;
    const currentFloor = floors[selectedFloorIndex];
    return (
      <>
        <div>
          <label>Select Floor:</label>
          <select value={selectedFloor} onChange={this.handleFloorChange}>
            {floors.map((floor) => (
              <option key={floor.id} value={floor.id}>
                {floor.label}
              </option>
            ))}
          </select>
        </div>
        <button onClick={() => this.handleAddNewRoom()}>Add new room</button>
        <button onClick={() => this.handleAddNewDoor()}>Add new door</button>
        <UncontrolledReactSVGPanZoom
          width={svgPanZoomWidth}
          height={svgPanZoomHeight}
          background="#F0F2F5"
          SVGBackground="#fff"
          className="svg-container"
          miniatureProps={{}}
          detectAutoPan={false}
          scaleFactorMin={0.2}
          scaleFactorMax={2}
        >
          <svg
            width={svgWidth}
            height={svgHeight}
            onDragStart={this.handleDragStart}
            onDrag={this.handleDrag}
            onMouseMove={resizing ? this.handleRoomResize : null}
            onMouseUp={resizing ? this.handleRoomResizeEnd : null}
          >
            {/* Render Rooms */}
            {currentFloor.rooms.map((room, index) => (
              <RoomObject
                key={room.id}
                index={index}
                room={room}
                handleRoomDrag={this.handleRoomDrag}
                handleRoomResizeStart={this.handleRoomResizeStart}
                svgWidth={svgWidth}
                svgHeight={svgHeight}
                svgBoundary={svgBoundary}
                selectedFloorIndex={selectedFloorIndex}
                dragDelta={dragDelta}
                openUpdateModal={this.openUpdateRoomModal}
                isConnectionInProgress={
                  this.state.selectedSensorIdForConnect ? true : false
                }
                handleFinishConnectSensor={this.handleFinishConnectSensor}
              />
            ))}

            {/* Render Doors */}
            {currentFloor.doors.map((door, index) => (
              <DoorObject
                key={door.id}
                index={index}
                door={door}
                doorTypes={doorTypes}
                handleDoorDrag={this.handleDoorDrag}
                svgWidth={svgWidth}
                svgHeight={svgHeight}
                svgBoundary={svgBoundary}
                roomMinWidth={roomMinWidth}
                roomMinHeight={roomMinHeight}
                selectedFloorIndex={selectedFloorIndex}
                dragDelta={dragDelta}
                openUpdateModal={this.openUpdateDoorModal}
                selectedSensorIdForConnect={
                  this.state.selectedSensorIdForConnect
                }
                handleStartConnectSensor={this.handleStartConnectSensor}
              />
            ))}

            {/* Connect Doors to Sensors */}
            {currentFloor.doors.map((door) => {
              return door.sensors.map((sensor) => {
                const sensorRoom = currentFloor.rooms.find(
                  (room) => room.id === sensor.roomId
                );
                if (!sensorRoom) return null; // Skip if sensor room is not found
                return (
                  <line
                    key={`connection-${sensor.id}`}
                    x1={door.x + 25}
                    y1={door.y + 10}
                    x2={sensorRoom.x + sensorRoom.width / 2}
                    y2={sensorRoom.y + sensorRoom.height / 2}
                    stroke={sensor.isSideA ? "red" : "green"}
                    strokeWidth="2"
                    strokeOpacity="0.4"
                    style={{ cursor: "pointer" }}
                  />
                );
              });
            })}
          </svg>
        </UncontrolledReactSVGPanZoom>
        {checkIfUserHasRoles(this.props.user, [
          userRoles.SYSTEM_ADMIN,
          userRoles.OFFICE_ADMIN,
        ]) && (
          <>
            <CreateOrUpdateRoomModal
              modalVisible={this.state.roomModalVisible}
              modalMode={this.state.modalMode}
              user={this.props.user}
              handleCancelModal={this.handleCancelRoomModal}
              selectedRoom={this.state.selectedRoom}
              createRoom={this.createRoom}
              updateRoom={this.updateRoom}
            />
            <CreateOrUpdateDoorModal
              modalVisible={this.state.doorModalVisible}
              modalMode={this.state.modalMode}
              user={this.props.user}
              doorTypes={this.state.doorTypes}
              handleCancelModal={this.handleCancelDoorModal}
              selectedDoor={this.state.selectedDoor}
              createDoor={this.createDoor}
              updateDoor={this.updateDoor}
              selectedSensorIdForConnect={this.state.selectedSensorIdForConnect}
              handleStartConnectSensor={this.handleStartConnectSensor}
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
});
const BuildingLayoutPage = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedBuildingLayoutPage)
);

export default BuildingLayoutPage;
