import { USERS_ACTIONS } from "../constants/action-types";

const initialState = {
  users: [],
  workingUsers: [],
  status: false,
  message: "",
  groups: [],
  saveStatus: false,
  importErrors: {},
  emailExists: null,
  hosts: [],
  colleagues: []
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_ACTIONS.USERS_LOADING:
      return Object.assign({}, state, {
        users: [],
        status: false,
        message: "",
      });
    case USERS_ACTIONS.USERS_LOADED:
      return Object.assign({}, state, {
        users: action.payload.users,
        status: true,
        message: "",
      });
    case USERS_ACTIONS.USERS_ERROR:
      return Object.assign({}, state, {
        users: [],
        status: false,
        message: action.payload,
      });
    case USERS_ACTIONS.WORKING_USERS_LOADING:
      return Object.assign({}, state, {
        workingUsers: [],
        status: false,
        message: "",
      });
    case USERS_ACTIONS.WORKING_USERS_LOADED:
      return Object.assign({}, state, {
        workingUsers: action.payload.users,
        status: true,
        message: "",
      });
    case USERS_ACTIONS.WORKING_USERS_ERROR:
      return Object.assign({}, state, {
        workingUsers: [],
        status: false,
        message: action.payload,
      });
    case USERS_ACTIONS.GROUPS_LOADING:
      return Object.assign({}, state, {
        groups: [],
        status: false,
        message: "",
      });
    case USERS_ACTIONS.GROUPS_LOADED:
      return Object.assign({}, state, {
        groups: action.payload.groups,
        status: true,
        message: "",
      });
    case USERS_ACTIONS.GROUPS_ERROR:
      return Object.assign({}, state, {
        groups: [],
        status: false,
        message: action.payload,
      });
    case USERS_ACTIONS.USER_REGISTER_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case USERS_ACTIONS.USER_REGISTER_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case USERS_ACTIONS.USER_REGISTER_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case USERS_ACTIONS.USER_IMPORT_ERRORS_ADD:
      return Object.assign({}, state, {
        importErrors: action.payload,
      });
    case USERS_ACTIONS.USER_IMPORT_ERRORS_EMPTY:
      return Object.assign({}, state, {
        importErrors: {},
      });
    case USERS_ACTIONS.USERS_EMAIL_EXISTS_LOADING:
      return Object.assign({}, state, {
        emailExists: null,
        status: false,
        message: "",
      });
    case USERS_ACTIONS.USERS_EMAIL_EXISTS_LOADED:
      return Object.assign({}, state, {
        emailExists: action.payload.emailExists,
        status: true,
        message: "",
      });
    case USERS_ACTIONS.USERS_EMAIL_EXISTS_ERROR:
      return Object.assign({}, state, {
        emailExists: null,
        status: false,
        message: action.payload,
      });
    case USERS_ACTIONS.HOSTS_LOADING:
      return Object.assign({}, state, {
        hosts: [],
        status: false,
        message: "",
      });
    case USERS_ACTIONS.HOSTS_LOADED:
      return Object.assign({}, state, {
        hosts: action.payload,
        status: true,
        message: "",
      });
    case USERS_ACTIONS.HOSTS_ERROR:
      return Object.assign({}, state, {
        hosts: [],
        status: false,
        message: action.payload,
      });
    case USERS_ACTIONS.COLLEAGUES_LOADING:
      return Object.assign({}, state, {
        colleagues: [],
        status: false,
        message: "",
      });
    case USERS_ACTIONS.COLLEAGUES_LOADED:
      return Object.assign({}, state, {
        colleagues: action.payload,
        status: true,
        message: "",
      });
    case USERS_ACTIONS.COLLEAGUES_ERROR:
      return Object.assign({}, state, {
        colleagues: [],
        status: false,
        message: action.payload,
      });
    case USERS_ACTIONS.USERS_VEHICLE_LOADING:
      return Object.assign({}, state, {
        users: [],
        status: false,
        message: "",
      });
    case USERS_ACTIONS.USERS_VEHICLE_LOADED:
      return Object.assign({}, state, {
        users: action.payload,
        status: true,
        message: "",
      });
    case USERS_ACTIONS.USERS_VEHICLE_ERROR:
      return Object.assign({}, state, {
        users: [],
        status: false,
        message: action.payload,
      });

    case USERS_ACTIONS.USERS_BY_OFFICE_LOADING:
      return Object.assign({}, state, {
        users: [],
        status: false,
        message: "",
      });
    case USERS_ACTIONS.USERS_BY_OFFICE_LOADED:
      return Object.assign({}, state, {
        users: action.payload,
        status: true,
        message: "",
      });
    case USERS_ACTIONS.USERS_BY_OFFICE_ERROR:
      return Object.assign({}, state, {
        users: [],
        status: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default usersReducer;
