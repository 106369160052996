import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Checkbox,
  message,
  Table,
  Space,
  Select,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {
  activateLanguage,
  getAllLanguages,
  saveCompanyText,
  setSelectedLanguageText,
} from "../../actions/language";
import { getOneCompanyById, saveDefaultLanguage } from "../../actions/company";
import { withTranslation } from "react-i18next";
import { refreshUser } from "../../actions/login";
import { getOneOfficeById, saveOfficeText, saveDefaultOfficeLanguage } from "../../actions/office";

const mapDispatchToProps = (dispatch) => {
  return {
    activateLanguage: (companyId, languageId, officeId) => dispatch(activateLanguage(companyId, languageId, officeId)),
    getOneCompanyById: (companyId) => dispatch(getOneCompanyById(companyId)),
    saveCompanyText: (params) => dispatch(saveCompanyText(params)),
    saveDefaultLanguage: (companyId, languageId) => dispatch(saveDefaultLanguage(companyId, languageId)),
    saveDefaultOfficeLanguage: (languageId) => dispatch(saveDefaultOfficeLanguage(languageId)),
    getAllLanguages: () => dispatch(getAllLanguages()),
    refreshUser: (userId) => dispatch(refreshUser(userId)),
    getOneOfficeById: (officeId) => dispatch(getOneOfficeById(officeId)),
    saveOfficeText: (params) => dispatch(saveOfficeText(params)),
    setSelectedLanguageText: (params) => dispatch(setSelectedLanguageText(params)),
  };
};
class ConnectedCompanyLanguages extends Component {
  state = {
    language: 1,
    selectedLanguageText: [{ language: { code: "" } }],
  };

  componentDidMount = async () => {

    await this.props.getAllLanguages();

    if (this.props.user.companyId) {
      await this.props.getOneCompanyById(this.props.user.companyId);
    } else {
      await this.props.getOneOfficeById(this.props.user.officeId);
    }

    if (this.props.user.companyId !== undefined && this.props.user.companyId !== null) {
      if (this.props.status) {
        let filled = [];
        this.props.company[0].companyTexts.forEach((text) => {
          if (!filled.includes(text.language.code) && text.isActive) {
            filled = [...filled, text.language.code];
          }
        });
        const defaultText = Object.assign(
          [],
          this.props.company[0].companyTexts.filter((text) => {
            return (
              text.language.id === this.props.company[0].defaultLanguage.id
            );
          })
        );

        const languageDefault = this.props.languages.find(
          (lang) => lang.id === this.props.company[0].defaultLanguage.id
        );

        const restLanguages = this.props.languages.filter(
          (lang) => lang.id !== this.props.company[0].defaultLanguage.id
        );

        console.log( `setting company things` )
        console.log( `filled:` )
        console.log( filled )
        console.log( `defaultText:` )
        console.log( defaultText )
        console.log( `languageDefault:` )
        console.log( languageDefault )
        console.log( `restLanguages:` )
        console.log( restLanguages )

        this.setState({
          filledLanguages: filled,
          selectedLanguageText: defaultText,
          activeLanguages: filled,
          languages: [languageDefault, ...restLanguages],
        });
      }
    } else {
      if (this.props.officeStatus) {
        let filled = [];
        this.props.office[0].companyTexts.forEach((text) => {
          if (!filled.includes(text.language.code) && text.isActive) {
            filled = [...filled, text.language.code];
          }
        });
        const defaultText = Object.assign(
          [],
          this.props.office[0].companyTexts.filter((text) => {
            return text.language.id === this.props.office[0].defaultLanguageId;
          })
        );

        const languageDefault = this.props.languages.find(
          (lang) => lang.id === this.props.office[0].defaultLanguageId
        );

        const restLanguages = this.props.languages.filter(
          (lang) => lang.id !== this.props.office[0].defaultLanguageId
        );
        this.setState({
          filledLanguages: filled,
          selectedLanguageText: defaultText,
          activeLanguages: filled,
          languages: [languageDefault, ...restLanguages],
        });
      }
    }
  };

  updateCompany = async () => {
    console.log( `updateCompany called` )

    if (this.props.user.companyId) {
      await this.props.getOneCompanyById(this.props.user.companyId);
    } else {
      await this.props.getOneOfficeById(this.props.user.officeId);
    }
    if (this.props.user.officeId === undefined || this.props.user.officeId === null) {
      if (this.props.status) {
        let filled = [];
        this.props.company[0].companyTexts.forEach((text) => {
          if (!filled.includes(text.language.code) && text.isActive) {
            filled = [...filled, text.language.code];
          }
        });
        this.setState({
          filledLanguages: filled,
          activeLanguages: filled,
        });
      }
    } else {
      if (this.props.officeStatus) {
        let filled = [];
        this.props.office[0].companyTexts.forEach((text) => {
          if (!filled.includes(text.language.code) && text.isActive) {
            filled = [...filled, text.language.code];
          }
        });
        const defaultText = Object.assign(
          [],
          this.props.office[0].companyTexts.filter((text) => {
            return text.language.id === this.props.office[0].defaultLanguageId;
          })
        );

        const languageDefault = this.props.languages.find(
          (lang) => lang.id === this.props.office[0].defaultLanguageId
        );

        const restLanguages = this.props.languages.filter(
          (lang) => lang.id !== this.props.office[0].defaultLanguageId
        );
        this.setState({
          filledLanguages: filled,
          selectedLanguageText: defaultText,
          activeLanguages: filled,
          languages: [languageDefault, ...restLanguages],
        });
      }
    }
  };

  activateLanguage = async (event, languageId) => {
    console.log( `activate language clicked` )
    console.log( languageId )
    event.stopPropagation();
    if (this.props.user.officeId === undefined || this.props.user.officeId === null) {
      if (
        (this.props.company[0]?.subscription?.maxLanguages === null
          ? Number.MAX_SAFE_INTEGER
          : this.props.company[0]?.subscription?.maxLanguages) >
        this.state.filledLanguages.length
      ) {
        if (this.props.user.companyId) {
          await this.props.activateLanguage(this.props.user.companyId, languageId);
        } else {
          await this.props.activateLanguage(
            this.props.user.companyId,
            languageId
          );
        }
        await this.updateCompany();
        let filled = [];
        this.props.company[0].companyTexts.forEach((text) => {
          if (!filled.includes(text.language.code) && text.isActive) {
            filled = [...filled, text.language.code];
          }
        });
        this.setState({ filledLanguages: filled });
        if (
          event.target.checked === false &&
          languageId === this.state.selectedLanguageText[0].language.id
        ) {
          this.selectLanguage({ id: this.props.company[0].defaultLanguage.id });
        }
      } else {
        console.log( `Not if` )
      }
    } else {
      await this.props.activateLanguage(
        undefined,
        languageId,
        this.props.user.officeId
      );
      await this.updateCompany();
      let filled = [];
      this.props.office[0].companyTexts.forEach((text) => {
        if (!filled.includes(text.language.code) && text.isActive) {
          filled = [...filled, text.language.code];
        }
      });
      this.setState({ filledLanguages: filled });
      if (
        event.target.checked === false &&
        languageId === this.state.selectedLanguageText[0].language.id
      ) {
        this.selectLanguage({ id: this.props.office[0].defaultLanguage.id });
      }
    }
  };

  selectLanguage = async (language) => {
    const toSave = {
      texts: this.state.selectedLanguageText,
    };
    if (this.props.user.officeId !== undefined && this.props.user.officeId !== null) {
      await this.props.saveOfficeText(toSave);
    } else {
      await this.props.saveCompanyText(toSave);
    }
    await this.updateCompany();
    let selected = [];
    if (this.props.user.companyId !== null && this.props.user.companyId !== undefined) {
        selected = this.props.company[0]?.companyTexts?.filter( (text) => text.language.id === language.id && text.isActive === true );
        if ( selected.length === 0 ) {
            selected = [{ language: { code: language.code }, textId: 3, languageId: language.id, textValue: "", companyId: this.props.company[0]?.id }]
        }
    } else {
        selected = this.props.office[0]?.companyTexts?.filter( (text) => text.language.id === language.id && text.isActive === true );
        if ( selected.length === 0 ) {
            selected = [{ language: { code: language.code }, textId: 3, languageId: language.id, textValue: "", officeId: this.props.office[0]?.id }]
        }
    }

    if (selected.length > 0) {
      this.setState({ language: language.id, selectedLanguageText: selected });
    } else {
      message.error(this.props.t("companyadmin-languages-activate-error"), 5);
    }
  };

  handleChangeFromSelected = (event, textId) => {
    const { selectedLanguageText } = this.state;

    let filtered = Object.assign(
      {},
      selectedLanguageText.find((text) => text.textId === textId)
    );
    const rest = Object.assign(
      [],
      selectedLanguageText.filter((text) => text.textId !== textId)
    );

    if ( Object.keys( filtered ).length === 0 ) {
        filtered = {
            languageId: this.state.language,
            textId: textId
        }
    }

    if ( this.props.user.companyId !== null && this.props.user.companyId !== undefined ) {

        filtered.companyId = this.props.user.companyId

    } else {

        filtered.officeId = this.props.user.officeId

    }

    filtered.textValue = event.target.value;

    const toSave = [...rest, filtered];

    this.props.setSelectedLanguageText(toSave);
    this.setState({ selectedLanguageText: toSave });
  };

  handleChangeDefaultLang = async (event) => {
    const newDefaultLanguage = this.props.languages.find(
      (language) => String(language.id) === event
    );
    if (!this.state.activeLanguages.includes(newDefaultLanguage.code)) {
      await this.props.activateLanguage(
        this.props.user.companyId,
        newDefaultLanguage.id,
        this.props.user.officeId
      );
    }
    if ( this.props.user.officeId !== null && this.props.user.officeId !== undefined ) {
        await this.props.saveDefaultOfficeLanguage(
            newDefaultLanguage.id
        );
    } else {
        await this.props.saveDefaultLanguage(
            this.props.user.companyId,
            newDefaultLanguage.id
        );
    }
    await this.props.refreshUser(this.props.user.id);
    this.setState({ defaultLanguage: newDefaultLanguage });
    await this.updateCompany();
  };

  render() {
    const columns = [
      {
        title: this.props.t("companyadmin-languages-table-header-language"),
        dataIndex: "code",
        key: "code",
      },
      {
        title: this.props.t("companyadmin-languages-table-header-activate"),
        key: "activate",
        render: (text, record) => {
          return (
            <Space size="middle" style={{ float: "center" }}>
              <Checkbox
                checked={this.state.filledLanguages?.includes(record.code)}
                onChange={(event) => this.activateLanguage(event, record.id)}
                disabled={
                  record.code === this.props.company[0]?.defaultLanguage.code
                }
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
            </Space>
          );
        },
      },
    ];
    return (
      <div>
        <Row>
          <Col style={{ padding: "0.5em" }}>
            <label>
              <strong>
                {this.props.t("companies-companyadmin-language-label")}
                {": "}
              </strong>
            </label>
            <br />
            <Select
              value={
                this.props.user.companyId
                  ? this.props.company[0]?.defaultLanguage &&
                    String(this.props.company[0]?.defaultLanguage.id)
                  : this.props.office[0]?.defaultLanguage &&
                    String(this.props.office[0]?.defaultLanguage.id)
              }
              onSelect={this.handleChangeDefaultLang}
            >
              {this.props.languages.map((language) => {
                return (
                  <Select.Option
                    key={language.id}
                  >{`${language.name} - ${language.code}`}</Select.Option>
                );
              })}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={4} style={{ padding: "0.5em" }}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={this.props.languages}
              pagination={{
                total: this.props.languages.length,
                pageSize: this.props.languages.length,
                hideOnSinglePage: true,
              }}
              size="small"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    this.selectLanguage(record);
                  },
                  style: {
                    background:
                      record.code ===
                        this.state.selectedLanguageText[0]?.language.code &&
                      "lightgrey",
                    cursor: "pointer",
                  },
                };
              }}
            />
          </Col>

          <Col span={20} style={{ padding: "0.5em" }}>
            <div style={{ padding: "0.5em 0.5em 0 1em" }}>
              <h4>{this.state.selectedLanguageText[0]?.language.code}</h4>
            </div>
            <div style={{ padding: "1em" }}>
              <label>
                <strong>
                  {this.props.t("companyadmin-languages-welcometitle-label")}
                </strong>
              </label>
              <TextArea
                value={
                  this.state.selectedLanguageText?.find(
                    (text) => text.textId === 2
                  )?.textValue
                }
                maxLength={80}
                onChange={(event) => this.handleChangeFromSelected(event, 2)}
              />
              <div>
                {
                  this.state.selectedLanguageText?.find(
                    (text) => text.textId === 2
                  )?.textValue?.length
                }
                /80
              </div>
            </div>
            <div style={{ padding: "1em" }}>
              <label>
                <strong>
                  {this.props.t("companyadmin-languages-welcometext-label")}
                </strong>
              </label>
              <TextArea
                value={
                  this.state.selectedLanguageText?.find(
                    (text) => text.textId === 1
                  )?.textValue
                }
                maxLength={150}
                onChange={(event) => this.handleChangeFromSelected(event, 1)}
              />
              <div>
                {
                  this.state.selectedLanguageText?.find(
                    (text) => text.textId === 1
                  )?.textValue?.length
                }
                /150
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  company: state.companyReducer.company,
  status: state.companyReducer.status,
  languages: state.languageReducer.languages,
  officeSaveStatus: state.officeReducer.saveStatus,
  officeStatus: state.officeReducer.status,
  office: state.officeReducer.office,
  selectedLanguageText: state.languageReducer.selectedLanguageText,
});
const CompanyLanguages = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedCompanyLanguages)
);
export default CompanyLanguages;
