import { RECEPTION_ACTIONS } from "../constants/action-types";
import axios from "axios";

export const getAllGuests = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: RECEPTION_ACTIONS.INVITES_LOADING });
    await axios
      .get("/1.0.0/reception", {
        params: { companyId: companyId },
      })
      .then((response) => {
        dispatch({
          type: RECEPTION_ACTIONS.INVITES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: RECEPTION_ACTIONS.INVITES_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};
export const getAllGuestsByOffice = (officeId) => {
  return async (dispatch) => {
    dispatch({ type: RECEPTION_ACTIONS.OFFICE_INVITES_LOADING });
    await axios
      .get("/2.0.0/reception/office", {
        params: { officeId: officeId },
      })
      .then((response) => {
        dispatch({
          type: RECEPTION_ACTIONS.OFFICE_INVITES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: RECEPTION_ACTIONS.OFFICE_INVITES_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllManualCreatedGuestsWithReservations = () => {
  return async (dispatch) => {
    dispatch({ type: RECEPTION_ACTIONS.NON_INVITED_GUESTS_LOADING });
    await axios
      .get("/2.0.0/reception/get-all-manual-created-guests", {})
      .then((response) => {
        dispatch({
          type: RECEPTION_ACTIONS.NON_INVITED_GUESTS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: RECEPTION_ACTIONS.NON_INVITED_GUESTS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllWorkLogs = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: RECEPTION_ACTIONS.WORKERS_LOADING });
    await axios
      .get("/1.0.0/reception/worklogs", {
        params: { companyId: companyId },
      })
      .then((response) => {
        dispatch({
          type: RECEPTION_ACTIONS.WORKERS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: RECEPTION_ACTIONS.WORKERS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};
export const getAllEvents = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: RECEPTION_ACTIONS.EVENTS_LOADING });
    await axios
      .get("/1.0.0/reception/events", {
        params: { companyId: companyId },
      })
      .then((response) => {
        dispatch({
          type: RECEPTION_ACTIONS.EVENTS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: RECEPTION_ACTIONS.EVENTS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};
export const getAllEventsByOffice = (officeId) => {
  return async (dispatch) => {
    dispatch({ type: RECEPTION_ACTIONS.OFFICE_EVENTS_LOADING });
    await axios
      .get("/2.0.0/reception/office/events", {
        params: { officeId: officeId },
      })
      .then((response) => {
        dispatch({
          type: RECEPTION_ACTIONS.OFFICE_EVENTS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: RECEPTION_ACTIONS.OFFICE_EVENTS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const saveInvitiation = (params) => {
  return async (dispatch) => {
    dispatch({ type: RECEPTION_ACTIONS.INVITE_SAVE_START });
    await axios
      .post("/2.0.0/reception", params)
      .then((response) => {
        dispatch({ type: RECEPTION_ACTIONS.INVITE_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: RECEPTION_ACTIONS.INVITE_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const modifyInvitiation = (params) => {
  return async (dispatch) => {
    dispatch({ type: RECEPTION_ACTIONS.INVITE_SAVE_START });
    await axios
      .put("/2.0.0/reception", params)
      .then((response) => {
        dispatch({ type: RECEPTION_ACTIONS.INVITE_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: RECEPTION_ACTIONS.INVITE_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const createWorklog = (params) => {
  return async (dispatch) => {
    dispatch({ type: RECEPTION_ACTIONS.WORKLOG_REGISTER_START });
    await axios
      .post("/2.0.0/reception/worklog", params)
      .then((response) => {
        dispatch({ type: RECEPTION_ACTIONS.WORKLOG_REGISTER_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: RECEPTION_ACTIONS.WORKLOG_REGISTER_ERROR,
          payload: err.response.data,
        });
        console.log(err);
      });
  };
};

export const modifyWorkLog = (params) => {
  return async (dispatch) => {
    dispatch({ type: RECEPTION_ACTIONS.WORKLOG_REGISTER_START });
    await axios
      .put("/2.0.0/reception/worklog", params)
      .then((response) => {
        dispatch({ type: RECEPTION_ACTIONS.WORKLOG_REGISTER_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: RECEPTION_ACTIONS.WORKLOG_REGISTER_ERROR,
          payload: err.response.data,
        });
        console.log(err);
      });
  };
};

export const modifyGuest = (params) => {
  return async (dispatch) => {
    dispatch({ type: RECEPTION_ACTIONS.GUEST_SAVE_START });
    await axios
      .post("/1.0.0/reception/modify-guest", params)
      .then((response) => {
        dispatch({ type: RECEPTION_ACTIONS.GUEST_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: RECEPTION_ACTIONS.GUEST_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err);
      });
  };
};

export const modifyPostCreatedGuest = (params) => {
  return async (dispatch) => {
    dispatch({ type: RECEPTION_ACTIONS.GUEST_SAVE_START });
    await axios
      .put("/1.0.0/reception/modify-post-created-guests", params)
      .then((response) => {
        dispatch({ type: RECEPTION_ACTIONS.GUEST_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: RECEPTION_ACTIONS.GUEST_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err);
      });
  };
}

export const modifyPostCreatedGuestWithVehicle = (params) => {
  return async (dispatch) => {
    dispatch({ type: RECEPTION_ACTIONS.GUEST_SAVE_START });
    await axios
      .put("/1.0.0/reception/modify-post-created-guests-with-vehicle", params)
      .then((response) => {
        dispatch({ type: RECEPTION_ACTIONS.GUEST_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: RECEPTION_ACTIONS.GUEST_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err);
      });
  };
}

export const deleteWorklog = (id) => {
  return async () => {
    await axios.delete("/2.0.0/reception/" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

export const getAllGuestsList = (qrCode, name, email, arrived, companyId, officeId) => {
  return async (dispatch) => {
    dispatch({ type: RECEPTION_ACTIONS.GUESTS_LIST_LOADING });
    await axios
      .get("/2.0.0/reception/get-all-guests", {
        params: {
          qrCode: qrCode,
          name: name,
          email: email,
          arrived: arrived,
          companyId: companyId,
          officeId: officeId,
        },
      })
      .then((response) => {
        dispatch({
          type: RECEPTION_ACTIONS.GUESTS_LIST_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: RECEPTION_ACTIONS.GUESTS_LIST_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllReceptionEventsByGuest = (guestId) => {
  return async (dispatch) => {
    dispatch({ type: RECEPTION_ACTIONS.GUEST_RECEPTION_EVENTS_LOADING });
    await axios
      .get(`/2.0.0/reception/get-all-reception-events/${guestId}`)
      .then((response) => {
        dispatch({
          type: RECEPTION_ACTIONS.GUEST_RECEPTION_EVENTS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: RECEPTION_ACTIONS.GUEST_RECEPTION_EVENTS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const markGuestsDepartured = (guestIds) => {
  console.log(guestIds)
  return async (dispatch) => {
    dispatch({ type: RECEPTION_ACTIONS.GUEST_DEPARTURE_SAVE_START });
    await axios
      .put("/2.0.0/reception/mark-guests-leaving", { guestIds: guestIds })
      .then((response) => {
        dispatch({
          type: RECEPTION_ACTIONS.GUEST_DEPARTURE_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: RECEPTION_ACTIONS.GUEST_DEPARTURE_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};
