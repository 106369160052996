import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import i18next from "i18next";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedWizardDownloadTemplate extends Component {
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <h3>{this.props.t("excel-template-download")}</h3>
        <p>{this.props.t("excel-template-click-picture")}</p>
        <div style={{ margin: "10px 0 10px 0" }}>
          <Button
            href={`${process.env.REACT_APP_BASE_URL}/api/1.0.0/users/template/TabLog-import-${i18next.language}.xlsx`}
            download
            type="link"
            style={{ width: "100px", height: "100px", padding: "0" }}
          >
            <DownloadOutlined
              style={{ fontSize: "100px", cursor: "pointer" }}
            />
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={this.props.prev}>
            {this.props.t("back-button-label")}
          </Button>
          <Button type="primary" onClick={this.props.next}>
            {this.props.t("next-button-label")}
          </Button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const WizardDownloadTemplate = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedWizardDownloadTemplate)
);
export default WizardDownloadTemplate;
