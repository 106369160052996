import React, { Component } from "react";
import { connect } from "react-redux";
import { getApiKey, loginWithToken } from "../../actions/login";
import { message } from "antd";
import { withTranslation } from "react-i18next";
const mapDispatchToProps = (dispatch) => {
  return {
    loginWithToken: (token) => dispatch(loginWithToken(token)),
    getApiKey: (token) => dispatch(getApiKey(token)),
  };
};
class ConnectedLoginRedirect extends Component {
  componentDidMount = async () => {
    const queryParams = new URLSearchParams(this.props.location.search);
    const token = queryParams.get("token");
    if (token) {
      await this.props.loginWithToken(token);
      if (this.props.status) {
        if (!this.props.user.completeWizard) {
          this.props.history.push("/wizard");
        } else {
          const groupIds = this.props.user.groups.map((group) => group.id);
          if (groupIds.includes(1)) {
            this.props.history.push("/tl/administration");
          } else if (groupIds.includes(2)) {
            await this.props.getApiKey(this.props.token);
            this.props.history.push("/tl/reception");
          } else if (groupIds.includes(3)) {
            this.props.history.push("/tl/company-management");
          } else if (groupIds.includes(4)) {
            this.props.history.push("/tl/company-management");
          } else if (groupIds.includes(5)) {
            message.error(this.props.t("login-fail-norights"), 5);
          }
        }
      } else {
        this.props.history.push("/login");
        message.error(this.props.t("login-redirect-fail"), 5);
      }
    } else {
      this.props.history.push("/login");
      message.error(this.props.t("login-redirect-fail"), 5);
    }
  };

  render() {
    return (
      <div>
        <div>{this.props.t("login-redirect-inprogress")}</div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  status: state.loginReducer.status,
});
const LoginRedirect = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedLoginRedirect)
);
export default LoginRedirect;
