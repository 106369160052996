import { Button } from "antd";
import Axios from "axios";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { userRoles } from "../../constants/roles-types";

const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedHelp extends Component {
  state = {
    helpHTML: "",
  };

  componentDidMount = async () => {
    await this.handleGetHelpHTML();
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.props.t !== prevProps.t) {
      await this.handleGetHelpHTML();
    }
  };

  handleGetHelpHTML = async () => {
    await Axios.get(
      `/1.0.0/assets/help/help_${this.props.i18n.language.toLowerCase()}.html`
    )
      .then((response) => {
        this.setState({ helpHTML: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  hasRight = (rights) => {
    let allowed = false;
    this.props.user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allowed = true;
      }
    });
    if (allowed === true) {
      return true;
    } else {
      return false;
    }
  };
  render() {
    return (
      <div>
        <div>
          {this.hasRight([userRoles.COMPANY_ADMIN]) && (
            <NavLink to="/wizard">
              <Button type="primary">
                {this.props.t("help-wizard-button")}
              </Button>
            </NavLink>
          )}
          <div style={{ margin: "1em" }}>
            <h1 style={{ fontSize: "1.5rem" }}>
              <strong>{this.props.t("menu-help-button-label")}</strong>
            </h1>
            <div dangerouslySetInnerHTML={{ __html: this.state.helpHTML }} />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
});
const Help = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedHelp)
);
export default Help;
