import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  message,
  Popconfirm,
  Switch,
  Select,
} from "antd";
import {
  activateCompany,
  deactivateCompany,
  getAllCompanies,
  getAllSubscriptions,
} from "../../actions/company";
import { withTranslation } from "react-i18next";
import axios from "axios";
import i18n from "../../i18n";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCompanies: () => dispatch(getAllCompanies()),
    activateCompany: (companyId) => dispatch(activateCompany(companyId)),
    deactivateCompany: (companyId) => dispatch(deactivateCompany(companyId)),
    getAllSubscriptions: () => dispatch(getAllSubscriptions()),
  };
};

class ConnectedSubscription extends Component {
  formRef = React.createRef();

  state = {
    modify: false,
    name: "",
    file: [],
    selectedId: null,
    companies: [],
  };

  componentDidMount = async () => {
    await this.updateList();
    await this.props.getAllSubscriptions();
  };

  updateList = async () => {
    await this.props.getAllCompanies();
    if (this.props.status) {
      const companies = this.props.companies.map((company) => {
        return {
          ...company,
          subscriptionName: company?.subscription?.name,
          status:
            company.active === true
              ? this.props.t("active-input-label")
              : this.props.t("inactive-label"),
        };
      });
      this.setState({ companies: companies });
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  removeFile = () => {
    this.setState({ file: [] });
  };

  beforeUpload = (file) => {
    this.setState({ file: [file] });
    return false;
  };

  submitForm = async () => {
    const formData = new FormData();
    if (this.state.modify === true) {
      formData.set("id", this.state.selectedId);
    }
    formData.set("name", this.state.name);
    formData.append("file", this.state.file[0]);
    await this.props.saveCompany(formData);
    if (this.props.saveStatus) {
      this.setState({ name: "", selectedId: null, modify: false, file: [] });
      this.formRef.current.resetFields();
      message.success(this.props.t("save-success"), 5);
    } else {
      if (this.props.message.code === 1006) {
        message.error(this.props.t("company-name-already-exists"), 5);
      } else {
        message.error(this.props.t("save-error-label"), 5);
      }
    }
    this.forceUpdate();
  };

  handleDelete = async (record) => {
    if (record.active) {
      await this.props.deactivateCompany(record.id);
    } else {
      await this.props.activateCompany(record.id);
    }
    if (this.props.status) {
      await this.updateList();
    }
  };

  handleChangeTrial = async (companyId) => {
    await axios
      .get("/2.0.0/company/trial", {
        params: { companyId: companyId },
      })
      .then(async (response) => {
        await this.updateList();
      })
      .catch(() => {
        message.error("Hiba");
      });
  };

  handleChangeSubscriptionType = async (companyId, subscriptionId) => {
    await axios
      .get("/2.0.0/company/subscription", {
        params: {
          companyId: companyId,
          subscriptionId: subscriptionId,
        },
      })
      .then(async () => {
        await this.updateList();
      })
      .catch(() => {
        message.error("Hiba");
      });
  };

  render() {
    const columns = [
      {
        title: this.props.t("companies-label-companyname"),
        render: (text, record) => {
          return (
            <Space size="middle">
              {
                record.companyTexts.find(
                  (text) =>
                    text.languageId === record.defaultLanguageId &&
                    text.textId === 3
                )?.textValue
              }
            </Space>
          );
        },
        key: "name",
      },
      {
        title: this.props.t("subscriptions-table-header-subscription-type"),
        dataIndex: "subscriptionName",
        key: "subscriptionName",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Select
                value={record.subscriptionId}
                onChange={(event) =>
                  this.handleChangeSubscriptionType(record.id, event)
                }
              >
                {this.props.subscriptions.map((s) => {
                  return (
                    <Select.Option key={s.id} value={s.id}>
                      {s.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Space>
          );
        },
      },
      {
        title: this.props.t("companies-table-header-date"),
        key: "date",
        render: (text, record) => (
          <Space size="middle">
            {new Date(parseInt(record.created)).toLocaleDateString()}
          </Space>
        ),
      },
      {
        title: this.props.t("subscriptions-table-header-status"),
        // dataIndex: "status",
        key: "status",
        render: (text, record) => {
          return (
            <Space size="middle">
              {record.status === "Inaktív" && i18n.language === "hu"
                ? "Inaktív"
                : record.status === "Inaktív" && i18n.language === "en"
                ? "Inactive"
                : record.status === "Inaktív" && i18n.language === "de"
                ? "Inaktiv"
                : record.status === "Aktív" && i18n.language === "hu"
                ? "Aktív"
                : record.status === "Aktív" && i18n.language === "en"
                ? "Active"
                : "Aktiv"}
            </Space>
          );
        },
      },
      {
        title: "Trial",
        key: "trial",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Switch
                checked={record.trial}
                onChange={() => this.handleChangeTrial(record.id)}
              />
            </Space>
          );
        },
      },
      {
        title: this.props.t("companies-table-header-logo"),
        key: "logo",
        render: (text, record) => (
          <Space size="middle">
            {record.logoName !== null ? (
              <img
                alt="logo"
                src={`${
                  process.env.REACT_APP_BASE_URL
                }/api/1.0.0/company/logo/${record.logoName}?token=${
                  this.props.token.split(" ")[1]
                }`}
                width={100}
              />
            ) : (
              this.props.t("companies-table-no-logo")
            )}
          </Space>
        ),
      },
      {
        title: this.props.t("subscriptions-table-header-activate"),
        key: "delete",
        render: (text, record) => (
          <Space size="middle">
            <Popconfirm
              onConfirm={() => this.handleDelete(record)}
              title={
                record.active
                  ? this.props.t("subscriptions-deactivate-confirm")
                  : this.props.t("subscriptions-activate-confirm")
              }
              okText={this.props.t("yes-button-label")}
              cancelText={this.props.t("no-button-label")}
            >
              <Button type="primary" danger={record.active}>
                {record.active
                  ? this.props.t("subscriptions-deactivate-button-label")
                  : this.props.t("subscriptions-activate-button-label")}
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    return (
      <div>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>{this.props.t("subscriptions-label")}</strong>
            </h1>
            <Table
              columns={columns}
              rowKey="id"
              dataSource={this.state.companies}
              locale={{ emptyText: this.props.t("empty-text") }}
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: true,
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  companies: state.companyReducer.companies,
  subscriptions: state.companyReducer.subscriptions,
  status: state.companyReducer.status,
  saveStatus: state.companyReducer.saveStatus,
});

const Subscription = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedSubscription)
);
export default Subscription;
