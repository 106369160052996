import axios from "axios";
import { PUSH_ACTIONS } from "../constants/action-types";


export const sendCustomPush = (params) => {
  return async (dispatch) => {
    dispatch({ type: PUSH_ACTIONS.PUSH_SAVE_START });
    await axios
      .post("/3.0.0/push/send/custom-push-notification", params)
      .then((response) => {
        dispatch({
          type: PUSH_ACTIONS.PUSH_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PUSH_ACTIONS.PUSH_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};