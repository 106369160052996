import React, { Component } from "react";
import { Input, Button, Form, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./login.scss";
import { connect } from "react-redux";
import { getApiKey, login } from "../../actions/login";
import { withTranslation } from "react-i18next";
import { openTagWithMessage } from "../../actions/tag";
import { userRoles } from "../../constants/roles-types";

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password)),
    getApiKey: (token) => dispatch(getApiKey(token)),
    openTagWithMessage: (messageToSet) =>
      dispatch(openTagWithMessage(messageToSet)),
  };
};

class ConnectedLogin extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    document.body.style = "background: #ffffff; !important";
  }

  state = {
    email: "",
    password: "",
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleFormSubmit = async () => {
    await this.props.login(this.state.email, this.state.password);
    if (this.props.status) {
      if (this.props.user.active === false) {
        message.error(this.props.t("login-fail-inactivity"), 5);
        this.formRef.current.resetFields();
        this.setState({ email: "", password: "" });
      } else {
        const groupIds = this.props.user.groups.map((group) => group.id);
        if (groupIds.includes(userRoles.OFFICE_ADMIN)) {
          // irodaház admin
          this.props.history.push("/tl/reg-app-tokens");
        } else if (!this.props.user.completeWizard) {
          this.props.history.push("/wizard");
        } else {
          if (groupIds.includes(userRoles.SYSTEM_ADMIN)) {
            message.success(this.props.t("login-success"), 5);
            this.props.history.push("/tl/subscriptions");
          } else if (groupIds.includes(userRoles.COMPANY_ADMIN)) {
            this.props.openTagWithMessage("regapps-tip");
            await this.props.getApiKey(this.props.token);
            message.success(this.props.t("login-success"), 5);
            this.props.openTagWithMessage("regapps-tip");
            this.props.history.push("/tl/reception");
          } else if (groupIds.includes(userRoles.OFFICE_ADMIN)) {
            message.success(this.props.t("login-success"), 5);
            this.props.openTagWithMessage("regapps-tip");
            this.props.history.push("/tl/reception");
          } else if (groupIds.includes(userRoles.RECEPTION_ADMIN)) {
            message.success(this.props.t("login-success"), 5);
            this.props.openTagWithMessage("reception-invited-tip");
            this.props.history.push("/tl/reception");
          } else if (groupIds.includes(userRoles.HR_ADMIN)) {
            message.success(this.props.t("login-success"), 5);
            this.props.openTagWithMessage("workers-users-tip");
            this.props.history.push("/tl/workers");
          } else if (groupIds.includes(userRoles.OFFICE_RECEPTION_ADMIN)) {
            message.success(this.props.t("login-success"), 5);
            this.props.openTagWithMessage("reception-invited-tip");
            this.props.history.push("/tl/reception");
          }
          else if (groupIds.includes(userRoles.INVITE_USER_RECEPTION_ADMIN)) {
            message.success(this.props.t("login-success"), 5);
            this.props.openTagWithMessage("reception-invited-tip");
            this.props.history.push("/tl/reception");
          }
          else if (groupIds.includes(userRoles.PARKING_RECEPTION)) {
            message.success(this.props.t("login-success"), 5);
            this.props.history.push("/tl/manual-enter");
          }
          else if (groupIds.includes(userRoles.USER)) {
            message.error(this.props.t("login-fail-norights"), 5);
          }
        }
      }
    } else {
      message.error(this.props.t("login-fail-badcredentials"), 5);
      this.formRef.current.resetFields();
      this.setState({ email: "", password: "" });
    }
  };

  render() {
    return (
      <div className="login-page-container">
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={this.handleFormSubmit}
          ref={this.formRef}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: this.props.t("email-empty"),
              },
              { type: "email", message: this.props.t("email-wrong-format") },
            ]}
          >
            <Input
              prefix={
                <UserOutlined
                  className="site-form-item-icon"
                  style={{ color: "#00b7ae" }}
                />
              }
              placeholder={this.props.t("email-label")}
              name="email"
              autoComplete="off"
              value={this.state.email || ""}
              onChange={this.handleChange}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: this.props.t("password-empty") },
            ]}
          >
            <Input
              prefix={
                <LockOutlined
                  className="site-form-item-icon"
                  style={{ color: "#00b7ae" }}
                />
              }
              type="password"
              placeholder={this.props.t("password-label")}
              name="password"
              value={this.state.password || ""}
              onChange={this.handleChange}
            />
          </Form.Item>
          <Form.Item style={{ width: "100%" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: "100%" }}
            >
              {this.props.t("login-button-label")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  status: state.loginReducer.status,
});

const Login = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedLogin)
);
export default Login;
