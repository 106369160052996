import React, { Component } from "react";
import { Input, Button, Space, Table, Popconfirm, message } from "antd";
import { connect } from "react-redux";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import Highlighter from "react-highlight-words";
import lodash from "lodash";
import { getRoomTypeName } from "../../../common/building.common";

const mapDispatchToProps = (dispatch) => {
  return {};
};

class ConnectedRoomsList extends Component {
  formRef = React.createRef();

  state = {
    rooms: [],

    openSearchModal: false,
    searchText: null,
    searchedColumn: null,
  };

  componentDidMount = async () => {
    this.setState({ rooms: this.props.rooms });
  };

  handleOpenUpdateModal = (record) => {
    this.props.openUpdateModal(record);
  };

  deletePopupConfirm = async (roomId) => {
    //TODO
    // await this.props.deleteEntrypoint(entryPointId);
    message.success(this.props.t("building-room-modal-delete-success-message"));
  };

  handleCancel = () => {
    this.setState({ visible: false, modify: false, selectedId: null });
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t("search-button-label")}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            {this.props.t("reset-button-label")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const nestedValue = lodash.get(record, dataIndex);
      if (nestedValue !== undefined) {
        return nestedValue
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
      return false;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => {
      return lodash.isEqual(this.state.searchedColumn, dataIndex) ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      );
    },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const colums = [
      {
        title: this.props.t("building-room-list-name-label"),
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps(
          "name",
          this.props.t("building-room-list-name-label")
        ),
      },
      {
        title: this.props.t("building-room-list-type-label"),
        dataIndex: "type",
        key: "type",
        sorter: (a, b) => a.type - b.type,
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps(
          "tpe",
          this.props.t("building-room-list-type-label")
        ),
        render: (text, record) => (
          <Space size="middle">
            {this.props.t(getRoomTypeName(record.type))}
          </Space>
        ),
      },
      {
        title: this.props.t("building-room-list-floor-label"),
        dataIndex: "floor",
        key: "floor",
        sorter: (a, b) => a.floor - b.floor,
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps(
          "floor",
          this.props.t("building-room-list-floor-label")
        ),
      },
      {
        title: this.props.t("entrypoints-update-title"),
        dataIndex: "update",
        width: "5%",
        render: (value, record) => (
          <>
            <Space size="middle">
              <Button
                type="primary"
                onClick={() => this.handleOpenUpdateModal(record)}
              >
                {this.props.t("modify-button-label")}
              </Button>
            </Space>
          </>
        ),
      },
      {
        title: this.props.t("delete-button-label"),
        dataIndex: "delete",
        width: "5%",
        render: (value, record) => (
          <>
            <Space size="middle">
              <Popconfirm
                title={this.props.t("entrypoints-popup-delete-question")}
                onConfirm={() => this.deletePopupConfirm(record.id)}
                okText={this.props.t("entrypoints-popup-button-ok-label")}
                cancelText={this.props.t(
                  "entrypoints-popup-button-cancle-label"
                )}
                placement="topLeft"
              >
                <Button
                  className="button"
                  type="primary"
                  htmlType="submit"
                  danger
                >
                  <DeleteOutlined />
                  {this.props.t("delete-button-label")}
                </Button>
              </Popconfirm>
            </Space>
          </>
        ),
      },
    ];

    return (
      <div className="rooms">
        <Table
          bordered={false}
          columns={colums}
          loading={this.props.loading}
          dataSource={this.props.rooms}
          pagination={
            this.state.rooms.length > 10
              ? {
                  position: ["bottomCenter"],
                  showSizeChanger: true,
                }
              : false
          }
          rowKey="id"
          onChange={this.props.handleTableChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
});

const RoomsList = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedRoomsList)
);
export default RoomsList;
