import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Input,
  Menu,
  Calendar,
  Typography,
  Select,
  TimePicker,
  Badge,
  message,
  DatePicker,
} from "antd";
import {
  getAllUsers,
  getAllGroups,
  getUserWorklog,
  getAllWorkingUsers,
} from "../../actions/users";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  BarsOutlined,
  FilterOutlined,
  DiffOutlined,
} from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import Axios from "axios";
import { withTranslation } from "react-i18next";
import { ReactComponent as NoPicture } from "../../static/Artboard.svg";
import { getAllWorkLogs } from "../../actions/reception";
import Timer from "react-compound-timer";
import hu_HU from "antd/lib/locale-provider/hu_HU";
import moment from "moment";
import i18n from "../../i18n";
import { worklogStatuses } from "../../constants/worklog-statuses";
import { userRoles } from "../../constants/roles-types";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUsers: (groupId, companyId) =>
      dispatch(getAllUsers(groupId, companyId)),
    getAllWorkingUsers: (companyId) => dispatch(getAllWorkingUsers(companyId)),
    getAllGroups: () => dispatch(getAllGroups()),
    getAllWorklogs: (companyId) => dispatch(getAllWorkLogs(companyId)),
    getUserWorklog: (userId) => dispatch(getUserWorklog(userId)),
  };
};
const { Text } = Typography;
const { SubMenu } = Menu;
class ConnectedWorkers extends Component {
  state = {
    users: [],
    loaded: false,
    lastName: "",
    firstName: "",
    email: "",
    position: "",
    summWorkingHour: "",
    workdiaryVisible: false,
    selectedUser: {},
    selectedWorklog: [],
    filteredInfo: {},
    checkIn: new Date(),
    checkOut: new Date(),
    statusType: "",
    modify: false,
    dailyFilter: false,
    weeklyFilter: false,
    monthlyFilter: false,
    detailsVisible: false,
    checkIfModifyable: true,
    selectedId: null,
    dateValue: moment(),
    selectedDateValue: moment(),
    end: new Date(),
    start: new Date(),
  };

  componentDidMount = async () => {
    await this.props.getAllGroups();
    await this.props.getAllWorkingUsers(this.props.user.companyId);
    this.props.socket.on("updateReception", async () => {
      await this.props.getAllWorkingUsers(this.props.user.companyId);
    });
    this.getUsers();
  };

  getUsers = async () => {
    this.setState({ loaded: false });
    const filteredusers = this.props.workingUsers.filter(
      (user) => user.deleted === false
    );
    if (this.props.usersStatus) {
      this.setState({ users: filteredusers });
      this.setState({ loaded: true });
    }
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t("search-button-label")}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            {this.props.t("reset-button-label")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      (record[dataIndex] || '').toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  closeDiary = () => {
    this.setState({
      workdiaryVisible: false,
      selectedUser: {},
      selectedWorklog: [],
    });
  };

  openDiary = async (record) => {
    this.setState({
      workdiaryVisible: true,
      selectedUser: record,
    });
    await Axios.get("/1.0.0/reception/worklogs", {
      params: { userId: record.id },
    })
      .then((response) => {
        this.setState({ selectedWorklog: response.data.worklogs });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ selectedWorklog: [] });
      });
  };

  hasRight = (rights, notAllowedSubscriptions) => {
    let allowed = false;
    this.props.user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allowed = true;
      }
    });
    if (allowed === true && notAllowedSubscriptions === undefined) {
      return true;
    } else {
      if (allowed === true) {
        if (this.allowedBySubscription(notAllowedSubscriptions)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  allowedBySubscription = (notAllowedSubscriptions) => {
    if (this.props.user.companyId === null) {
      return false;
    } else {
      if (
        notAllowedSubscriptions !== undefined &&
        notAllowedSubscriptions !== null
      ) {
        if (
          notAllowedSubscriptions.includes(
            this.props.user.company.subscriptionId
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  openDetails = async (record) => {
    this.setState({
      detailsVisible: true,
      weeklyFilter: false,
      monthlyFilter: false,
      selectedUser: record,
    });
    await Axios.get("/1.0.0/reception/worklogs", {
      params: { userId: record.id },
    })
      .then((response) => {
        this.setState({
          selectedWorklog: response.data.worklogs,
          start: record.checkIn,
          end: record.checkOut,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ selectedWorklog: [] });
      });
  };

  getListData(record) {
    let listData = [];
    let checkinDate = this.state.selectedWorklog.filter(
      (worklogs) =>
        record.format("YYYY-MM-DD") ===
        moment(worklogs.checkIn).format("YYYY-MM-DD")
    );
    if (checkinDate.length !== 0) {
      if (checkinDate[0].statusId === worklogStatuses.awayFromKeyboard) {
        listData = listData = [{ type: "warning" }];
      } else {
        listData = listData = [{ type: "success" }];
      }
    }

    return listData;
  }

  dateCellRender(record) {
    const listData = this.getListData(record);
    return (
      <ul>
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} />
          </li>
        ))}
      </ul>
    );
  }

  onPanelChange = (value) => {
    this.setState({ value });
  };

  onSelect = (record) => {
    this.setState({
      dateValue: record,
      selectedDateValue: record,
      monthlyFilter: false,
      dailyFilter: false,
      weeklyFilter: false,
    });
  };

  closeDetails = () => {
    this.setState({
      detailsVisible: false,
      weeklyFilter: false,
      monthlyFilter: false,
      selectedUser: {},
      selectedWorklog: [],
    });
  };

  weeklyWorklogSum = () => {
    let nettoWorkingHour = this.state.selectedWorklog.map((log) => {
      if (
        moment(this.state.selectedDateValue).startOf("week") <=
        moment(log.checkIn) &&
        moment(log.checkOut) <=
        moment(this.state.selectedDateValue).endOf("week")
      ) {
        return log.checkOut - log.checkIn;
      } else {
        return 0;
      }
    });
    let allBreakHour = this.state.selectedWorklog.map((log) => {
      if (
        moment(this.state.selectedDateValue).startOf("week") <=
        moment(log.breakTime) <=
        moment(this.state.selectedDateValue).endOf("week")
      ) {
        return log.breakTime;
      } else {
        return 0;
      }
    });
    const allBreakTime = allBreakHour.reduce((a, b) => a + b, 0);
    const fixedAllBreakTime = allBreakTime / 3600000;
    const decPartBreak = fixedAllBreakTime - Math.floor(fixedAllBreakTime);
    const breakMins = (decPartBreak * 60).toFixed(0);
    const fixedBreakHour = Math.floor(fixedAllBreakTime);
    const totalBreakTime = `${fixedBreakHour} ${this.props.t(
      "working-hours-hour"
    )} ${breakMins} ${this.props.t("working-hours-minute")}`;

    const all = nettoWorkingHour.reduce((a, b) => a + b, 0);
    const fixedAll =
      fixedAllBreakTime <= 0
        ? all / 3600000 - fixedAllBreakTime
        : all / 3600000;
    const decPart = fixedAll - Math.floor(fixedAll);
    const mins = (decPart * 60).toFixed(0);
    const fixedHour = Math.floor(fixedAll);
    const total = `${fixedHour} ${this.props.t(
      "working-hours-hour"
    )} ${mins} ${this.props.t("working-hours-minute")}`;

    const nettoWorkingHourAndBreakTime = `${total} ${this.props.t(
      "netto-working-hour"
    )} ${fixedAllBreakTime <= 0
        ? totalBreakTime + this.props.t("all-breaktime")
        : ""
      }`;

    this.setState({
      summWorkingHour: nettoWorkingHourAndBreakTime,
      monthlyFilter: false,
      dailyFilter: false,
      weeklyFilter: true,
    });
  };

  monthlyWorklogSum = () => {
    let nettoWorkingHour = this.state.selectedWorklog.map((log) => {
      if (
        moment(this.state.selectedDateValue).startOf("month") <=
        moment(log.checkIn) &&
        moment(log.checkOut) <=
        moment(this.state.selectedDateValue).endOf("month")
      ) {
        return log.checkOut - log.checkIn;
      } else {
        return 0;
      }
    });

    let allBreakHour = this.state.selectedWorklog.map((log) => {
      if (
        moment(this.state.selectedDateValue).startOf("month") <=
        moment(log.breakTime) <=
        moment(this.state.selectedDateValue).endOf("month")
      ) {
        return log.breakTime;
      } else {
        return 0;
      }
    });
    const allBreakTime = allBreakHour.reduce((a, b) => a + b, 0);
    const fixedAllBreakTime = allBreakTime / 3600000;
    const decPartBreak = fixedAllBreakTime - Math.floor(fixedAllBreakTime);
    const breakMins = (decPartBreak * 60).toFixed(0);
    const fixedBreakHour = Math.floor(fixedAllBreakTime);
    const totalBreakTime = `${fixedBreakHour} ${this.props.t(
      "working-hours-hour"
    )} ${breakMins} ${this.props.t("working-hours-minute")}`;

    const all = nettoWorkingHour.reduce((a, b) => a + b, 0);
    const fixedAll =
      fixedAllBreakTime <= 0
        ? all / 3600000 - fixedAllBreakTime
        : all / 3600000;
    const decPart = fixedAll - Math.floor(fixedAll);
    const mins = (decPart * 60).toFixed(0);
    const fixedHour = Math.floor(fixedAll);
    const total = `${fixedHour} ${this.props.t(
      "working-hours-hour"
    )} ${mins} ${this.props.t("working-hours-minute")}`;

    const nettoWorkingHourAndBreakTime = `${total} ${this.props.t(
      "netto-working-hour"
    )} ${fixedAllBreakTime <= 0
        ? totalBreakTime + this.props.t("all-breaktime")
        : ""
      }`;
    this.setState({
      summWorkingHour: nettoWorkingHourAndBreakTime,
      weeklyFilter: false,
      dailyFilter: false,
      monthlyFilter: true,
    });
  };

  dailyWorklogSum = () => {
    let nettoWorkingHour = this.state.selectedWorklog.map((log) => {
      if (
        moment(this.state.selectedDateValue).startOf("day") <=
        moment(log.checkIn) &&
        moment(log.checkOut) <=
        moment(this.state.selectedDateValue).endOf("day")
      ) {
        return log.checkOut - log.checkIn;
      } else {
        return 0;
      }
    });
    let allBreakHour = this.state.selectedWorklog.map((log) => {
      if (
        moment(this.state.selectedDateValue).startOf("day") <=
        moment(log.breakTime) <=
        moment(this.state.selectedDateValue).endOf("day")
      ) {
        return log.breakTime;
      } else {
        return 0;
      }
    });
    const allBreakTime = allBreakHour.reduce((a, b) => a + b, 0);
    const fixedAllBreakTime = allBreakTime / 3600000;
    const decPartBreak = fixedAllBreakTime - Math.floor(fixedAllBreakTime);
    const breakMins = (decPartBreak * 60).toFixed(0);
    const fixedBreakHour = Math.floor(fixedAllBreakTime);
    const totalBreakTime = `${fixedBreakHour} ${this.props.t(
      "working-hours-hour"
    )} ${breakMins} ${this.props.t("working-hours-minute")}`;

    const all = nettoWorkingHour.reduce((a, b) => a + b, 0);
    const fixedAll =
      fixedAllBreakTime <= 0
        ? all / 3600000 - fixedAllBreakTime
        : all / 3600000;
    const decPart = fixedAll - Math.floor(fixedAll);
    const mins = (decPart * 60).toFixed(0);
    const fixedHour = Math.floor(fixedAll);
    const total = `${fixedHour} ${this.props.t(
      "working-hours-hour"
    )} ${mins} ${this.props.t("working-hours-minute")}`;

    const nettoWorkingHourAndBreakTime = `${total} ${this.props.t(
      "netto-working-hour"
    )} ${fixedAllBreakTime <= 0
        ? totalBreakTime + this.props.t("all-breaktime")
        : ""
      }`;
    this.setState({
      summWorkingHour: nettoWorkingHourAndBreakTime,
      dailyFilter: true,
      weeklyFilter: false,
      monthlyFilter: false,
    });
  };

  resetFilterSum = () => {
    this.setState({
      summWeekly: "",
      summMonthly: "",
      dailyFilter: false,
      weeklyFilter: false,
      monthlyFilter: false,
    });
  };

  excelExportSchema = async (userId, startDate, endDate) => {
    let url = "/2.0.0/reception/xlsx";
    let right;
    this.hasRight([userRoles.COMPANY_ADMIN, userRoles.RECEPTION_ADMIN]) ? (right = true) : (right = false);
    let toSend = {
      start: startDate.format("YYYY-MM-DD HH:mm"),
      end: endDate.format("YYYY-MM-DD HH:mm"),
      userId: userId,
      language: i18n.language,
      right: right,
    };
    await Axios.post(url, toSend, { responseType: "blob" })
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `excel_export_${new Date().toISOString()}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        message.error(this.props.t("empty-text"));
        console.log(err);
      });
  };

  csvExportSchema = async (userId, startDate, endDate) => {
    let toSend = {
      start: startDate.format("YYYY-MM-DD HH:mm"),
      end: endDate.format("YYYY-MM-DD HH:mm"),
      userId: userId,
      language: i18n.language,
    };
    let url = "/2.0.0/reception/csv";
    await Axios.post(url, toSend, { responseType: "blob" })
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `csv_export_${new Date().toISOString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        message.error(this.props.t("empty-text"));
        console.log(err);
      });
  };

  excelExport = async (record) => {
    const startDate = moment(this.state.selectedDateValue).startOf("year");
    const endDate = moment(this.state.selectedDateValue).endOf("year");
    const userId = record.id;
    this.excelExportSchema(userId, startDate, endDate);
  };

  csvExport = async (record) => {
    const startDate = moment(this.state.selectedDateValue).startOf("year");
    const endDate = moment(this.state.selectedDateValue).endOf("year");
    const userId = record.id;
    this.csvExportSchema(userId, startDate, endDate);
  };

  onChangeYear = async (event) => {
    this.setState({
      selectedDateValue: moment(event),
    });
  };

  disabledDate(record) {
    return !(
      moment().startOf("day").subtract(3, "days") < record &&
      record < moment().endOf("day")
    );
  }

  disabledHrDate(record) {
    return !(
      moment().startOf("day").subtract(45, "days") < record &&
      record < moment().endOf("day")
    );
  }

  exportDisabledDate(record) {
    return (
      moment().startOf("year").add(1, "years") < record
    );
  }

  render() {
    moment.updateLocale("en", {
      week: {
        dow: 8,
      },
      weekdaysMin: [
        this.props.t("Sun"),
        this.props.t("Mon"),
        this.props.t("Tue"),
        this.props.t("Wed"),
        this.props.t("Thu"),
        this.props.t("Fri"),
        this.props.t("Sat"),
      ],
      monthsShort: [
        this.props.t("Jan"),
        this.props.t("Feb"),
        this.props.t("Mar"),
        this.props.t("Apr"),
        this.props.t("May"),
        this.props.t("Jun"),
        this.props.t("Jul"),
        this.props.t("Aug"),
        this.props.t("Sep"),
        this.props.t("Oct"),
        this.props.t("Nov"),
        this.props.t("Dec"),
      ],
    });

    let filteredLogs = this.state.selectedWorklog.filter(
      (worklogs) =>
        moment(worklogs.checkIn).format("YYYY-MM-DD") ===
        this.state.selectedDateValue.format("YYYY-MM-DD")
    );

    let weeklyfilteredLogs = this.state.selectedWorklog.filter(
      (worklogs) =>
        moment(this.state.selectedDateValue).startOf("week") <=
        moment(worklogs.checkIn) &&
        moment(worklogs.checkIn) <=
        moment(this.state.selectedDateValue).endOf("week")
    );

    let monthlyfilteredLogs = this.state.selectedWorklog.filter(
      (worklogs) =>
        moment(this.state.selectedDateValue).startOf("month") <=
        moment(worklogs.checkIn) &&
        moment(worklogs.checkIn) <=
        moment(this.state.selectedDateValue).endOf("month")
    );

    let decider =
      this.state.weeklyFilter === true
        ? weeklyfilteredLogs
        : this.state.monthlyFilter === true
          ? monthlyfilteredLogs
          : filteredLogs;

    const columns = [
      {
        title: this.props.t("currently-working-title"),
        key: "working",
        render: (string, record) => {
          return (
            <Space
              size="middle"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              <div
                style={{
                  width: "1.1vw",
                  height: "1.1vw",
                  borderRadius: "50%",
                  backgroundColor:
                    record.lastWorkLog === null
                      ? "gray"
                      : record.lastWorkLog.checkOut === null
                        ? "green"
                        : "gray",
                }}
              />
            </Space>
          );
        },
      },
      {
        title: this.props.t("profile-picture-label"),
        key: "profilePicture",
        onCell: () => {
          return {
            style: { textAlign: "center", verticalAlign: "middle" },
          };
        },
        render: (text, record) => {
          return (
            <Space size="middle">
              {record.filename !== null ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL
                    }/api/1.0.0/users/profile/${record.filename}?token=${this.props.token.split(" ")[1]
                    }`}
                  alt="profilepic"
                  style={{
                    width: "55px",
                    height: "55px",
                    objectFit: "cover",
                    borderRadius: "50% 50% 50% 50%",
                  }}
                />
              ) : (
                <NoPicture
                  style={{
                    width: "55px",
                    height: "55px",
                    objectFit: "cover",
                    borderRadius: "50% 50% 50% 50%",
                    backgroundColor: "#CECECE",
                  }}
                />
              )}
            </Space>
          );
        },
      },
      {
        title: this.props.t("guest-register-lastname-label"),
        dataIndex: "lastName",
        key: "lastName",
        filteredValue: this.state.filteredInfo.lastName || null,
        ...this.getColumnSearchProps(
          "lastName",
          this.props.t("guest-register-lastname-label")
        ),
      },
      {
        title: this.props.t("guest-register-firstname-label"),
        dataIndex: "firstName",
        key: "firstName",
        filteredValue: this.state.filteredInfo.firstName || null,
        ...this.getColumnSearchProps(
          "firstName",
          this.props.t("guest-register-firstname-label")
        ),
      },
      {
        title: this.props.t("profile-position-label"),
        dataIndex: "position",
        key: "position",
        filteredValue: this.state.filteredInfo.position || null,
        ...this.getColumnSearchProps(
          "position",
          this.props.t("profile-position-label")
        ),
      },
      {
        title: this.props.t("email-label"),
        dataIndex: "email",
        key: "email",
        filteredValue: this.state.filteredInfo.email || null,
        ...this.getColumnSearchProps("email", this.props.t("email-label")),
      },
      {
        title: this.props.t("workers-table-header-group"),
        key: "group",
        render: (text, record) => {
          let highestRight;
          const groupIds = record.groups.map((group) => group.id);
          if (groupIds.includes(2)) {
            highestRight = this.props.t("workers-group-companyadmin");
          } else if (groupIds.includes(3)) {
            highestRight = this.props.t("workers-group-reception");
          } else if (groupIds.includes(4)) {
            highestRight = this.props.t("workers-group-hr");
          } else {
            highestRight = this.props.t("workers-group-worker");
          }
          return <Space size="middle">{highestRight}</Space>;
        },
      },
      {
        title: this.props.t("workers-details-currently-working-hour-label"),
        key: "time",
        render: (string, data) => {
          const now = new Date();
          const fromTime = new Date(data.lastWorkLog?.checkIn);
          const diffTime = now.getTime() - fromTime.getTime();
          return (
            <Space size="middle">
              {data.lastWorkLog === null ? (
                "-"
              ) : data.lastWorkLog.checkOut !== null ? (
                "-"
              ) : (
                <div>
                  <Timer initialTime={diffTime} direction="forward">
                    {diffTime >= 86400000 && (
                      <>
                        <Timer.Days /> {this.props.t("working-hours-day")}{" "}
                      </>
                    )}
                    <Timer.Hours /> {this.props.t("working-hours-hour")}{" "}
                    <Timer.Minutes /> {this.props.t("working-hours-minute")}{" "}
                    <Timer.Seconds /> {this.props.t("working-hours-second")}
                  </Timer>
                </div>
              )}
            </Space>
          );
        },
      },
      {
        title: this.props.t("details-button-label"),
        key: "details",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.openDetails(record)}>
                <BarsOutlined />
              </Button>
            </Space>
          );
        },
      },
      {
        title: this.props.t("export-button-label"),
        key: "export",
        render: (text, record) => {
          return (
            <Menu style={{ position: "relative", top: "-2em" }} mode="vertical">
              <SubMenu key="sub1" icon={<DiffOutlined />} title="">
                <DatePicker picker="year" onChange={this.onChangeYear} disabledDate={this.exportDisabledDate} />
                <Menu.Item onClick={() => this.excelExport(record)}>
                  Excel {this.props.t("export-button-label")}
                </Menu.Item>
                <Menu.Item onClick={() => this.csvExport(record)}>
                  CSV {this.props.t("export-button-label")}
                </Menu.Item>
              </SubMenu>
            </Menu>
          );
        },
      },
    ];

    const worklogColumns = [
      {
        title: this.props.t("workers-details-table-header-checkin"),
        key: "checkIn",
        render: (text, record) => {
          return (
            <Space size="middle">
              {record.checkIn && !record.checkOut
                ? new Date(record.checkIn).toLocaleString()
                : null}
            </Space>
          );
        },
      },
      {
        title: this.props.t("workers-details-currently-working-hour-label"),
        key: "all",
        render: (text, record) => {
          const workingHours =
            (new Date() - new Date(record.checkIn)) / 3600000;
          const decimalPart =
            workingHours.toFixed(2) - Math.floor(workingHours.toFixed(2));
          const minutes = (decimalPart * 60).toFixed(0);
          const fixedWorkingHours = Math.floor(workingHours);
          const decider =
            record.checkIn && !record.checkOut
              ? fixedWorkingHours +
              this.props.t("working-hours-hour") +
              minutes +
              this.props.t("working-hours-minute")
              : null;
          return <Space size="middle">{decider}</Space>;
        },
      },
    ];

    const detailsColumns = [
      {
        title: this.props.t("workers-details-table-header-checkin"),
        key: "checkIn",
        render: (text, record) => {
          return (
            <>
              {this.state.checkIfModifyable === false ? (
                <Space direction="vertical" size={"middle"}>
                  {record.checkIn ? (
                    <TimePicker
                      style={{ width: "6em" }}
                      mode="date"
                      name="start"
                      minuteStep={5}
                      locale={{ dateTimeFormat: "HH:mm" }}
                      value={moment(record.checkIn)}
                      disabled={this.state.selectedId !== record.id}
                      showTime={{ format: "HH:mm" }}
                      format="HH:mm"
                      onChange={(event) => this.onChangeCheckIn(event, record)}
                    />
                  ) : (
                    this.props.t("workers-details-notcheckedin")
                  )}
                </Space>
              ) : (
                moment(record.checkIn).format("HH:mm")
              )}
            </>
          );
        },
      },
      {
        title: this.props.t("workers-details-table-header-checkout"),
        key: "checkOut",
        render: (text, record) => {
          return (
            <>
              {this.state.checkIfModifyable === false ? (
                <Space direction="vertical" size={"middle"}>
                  {record.checkOut ? (
                    <TimePicker
                      style={{ width: "6em" }}
                      mode="date"
                      name="end"
                      minuteStep={5}
                      locale={{ dateTimeFormat: "HH:mm" }}
                      value={moment(record.checkOut)}
                      disabled={this.state.selectedId !== record.id}
                      showTime={{ format: "HH:mm" }}
                      format="HH:mm"
                      onChange={(event) => this.onChangeCheckOut(event, record)}
                    />
                  ) : (
                    this.props.t("workers-details-notcheckedout")
                  )}
                </Space>
              ) : record.checkOut ? (
                moment(record.checkOut).format("HH:mm")
              ) : (
                this.props.t("workers-details-notcheckedout")
              )}
            </>
          );
        },
      },
      {
        title: this.props.t("worklog-status-type"),
        key: "status",
        render: (text, record) => {
          return this.state.checkIfModifyable === false ? (
            <Select
              style={{ width: "100%" }}
              value={this.state.statusType}
              onChange={this.changeEventTypes}
              disabled={this.state.checkIfModifyable}
            >
              <Select.Option key={1} value={1}>
                {this.props.t("workFromOffice")}
              </Select.Option>
              <Select.Option key={2} value={2}>
                {this.props.t("homeOffice")}
              </Select.Option>
              <Select.Option key={3} value={3}>
                {this.props.t("notWorking")}
              </Select.Option>
              <Select.Option key={4} value={4}>
                {this.props.t("onBreak")}
              </Select.Option>
              <Select.Option key={5} value={5}>
                {this.props.t("awayFromKeyboard")}
              </Select.Option>
              <Select.Option key={6} value={6}>
                {this.props.t("busy")}
              </Select.Option>
            </Select>
          ) : record.statusId === 1 ? (
            this.props.t("workFromOffice")
          ) : record.statusId === 2 ? (
            this.props.t("homeOffice")
          ) : record.statusId === 3 ? (
            this.props.t("notWorking")
          ) : record.statusId === 4 ? (
            this.props.t("onBreak")
          ) : record.statusId === 5 ? (
            this.props.t("awayFromKeyboard")
          ) : record.statusId === 6 ? (
            this.props.t("busy")
          ) : (
            []
          );
        },
      },
    ];

    return (
      <div>
        <Row>
          <Col span={24}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>{this.props.t("current-workers-title")}</strong>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={[...columns]}
              rowKey="id"
              dataSource={this.state.users}
              locale={{ emptyText: this.props.t("empty-text") }}
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: true,
              }}
              onChange={this.handleChangeTable}
            />
          </Col>
        </Row>
        <Modal
          visible={this.state.workdiaryVisible}
          onCancel={this.closeDiary}
          title={this.props.t("workdiary-button-label")}
          maskClosable={false}
          centered
          footer={null}
          bodyStyle={{ overflowY: "auto", maxHeight: "800px" }}
        >
          <div>
            <Row>
              <Col span={24}>
                <Table
                  columns={[...worklogColumns]}
                  rowKey="id"
                  dataSource={this.state.selectedWorklog}
                  locale={{ emptyText: this.props.t("empty-text") }}
                  pagination={{
                    pageSize: 1,
                    showSizeChanger: false,
                    disabled: true,
                    position: ["none"],
                  }}
                />
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          visible={this.state.detailsVisible}
          onCancel={this.closeDetails}
          title={this.props.t("details-button-label")}
          maskClosable={false}
          centered
          footer={null}
          width={880}
          bodyStyle={{ overflowY: "auto", maxHeight: "800px" }}
        >
          <div>
            <Row style={{ padding: "1em" }}>
              <Col span={24}>
                <div style={{ display: "flex" }}>
                  <div>
                    <strong>
                      {this.props.t("reception-events-table-header-name")}:
                    </strong>
                    &nbsp;
                  </div>
                  <div>
                    {this.state.selectedUser.lastName +
                      " " +
                      this.state.selectedUser.firstName}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <strong>{this.props.t("email-label")}:</strong>&nbsp;
                  </div>
                  <div>{this.state.selectedUser.email}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <strong>{this.props.t("profile-position-label")}:</strong>
                    &nbsp;
                  </div>
                  <div>{this.state.selectedUser.position}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <strong>{this.props.t("profile-picture-label")}:</strong>
                    &nbsp;
                  </div>
                  <div>
                    {this.state.selectedUser.filename !== null &&
                      this.state.selectedUser.filename !== undefined ? (
                      <img
                        src={`${process.env.REACT_APP_BASE_URL
                          }/api/1.0.0/users/profile/${this.state.selectedUser.filename
                          }?token=${this.props.token.split(" ")[1]}`}
                        alt="profilkép"
                        width={100}
                        height={100}
                      />
                    ) : (
                      this.props.t("workers-workers-noprofilepic")
                    )}
                  </div>
                </div>
                {this.hasRight([userRoles.COMPANY_ADMIN])}
                <Menu
                  style={{
                    width: "7em",
                    height: "3em",
                    position: "relative",
                    left: "4em",
                    top: "3em",
                  }}
                  mode="vertical"
                >
                  <SubMenu key="sub1" icon={<DiffOutlined />} title="...">
                    <Menu.ItemGroup
                      key="filter"
                      title={this.props.t("filters-button-label")}
                    >
                      <SubMenu
                        key="sub2"
                        title={this.props.t("filtration")}
                        icon={<FilterOutlined />}
                      >
                        <Menu.Item onClick={this.dailyWorklogSum} key="daily">
                          {this.props.t("daily-worklog-sum")}
                        </Menu.Item>
                        <Menu.Item onClick={this.weeklyWorklogSum} key="weekly">
                          {this.props.t("weekly-worklog-sum")}
                        </Menu.Item>
                        <Menu.Item
                          onClick={this.monthlyWorklogSum}
                          key="monthly"
                        >
                          {this.props.t("monthly-worklog-sum")}
                        </Menu.Item>
                        <Menu.Item onClick={this.resetFilterSum} key="reset">
                          {this.props.t(
                            "reception-guests-resetfilters-button-label"
                          )}
                        </Menu.Item>
                      </SubMenu>
                    </Menu.ItemGroup>
                  </SubMenu>
                </Menu>
              </Col>
              <Calendar
                dateCellRender={(record) => this.dateCellRender(record)}
                value={this.state.dateValue}
                onSelect={this.onSelect}
                disabledDate={
                  this.hasRight([userRoles.COMPANY_ADMIN])
                    ? null
                    : this.hasRight([userRoles.HR_ADMIN])
                      ? this.disabledHrDate
                      : this.disabledDate
                }
                onPanelChange={this.onPanelChange}
                style={{
                  width: "50em",
                  marginBottom: "1em",
                  height: "25em",
                  marginLeft: "3em",
                }}
                fullscreen={false}
                locale={hu_HU}
                centered
              />
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  columns={detailsColumns}
                  rowKey="id"
                  dataSource={decider}
                  locale={{ emptyText: this.props.t("empty-text") }}
                  summary={() => {
                    return (
                      <>
                        {this.state.weeklyFilter ||
                          this.state.dailyFilter ||
                          this.state.monthlyFilter === true ? (
                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                              {this.props.t("total-working-hours")}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell colSpan={4}>
                              <Text type="danger">
                                {this.state.summWorkingHour}
                              </Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  }}
                  pagination={{
                    position: ["bottomCenter"],
                    pageSize: 10,
                    showSizeChanger: false,
                  }}
                />
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  users: state.usersReducer.users,
  workingUsers: state.usersReducer.workingUsers,
  groups: state.usersReducer.groups,
  usersStatus: state.usersReducer.status,
});
const WorkingUsers = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkers)
);
export default WorkingUsers;
