import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "antd";
import {
  getAllCompanies,
  saveCompany,
  getAllSubscriptions,
  deleteCompany,
  modifyCompany,
  getAllAddresses,
  saveCompanyAddress,
} from "../../actions/company";
import { PlusOutlined } from "@ant-design/icons";
import { getAllLanguages } from "../../actions/language";
import { withTranslation } from "react-i18next";
import CompaniesList from "../../components/Companies/CompanyList/company-list";
import CreateOrUpdateCompanyModal from "../../components/Companies/CreateOrUpdateCompanyModal/createOrUpdateCompanyModal";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCompanies: () => dispatch(getAllCompanies()),
    getAllLanguages: () => dispatch(getAllLanguages()),
    getAllSubscriptions: () => dispatch(getAllSubscriptions()),
    saveCompany: (params) => dispatch(saveCompany(params)),
    deleteCompany: (companyId) => dispatch(deleteCompany(companyId)),
    modifyCompany: (companyId, params) =>
      dispatch(modifyCompany(companyId, params)),
    getAllAddresses: (companyId, officeId) =>
      dispatch(getAllAddresses(companyId, officeId)),
    saveCompanyAddress: (params) => dispatch(saveCompanyAddress(params)),
  };
};

class ConnectedCompaniesPage extends Component {
  formRef = React.createRef();

  state = {
    createModalVisible: false,
    newModalVisible: false,
    modalMode: "create",
    name: "",
    email: "",
    defaultLanguage: "",
    subscription: null,
    companyId: null,
    officeId: null,
    hasParklIntegration: false,
    parklId: null,
    file: [],
    selectedCompany: null,
  };

  componentDidMount = async () => {
    await this.props.getAllCompanies();
    await this.props.getAllLanguages();
    await this.props.getAllSubscriptions();
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheckboxChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  createOrUpdateCompany = async (companyData, isCreate = false) => {
    //TODO: change BE naming to clean this up
    const objectToSave = companyData;
    objectToSave.lang = companyData.langCode;
    objectToSave.subscription = companyData.subscriptionId;
    if (isCreate) {
      await this.props.saveCompany(companyData);
    } else {
      await this.props.modifyCompany(
        this.state.selectedCompany.id,
        objectToSave
      );
    }
    await this.props.getAllCompanies();
  };

  openNewModal = () => {
    this.setState({ modalMode: "create", createModalVisible: true });
  };

  closeNewModal = () => {
    this.setState({
      createModalVisible: false,
      selectedCompany: null,
    });
  };

  openEditModal = (company) => {
    this.setState({
      selectedCompany: company,
      createModalVisible: true,
      modalMode: "modify",
    });
  };

  deleteCompany = async (companyId) => {
    await this.props.deleteCompany(companyId);
    if (this.props.saveStatus) {
      await this.props.getAllCompanies();
    }
  };

  changeLanguage = (event) => {
    this.setState({ defaultLanguage: event });
  };
  
  changeSubscription = (event) => {
    this.setState({ subscription: event });
  };

  render() {
    return (
      <div>
        <Row>
          <Col span={12} style={{ padding: "1em" }}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>{this.props.t("companies-title")}</strong>
            </h1>
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col style={{ padding: "1em" }}>
            <Button type="primary" onClick={this.openNewModal}>
              <PlusOutlined />
              {this.props.t("companies-newcompany-title")}
            </Button>
          </Col>
        </Row>
        <Row>
          <CompaniesList
            companies={this.props.companies}
            editable={true}
            deleteCompany={this.deleteCompany}
            openEditModal={this.openEditModal}
          />
        </Row>
        <CreateOrUpdateCompanyModal
          modalVisible={this.state.createModalVisible}
          modalMode={this.state.modalMode}
          closeModal={this.closeNewModal}
          selectedCompany={this.state.selectedCompany}
          createOrUpdateCompany={this.createOrUpdateCompany}
          languages={this.props.languages}
          subscriptions={this.props.subscriptions}
          saveStatus={this.props.saveStatus}
          message={this.props.message}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  //REMOVE companies
  companies: state.companyReducer.companies,
  subscriptions: state.companyReducer.subscriptions,
  saveStatus: state.companyReducer.saveStatus,
  languages: state.languageReducer.languages,
  message: state.companyReducer.message,
  addresses: state.companyReducer.addresses,
  companyId: state.companyReducer.companyId,
});

const CompaniesPage = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedCompaniesPage)
);
export default CompaniesPage;
