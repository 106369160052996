import { LOGIN_ACTIONS } from "../constants/action-types";

const initialState = {
  token: null,
  user: {},
  message: {},
  status: false,
  apiKey: null,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ACTIONS.TOKEN_LOADING:
      return Object.assign({}, state, {
        token: null,
        user: {},
        message: "",
        status: false,
      });
    case LOGIN_ACTIONS.TOKEN_LOADED:
      return Object.assign({}, state, {
        token: action.payload.token,
        user: action.payload.user,
        message: "",
        status: true,
      });
    case LOGIN_ACTIONS.TOKEN_ERROR:
      return Object.assign({}, state, {
        token: null,
        user: {},
        message: action.payload !== undefined ? action.payload.message : {code:1002,message:"Invalid email or password"},
        status: false,
      });
    case LOGIN_ACTIONS.LOGOUT:
      return Object.assign({}, state, {
        token: null,
        user: {},
        message: "",
        status: false,
        apiKey: null,
      });
    case LOGIN_ACTIONS.API_KEY_LOADING:
      return Object.assign({}, state, {
        apiKey: null,
        status: false,
      });
    case LOGIN_ACTIONS.API_KEY_LOADED:
      return Object.assign({}, state, {
        apiKey: action.payload,
        status: true,
      });
    case LOGIN_ACTIONS.API_KEY_ERROR:
      return Object.assign({}, state, {
        apiKey: null,
        status: false,
        message: action.payload.message,
      });

    default:
      return state;
  }
};

export default loginReducer;
