import { EMERGENCY_ACTIONS } from "../constants/action-types";

const initialState = {
  emergency: false,
};

const emergencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMERGENCY_ACTIONS.EMERGENCY_ACTIVATE:
      return Object.assign({}, state, {
        emergency: true,
      });
    case EMERGENCY_ACTIONS.EMERGENCY_DEACTIVATE:
      return Object.assign({}, state, {
        emergency: false,
      });

    default:
      return state;
  }
};

export default emergencyReducer;
