import axios from "axios";
import { ENTRYPOINTS_ACTIONS } from "../constants/action-types";

export const getAllEntrypoints = () => {
  return async (dispatch) => {
    dispatch({ type: ENTRYPOINTS_ACTIONS.ENTRYPOINTS_LOADING });
    await axios
      .get("/2.0.0/entrypoint", {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: ENTRYPOINTS_ACTIONS.ENTRYPOINTS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ENTRYPOINTS_ACTIONS.ENTRYPOINTS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllEntrypointsByOffice = () => {
  return async (dispatch) => {
    dispatch({ type: ENTRYPOINTS_ACTIONS.OFFICE_ENTRYPOINTS_LOADING });
    await axios
      .get("/2.0.0/entrypoint/office", {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: ENTRYPOINTS_ACTIONS.OFFICE_ENTRYPOINTS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ENTRYPOINTS_ACTIONS.OFFICE_ENTRYPOINTS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getEntrypointById = (id) => {
  return async (dispatch) => {
    dispatch({ type: ENTRYPOINTS_ACTIONS.ENTRYPOINTS_LOADING });
    await axios
      .get("/2.0.0/entrypoint/" + id, {})
      .then((response) => {
        dispatch({
          type: ENTRYPOINTS_ACTIONS.ENTRYPOINTS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ENTRYPOINTS_ACTIONS.ENTRYPOINTS_REGISTER_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllEntrypointsByAddressId = (addressId) => {
  return async (dispatch) => {
    dispatch({ type: ENTRYPOINTS_ACTIONS.ENTRYPOINTS_LOADING });
    await axios
      .get("/2.0.0/entrypoint/" + addressId, {})
      .then((response) => {
        dispatch({
          type: ENTRYPOINTS_ACTIONS.ENTRYPOINTS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ENTRYPOINTS_ACTIONS.ENTRYPOINTS_REGISTER_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const createEntrypoints = (params) => {
  return async (dispatch) => {
    dispatch({ type: ENTRYPOINTS_ACTIONS.ENTRYPOINTS_REGISTER_START });
    await axios
      .post("/2.0.0/entrypoint/create", params, {
        //headers: { Authorization: token },
      })
      .then(() => {
        dispatch({ type: ENTRYPOINTS_ACTIONS.ENTRYPOINTS_REGISTER_SUCCESS }) ;
      })
      .catch((err) => {
        dispatch({
          type: ENTRYPOINTS_ACTIONS.ENTRYPOINTS_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const modifyEntrypoints = (params) => {
  return async (dispatch) => {
    dispatch({ type: ENTRYPOINTS_ACTIONS.ENTRYPOINTS_REGISTER_START });
    await axios
      .post("/2.0.0/entrypoint/modify", params, {})
      .then(() => {
        dispatch({ type: ENTRYPOINTS_ACTIONS.ENTRYPOINTS_REGISTER_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: ENTRYPOINTS_ACTIONS.ENTRYPOINTS_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const deleteEntrypoints = (id) => {
  return async () => {
    await axios.delete("/2.0.0/entrypoint/" + id).catch((err) => {
      console.log(err);
    });
  };
};
