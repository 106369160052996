import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Input,
  Form,
  message,
  Popconfirm,
  Checkbox,
  Switch,
  Dropdown,
  Menu,
  Tooltip,
  TimePicker,
  Badge,
  Calendar,
  Typography,
  Select,
} from "antd";
import {
  getAllUsers,
  getAllGroups,
  deleteUser,
  activateUser,
  saveImportedUsers,
  modifyUser,
  activateUsersInBatch,
  deactivateUsersInBatch,
  emptyImportErrors,
  addImportErrors,
  saveInvitedUsers,
  getAllUsersOffice,
  addOrRemoveCompanyAdminRole,
} from "../../actions/users";
import {
  createWorklog,
  deleteWorklog,
  modifyWorkLog,
} from "../../actions/reception";
import Highlighter from "react-highlight-words";
import {
  BarsOutlined,
  DeleteFilled,
  EditFilled,
  FilterOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
  ExportOutlined,
  DiffOutlined,
} from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import WizardImportUsers from "../Wizard/wizardImportUsers";
import Axios from "axios";
import { withTranslation } from "react-i18next";
import { ReactComponent as NoPicture } from "../../static/Artboard.svg";
import WizardDownloadTemplate from "../Wizard/wizardDownloadTemplate";
import WizardExcelImport from "../Wizard/wizardExcelImport";
import moment from "moment";
import hu_HU from "antd/lib/locale-provider/hu_HU";
import "./workers.scss";
// import jsPDF from "jspdf";
import "jspdf-autotable";
import i18n from "../../i18n";
import { getAllAddresses, getAllCompaniesByOfficeId } from "../../actions/company";
import { userRoles } from "../../constants/roles-types";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUsers: (groupId, companyId) => dispatch(getAllUsers(groupId, companyId)),
    getAllUsersOffice: (companyId) => dispatch(getAllUsersOffice(companyId)),
    getAllGroups: () => dispatch(getAllGroups()),
    deleteUser: (userId) => dispatch(deleteUser(userId)),
    activateUser: (userId) => dispatch(activateUser(userId)),
    activateUsersInBatch: (userIds) => dispatch(activateUsersInBatch(userIds)),
    deactivateUsersInBatch: (userIds) => dispatch(deactivateUsersInBatch(userIds)),
    saveImportedUsers: (saveObject) => dispatch(saveImportedUsers(saveObject)),
    saveInvitedUsers: (saveObject) => dispatch(saveInvitedUsers(saveObject)),
    modifyUser: (user) => dispatch(modifyUser(user)),
    emptyImportErrors: () => dispatch(emptyImportErrors()),
    addImportErrors: (errors) => dispatch(addImportErrors(errors)),
    modifyWorkLog: (params) => dispatch(modifyWorkLog(params)),
    deleteWorklog: (id) => dispatch(deleteWorklog(id)),
    createWorklog: (params) => dispatch(createWorklog(params)),
    getAllAddresses: (companyId, officeId) => dispatch(getAllAddresses(companyId, officeId)),
    getAllCompaniesByOfficeId: (officeId) => dispatch(getAllCompaniesByOfficeId(officeId)),
    addOrRemoveCompanyAdminRole: (userId, isCompanyAdmin) => dispatch(addOrRemoveCompanyAdminRole(userId, isCompanyAdmin))
  };
};
const { Text } = Typography;
const { SubMenu } = Menu;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

class ConnectedWorkers extends Component {
  state = {
    email: "",
    lastName: "",
    position: "",
    firstName: "",
    modifiedBy: "",
    statusType: "",
    phoneNumber: "",
    exportFormat: "",
    summWorkingHour: "",
    active: true,
    loaded: false,
    modify: false,
    modified: false,
    dailyFilter: false,
    weeklyFilter: false,
    monthlyFilter: false,
    visibleDecide: false,
    detailsVisible: false,
    newWorkerVisible: false,
    workdiaryVisible: false,
    checkIfModifyable: true,
    excelImportVisible: false,
    importWorkersVisible: false,
    downloadTemplateVisible: false,
    users: [],
    selectedWorklog: [],
    filteredWorklog: [],
    selectedUsersForActivate: [],
    filteredInfo: {},
    selectedUser: {},
    filterKey: null,
    selectedId: null,
    dateValue: moment(),
    selectedDateValue: moment(),
    end: new Date(),
    start: new Date(),
    checkIn: new Date(),
    checkOut: new Date(),
    selectedCompanyId: null,
    firstVehicle: null,
    secondVehicle: null,
    isUserCompanyAdmin: false,
  };

  formRef = React.createRef();
  componentDidMount = async () => {
    await this.props.getAllGroups();
    await this.getUsers();
    this.setState({ loaded: true });
  };

  getUsers = async () => {
    this.setState({ loaded: false });
    if (this.props.user.officeId) {
      await this.props.getAllUsersOffice(null);
      await this.props.getAllCompaniesByOfficeId(this.props.user.officeId);
    } else {
      await this.props.getAllUsers([2, 3, 4, 5], this.props.user.companyId);
    }
    const filteredusers = this.props.users.filter(
      (user) => user.deleted === false
    );
    if (this.props.usersStatus) {
      this.setState({ users: filteredusers });
    }
    this.setState({ loaded: true });
  };

  handleChangeActive = (event) => {
    this.setState({ active: event });
  };

  handleFilterNeverActivated = () => {
    const filteredUsers = this.props.users.filter(
      (user) => user.inviteSent === false && user.active === false
    );
    this.setState({ users: filteredUsers, filterKey: "never" });
  };

  handleFilterInactive = () => {
    const filteredUsers = this.props.users.filter(
      (user) => user.active === false
    );
    this.setState({ users: filteredUsers, filterKey: "inactive" });
  };

  handleFilterActive = () => {
    const filteredUsers = this.props.users.filter(
      (user) => user.active === true
    );
    this.setState({ users: filteredUsers, filterKey: "active" });
  };

  handleFilterEverybody = () => {
    this.setState({ users: this.props.users, filterKey: "everybody" });
  };

  handleResetFilter = async () => {
    await this.getUsers();
    this.resetFilter();
  };

  resetFilter = () => {
    this.setState({
      filteredInfo: {},
      searchedColumn: null,
      searchText: "",
      filterKey: null,
    });
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  getColumnSearchProps = (dataIndex, searchLabel, subDataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex, subDataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex, subDataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t("search-button-label")}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            {this.props.t("reset-button-label")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      subDataIndex ? (record[dataIndex][subDataIndex] || '').toString().toLowerCase().includes(value.toLowerCase())
        : (record[dataIndex] || '').toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  openInviteNewWorker = () => {
    this.setState({
      newWorkerVisible: true,
      email: "",
      firstName: "",
      lastName: "",
      position: "",
      phoneNumber: "",
      firstVehicle: null,
      secondVehicle: null
    });
  };

  openModifyWorker = (record) => {
    let vehicles = [];

    if (record.vehicles) {
      record.vehicles.forEach(vehicle => {
        vehicles.push(vehicle.licencePlateNumber);
      });
    }

    const isCompanyAdmin = record.groups.some(group => group.id === userRoles.COMPANY_ADMIN);

    this.setState({
      newWorkerVisible: true,
      email: record.email,
      firstName: record.firstName,
      lastName: record.lastName,
      position: record.position,
      phoneNumber: record.phoneNumber,
      selectedId: record.id,
      modify: true,
      active: record.active,
      selectedCompanyId: record.companyId,
      firstVehicle: vehicles.length > 0 ? vehicles[0] : null,
      secondVehicle: vehicles.length > 1 ? vehicles[1] : null,
      isUserCompanyAdmin: isCompanyAdmin
    });
    this.formRef.current.setFieldsValue({
      email: record.email,
      firstName: record.firstName,
      lastName: record.lastName,
      position: record.position,
      phoneNumber: record.phoneNumber,
      active: record.active,
      selectedCompanyId: record.companyId,
      firstVehicle: vehicles.length > 0 ? vehicles[0] : null,
      secondVehicle: vehicles.length > 1 ? vehicles[1] : null
    });
  };

  closeInviteNewWorker = () => {
    this.formRef.current.resetFields();
    this.setState({
      newWorkerVisible: false,
      email: "",
      firstName: "",
      lastName: "",
      position: "",
      phoneNumber: "",
      selectedId: null,
      modify: false,
      active: true,
      selectedCompanyId: null,
      firstVehicle: null,
      secondVehicle: null
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDeleteUser = async (record) => {
    await this.props.deleteUser(record.id);
    await this.getUsers();
  };

  handleActivateUser = async (record) => {
    await this.props.activateUser(record.id);
    await this.getUsers();
  };

  handleSubmit = async () => {
    if (this.state.modify) {
      const user = {
        id: this.state.selectedId,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        position: this.state.position,
        phoneNumber: this.state.phoneNumber,
        active: this.state.active,
        companyId: this.state.selectedCompanyId,
        firstVehicle: this.state.firstVehicle,
        secondVehicle: this.state.secondVehicle
      };
      await this.props.modifyUser(user);

      if (this.props.user.officeId) {
        await this.props.addOrRemoveCompanyAdminRole(user.id, this.state.isUserCompanyAdmin);
      }
      if (this.props.saveStatus) {
        message.success(this.props.t("save-success"), 5);
        await this.getUsers();
        this.closeInviteNewWorker();
      } else {
        message.error(this.props.t("save-fail"), 5);
      }
    } else {
      const userToAdd = {
        lastName: this.state.lastName,
        firstName: this.state.firstName,
        email: this.state.email,
        position: this.state.position,
        phoneNumber: this.state.phoneNumber,
        active: this.state.active,
        firstVehicle: this.state.firstVehicle,
        secondVehicle: this.state.secondVehicle
      };
      const saveObj = {
        users: [userToAdd],
        companyId: this.state.selectedCompanyId ? this.state.selectedCompanyId : this.props.user.companyId,
      };
      await this.props.saveInvitedUsers(saveObj);
      if (this.props.saveStatus) {
        message.success(this.props.t("workers-new-user-invite-success"), 5);
        await this.getUsers();
        this.closeInviteNewWorker();
      } else {
        message.error(this.props.t("workers-new-user-invite-fail"), 5);
      }
    }
  };

  handleClickInvite = async () => {
    await this.formRef.current.validateFields();
    const errors = this.formRef.current.getFieldsError();
    if (
      !errors.filter((error) => {
        return error.errors.length !== 0;
      })[0]
    ) {
      await this.handleSubmit();
    }
  };

  openImportWorkers = () => {
    this.props.emptyImportErrors();
    this.props.addImportErrors({
      duplicatedInSystem: [],
      emptyFirstName: [],
      emptyLastName: [],
      badEmail: [],
      duplicatedEmail: [],
    });
    this.setState({
      downloadTemplateVisible: true,
      excelImportVisible: false,
      importWorkersVisible: false,
    });
  };

  switchToExcelImport = () => {
    this.setState({
      excelImportVisible: true,
      downloadTemplateVisible: false,
      importWorkersVisible: false,
    });
  };

  openImportUsers = () => {
    this.setState({
      importWorkersVisible: true,
      downloadTemplateVisible: false,
      excelImportVisible: false,
    });
  };

  handleBackFromTemplate = () => {
    this.setState({
      downloadTemplateVisible: false,
      excelImportVisible: false,
      importWorkersVisible: false,
    });
  };

  handleBackFromExcelImport = () => {
    this.setState({
      downloadTemplateVisible: true,
      excelImportVisible: false,
      importWorkersVisible: false,
    });
  };

  closeImportWorkers = () => {
    this.setState({ importWorkersVisible: false });
  };

  closeImportWorkersWithRefresh = async () => {
    this.setState({ importWorkersVisible: false });
    await this.getUsers();
  };

  openDetails = async (record) => {
    this.setState({
      detailsVisible: true,
      weeklyFilter: false,
      monthlyFilter: false,
      selectedUser: record,
    });
    await Axios.get("/1.0.0/reception/worklogs", {
      params: { userId: record.id },
    })
      .then((response) => {
        this.setState({
          selectedWorklog: response.data.worklogs,
          start: record.checkIn,
          end: record.checkOut,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ selectedWorklog: [] });
      });
  };

  closeDetails = () => {
    this.setState({
      detailsVisible: false,
      weeklyFilter: false,
      monthlyFilter: false,
      selectedUser: {},
      selectedWorklog: [],
    });
  };

  handleChangeSelectedUser = (event, user) => {
    if (event.target.checked) {
      this.setState({
        selectedUsersForActivate: [
          ...this.state.selectedUsersForActivate,
          user.id,
        ],
      });
    } else {
      this.setState({
        selectedUsersForActivate: this.state.selectedUsersForActivate.filter(
          (id) => id !== user.id
        ),
      });
    }
  };

  handleSelectAllUsers = (event) => {
    if (
      event.target.checked &&
      this.state.selectedUsersForActivate.length < this.state.users.length - 1
    ) {
      this.setState({
        selectedUsersForActivate: this.state.users
          .filter((user) => user.id !== this.props.user.id)
          .map((user) => {
            return user.id;
          }),
      });
    } else if (
      event.target.checked &&
      this.state.selectedUsersForActivate.length === this.state.users.length - 1
    ) {
      this.setState({ selectedUsersForActivate: [] });
    } else if (event.target.checked === false) {
      this.setState({ selectedUsersForActivate: [] });
    }
  };

  handleActivateUsersInBatch = async () => {
    await this.props.activateUsersInBatch(this.state.selectedUsersForActivate);
    await this.getUsers();
  };

  handleDeactivateUsersInBatch = async () => {
    await this.props.deactivateUsersInBatch(
      this.state.selectedUsersForActivate
    );
    await this.getUsers();
  };

  renderFilterMenu = () => {
    return (
      <Menu activeKey={this.state.filterKey}>
        <Menu.Item onClick={this.handleFilterActive} key="active">
          {this.props.t("active-filter-button-label")}
        </Menu.Item>
        <Menu.Item onClick={this.handleFilterInactive} key="inactive">
          {this.props.t("inactive-filter-button-label")}
        </Menu.Item>
        <Menu.Item onClick={this.handleFilterNeverActivated} key="never">
          {this.props.t("filter-nonused-button-label")}
        </Menu.Item>
      </Menu>
    );
  };

  onChangeCheckIn = (event, record) => {
    let currentWorklog = this.state.selectedWorklog.filter(
      (log) => log.id === record.id
    )[0];
    const date = moment(event).toDate();
    currentWorklog.checkIn = date;
    const newWorklog = this.state.selectedWorklog.map((log) => {
      if (log.id === record.id) {
        log = currentWorklog;
      }
      return log;
    });
    this.setState({ selectedWorklog: newWorklog });
    this.setState({ checkIn: date });
  };

  onChangeCheckOut = (event, record) => {
    let currentWorklog = this.state.selectedWorklog.filter(
      (log) => log.id === record.id
    )[0];
    if (event === null) {
      this.setState({ end: new Date() });
    } else {
      const date = moment(event).toDate();
      currentWorklog.checkOut = date;

      const newWorklog = this.state.selectedWorklog.map((log) => {
        if (log.id === record.id) {
          log = currentWorklog;
        }
        return log;
      });
      this.setState({ selectedWorklog: newWorklog });
      this.setState({ checkOut: date });
    }
  };

  onChangeStatusType = (event, record) => {
    this.setState({ statusType: event });
  };

  disabledDate(record) {
    return !(
      moment().startOf("day").subtract(3, "days") < record &&
      record < moment().endOf("day")
    );
  }

  disabledHrDate(record) {
    return !(
      moment().startOf("day").subtract(45, "days") < record &&
      record < moment().endOf("day")
    );
  }

  disabledAdminDate(record) {
    return !(record < moment().endOf("day"));
  }

  handleModifyDate = async (record) => {
    const decider =
      record.checkIn === this.state.checkIn
        ? "(" + this.props.t("workers-details-table-header-checkin") + ")"
        : record.checkOut === this.state.checkOut
          ? "(" + this.props.t("workers-details-table-header-checkout") + ")"
          : "(" + this.props.t("companyadmin-regapp-table-header-inuse") + ")";

    const fullname = `${this.props.user.lastName} ${this.props.user.firstName} ${decider}`;
    const data = {
      checkIn:
        this.state.checkIn !== new Date()
          ? moment(record.checkIn).utc().format()
          : this.state.checkIn,
      checkOut:
        this.state.checkOut !== new Date()
          ? moment(record.checkOut).utc().format()
          : this.state.checkOut,
      statusId:
        this.state.statusType === "" ? record.statusId : this.state.statusType,
      modifiedBy: fullname,
      id: record.id,
      userId: this.state.selectedUser.id,
      homeOffice:
        this.state.statusType === this.props.t("homeOffice") ? true : false,
    };
    record.checkIn === this.state.checkIn ||
      record.checkOut === this.state.checkOut ||
      record.statusId !== this.state.statusType
      ? await this.props.modifyWorkLog(data)
      : message.warning(this.props.t("not-changed"));
    if (this.props.saveStatus) {
      this.setState({
        checkIn: "",
        checkOut: "",
        modifiedBy: "",
        id: "",
      });
    }
    this.setState({
      selectedId: "",
      checkIfModifyable: true,
    });
    await Axios.get("/1.0.0/reception/worklogs", {
      params: { userId: this.state.selectedUser.id },
    })
      .then((response) => {
        this.setState({
          selectedWorklog: response.data.worklogs,
          start: this.state.selectedUser.checkIn,
          end: this.state.selectedUser.checkOut,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ selectedWorklog: [] });
      });
  };

  handleCheckModifyDate = async (record) => {
    this.setState({
      selectedId: record.id,
      checkIfModifyable: false,
      statusType: record.statusId,
    });
  };

  handleCompanySelect = async (event) => {
    this.setState({
      selectedCompanyId: event,
    });
  };

  setCompanyAdmin = async (event) => {
    this.setState({
      isUserCompanyAdmin: event.target.checked,
    });
  };

  handleDeleteDate = async (record) => {
    await this.props.deleteWorklog(record.id);
    await Axios.get("/1.0.0/reception/worklogs", {
      params: { userId: this.state.selectedUser.id },
    })
      .then((response) => {
        this.setState({ selectedWorklog: response.data.worklogs });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ selectedWorklog: [] });
      });
  };

  cancelModifyDate = async () => {
    this.setState({
      selectedId: "",
      checkIfModifyable: true,
      start: moment(this.state.selectedUser.checkIn),
      end: moment(this.state.selectedUser.checkOut),
    });
    await Axios.get("/1.0.0/reception/worklogs", {
      params: { userId: this.state.selectedUser.id },
    })
      .then((response) => {
        this.setState({ selectedWorklog: response.data.worklogs });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ selectedWorklog: [] });
      });
  };

  hasRight = (rights, notAllowedSubscriptions) => {
    let allowed = false;
    this.props.user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allowed = true;
      }
    });
    if (allowed === true && notAllowedSubscriptions === undefined) {
      return true;
    } else {
      if (allowed === true) {
        if (this.allowedBySubscription(notAllowedSubscriptions)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  allowedBySubscription = (notAllowedSubscriptions) => {
    if (this.props.user.companyId === null) {
      return false;
    } else {
      if (
        notAllowedSubscriptions !== undefined &&
        notAllowedSubscriptions !== null
      ) {
        if (
          notAllowedSubscriptions.includes(
            this.props.user.company.subscriptionId
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  createWorklog = async () => {
    const data = {
      checkIn: this.state.selectedDateValue.format("YYYY-MM-DD"),
      checkOut: this.state.selectedDateValue.format("YYYY-MM-DD"),
      statusId: 1,
      isManualCheckIn: false,
      userId: this.state.selectedUser.id,
      homeOffice: false,
    };
    let doubleCheck = this.state.selectedWorklog.filter(
      (worklogs) =>
        moment(worklogs.checkIn).format("YYYY-MM-DD") === data.checkIn
    );
    if (doubleCheck.length === 0) {
      await this.props.createWorklog(data);
      if (this.props.saveStatus) {
        this.setState({
          checkIn: "",
          checkOut: "",
          userId: "",
        });
      }
      await Axios.get("/1.0.0/reception/worklogs", {
        params: { userId: this.state.selectedUser.id },
      })
        .then((response) => {
          this.setState({ selectedWorklog: response.data.worklogs });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ selectedWorklog: [] });
        });
    } else {
      message.error(this.props.t("worklog-already-exists-to-this-date"));
    }
  };

  getListData(record) {
    let listData;
    let checkinDate = this.state.selectedWorklog.filter(
      (worklogs) =>
        record.format("YYYY-MM-DD") ===
        moment(worklogs.checkIn).format("YYYY-MM-DD")
    );
    listData =
      checkinDate.length !== 0 ? (listData = [{ type: "success" }]) : [];

    return listData || [];
  }

  dateCellRender(record) {
    const listData = this.getListData(record);
    return (
      <ul>
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} />
          </li>
        ))}
      </ul>
    );
  }

  onSelect = (record) => {
    this.setState({
      dateValue: record,
      selectedDateValue: record,
      visibleDecide: false,
      monthlyFilter: false,
      dailyFilter: false,
      weeklyFilter: false,
    });
  };

  onPanelChange = (value) => {
    this.setState({ value });
  };

  weeklyWorklogSum = () => {
    this.setState({ visibleDecide: true });
    let nettoWorkingHour = this.state.selectedWorklog.map((log) => {
      if (
        moment(this.state.selectedDateValue).startOf("week") <=
        moment(log.checkIn) &&
        moment(log.checkOut) <=
        moment(this.state.selectedDateValue).endOf("week")
      ) {
        return log.checkOut - log.checkIn;
      } else {
        return 0;
      }
    });
    let allBreakHour = this.state.selectedWorklog.map((log) => {
      if (
        moment(this.state.selectedDateValue).startOf("week") <=
        moment(log.breakTime) <=
        moment(this.state.selectedDateValue).endOf("week")
      ) {
        return log.breakTime;
      } else {
        return 0;
      }
    });
    const allBreakTime = allBreakHour.reduce((a, b) => a + b, 0);
    const fixedAllBreakTime = allBreakTime / 3600000;
    const decPartBreak = fixedAllBreakTime - Math.floor(fixedAllBreakTime);
    const breakMins = (decPartBreak * 60).toFixed(0);
    const fixedBreakHour = Math.floor(fixedAllBreakTime);
    const totalBreakTime = `${fixedBreakHour} ${this.props.t(
      "working-hours-hour"
    )} ${breakMins} ${this.props.t("working-hours-minute")}`;

    const all = nettoWorkingHour.reduce((a, b) => a + b, 0);
    const fixedAll =
      fixedAllBreakTime >= 0
        ? all / 3600000 - fixedAllBreakTime
        : all / 3600000;
    const decPart = fixedAll - Math.floor(fixedAll);
    const mins = (decPart * 60).toFixed(0);
    const fixedHour = Math.floor(fixedAll);
    const total = `${fixedHour} ${this.props.t(
      "working-hours-hour"
    )} ${mins} ${this.props.t("working-hours-minute")}`;

    const nettoWorkingHourAndBreakTime = `${total} ${this.props.t(
      "netto-working-hour"
    )} ${fixedAllBreakTime <= 0
      ? totalBreakTime + this.props.t("all-breaktime")
      : ""
      }`;

    this.setState({
      summWorkingHour: nettoWorkingHourAndBreakTime,
      monthlyFilter: false,
      dailyFilter: false,
      weeklyFilter: true,
    });
  };

  monthlyWorklogSum = () => {
    this.setState({ visibleDecide: true });
    let nettoWorkingHour = this.state.selectedWorklog.map((log) => {
      if (
        moment(this.state.selectedDateValue).startOf("month") <=
        moment(log.checkIn) &&
        moment(log.checkOut) <=
        moment(this.state.selectedDateValue).endOf("month")
      ) {
        return log.checkOut - log.checkIn;
      } else {
        return 0;
      }
    });

    let allBreakHour = this.state.selectedWorklog.map((log) => {
      if (
        moment(this.state.selectedDateValue).startOf("month") <=
        moment(log.breakTime) <=
        moment(this.state.selectedDateValue).endOf("month")
      ) {
        return log.breakTime;
      } else {
        return 0;
      }
    });
    const allBreakTime = allBreakHour.reduce((a, b) => a + b, 0);
    const fixedAllBreakTime = allBreakTime / 3600000;
    const decPartBreak = fixedAllBreakTime - Math.floor(fixedAllBreakTime);
    const breakMins = (decPartBreak * 60).toFixed(0);
    const fixedBreakHour = Math.floor(fixedAllBreakTime);
    const totalBreakTime = `${fixedBreakHour} ${this.props.t(
      "working-hours-hour"
    )} ${breakMins} ${this.props.t("working-hours-minute")}`;

    const all = nettoWorkingHour.reduce((a, b) => a + b, 0);
    const fixedAll =
      fixedAllBreakTime >= 0
        ? all / 3600000 - fixedAllBreakTime
        : all / 3600000;
    const decPart = fixedAll - Math.floor(fixedAll);
    const mins = (decPart * 60).toFixed(0);
    const fixedHour = Math.floor(fixedAll);
    const total = `${fixedHour} ${this.props.t(
      "working-hours-hour"
    )} ${mins} ${this.props.t("working-hours-minute")}`;

    const nettoWorkingHourAndBreakTime = `${total} ${this.props.t(
      "netto-working-hour"
    )} ${fixedAllBreakTime <= 0
      ? totalBreakTime + this.props.t("all-breaktime")
      : ""
      }`;
    this.setState({
      summWorkingHour: nettoWorkingHourAndBreakTime,
      weeklyFilter: false,
      dailyFilter: false,
      monthlyFilter: true,
    });
  };

  dailyWorklogSum = () => {
    this.setState({ visibleDecide: true });
    let nettoWorkingHour = this.state.selectedWorklog.map((log) => {
      if (
        moment(this.state.selectedDateValue).startOf("day") <=
        moment(log.checkIn) &&
        moment(log.checkOut) <=
        moment(this.state.selectedDateValue).endOf("day")
      ) {
        return log.checkOut - log.checkIn;
      } else {
        return 0;
      }
    });
    let allBreakHour = this.state.selectedWorklog.map((log) => {
      if (
        moment(this.state.selectedDateValue).startOf("day") <=
        moment(log.breakTime) <=
        moment(this.state.selectedDateValue).endOf("day")
      ) {
        return log.breakTime;
      } else {
        return 0;
      }
    });
    const allBreakTime = allBreakHour.reduce((a, b) => a + b, 0);
    const fixedAllBreakTime = allBreakTime / 3600000;
    const decPartBreak = fixedAllBreakTime - Math.floor(fixedAllBreakTime);
    const breakMins = (decPartBreak * 60).toFixed(0);
    const fixedBreakHour = Math.floor(fixedAllBreakTime);
    const totalBreakTime = `${fixedBreakHour} ${this.props.t(
      "working-hours-hour"
    )} ${breakMins} ${this.props.t("working-hours-minute")}`;

    const all = nettoWorkingHour.reduce((a, b) => a + b, 0);
    const fixedAll =
      fixedAllBreakTime >= 0
        ? all / 3600000 - fixedAllBreakTime
        : all / 3600000;
    const decPart = fixedAll - Math.floor(fixedAll);
    const mins = (decPart * 60).toFixed(0);
    const fixedHour = Math.floor(fixedAll);
    const total = `${fixedHour} ${this.props.t(
      "working-hours-hour"
    )} ${mins} ${this.props.t("working-hours-minute")}`;

    const nettoWorkingHourAndBreakTime = `${total} ${this.props.t(
      "netto-working-hour"
    )} ${fixedAllBreakTime <= 0
      ? totalBreakTime + this.props.t("all-breaktime")
      : ""
      }`;
    this.setState({
      summWorkingHour: nettoWorkingHourAndBreakTime,
      dailyFilter: true,
      weeklyFilter: false,
      monthlyFilter: false,
    });
  };

  resetFilterSum = () => {
    this.setState({
      summWeekly: "",
      summMonthly: "",
      visibleDecide: false,
      dailyFilter: false,
      weeklyFilter: false,
      monthlyFilter: false,
    });
  };

  renderWorklogFilterMenu = () => {
    return (
      <Menu activeKey={this.state.filterKey}>
        <Menu.Item onClick={this.dailyWorklogSum} key="daily">
          {this.props.t("daily-worklog-sum")}
        </Menu.Item>
        <Menu.Item onClick={this.weeklyWorklogSum} key="weekly">
          {this.props.t("weekly-worklog-sum")}
        </Menu.Item>
        <Menu.Item onClick={this.monthlyWorklogSum} key="monthly">
          {this.props.t("monthly-worklog-sum")}
        </Menu.Item>
        <Menu.Item onClick={this.resetFilterSum} key="reset">
          {this.props.t("reception-guests-resetfilters-button-label")}
        </Menu.Item>
      </Menu>
    );
  };

  renderWorklogExportMenu = () => {
    return (
      <Menu activeKey={this.state.filterKey}>
        <Menu.Item onClick={this.excelExport} key="excel">
          {this.props.t("excel-button-label") + " (.xlsx)"}
        </Menu.Item>
        <Menu.Item onClick={this.csvExport} key="csv">
          {this.props.t("csv-button-label") + " (.csv)"}
        </Menu.Item>
        <Menu.Item onClick={this.pdfExport} key="pdf">
          {this.props.t("pdf-button-label") + " (.pdf)"}
        </Menu.Item>
      </Menu>
    );
  };

  changeEventTypes = (event) => {
    this.setState({
      statusType: event,
    });
  };

  excelExport = async () => {
    const name =
      this.state.selectedUser.lastName +
      " " +
      this.state.selectedUser.firstName +
      " ";
    const startDate =
      this.state.weeklyFilter === true
        ? moment(this.state.selectedDateValue).startOf("week")
        : this.state.monthlyFilter === true
          ? moment(this.state.selectedDateValue).startOf("month")
          : moment(this.state.selectedDateValue).startOf("day");

    const endDate =
      this.state.weeklyFilter === true
        ? moment(this.state.selectedDateValue).endOf("week")
        : this.state.monthlyFilter === true
          ? moment(this.state.selectedDateValue).endOf("month")
          : moment(this.state.selectedDateValue).endOf("day");
    let right;
    this.hasRight([userRoles.COMPANY_ADMIN, userRoles.RECEPTION_ADMIN]) ? (right = true) : (right = false);
    let toSend = {
      start: startDate.format("YYYY-MM-DD HH:mm"),
      end: endDate.format("YYYY-MM-DD HH:mm"),
      userId: this.state.selectedUser.id,
      language: i18n.language,
      right: right,
    };
    let url = "/2.0.0/reception/xlsx";
    await Axios.post(url, toSend, { responseType: "blob" })
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${name}_export_${new Date().toISOString()}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        message.error(this.props.t("empty-text"));
        console.log(err);
      });
  };

  csvExport = async () => {
    const name =
      this.state.selectedUser.lastName +
      " " +
      this.state.selectedUser.firstName +
      " ";
    const startDate =
      this.state.weeklyFilter === true
        ? moment(this.state.selectedDateValue).startOf("week")
        : this.state.monthlyFilter === true
          ? moment(this.state.selectedDateValue).startOf("month")
          : moment(this.state.selectedDateValue).startOf("day");

    const endDate =
      this.state.weeklyFilter === true
        ? moment(this.state.selectedDateValue).endOf("week")
        : this.state.monthlyFilter === true
          ? moment(this.state.selectedDateValue).endOf("month")
          : moment(this.state.selectedDateValue).endOf("day");
    let toSend = {
      start: startDate.format("YYYY-MM-DD HH:mm"),
      end: endDate.format("YYYY-MM-DD HH:mm"),
      userId: this.state.selectedUser.id,
    };
    let url = "/2.0.0/reception/csv";
    await Axios.post(url, toSend, { responseType: "blob" })
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${name}_export_${new Date().toISOString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        message.error(this.props.t("empty-text"));
        console.log(err);
      });
  };

  pdfExport = async () => {
    const name =
      this.state.selectedUser.lastName +
      " " +
      this.state.selectedUser.firstName +
      " ";
    const startDate =
      this.state.weeklyFilter === true
        ? moment(this.state.selectedDateValue).startOf("week")
        : this.state.monthlyFilter === true
          ? moment(this.state.selectedDateValue).startOf("month")
          : moment(this.state.selectedDateValue).startOf("day");

    const endDate =
      this.state.weeklyFilter === true
        ? moment(this.state.selectedDateValue).endOf("week")
        : this.state.monthlyFilter === true
          ? moment(this.state.selectedDateValue).endOf("month")
          : moment(this.state.selectedDateValue).endOf("day");
    let right;
    this.hasRight([userRoles.COMPANY_ADMIN, userRoles.RECEPTION_ADMIN]) ? (right = true) : (right = false);
    let toSend = {
      start: startDate.format("YYYY-MM-DD HH:mm"),
      end: endDate.format("YYYY-MM-DD HH:mm"),
      userId: this.state.selectedUser.id,
      language: i18n.language,
      right: right,
    };
    let url = "/2.0.0/reception/pdf";
    await Axios.post(url, toSend, { responseType: "blob" })
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${name}_export_${new Date().toISOString()}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        message.error(this.props.t("empty-text"));
        console.log(err);
      });
  };

  render() {
    moment.updateLocale("en", {
      week: {
        dow: 8,
      },
      weekdaysMin: [
        this.props.t("Sun"),
        this.props.t("Mon"),
        this.props.t("Tue"),
        this.props.t("Wed"),
        this.props.t("Thu"),
        this.props.t("Fri"),
        this.props.t("Sat"),
      ],
      monthsShort: [
        this.props.t("Jan"),
        this.props.t("Feb"),
        this.props.t("Mar"),
        this.props.t("Apr"),
        this.props.t("May"),
        this.props.t("Jun"),
        this.props.t("Jul"),
        this.props.t("Aug"),
        this.props.t("Sep"),
        this.props.t("Oct"),
        this.props.t("Nov"),
        this.props.t("Dec"),
      ],
    });

    let filteredLogs = this.state.selectedWorklog.filter(
      (worklogs) =>
        moment(worklogs.checkIn).format("YYYY-MM-DD") ===
        this.state.selectedDateValue.format("YYYY-MM-DD")
    );

    let weeklyfilteredLogs = this.state.selectedWorklog.filter(
      (worklogs) =>
        moment(this.state.selectedDateValue).startOf("week") <=
        moment(worklogs.checkIn) &&
        moment(worklogs.checkIn) <=
        moment(this.state.selectedDateValue).endOf("week")
    );

    let monthlyfilteredLogs = this.state.selectedWorklog.filter(
      (worklogs) =>
        moment(this.state.selectedDateValue).startOf("month") <=
        moment(worklogs.checkIn) &&
        moment(worklogs.checkIn) <=
        moment(this.state.selectedDateValue).endOf("month")
    );

    let decider =
      this.state.weeklyFilter === true
        ? weeklyfilteredLogs
        : this.state.monthlyFilter === true
          ? monthlyfilteredLogs
          : filteredLogs;

    let columns = [
      {
        title: (
          <Checkbox
            checked={
              this.state.selectedUsersForActivate.length ===
              this.state.users.length - 1
            }
            indeterminate={
              this.state.selectedUsersForActivate.length > 0 &&
              this.state.selectedUsersForActivate.length !==
              this.state.users.length - 1
            }
            onChange={this.handleSelectAllUsers}
          />
        ),
        key: "details",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Checkbox
                onChange={(event) =>
                  this.handleChangeSelectedUser(event, record)
                }
                checked={this.state.selectedUsersForActivate.includes(
                  record.id
                )}
                disabled={record.id === this.props.user.id}
              />
            </Space>
          );
        },
      },
      {
        title: this.props.t("profile-picture-label"),
        key: "profilePicture",
        onCell: () => {
          return {
            style: { textAlign: "center", verticalAlign: "middle" },
          };
        },
        render: (text, record) => {
          return (
            <Space size="middle">
              {record.filename !== null ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL
                    }/api/1.0.0/users/profile/${record.filename}?token=${this.props.token.split(" ")[1]
                    }`}
                  alt="profilepic"
                  style={{
                    width: "55px",
                    height: "55px",
                    objectFit: "cover",
                    borderRadius: "50% 50% 50% 50%",
                  }}
                />
              ) : (
                <NoPicture
                  style={{
                    width: "55px",
                    height: "55px",
                    objectFit: "cover",
                    borderRadius: "50% 50% 50% 50%",
                    backgroundColor: "#CECECE",
                  }}
                />
              )}
            </Space>
          );
        },
      },
      {
        title: this.props.t("guest-register-lastname-label"),
        dataIndex: "lastName",
        key: "lastName",
        filteredValue: this.state.filteredInfo.lastName || null,
        ...this.getColumnSearchProps(
          "lastName",
          this.props.t("guest-register-lastname-label")
        ),
      },
      {
        title: this.props.t("guest-register-firstname-label"),
        dataIndex: "firstName",
        key: "firstName",
        filteredValue: this.state.filteredInfo.firstName || null,
        ...this.getColumnSearchProps(
          "firstName",
          this.props.t("guest-register-firstname-label")
        ),
      }
    ];

    if (this.props.user.officeId) {
      columns.push(
        {
          title: this.props.t("profile-company-label"),
          dataIndex: "companyName",
          key: "companyName",
          filteredValue: this.state.filteredInfo.companyName || null,
          ...this.getColumnSearchProps(
            "company",
            this.props.t("profile-company-label"),
            "name"
          ),
          render: (text, record) => {
            return (<>{record.company.name}</>
            );
          },
        })
    }

    columns.push(
      {
        title: this.props.t("profile-position-label"),
        dataIndex: "position",
        key: "position",
        filteredValue: this.state.filteredInfo.position || null,
        ...this.getColumnSearchProps(
          "position",
          this.props.t("profile-position-label")
        ),
      },
      {
        title: this.props.t("email-label"),
        dataIndex: "email",
        key: "email",
        filteredValue: this.state.filteredInfo.email || null,
        ...this.getColumnSearchProps("email", this.props.t("email-label")),
      },
      {
        title: this.props.t("workers-table-header-group"),
        key: "group",
        render: (text, record) => {
          let highestRight;
          const groupIds = record.groups.map((group) => group.id);
          if (groupIds.includes(2)) {
            highestRight = this.props.t("workers-group-companyadmin");
          } else if (groupIds.includes(3)) {
            highestRight = this.props.t("workers-group-reception");
          } else if (groupIds.includes(4)) {
            highestRight = this.props.t("workers-group-hr");
          } else {
            highestRight = this.props.t("workers-group-worker");
          }
          return <Space size="middle">{highestRight}</Space>;
        },
      })

    if (!this.props.user.officeId) {
      columns.push(
        {
          title: this.props.t("details-button-label"),
          key: "details",
          render: (text, record) => {
            return (
              <Space size="middle">
                <Button onClick={() => this.openDetails(record)}>
                  <BarsOutlined />
                </Button>
              </Space>
            );
          },
        })
    }


    columns.push(
      {
        title: this.props.t("modify-button-label"),
        key: "modify",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.openModifyWorker(record)}>
                <EditFilled />
              </Button>
            </Space>
          );
        },
      },
      {
        title: this.props.t("activate-button-label"),
        key: "activate",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Switch
                checked={record.active}
                disabled={record.id === this.props.user.id}
                onChange={() => this.handleActivateUser(record)}
              />
            </Space>
          );
        },
      },
      {
        title: this.props.t("delete-button-label"),
        key: "delete",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title={this.props.t("workers-worker-delete-confirm")}
                okText={this.props.t("yes-button-label")}
                cancelText={this.props.t("no-button-label")}
                onConfirm={() => this.handleDeleteUser(record)}
                disabled={record.id === this.props.user.id}
              >
                <Button
                  disabled={record.id === this.props.user.id}
                  danger
                  type="primary"
                >
                  <DeleteFilled />
                </Button>
              </Popconfirm>
            </Space>
          );
        },
      })

    const detailsDeciderColumns = [
      this.state.visibleDecide === true
        ? {
          title: this.props.t("workers-details-table-header-checkin"),
          key: "checkIn",
          render: (text, record) => {
            return (
              <Space size="middle">
                {new Date(record.checkIn).toLocaleString()}
              </Space>
            );
          },
        }
        : {
          title: this.props.t("workers-details-table-header-checkin"),
          key: "checkIn",
          render: (text, record) => {
            return (
              <>
                {this.state.checkIfModifyable === false ? (
                  <Space direction="vertical" size={"middle"}>
                    {record.checkIn ? (
                      <TimePicker
                        style={{ width: "6em" }}
                        mode="date"
                        name="start"
                        minuteStep={5}
                        locale={{ dateTimeFormat: "HH:mm" }}
                        value={moment(record.checkIn)}
                        disabled={this.state.selectedId !== record.id}
                        showTime={{ format: "HH:mm" }}
                        format="HH:mm"
                        onChange={(event) =>
                          this.onChangeCheckIn(event, record)
                        }
                      />
                    ) : (
                      this.props.t("workers-details-notcheckedin")
                    )}
                  </Space>
                ) : record.checkIn ? (
                  moment(record.checkIn).format("HH:mm")
                ) : (
                  this.props.t("workers-details-notcheckedin")
                )}
              </>
            );
          },
        },
      this.state.visibleDecide === true
        ? {
          title: this.props.t("workers-details-table-header-checkout"),
          key: "checkOut",
          render: (text, record) => {
            return (
              <Space size="middle">
                {record.checkOut
                  ? new Date(record.checkOut).toLocaleString()
                  : this.props.t("workers-details-notcheckedout")}
              </Space>
            );
          },
        }
        : {
          title: this.props.t("workers-details-table-header-checkout"),
          key: "checkOut",
          render: (text, record) => {
            return (
              <>
                {this.state.checkIfModifyable === false ? (
                  <Space direction="vertical" size={"middle"}>
                    {record.checkOut ? (
                      <TimePicker
                        style={{ width: "6em" }}
                        mode="date"
                        name="end"
                        minuteStep={5}
                        locale={{ dateTimeFormat: "HH:mm" }}
                        value={moment(record.checkOut)}
                        disabled={this.state.selectedId !== record.id}
                        showTime={{ format: "HH:mm" }}
                        format="HH:mm"
                        onChange={(event) =>
                          this.onChangeCheckOut(event, record)
                        }
                      />
                    ) : (
                      this.props.t("workers-details-notcheckedout")
                    )}
                  </Space>
                ) : record.checkOut ? (
                  moment(record.checkOut).format("HH:mm")
                ) : (
                  this.props.t("workers-details-notcheckedout")
                )}
              </>
            );
          },
        },
    ];

    const detailsColumns = [
      {
        title: this.props.t("worklog-status-type"),
        key: "status",
        render: (text, record) => {
          return this.state.checkIfModifyable === false ? (
            <Select
              style={{ width: "12em" }}
              value={
                this.state.selectedId !== record.id
                  ? record.statusId
                  : this.state.statusType
              }
              onChange={(event) => this.onChangeStatusType(event, record)}
              disabled={this.state.selectedId !== record.id}
            >
              <Select.Option key={1} value={1}>
                {this.props.t("workFromOffice")}
              </Select.Option>
              <Select.Option key={2} value={2}>
                {this.props.t("homeOffice")}
              </Select.Option>
              <Select.Option key={3} value={3}>
                {this.props.t("notWorking")}
              </Select.Option>
              <Select.Option key={4} value={4}>
                {this.props.t("onBreak")}
              </Select.Option>
              <Select.Option key={5} value={5}>
                {this.props.t("awayFromKeyboard")}
              </Select.Option>
              <Select.Option key={6} value={6}>
                {this.props.t("busy")}
              </Select.Option>
            </Select>
          ) : record.statusId === 1 ? (
            this.props.t("workFromOffice")
          ) : record.statusId === 2 ? (
            this.props.t("homeOffice")
          ) : record.statusId === 3 ? (
            this.props.t("notWorking")
          ) : record.statusId === 4 ? (
            this.props.t("onBreak")
          ) : record.statusId === 5 ? (
            this.props.t("awayFromKeyboard")
          ) : record.statusId === 6 ? (
            this.props.t("busy")
          ) : (
            []
          );
        },
      },
    ];

    const detailsAdminColumns = this.hasRight([userRoles.COMPANY_ADMIN, userRoles.HR_ADMIN])
      ? [
        {
          title: this.props.t("workers-worklog-worktimetype-label"),
          key: "isManualCheckIn",
          render: (text, record) => {
            record.isManualCheckIn === 0
              ? (record.isManualCheckIn = this.props.t(
                "workers-worklog-worktimetype-manual"
              ))
              : (record.isManualCheckIn = this.props.t(
                "workers-worklog-worktimetype-automatic"
              ));
            return (
              <Space size="middle">
                {record.isPostCreatedWorklog
                  ? this.props.t("auto")
                  : record.isManualCheckIn}
              </Space>
            );
          },
        },
        {
          title: this.props.t("modifiedBy"),
          key: "modifiedBy",
          dataIndex: "modifiedBy",
        },
        {
          title: this.props.t("workers-workdiary-table-header-modify"),
          key: "modify",
          render: (text, record) => {
            const datenow = new Date();
            const createdDate = new Date(record.created);
            // const tomorrowDate = moment(createdDate).add(1, "days");
            // const endOfTomorrow = moment(tomorrowDate).endOf("day");
            const plusThirtyDays = moment(createdDate).add(30, "days");
            const endOfThirtyDays = moment(plusThirtyDays).endOf("day");
            return (
              <>
                {this.hasRight([userRoles.HR_ADMIN]) ? (
                  datenow <= endOfThirtyDays ? (
                    moment(record.checkIn).format("YYYY-MM-DD") ===
                      this.state.selectedDateValue.format("YYYY-MM-DD") ? (
                      <Space size="middle">
                        {record.id !== this.state.selectedId ? (
                          <Button
                            // disabled={disabled}
                            onClick={() => this.handleCheckModifyDate(record)}
                          >
                            <EditFilled />
                          </Button>
                        ) : (
                          <Space>
                            <Button
                              size="small"
                              type="primary"
                              onClick={() => this.handleModifyDate(record)}
                            >
                              <CheckOutlined />
                            </Button>
                            <Button
                              size="small"
                              onClick={() => this.cancelModifyDate(record)}
                              danger
                            >
                              <CloseOutlined />
                            </Button>
                          </Space>
                        )}
                      </Space>
                    ) : (
                      []
                    )
                  ) : (
                    []
                  )
                ) : this.hasRight([userRoles.COMPANY_ADMIN]) ? (
                  <Space size="middle">
                    {record.id !== this.state.selectedId ? (
                      <Button
                        // disabled={disabled}
                        onClick={() => this.handleCheckModifyDate(record)}
                      >
                        <EditFilled />
                      </Button>
                    ) : (
                      <Space>
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => this.handleModifyDate(record)}
                        >
                          <CheckOutlined />
                        </Button>
                        <Button
                          size="small"
                          onClick={() => this.cancelModifyDate(record)}
                          danger
                        >
                          <CloseOutlined />
                        </Button>
                      </Space>
                    )}
                  </Space>
                ) : // datenow <= endOfTomorrow ? (
                  moment(record.checkIn).format("YYYY-MM-DD") ===
                    this.state.selectedDateValue.format("YYYY-MM-DD") ? (
                    <Space size="middle">
                      {record.id !== this.state.selectedId ? (
                        <Button
                          // disabled={disabled}
                          onClick={() => this.handleCheckModifyDate(record)}
                        >
                          <EditFilled />
                        </Button>
                      ) : (
                        <Space>
                          <Button
                            size="small"
                            type="primary"
                            onClick={() => this.handleModifyDate(record)}
                          >
                            <CheckOutlined />
                          </Button>
                          <Button
                            size="small"
                            onClick={() => this.cancelModifyDate(record)}
                            danger
                          >
                            <CloseOutlined />
                          </Button>
                        </Space>
                      )}
                    </Space>
                  ) : (
                    []
                  )}
              </>
            );
          },
        },
        {
          title: this.props.t("workers-workdiary-table-header-delete"),
          key: "delete",
          render: (text, record) => {
            const datenow = new Date();
            const createdDate = new Date(record.created);
            const tomorrowDate = moment(createdDate).add(1, "days");
            const endOfTomorrow = moment(tomorrowDate).endOf("day");
            const plusThirtyDays = moment(createdDate).add(30, "days");
            const endOfThirtyDays = moment(plusThirtyDays).endOf("day");

            return (
              <>
                {this.hasRight([userRoles.HR_ADMIN]) ? (
                  datenow <= endOfThirtyDays ? (
                    moment(record.checkIn).format("YYYY-MM-DD") ===
                      this.state.selectedDateValue.format("YYYY-MM-DD") ? (
                      <Space size="middle">
                        <Popconfirm
                          title={this.props.t(
                            "workers-worklog-delete-confirm"
                          )}
                          okText={this.props.t("yes-button-label")}
                          cancelText={this.props.t("no-button-label")}
                          onConfirm={() => this.handleDeleteDate(record)}
                        >
                          <Button type="primary" danger>
                            <DeleteFilled />
                          </Button>
                        </Popconfirm>
                      </Space>
                    ) : (
                      []
                    )
                  ) : (
                    []
                  )
                ) : this.hasRight([userRoles.COMPANY_ADMIN]) ? (
                  moment(record.checkIn).format("YYYY-MM-DD") ===
                    this.state.selectedDateValue.format("YYYY-MM-DD") ? (
                    <Space size="middle">
                      <Popconfirm
                        title={this.props.t("workers-worklog-delete-confirm")}
                        okText={this.props.t("yes-button-label")}
                        cancelText={this.props.t("no-button-label")}
                        onConfirm={() => this.handleDeleteDate(record)}
                      >
                        <Button type="primary" danger>
                          <DeleteFilled />
                        </Button>
                      </Popconfirm>
                    </Space>
                  ) : (
                    []
                  )
                ) : datenow <= endOfTomorrow ? (
                  moment(record.checkIn).format("YYYY-MM-DD") ===
                    this.state.selectedDateValue.format("YYYY-MM-DD") ? (
                    <Space size="middle">
                      <Popconfirm
                        title={this.props.t("workers-worklog-delete-confirm")}
                        okText={this.props.t("yes-button-label")}
                        cancelText={this.props.t("no-button-label")}
                        onConfirm={() => this.handleDeleteDate(record)}
                      >
                        <Button type="primary" danger>
                          <DeleteFilled />
                        </Button>
                      </Popconfirm>
                    </Space>
                  ) : (
                    []
                  )
                ) : (
                  []
                )}
              </>
            );
          },
        },
      ]
      : [];

    return (
      <div>
        <Row>
          <Col span={24}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>{this.props.t("workers-title")}</strong>
            </h1>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ padding: "0.5em" }}>
                <Button
                  style={{ margin: "1em" }}
                  onClick={this.handleActivateUsersInBatch}
                  disabled={this.state.selectedUsersForActivate.length === 0}
                >
                  {this.props.t("workers-workers-activate-button-label")}
                </Button>
                <Button
                  style={{ margin: "1em" }}
                  onClick={this.handleDeactivateUsersInBatch}
                  disabled={this.state.selectedUsersForActivate.length === 0}
                >
                  {this.props.t("workers-workers-deactivate-button-label")}
                </Button>
              </div>
              <div style={{ float: "right", padding: "1em" }}>
                <Button
                  type="primary"
                  onClick={this.openInviteNewWorker}
                  disabled={
                    !this.props.user.officeId && (
                      this.props.user.company.subscription.maxUser === 0 ||
                      this.state.users.length >=
                      (this.props.user.company.subscription.maxUser === null
                        ? Number.MAX_SAFE_INTEGER
                        : this.props.user.company.subscription.maxUser))
                  }
                  style={{ margin: "1em" }}
                >
                  <PlusOutlined />
                  {this.props.t("workers-workers-newworker-button-label")}
                </Button>
                <Button
                  type="primary"
                  onClick={this.openImportWorkers}
                  disabled={
                    !this.props.user.officeId && (
                      this.props.user.company.subscription.maxUser === 0 ||
                      this.state.users.length >=
                      (this.props.user.company.subscription.maxUser === null
                        ? Number.MAX_SAFE_INTEGER
                        : this.props.user.company.subscription.maxUser))
                  }
                  style={{ margin: "1em" }}
                >
                  <PlusOutlined />
                  {this.props.t(
                    "workers-workers-newworkersimport-button-label"
                  )}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div style={{ display: "flex", padding: "0.5em" }}>
              <Dropdown overlay={this.renderFilterMenu} placement="bottomLeft">
                <Button icon={<FilterOutlined />} style={{ margin: "1em" }}>
                  {this.props.t("filters-button-label")}
                </Button>
              </Dropdown>
              <Button
                style={{ margin: "1em" }}
                onClick={this.handleResetFilter}
                disabled={
                  this.state.filterKey === null &&
                  Object.keys(this.state.filteredInfo).length === 0
                }
              >
                {this.props.t("reception-guests-resetfilters-button-label")}
              </Button>
              <div style={{ margin: "1em" }}>
                {this.props.t("never-activated-ammount")}{" "}
                {
                  this.props.users.filter(
                    (user) => user.inviteSent === false && user.active === false
                  ).length
                }{" "}
                <Tooltip title={this.props.t("never-activated-explanation")}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              rowKey="id"
              dataSource={this.state.users}
              locale={{ emptyText: this.props.t("empty-text") }}
              loading={!this.props.usersStatus && !this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: true,
              }}
              onChange={this.handleChangeTable}
            />
          </Col>
        </Row>
        <Modal
          visible={this.state.newWorkerVisible}
          onCancel={this.closeInviteNewWorker}
          title={
            this.state.modify
              ? this.props.t("modify-button-label")
              : this.props.t("workers-workers-newworker-button-label")
          }
          maskClosable={false}
          forceRender
          centered
          footer={[
            <Button
              key="invite"
              type="primary"
              onClick={this.handleClickInvite}
            >
              {this.state.modify
                ? this.props.t("modify-button-label")
                : this.props.t("invite-button-label")}
            </Button>,
            <Button key="cancel" onClick={this.closeInviteNewWorker}>
              {this.props.t("cancel-button-label")}
            </Button>,
          ]}
        >
          <Form
            onFinish={this.handleSubmit}
            ref={this.formRef}
            {...formItemLayout}
            name="design"
            scrollToFirstError
          >
            <Form.Item
              name="lastName"
              label={this.props.t("guest-register-lastname-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("guest-register-lastname-text"),
                },
              ]}
            >
              <Input name="lastName" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item
              name="firstName"
              label={this.props.t("guest-register-firstname-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("guest-register-firstname-text"),
                },
              ]}
            >
              <Input name="firstName" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item
              name="email"
              label={this.props.t("email-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("email-empty"),
                },
                {
                  type: "email",
                  message: this.props.t("email-wrong-format"),
                },
              ]}
            >
              <Input name="email" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item
              name="position"
              label={this.props.t("profile-position-label")}
            >
              <Input name="position" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={this.props.t("profile-phone-label")}
              rules={[
                {
                  pattern:
                    // eslint-disable-next-line no-useless-escape
                    /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/,
                  message: this.props.t("wrong-format"),
                },
                {
                  max: 15,
                  message: this.props.t("phone-number-max-digit")
                },
              ]}
            >
              <Input name="phoneNumber" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item name="active" label={this.props.t("active-input-label")}>
              <Switch
                checked={this.state.active}
                onChange={this.handleChangeActive}
              />
            </Form.Item>
            {this.props.user.officeId && (
              <>
                <Form.Item
                  name="selectedCompanyId"
                  label={this.props.t("profile-company-label")}
                  rules={[{ required: true }]}
                >
                  <Select
                    style={{ width: "15em" }}
                    onChange={(event) => this.handleCompanySelect(event)}
                    value={this.state.selectedCompanyId}
                  >
                    {this.props.companies.map((company) => {
                      return (
                        <Select.Option key={company.id} value={company.id}>
                          {company.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="isCompanyId"
                  label={this.props.t("workers-group-companyadmin")}
                >
                  <Checkbox
                    checked={this.state.isUserCompanyAdmin}
                    onChange={(event) => this.setCompanyAdmin(event)}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              name="firstVehicle"
              label={this.props.t("profile-vehicle-plate-number-n1")}
            >
              <Input
                name="firstVehicle"
                value={this.state.firstVehicle}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              name="secondVehicle"
              label={this.props.t("profile-vehicle-plate-number-n2")}
            >
              <Input
                name="secondVehicle"
                value={this.state.secondVehicle}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item hidden>
              <Button htmlType="submit" type="primary" hidden>
                {this.props.t("add-button-label")}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          visible={this.state.importWorkersVisible}
          onCancel={this.closeImportWorkers}
          title={this.props.t("workers-workers-newworkersimport-button-label")}
          maskClosable={false}
          centered
          footer={null}
          width={1300}
          bodyStyle={{ overflowY: "auto", maxHeight: "800px" }}
        >
          <div>
            <WizardImportUsers
              next={this.closeImportWorkersWithRefresh}
              wizard={false}
              companies={this.props.companies}
            />
          </div>
        </Modal>
        <Modal
          maskClosable={false}
          closable={true}
          footer={null}
          visible={this.state.downloadTemplateVisible}
          centered
          onCancel={this.handleBackFromTemplate}
        >
          <WizardDownloadTemplate
            next={this.switchToExcelImport}
            prev={this.handleBackFromTemplate}
          />
        </Modal>
        <Modal
          maskClosable={false}
          closable={true}
          footer={null}
          visible={this.state.excelImportVisible}
          centered
          onCancel={this.handleBackFromTemplate}
        >
          <WizardExcelImport
            next={this.openImportUsers}
            nextVisible={true}
            back={this.handleBackFromExcelImport}
            companies={this.props.companies}
          />
        </Modal>
        <Modal
          visible={this.state.detailsVisible}
          onCancel={this.closeDetails}
          title={this.props.t("details-button-label")}
          maskClosable={false}
          centered
          footer={null}
          width={880}
          bodyStyle={{ overflowY: "auto", maxHeight: "800px" }}
        >
          <div>
            <Row style={{ padding: "1em" }}>
              <Col span={24}>
                <div style={{ display: "flex" }}>
                  <div>
                    <strong>
                      {this.props.t("reception-events-table-header-name")}:
                    </strong>
                    &nbsp;
                  </div>
                  <div>
                    {this.state.selectedUser.lastName +
                      " " +
                      this.state.selectedUser.firstName}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <strong>{this.props.t("email-label")}:</strong>&nbsp;
                  </div>
                  <div>{this.state.selectedUser.email}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <strong>{this.props.t("profile-position-label")}:</strong>
                    &nbsp;
                  </div>
                  <div>{this.state.selectedUser.position}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <strong>{this.props.t("profile-picture-label")}:</strong>
                    &nbsp;
                  </div>
                  <div>
                    {this.state.selectedUser.filename !== null &&
                      this.state.selectedUser.filename !== undefined ? (
                      <img
                        src={`${process.env.REACT_APP_BASE_URL
                          }/api/1.0.0/users/profile/${this.state.selectedUser.filename
                          }?token=${this.props.token.split(" ")[1]}`}
                        alt="profilkép"
                        width={100}
                        height={100}
                      />
                    ) : (
                      this.props.t("workers-workers-noprofilepic")
                    )}
                  </div>
                </div>
                {this.hasRight([userRoles.COMPANY_ADMIN])}
                <Button
                  type="primary"
                  size="small"
                  style={{ position: "absolute", top: "0em", right: "1em" }}
                  onClick={() => this.createWorklog()}
                >
                  <PlusOutlined />
                </Button>
                <Menu
                  style={{
                    width: "7em",
                    height: "3em",
                    position: "relative",
                    left: "4em",
                    top: "3em",
                  }}
                  mode="vertical"
                >
                  <SubMenu key="sub1" icon={<DiffOutlined />} title="...">
                    <Menu.ItemGroup
                      key="filter"
                      title={this.props.t("filters-button-label")}
                    >
                      <SubMenu
                        key="sub2"
                        title={this.props.t("filtration")}
                        icon={<FilterOutlined />}
                      >
                        <Menu.Item onClick={this.dailyWorklogSum} key="daily">
                          {this.props.t("daily-worklog-sum")}
                        </Menu.Item>
                        <Menu.Item onClick={this.weeklyWorklogSum} key="weekly">
                          {this.props.t("weekly-worklog-sum")}
                        </Menu.Item>
                        <Menu.Item
                          onClick={this.monthlyWorklogSum}
                          key="monthly"
                        >
                          {this.props.t("monthly-worklog-sum")}
                        </Menu.Item>
                        <Menu.Item onClick={this.resetFilterSum} key="reset">
                          {this.props.t(
                            "reception-guests-resetfilters-button-label"
                          )}
                        </Menu.Item>
                      </SubMenu>
                    </Menu.ItemGroup>

                    <Menu.ItemGroup
                      key="exp"
                      title={this.props.t("export-button-label")}
                    >
                      <SubMenu
                        key="sub3"
                        title="Export"
                        icon={<ExportOutlined />}
                      >
                        <Menu.Item onClick={this.excelExport} key="excel">
                          {this.props.t("excel-button-label") + " (.xlsx)"}
                        </Menu.Item>
                        <Menu.Item onClick={this.csvExport} key="csv">
                          {this.props.t("csv-button-label") + " (.csv)"}
                        </Menu.Item>
                        <Menu.Item onClick={this.pdfExport} key="pdf">
                          {this.props.t("pdf-button-label") + " (.pdf)"}
                        </Menu.Item>
                      </SubMenu>
                    </Menu.ItemGroup>
                  </SubMenu>
                </Menu>
              </Col>
              <Calendar
                dateCellRender={(record) => this.dateCellRender(record)}
                value={this.state.dateValue}
                onSelect={this.onSelect}
                disabledDate={
                  this.hasRight([userRoles.COMPANY_ADMIN])
                    ? this.disabledAdminDate
                    : this.hasRight([userRoles.HR_ADMIN])
                      ? this.disabledHrDate
                      : this.disabledDate
                }
                onPanelChange={this.onPanelChange}
                style={{
                  width: "50em",
                  marginBottom: "1em",
                  height: "25em",
                  marginLeft: "3em",
                }}
                fullscreen={false}
                locale={hu_HU}
                centered
              />
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  columns={[
                    ...detailsDeciderColumns,
                    ...detailsColumns,
                    ...detailsAdminColumns,
                  ]}
                  rowKey="id"
                  dataSource={decider}
                  locale={{ emptyText: this.props.t("empty-text") }}
                  summary={() => {
                    return (
                      <>
                        {this.state.weeklyFilter ||
                          this.state.dailyFilter ||
                          this.state.monthlyFilter === true ? (
                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                              {this.props.t("total-working-hours")}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell colSpan={4}>
                              <Text type="danger">
                                {this.state.summWorkingHour}
                              </Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  }}
                  pagination={{
                    position: ["bottomCenter"],
                    showSizeChanger: true,
                  }}
                />
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  users: state.usersReducer.users,
  groups: state.usersReducer.groups,
  usersStatus: state.usersReducer.status,
  saveStatus: state.usersReducer.saveStatus,
  addresses: state.companyReducer.addresses,
  companies: state.companyReducer.companies,
});
const Workers = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkers)
);
export default Workers;
