import axios from "axios";
import { LOGIN_ACTIONS } from "../constants/action-types";
import HttpService from "../services/http";

export const login = (email, password) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_ACTIONS.TOKEN_LOADING });
    await axios
      .post("/1.0.0/users/login", {
        email,
        password,
      })
      .then((response) => {
        const tokenToSave = "Bearer " + response.data.accessToken;
        HttpService.setAxiosDefaults(tokenToSave);
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_LOADED,
          payload: { token: tokenToSave, user: response.data.user },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_ERROR,
          payload: err.response?.data,
        });
        console.log(err.message);
      });
  };
};

export const refreshUser = (userId) => {
  return async (dispatch) => {
    await axios
      .get("/1.0.0/users/refresh-user", {
        params: { userId: userId },
      })
      .then((response) => {
        const tokenToSave = "Bearer " + response.data.accessToken;
        HttpService.setAxiosDefaults(tokenToSave);
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_LOADED,
          payload: { token: tokenToSave, user: response.data.user },
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
};

export const loginWithToken = (token) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_ACTIONS.TOKEN_LOADING });
    await axios
      .get("/1.0.0/users/token-login", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        const tokenToSave = "Bearer " + response.data.accessToken;
        HttpService.setAxiosDefaults(tokenToSave);
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_LOADED,
          payload: { token: tokenToSave, user: response.data.user },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getApiKey = (token) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_ACTIONS.API_KEY_LOADING });
    await axios
      .get("/2.0.0/users/get-key", {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: LOGIN_ACTIONS.API_KEY_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_ACTIONS.API_KEY_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: LOGIN_ACTIONS.LOGOUT });
  };
};
