import { RECEPTION_ACTIONS } from "../constants/action-types";

const initialState = {
  invites: [],
  workers: [],
  events: [],
  guests: [],
  status: false,
  message: "",
  saveStatus: false,
  buttonDisabled: false,
  guestSaveStatus: false,
};

const receptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEPTION_ACTIONS.INVITES_LOADING:
      return Object.assign({}, state, {
        invites: [],
        status: false,
        message: "",
      });
    case RECEPTION_ACTIONS.INVITES_LOADED:
      return Object.assign({}, state, {
        invites: action.payload.invites,
        status: true,
        message: "",
      });
    case RECEPTION_ACTIONS.INVITES_ERROR:
      return Object.assign({}, state, {
        invites: [],
        status: false,
        message: action.payload,
      });
    case RECEPTION_ACTIONS.OFFICE_INVITES_LOADING:
      return Object.assign({}, state, {
        invites: [],
        status: false,
        message: "",
      });
    case RECEPTION_ACTIONS.OFFICE_INVITES_LOADED:
      return Object.assign({}, state, {
        invites: action.payload.invites,
        status: true,
        message: "",
      });
    case RECEPTION_ACTIONS.OFFICE_INVITES_ERROR:
      return Object.assign({}, state, {
        invites: [],
        status: false,
        message: action.payload,
      });
    case RECEPTION_ACTIONS.NON_INVITED_GUESTS_LOADING:
      return Object.assign({}, state, {
        guests: [],
        status: false,
        message: "",
      });
    case RECEPTION_ACTIONS.NON_INVITED_GUESTS_LOADED:
      return Object.assign({}, state, {
        guests: action.payload,
        status: true,
        message: "",
      });
    case RECEPTION_ACTIONS.NON_INVITED_GUESTS_ERROR:
      return Object.assign({}, state, {
        guests: [],
        status: false,
        message: action.payload,
      });
    case RECEPTION_ACTIONS.WORKERS_LOADING:
      return Object.assign({}, state, {
        workers: [],
        status: false,
        message: "",
      });
    case RECEPTION_ACTIONS.WORKERS_LOADED:
      return Object.assign({}, state, {
        workers: action.payload.workers,
        status: true,
        message: "",
      });
    case RECEPTION_ACTIONS.WORKERS_ERROR:
      return Object.assign({}, state, {
        workers: [],
        status: false,
        message: action.payload,
      });
    case RECEPTION_ACTIONS.EVENTS_LOADING:
      return Object.assign({}, state, {
        events: [],
        status: false,
        message: "",
      });
    case RECEPTION_ACTIONS.EVENTS_LOADED:
      return Object.assign({}, state, {
        events: action.payload.events,
        status: true,
        message: "",
      });
    case RECEPTION_ACTIONS.EVENTS_ERROR:
      return Object.assign({}, state, {
        events: [],
        status: false,
        message: action.payload,
      });
    case RECEPTION_ACTIONS.OFFICE_EVENTS_LOADING:
      return Object.assign({}, state, {
        events: [],
        status: false,
        message: "",
      });
    case RECEPTION_ACTIONS.OFFICE_EVENTS_LOADED:
      return Object.assign({}, state, {
        events: action.payload.events,
        status: true,
        message: "",
      });
    case RECEPTION_ACTIONS.OFFICE_EVENTS_ERROR:
      return Object.assign({}, state, {
        events: [],
        status: false,
        message: action.payload,
      });
    case RECEPTION_ACTIONS.INVITE_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
        buttonDisabled: true,
      });
    case RECEPTION_ACTIONS.INVITE_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
        buttonDisabled: false,
      });
    case RECEPTION_ACTIONS.INVITE_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
        buttonDisabled: false,
      });
    case RECEPTION_ACTIONS.WORKLOG_REGISTER_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case RECEPTION_ACTIONS.WORKLOG_REGISTER_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case RECEPTION_ACTIONS.WORKLOG_REGISTER_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case RECEPTION_ACTIONS.GUEST_SAVE_START:
      return Object.assign({}, state, {
        guestSaveStatus: false,
      });
    case RECEPTION_ACTIONS.GUEST_SAVE_SUCCESS:
      return Object.assign({}, state, {
        guestSaveStatus: true,
      });
    case RECEPTION_ACTIONS.GUEST_SAVE_ERROR:
      return Object.assign({}, state, {
        guestSaveStatus: false,
        message: action.payload,
      });
    case RECEPTION_ACTIONS.GUESTS_LIST_LOADING:
      return Object.assign({}, state, {
        guestsList: [],
        status: false,
        message: "",
      });
    case RECEPTION_ACTIONS.GUESTS_LIST_LOADED:
      return Object.assign({}, state, {
        guestsList: action.payload,
        status: true,
        message: "",
      });
    case RECEPTION_ACTIONS.GUESTS_LIST_ERROR:
      return Object.assign({}, state, {
        guestsList: [],
        status: false,
        message: action.payload,
      });
    case RECEPTION_ACTIONS.GUEST_RECEPTION_EVENTS_LOADING:
      return Object.assign({}, state, {
        guestReceptionEvents: [],
        status: false,
        message: "",
      });
    case RECEPTION_ACTIONS.GUEST_RECEPTION_EVENTS_LOADED:
      return Object.assign({}, state, {
        guestReceptionEvents: action.payload,
        status: true,
        message: "",
      });
    case RECEPTION_ACTIONS.GUEST_RECEPTION_EVENTS_ERROR:
      return Object.assign({}, state, {
        guestReceptionEvents: [],
        status: false,
        message: action.payload,
      });
      case RECEPTION_ACTIONS.GUEST_DEPARTURE_SAVE_START:
        return Object.assign({}, state, {
          guestReceptionEvents: [],
          status: false,
          message: "",
        });
      case RECEPTION_ACTIONS.GUEST_DEPARTURE_SAVE_SUCCESS:
        return Object.assign({}, state, {
          guestReceptionEvents: action.payload,
          status: true,
          message: "",
        });
      case RECEPTION_ACTIONS.GUEST_DEPARTURE_SAVE_ERROR:
        return Object.assign({}, state, {
          guestReceptionEvents: [],
          status: false,
          message: action.payload,
        });
    default:
      return state;
  }
};

export default receptionReducer;
