import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const mapDispatchToProps = (dispatch) => {
  return {};
};

class ConnectedBuildingUserPermissionPage extends Component {
  state = {};

  componentDidMount = async () => {};

  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
});
const BuildingUserPermissionPage = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedBuildingUserPermissionPage)
);

export default BuildingUserPermissionPage;
