import axios from "axios";
import { PROXIMITYCARD_ACTIONS } from "../constants/action-types";

export const getAllCardsByCompany = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_LOADING });
    await axios
      .get("/2.0.0/entrypoint/get-proxy-cards-for-company/" + companyId)
      .then((response) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllCardsByOffice = (officeId, companyId) => {
  return async (dispatch) => {
    dispatch({ type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_LOADING });
    await axios
      .get("/2.0.0/entrypoint/get-proxy-cards-by-office/" + officeId, { params: { companyId: companyId } })
      .then((response) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const importCard = (params) => {
  return async (dispatch) => {
    dispatch({ type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_START });
    await axios
      .post("/2.0.0/entrypoint/import", params)
      .then((response) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const importCardsOffice = (companyId, params) => {
  return async (dispatch) => {
    dispatch({ type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_START });
    await axios
      .post("/2.0.0/entrypoint/import-proxy-cards/" + companyId, params)
      .then((response) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const modifyActivationTime = (params) => {
  return async (dispatch) => {
    dispatch({ type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_START });
    await axios
      .put("/2.0.0/entrypoint/modify-proxy-activation-time", params)
      .then((response) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const activateCard = (cardId) => {
  return async (dispatch) => {
    dispatch({ type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_START });
    await axios
      .put("/2.0.0/entrypoint/activate-proxy/" + cardId)
      .then((response) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const banCard = (cardId) => {
  return async (dispatch) => {
    dispatch({ type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_START });
    await axios
      .put("/2.0.0/entrypoint/ban-proxy/" + cardId)
      .then((response) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const deleteCard = (cardId) => {
  return async (dispatch) => {
    dispatch({ type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_DELETE_START });
    await axios
      .delete("/2.0.0/entrypoint/delete-proxy-card/" + cardId)
      .then((response) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_DELETE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const addImportErrors = (errors) => {
  return (dispatch) => {
    dispatch({
      type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_IMPORT_ERRORS_ADD,
      payload: errors,
    });
  };
};

export const emptyImportErrors = () => {
  return (dispatch) => {
    dispatch({ type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_IMPORT_ERRORS_EMPTY });
  };
};

export const addAddressToCard = (params) => {
  return async (dispatch) => {
    dispatch({ type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_START });
    await axios
      .put("/2.0.0/entrypoint/modify-proxy-address", params)
      .then((response) => {
        dispatch({ type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_ERROR,
          payload: err?.response?.data,
        });
        console.log(err.message);
      });
  };
};

export const createCard = (params) => {
  return async (dispatch) => {
    dispatch({ type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_START });
    await axios
      .post("/3.0.0/proximity", params)
      .then((response) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PROXIMITYCARD_ACTIONS.PROXIMITYCARD_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};
