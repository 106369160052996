import { message } from "antd";
import axios from "axios";
import { MESSAGE_ACTIONS } from "../constants/action-types";

export const getMessages = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: MESSAGE_ACTIONS.MESSAGES_LOADING });
    await axios
      .get("/1.0.0/message", { params: { companyId: companyId } })
      .then((response) => {
        dispatch({
          type: MESSAGE_ACTIONS.MESSAGES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_ACTIONS.MESSAGES_ERROR,
          payload: err.response?.data,
        });
      });
  };
};

export const saveMessage = (params) => {
  return async (dispatch) => {
    dispatch({ type: MESSAGE_ACTIONS.MESSAGE_SAVE_START });
    await axios
      .post("/1.0.0/message", params)
      .then((response) => {
        dispatch({
          type: MESSAGE_ACTIONS.MESSAGE_SAVE_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_ACTIONS.MESSAGE_SAVE_ERROR,
          payload: err.response?.data,
        });
      });
  };
};

export const saveFirstMessage = (params) => {
  return async (dispatch) => {
    dispatch({ type: MESSAGE_ACTIONS.MESSAGE_SAVE_START });
    await axios
      .post("/1.0.0/message/first-message", params)
      .then((response) => {
        dispatch({
          type: MESSAGE_ACTIONS.MESSAGE_SAVE_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_ACTIONS.MESSAGE_SAVE_ERROR,
          payload: err.response?.data,
        });
      });
  };
};

export const modifyMessage = (params) => {
  return async (dispatch) => {
    dispatch({ type: MESSAGE_ACTIONS.MESSAGE_SAVE_START });
    await axios
      .put("/1.0.0/message", params)
      .then((response) => {
        dispatch({
          type: MESSAGE_ACTIONS.MESSAGE_SAVE_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_ACTIONS.MESSAGE_SAVE_ERROR,
          payload: err.response?.data,
        });
      });
  };
};

export const deleteMessage = (messageId) => {
  return async () => {
    await axios.delete("/1.0.0/message/" + messageId).catch((err) => {
      console.log(err.message);
      message.error("Sikertelen törlés!", 5);
    });
  };
};

export const activateMessage = (messageId) => {
  return async () => {
    await axios
      .get("/1.0.0/message/activate", { params: { messageId: messageId } })
      .catch((err) => {
        console.log(err.message);
        message.error("Sikertelen aktiválás!", 5);
      });
  };
};
