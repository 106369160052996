import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, message, Row, Col, Radio } from "antd"
import Axios from "axios";
import { withTranslation } from "react-i18next";
import hu from "../../static/hu.png";
import de from "../../static/de.png";
import en from "../../static/en.png";
import check from "../../static/check.png";
import i18n from "../../i18n";
import { getCompaniesByToken } from "../../actions/company"
import "./guest-register.scss"
import { CheckOutlined } from "@ant-design/icons"

const mapDispatchToProps = (dispatch) => {
  return {
      getCompaniesByToken: ( token ) => dispatch( getCompaniesByToken( token ) )
  };
};
class ConnectedGuestRegister extends Component {
  state = {
    lastName: "",
    firstName: "",
    representedCompany: "",
    hostFromCompany: "",
    hostlastname: "",
    comment: "",
    token: this.props.match.params.token,
    guest: null,
    host: null,
    company: null
  };

  componentDidMount = async () => {

      await this.props.getCompaniesByToken( this.props.match.params.token )

  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCompanyChange = ( event ) => {
      this.setState( { company: event.target.value } )
  };

  changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  submitForm = async () => {
    let objectToSend = {
      comment: this.state.comment,
      host: this.state.hostFromCompany,
      hostlastname: this.state.hostlastname,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      representedCompany: this.state.representedCompany,
    };

    if ( this.props.companies.length > 0 ) {
        objectToSend = { ...objectToSend, companyId: this.state.company }
    }

    await Axios.post("/1.0.0/reception/register-guest", objectToSend, {
      params: { token: this.state.token },
    })
      .then((response) => {
        this.setState({ host: response.data.host, guest: response.data.guest });
      })
      .catch((err) => {
        message.error(this.props.t("guest-register-error"), 5);
      });
  };

  render() {

    const companyRadios = []
    this.props.companies.forEach( company => companyRadios.push(
        ( <Radio className={ [ "company-radio" ] } value={ company.id } style={{ boxShadow: "0em 0em 0.5em 0 #CCC", borderRadius: "1em", marginBottom: "1em", position: "relative", width: "100%" }}>
            <img alt={ company.name } src={ `${ process.env.REACT_APP_BASE_URL }/api/1.0.0/company/logo/${ company.logoName }` } style={{ padding: "1em", width: "100%" }} />
            <div style={{ textAlign: "center" }}>{ company.name }</div>
            <div style={{ alignItems: "center", backgroundColor: "#00aea9", borderRadius: "50%", display: this.state.company === company.id ? "flex" : "none", height: "2em", justifyContent: "center", position: "absolute", right: "1em", top: "1em", width: "2em" }}>
                <CheckOutlined style={{ color: "white" }} />
            </div>
        </Radio> )
    ) )

    return (
      <Row>
        <Col span={24}>
          <div
            style={{
              padding: "20px",
              backgroundColor: "#00aea9",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "20%", maxWidth: "75px" }}>
              <img
                src={de}
                alt="de_flag"
                width={"100%"}
                onClick={() => this.changeLanguage("de")}
                style={{
                  opacity: i18n.language === "de" ? "1" : "0.7",
                  paddingBottom: "10px",
                  borderBottom: i18n.language === "de" ? "3px solid white" : "",
                }}
              />
            </div>
            <div style={{ width: "20%", maxWidth: "75px" }}>
              <img
                src={hu}
                alt="hu_flag"
                width={"100%"}
                onClick={() => this.changeLanguage("hu")}
                style={{
                  opacity: i18n.language === "hu" ? "1" : "0.7",
                  paddingBottom: "10px",
                  borderBottom: i18n.language === "hu" ? "3px solid white" : "",
                }}
              />
            </div>
            <div style={{ width: "20%", maxWidth: "75px" }}>
              <img
                src={en}
                alt="en_flag"
                width={"100%"}
                onClick={() => this.changeLanguage("en")}
                style={{
                  opacity: i18n.language === "en" ? "1" : "0.7",
                  paddingBottom: "10px",
                  borderBottom: i18n.language === "en" ? "3px solid white" : "",
                }}
              />
            </div>
          </div>
          {!this.state.guest && (
            <div>
              <div style={{ backgroundColor: "#00aea9" }}>
                <h1
                  style={{
                    textAlign: "center",
                    color: "#ffffff",
                    fontSize: "2.2rem",
                  }}
                >
                  {this.props.t("guest-register-title")}
                </h1>
              </div>
              <Form onFinish={this.submitForm}>
                { this.props.companies.length > 0 &&
                <Form.Item name="companyId" style={{ padding: "1em" }} rules={[
                    {
                        required: true,
                        message: this.props.t("messages-company-text"),
                    },
                ]}>
                    <Radio.Group onChange={ this.handleCompanyChange } value={ this.state.company } style={{ width: "100%" }}>
                        { companyRadios }
                    </Radio.Group>
                </Form.Item> }
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: this.props.t("guest-register-lastname-text"),
                    },
                  ]}
                  style={{
                    padding: "1em",
                  }}
                >
                  <Input
                    value={this.state.lastName}
                    name="lastName"
                    placeholder={this.props.t("guest-register-lastname-label")}
                    style={{ borderRadius: "25px", fontSize: "1.5rem" }}
                    onChange={this.handleChange}
                  />
                </Form.Item>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: this.props.t("guest-register-firstname-text"),
                    },
                  ]}
                  style={{
                    padding: "1em",
                  }}
                >
                  <Input
                    placeholder={this.props.t("guest-register-firstname-label")}
                    value={this.state.firstName}
                    name="firstName"
                    onChange={this.handleChange}
                    style={{ borderRadius: "25px", fontSize: "1.5rem" }}
                  />
                </Form.Item>
                <Form.Item
                  name="representedCompany"
                  rules={[
                    {
                      required: false,
                      message: this.props.t("guest-register-company-text"),
                    },
                  ]}
                  style={{
                    padding: "1em",
                  }}
                >
                  <Input
                    placeholder={this.props.t("guest-register-company-label")}
                    value={this.state.representedCompany}
                    name="representedCompany"
                    onChange={this.handleChange}
                    style={{ borderRadius: "25px", fontSize: "1.5rem" }}
                  />
                </Form.Item>
                <Form.Item
                  name="hostlastname"
                  rules={[
                    {
                      required: false,
                      message: this.props.t("guest-register-host-text"),
                    },
                  ]}
                  style={{
                    padding: "1em",
                  }}
                >
                  <Input
                    placeholder={`${this.props.t(
                      "guest-register-host-label"
                    )} ${this.props
                      .t("guest-register-lastname-label")
                      .toLowerCase()}`}
                    value={this.state.hostlastname}
                    name="hostlastname"
                    onChange={this.handleChange}
                    style={{ borderRadius: "25px", fontSize: "1.5rem" }}
                  />
                </Form.Item>
                <Form.Item
                  name="hostFromCompany"
                  rules={[
                    {
                      required: false,
                      message: this.props.t("guest-register-host-text"),
                    },
                  ]}
                  style={{
                    padding: "1em",
                  }}
                >
                  <Input
                    placeholder={`${this.props.t(
                      "guest-register-host-label"
                    )} ${this.props
                      .t("guest-register-firstname-label")
                      .toLowerCase()}`}
                    value={this.state.hostFromCompany}
                    name="hostFromCompany"
                    onChange={this.handleChange}
                    style={{ borderRadius: "25px", fontSize: "1.5rem" }}
                  />
                </Form.Item>
                <Form.Item
                  name="comment"
                  style={{
                    padding: "1em",
                  }}
                >
                  <Input.TextArea
                    rows={3}
                    value={this.state.comment}
                    name="comment"
                    onChange={this.handleChange}
                    placeholder={this.props.t("guest-register-comment-label")}
                    style={{ borderRadius: "25px", fontSize: "1.5rem" }}
                  />
                </Form.Item>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      fontSize: "2.2rem",
                      height: "5%",
                      borderRadius: "50px",
                      width: "60%",
                    }}
                  >
                    {this.props.t("register-button-label")}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
          {this.state.guest && (
            <div>
              <div style={{ backgroundColor: "#00aea9" }}>
                <h1
                  style={{
                    textAlign: "center",
                    color: "#ffffff",
                    fontSize: "2.2rem",
                  }}
                >
                  {this.props.t("guest-register-success")}
                </h1>
              </div>
              <div>
                <div
                  style={{
                    textAlign: "center",
                    margin: "40px 0 40px 0",
                  }}
                >
                  <img src={check} width={"40%"} alt="check" />
                </div>
                <div
                  style={{
                    textAlign: "center",
                    padding: "15px",
                    fontSize: "1.5rem",
                  }}
                >
                  <p>
                    {this.props.t("guest-details-register-dear")}{" "}
                    {this.state.guest.guest.firstName}
                  </p>
                  <p>{this.props.t("guest-details-checkin-text")}</p>
                  <p>
                    {this.props.t("guest-details-register-yours")}{" "}
                    {this.state.guest.host && (
                      <>
                        {this.state.guest.host.lastName}{" "}
                        {this.state.guest.host.firstName}
                      </>
                    )}
                    {!this.state.host && <>{this.state.guest.guest.company}</>}
                  </p>
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({
    companies: state.companyReducer.companies
});
const GuestRegister = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedGuestRegister)
);
export default GuestRegister;
