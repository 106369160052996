import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Input, Col, Row, Button, Modal, Tooltip, Select } from "antd";
import { getAllLanguages, saveCompanyText } from "../../actions/language";
import { modifyUserWizard } from "../../actions/users";
import {
  getOneCompanyById,
  saveCompanyAddress,
  saveCompanyTimezone,
  saveDefaultLanguage,
} from "../../actions/company";
import logo from "../../static/logo2.png";
import udv from "../../static/udv.png";
import orulunk from "../../static/orulunk.png";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import i18n from "../../i18n";
import { timezones } from "../../constants/timezones";
import HttpService from "../../services/http";
React.lazy(() => import("./wizard.scss"));

const mapDispatchToProps = (dispatch) => {
  return {
    saveCompanyText: (params) => dispatch(saveCompanyText(params)),
    modifyUserWizard: (user) => dispatch(modifyUserWizard(user)),
    getOneCompanyById: (companyId) => dispatch(getOneCompanyById(companyId)),
    saveCompanyAddress: (params) => dispatch(saveCompanyAddress(params)),
    saveCompanyTimezone: (params) => dispatch(saveCompanyTimezone(params)),
    saveDefaultLanguage: (companyId, languageId) =>
      dispatch(saveDefaultLanguage(companyId, languageId)),
    getAllLanguages: () => dispatch(getAllLanguages()),
  };
};
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

class ConnectedWizardBasicData extends Component {
  formRef = React.createRef();

  state = {
    selectedLanguageText: [],
    firstName: this.props.user.firstName,
    lastName: this.props.user.lastName,
    phoneNumber: this.props.user.phoneNumber,
    position: this.props.user.position,
    companyName: this.props.user.company.name,
    country: this.props.user.company.addresses[0]
      ? this.props.user.company.addresses[0].country
      : "",
    zip: this.props.user.company.addresses[0]
      ? this.props.user.company.addresses[0].zip
      : "",
    city: this.props.user.company.addresses[0]
      ? this.props.user.company.addresses[0].city
      : "",
    address: this.props.user.company.addresses[0]
      ? this.props.user.company.addresses[0].address
      : "",
    name: this.props.user.company.addresses[0]
      ? this.props.user.company.addresses[0].name
      : "",
    password: "",
    welcomeTitle: "",
    welcomeText: "",
    welcomeVisible: true,
    nextPage: false,
    timezone: this.props.user.company.timezone
      ? this.props.user.company.timezone
      : "Europe/Budapest",
  };

  componentDidMount = async () => {
    HttpService.setAxiosDefaults(this.props.token);
    await this.props.getAllLanguages();
    await this.props.getOneCompanyById(this.props.user.companyId);
    if (this.props.status) {
      const companyName = this.props.company[0].companyTexts.filter(
        (text) =>
          text.languageId === this.props.company[0].defaultLanguageId &&
          text.textId === 3
      );
      const welcomeTitle = this.props.company[0].companyTexts.filter(
        (text) =>
          text.languageId === this.props.company[0].defaultLanguageId &&
          text.textId === 2
      );
      const welcomeText = this.props.company[0].companyTexts.filter(
        (text) =>
          text.languageId === this.props.company[0].defaultLanguageId &&
          text.textId === 1
      );
      this.setState({
        companyName: companyName[0]
          ? companyName[0].textValue
          : this.props.user.company.name,
        welcomeText: welcomeText[0]?.textValue,
        welcomeTitle: welcomeTitle[0]?.textValue,
      });
      this.formRef.current.setFieldsValue({
        companyName: companyName[0]
          ? companyName[0].textValue
          : this.props.user.company.name,
        welcomeText: welcomeText[0]?.textValue,
        welcomeTitle: welcomeTitle[0]?.textValue,
      });
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSaveLanguage = async () => {
    const defaultLanguageTexts = [
      {
        companyId: this.props.user.company.id,
        textId: 3,
        textValue: this.state.companyName,
        languageId: this.props.user.company.defaultLanguageId,
        isActive: true,
      },
    ];
    const toSave = {
      texts: defaultLanguageTexts,
    };
    await this.props.saveCompanyText(toSave);
  };

  handleSaveAddress = async () => {
    if (!this.props.user.company.addresses[0]) {
      const toSave = {
        country: this.state.country,
        zip: this.state.zip,
        city: this.state.city,
        address: this.state.address,
        companyId: this.props.user.companyId,
        name: this.state.name,
        isPublic: true,
        isAbleToInvite: true,
        isParkingAvailable: true,
        isOutsideGate: true,
      };
      await this.props.saveCompanyAddress(toSave);
    }
  };

  handleSaveTimezone = async () => {
    const toSave = {
      timezone: this.state.timezone,
      companyId: this.props.user.companyId,
    };
    await this.props.saveCompanyTimezone(toSave);
  };

  handleSaveProfile = async () => {
    const userToSave = {
      id: this.props.user.id,
      email: this.props.user.email,
      password: this.state.password === "" || null ? "" : this.state.password,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      position: this.state.position,
      phoneNumber: this.state.phoneNumber,
      active: true,
    };
    await this.props.modifyUserWizard(userToSave);
  };

  submitForm = async () => {
    this.setState({ nextPage: true });
  };

  closeWelcome = () => {
    this.setState({ welcomeVisible: false });
  };

  switchToNextPage = async () => {
    this.setState({ nextPage: false });
    await this.handleSaveLanguage();
    await this.handleSaveProfile();
    if (!this.props.company[0]?.office) {
      await this.handleSaveAddress();
    }
    await this.handleSaveTimezone();
    if (this.props.languageStatus) {
      await this.props.refresh();
      this.props.next();
    }
  };

  closePopup = () => {
    this.setState({ nextPage: false });
  };

  handleChangeTimezone = (event) => {
    this.setState({ timezone: event });
  };

  handleChangeLanguage = async (event) => {
    const selectedlanguage = this.props.languages.find(
      (language) => language.code === event.toUpperCase()
    );
    if (selectedlanguage) {
      await this.props.saveDefaultLanguage(
        this.props.user.companyId,
        selectedlanguage.id
      );
      await this.props.refresh();
    }
    i18n.changeLanguage(event);
  };

  render() {
    return (
      <div style={{ margin: "0 auto", width: "20%" }}>
        <div>
          <h1>
            {this.props.t("wizard-basic-data-title")}{" "}
            <Tooltip
              title={this.props.t("wizard-basic-data-title-tooltip")}
              placement="right"
            >
              <QuestionCircleOutlined style={{ fontSize: "14px" }} />
            </Tooltip>
          </h1>
        </div>
        <div>
          <Form
            ref={this.formRef}
            {...formItemLayout}
            name="company"
            onFinish={this.submitForm}
            scrollToFirstError
            layout="vertical"
            wrapperCol={{ style: { padding: "0", margin: "0 0 0 0" } }}
          >
            <Form.Item
              label={
                <strong>{this.props.t("companies-label-companyname")}:</strong>
              }
              name="companyName"
              initialValue={this.state.companyName}
              rules={[
                {
                  required: true,
                  message: this.props.t("companies-companyname-text"),
                },
              ]}
            >
              <Input
                name="companyName"
                onChange={this.handleChange}
                value={this.state.companyName}
              />
            </Form.Item>
            <Form.Item
              label={
                <strong>
                  {this.props.t("companyadmin-address-timezone-label")}
                </strong>
              }
              name="timezone"
              initialValue={this.state.timezone}
              rules={[
                {
                  required: true,
                  message: this.props.t("wizard-basic-data-timezone-text"),
                },
              ]}
            >
              <Select onChange={this.handleChangeTimezone} showSearch>
                {timezones.map((timezone) => {
                  return (
                    <Select.Option
                      key={timezone.Index}
                      value={timezone.momentTimezone}
                    >
                      {timezone.Time}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            {!this.props.company[0]?.office && (
              <Form.Item
                label={
                  <strong>
                    {this.props.t("companyadmin-address-name-label")}:
                  </strong>
                }
                name="name"
                initialValue={this.state.name}
                rules={[
                  {
                    required: true,
                    message: this.props.t("companyadmin-address-name-text"),
                  },
                ]}
              >
                <Input
                  name="name"
                  onChange={this.handleChange}
                  value={this.state.name}
                />
              </Form.Item>
            )}

            {!this.props.company[0]?.office && (
              <Row>
                <Col span={12} style={{ paddingRight: "5px" }}>
                  <Form.Item
                    label={
                      <strong>
                        {this.props.t("companyadmin-address-country-label")}
                      </strong>
                    }
                    name="country"
                    initialValue={this.state.country}
                    rules={[
                      {
                        required: true,
                        message: this.props.t(
                          "companyadmin-address-country-text"
                        ),
                      },
                    ]}
                  >
                    <Input
                      name="country"
                      onChange={this.handleChange}
                      value={this.state.country}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ paddingLeft: "5px" }}>
                  <Form.Item
                    label={
                      <strong>
                        {this.props.t("companyadmin-address-zip-label")}
                      </strong>
                    }
                    name="zip"
                    initialValue={this.state.zip}
                    rules={[
                      {
                        required: true,
                        message: this.props.t("companyadmin-address-zip-text"),
                      },
                    ]}
                  >
                    <Input
                      name="zip"
                      onChange={this.handleChange}
                      value={this.state.zip}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {!this.props.company[0]?.office && (
              <Row>
                <Col span={12} style={{ paddingRight: "5px" }}>
                  <Form.Item
                    label={
                      <strong>
                        {this.props.t("companyadmin-address-city-label")}
                      </strong>
                    }
                    name="city"
                    initialValue={this.state.city}
                    rules={[
                      {
                        required: true,
                        message: this.props.t("companyadmin-address-city-text"),
                      },
                    ]}
                  >
                    <Input
                      name="city"
                      onChange={this.handleChange}
                      value={this.state.city}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ paddingLeft: "5px" }}>
                  <Form.Item
                    label={
                      <strong>
                        {this.props.t("companyadmin-address-address-label")}
                      </strong>
                    }
                    name="address"
                    initialValue={this.state.address}
                    rules={[
                      {
                        required: true,
                        message: this.props.t(
                          "companyadmin-address-address-text"
                        ),
                      },
                    ]}
                  >
                    <Input
                      name="address"
                      onChange={this.handleChange}
                      value={this.state.address}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Form.Item
              label={
                <strong>
                  {this.props.t("guest-register-lastname-label")}:
                </strong>
              }
              name="lastName"
              initialValue={this.props.user.lastName}
              rules={[
                {
                  required: true,
                  message: this.props.t("guest-register-lastname-text"),
                },
              ]}
            >
              <Input
                name="lastName"
                onChange={this.handleChange}
                value={this.state.lastName}
              />
            </Form.Item>
            <Form.Item
              label={
                <strong>
                  {this.props.t("guest-register-firstname-label")}:
                </strong>
              }
              name="firstName"
              initialValue={this.state.firstName}
              rules={[
                {
                  required: true,
                  message: this.props.t("guest-register-firstname-text"),
                },
              ]}
            >
              <Input
                name="firstName"
                onChange={this.handleChange}
                value={this.state.firstName}
              />
            </Form.Item>
            <Form.Item
              label={<strong>{this.props.t("profile-phone-label")}:</strong>}
              name="phoneNumber"
              initialValue={this.state.phoneNumber}
              rules={[
                {
                  //^[+][0-9()-\/]+$
                  pattern:
                  // eslint-disable-next-line no-useless-escape
                  /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/,
                  message: this.props.t("wrong-format"),
                },
                {
                  max: 15,
                  message: this.props.t("phone-number-max-digit"),
                },
              ]}
            >
              <Input
                name="phoneNumber"
                onChange={this.handleChange}
                value={this.state.phoneNumber}
              />
            </Form.Item>
            <Form.Item
              label={<strong>{this.props.t("profile-position-label")}:</strong>}
              name="position"
              initialValue={this.state.position}
            >
              <Input
                name="position"
                onChange={this.handleChange}
                value={this.state.position}
              />
            </Form.Item>

            <Row>
              <Col span={12}></Col>
            </Row>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit">
                {this.props.t("next-button-label")}
              </Button>
            </Form.Item>
          </Form>
        </div>
        <Modal
          visible={this.state.welcomeVisible}
          closable={false}
          footer={null}
          centered
          bodyStyle={{
            backgroundImage: `url("${udv}")`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            height: "600px",
          }}
          width={900}
        >
          <Row>
            <Col span={12}>
              <div style={{ textAlign: "left" }}>
                <div style={{ textAlign: "center" }}>
                  <img
                    src={logo}
                    alt="logo"
                    style={{ width: "15%", marginTop: "10px" }}
                  />
                </div>
                <h1
                  style={{
                    color: "#00AEA9",
                    marginTop: "15px",
                    fontSize: "2.5rem",
                    paddingLeft: "0.62em",
                  }}
                >
                  <strong>
                    {parse(this.props.t("wizard-welcome-popup-welcometext"))}
                  </strong>
                </h1>
                <p
                  style={{
                    fontStyle: "italic",
                    paddingLeft: "0.8em",
                    fontSize: "2rem",
                    color: "#00AEA9",
                    fontWeight: "500",
                    marginBottom: "0.5em",
                  }}
                >
                  {parse(
                    this.props.t(
                      "wizard-welcome-popup-welcometext-smartworkplace"
                    )
                  )}
                </p>
                <p style={{ paddingLeft: "1.5em", fontSize: "1.2rem" }}>
                  {this.props.t("wizard-welcome-popup-welcometext-help")}
                </p>
                <div style={{ paddingLeft: "1.5em", fontSize: "1.2rem" }}>
                  {this.props.t("choose-language")} -&nbsp;
                  <Select
                    style={{ width: "130px" }}
                    value={i18n.language}
                    onChange={this.handleChangeLanguage}
                  >
                    <Select.Option key="hu" value="hu">
                      Magyar
                    </Select.Option>
                    <Select.Option key="en" value="en">
                      English
                    </Select.Option>
                    <Select.Option key="de" value="de">
                      Deutsch
                    </Select.Option>
                  </Select>
                </div>
                <div style={{ paddingLeft: "2em", paddingTop: "1em" }}>
                  <Button
                    onClick={this.closeWelcome}
                    type="primary"
                    size="large"
                  >
                    {this.props.t("next-button-label")}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
        <Modal
          visible={this.state.nextPage}
          closable={false}
          footer={null}
          centered
          bodyStyle={{
            backgroundImage: `url("${orulunk}")`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            height: "600px",
          }}
          width={900}
        >
          <Row>
            <Col span={12}>
              <div style={{ textAlign: "left" }}>
                <h1
                  style={{
                    color: "#00AEA9",
                    marginTop: "0px",
                    fontSize: "2.5rem",
                    paddingLeft: "0.62em",
                  }}
                >
                  <strong>
                    {this.props.t("wizard-happy-popup-text")}
                    <br />
                    {this.state.lastName} {this.state.firstName}!
                  </strong>
                </h1>
                <p
                  style={{
                    paddingLeft: "1.5em",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                  }}
                >
                  {parse(this.props.t("wizard-happy-popup-profile-ready"))}
                </p>
                <p
                  style={{
                    paddingLeft: "1.5em",
                    paddingRight: "1.5em",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                  }}
                >
                  {parse(this.props.t("wizard-happy-popup-profile-tablet"))}
                </p>
                <div style={{ padding: "2em" }}>
                  <Button
                    onClick={this.closePopup}
                    size="large"
                    style={{ marginRight: "15px" }}
                  >
                    {this.props.t("back-button-label")}
                  </Button>
                  <Button
                    onClick={this.switchToNextPage}
                    type="primary"
                    size="large"
                  >
                    {this.props.t("next-button-label")}
                  </Button>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  textAlign: "center",
                  paddingLeft: "36px",
                  paddingTop: "20px",
                  color: "#F9F9F9",
                  fontSize: "1.2rem",
                }}
              >
                <div>
                  <strong>
                    {this.props.t("wizard-happy-popup-profile-customize")}
                  </strong>
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  languageStatus: state.languageReducer.saveStatus,
  userStatus: state.usersReducer.saveStatus,
  company: state.companyReducer.company,
  status: state.companyReducer.status,
  languages: state.languageReducer.languages,
});
const WizardBasicData = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedWizardBasicData)
);
export default WizardBasicData;
