import React, { Component } from "react";
import { message } from "antd";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Col, Row } from "antd";
import RoomsList from "../../components/Rooms/RoomsList/rooms-list";
import CreateOrUpdateRoomModal from "../../components/Rooms/CreateOrUpdateRoomModal/createOrUpdateRoomModal";

const mapDispatchToProps = (dispatch) => {
  return {};
};

class ConnectedBuildingRoomsPage extends Component {
  state = {
    loading: false,
    modalVisible: false,
    modalMode: "create",
    selectedRoom: null,
    rooms: [
      {
        id: 1,
        name: "Room A1",
        isPublic: true,
        type: 2,
        floor: 1,
        companies: [],
      },
      {
        id: 2,
        name: "Room A2",
        isPublic: false,
        type: 1,
        floor: 1,
        companies: [
          {
            id: 150,
            name: "WING",
          },
          {
            id: 176,
            name: "TK Elevator",
          },
          {
            id: 178,
            name: "NEO Property Sevices",
          },
        ],
      },
      {
        id: 3,
        name: "Room A3",
        isPublic: false,
        type: 1,
        floor: 1,
        companies: [
          {
            id: 176,
            name: "TK Elevator",
          },
          {
            id: 178,
            name: "NEO Property Sevices",
          },
        ],
      },
      {
        id: 4,
        name: "Room A4",
        isPublic: false,
        type: 3,
        floor: 1,
        companies: [
          {
            id: 178,
            name: "NEO Property Sevices",
          },
        ],
      },
      {
        id: 5,
        name: "Room B1",
        isPublic: false,
        type: 1,
        floor: 2,
        companies: [
          {
            id: 178,
            name: "NEO Property Sevices",
          },
        ],
      },
      {
        id: 6,
        name: "Room B2",
        isPublic: true,
        type: 4,
        floor: 2,
      },
    ],
  };

  componentDidMount = async () => {};

  successCreateMessage = () => {
    message.success(
      this.props.t("building-room-modal-create-success-message"),
      5
    );
  };

  successUpdateMessage = () => {
    message.success(
      this.props.t("building-room-modal-update-success-message"),
      5
    );
  };

  openCreateModal = () => {
    this.setState({ modalVisible: true, modalMode: "create" });
  };

  handleCancelModal = () => {
    this.setState({ modalVisible: false, selectedRoom: null });
  };

  openUpdateModal = (record) => {
    this.setState({
      modalVisible: true,
      modalMode: "update",
      selectedRoom: record,
    });
  };

  createRoom = async (roomData) => {
    this.successCreateMessage();
    this.handleCancelModal();
    //TODO
    /* const saveData = {
      companyId: this.props.user?.companyId ? this.props.user.companyId : null,
      officeId: this.props.user?.officeId ? this.props.user.officeId : null,
      name: entrypointData.name,
      addressId: entrypointData.addressId,
      deviceId: entrypointData.deviceId,
      controllerAddress: entrypointData.controllerAddress,
      rfidString: entrypointData.rfidString,
      openTime: parseInt(entrypointData.openTime),
      actuatorsState: parseInt(entrypointData.actuatorsState),
      desiredState: parseInt(entrypointData.desiredState),
      isExit: entrypointData.isExit,
      epId: entrypointData.epId,
    };

    await this.props.createEntrypoints(saveData);
    if (this.props.saveStatus) {
      this.successMessage();
      this.handleCancelModal();
      this.getAllEntrypoints();
    } else {
      if (this.props.message && this.props.message.code === 5404) {
        message.error(this.props.t("entrypoints-save-duplicate-error"));
      } else {
        message.error(this.props.t("entrypoints-save-error"));
      }
    }*/
  };

  updateRoom = async (roomData) => {
    this.successUpdateMessage();
    this.handleCancelModal();
    //TODO
    /* const saveData = {
      id: entrypointData.id,
      companyId: this.props.user?.companyId ? this.props.user.companyId : null,
      officeId: this.props.user?.officeId ? this.props.user.officeId : null,
      name: entrypointData.name,
      addressId: entrypointData.addressId,
      deviceId: entrypointData.deviceId,
      controllerAddress: entrypointData.controllerAddress,
      rfidString: entrypointData.rfidString,
      openTime: parseInt(entrypointData.openTime),
      actuatorsState: parseInt(entrypointData.actuatorsState),
      desiredState: parseInt(entrypointData.desiredState),
      isExit: entrypointData.isExit,
      epId: entrypointData.epId,
    };

    await this.props.modifyEntrypoints(saveData);
    if (this.props.saveStatus) {
      this.successMessage();
      this.handleCancelModal();
      this.getAllEntrypoints();
    } else {
      if (this.props.message && this.props.message.code === 5404) {
        message.error(this.props.t("entrypoints-save-duplicate-error"));
      } else {
        message.error(this.props.t("entrypoints-save-error"));
      }
    }*/
  };

  handleTableChange = () => {
    this.getAllRooms();
  };

  getAllRooms = async () => {
    this.setState({ loading: true });
    //TODO
    // await this.props.getAllEntrypoints();
    this.setState({
      loading: false,
    });
  };

  deleteRoom = async (roomId) => {
    //TODO
  };

  render() {
    return (
      <>
        <Row style={{ padding: "0.5em" }}>
          <Col span={24}>
            <RoomsList
              loading={this.state.loading}
              rooms={this.state.rooms}
              handleTableChange={this.handleTableChange}
              deleteRoom={this.deleteRoom}
              openUpdateModal={this.openUpdateModal}
            />
          </Col>
        </Row>
        <CreateOrUpdateRoomModal
          modalVisible={this.state.modalVisible}
          modalMode={this.state.modalMode}
          user={this.props.user}
          handleCancelModal={this.handleCancelModal}
          selectedRoom={this.state.selectedRoom}
          createRoom={this.createRoom}
          updateRoom={this.updateRoom}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
});
const BuildingRoomsPage = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedBuildingRoomsPage)
);

export default BuildingRoomsPage;
