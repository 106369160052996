import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  Input,
  message,
  Space,
  Tooltip,
  Popconfirm,
  Select,
} from "antd";
import {
  saveImportedUsers,
  getAllUsers,
  deleteUserByEmail,
  emptyImportErrors,
  checkIfEmailExists,
} from "../../actions/users";
import Modal from "antd/lib/modal/Modal";
import WizardExcelImport from "./wizardExcelImport";
import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import WizardDownloadTemplate from "./wizardDownloadTemplate";

const mapDispatchToProps = (dispatch) => {
  return {
    saveImportedUsers: (saveObject) => dispatch(saveImportedUsers(saveObject)),
    getAllUsers: (groupIds, companyId, activeOnly) =>
      dispatch(getAllUsers(groupIds, companyId, activeOnly)),
    deleteUserByEmail: (email) => dispatch(deleteUserByEmail(email)),
    emptyImportErrors: () => dispatch(emptyImportErrors()),
    checkIfEmailExists: (email) => dispatch(checkIfEmailExists(email)),
  };
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 20,
    },
  },
};

class ConnectedWizardImportUsers extends Component {
  state = {
    file: [],
    users: [],
    newUsers: [],
    lastName: "",
    firstName: "",
    email: "",
    position: "",
    phoneNumber: "",
    modify: false,
    modifyIndex: null,
    excelImportVisible: false,
    errorsVisible: false,
    errors: 0,
    downloadTemplateVisible: false,
    beforeEmail: "",
    selectedCompanyId: null,
  };

  formRef = React.createRef();

  componentDidMount = async () => {
    await this.getUsers();
    let errorNumber = 0;
    if (this.props.importErrors) {
      if (
        this.props.importErrors.duplicatedInSystem &&
        this.props.importErrors.duplicatedInSystem[0]
      ) {
        errorNumber =
          errorNumber + this.props.importErrors.duplicatedInSystem.length;
      }
      if (
        this.props.importErrors.emptyFirstName &&
        this.props.importErrors.emptyFirstName[0]
      ) {
        errorNumber =
          errorNumber + this.props.importErrors.emptyFirstName.length;
      }
      if (
        this.props.importErrors.emptyLastName &&
        this.props.importErrors.emptyLastName[0]
      ) {
        errorNumber =
          errorNumber + this.props.importErrors.emptyLastName.length;
      }
      if (
        this.props.importErrors.badEmail &&
        this.props.importErrors.badEmail[0]
      ) {
        errorNumber = errorNumber + this.props.importErrors.badEmail.length;
      }
      if (
        this.props.importErrors.duplicatedEmail &&
        this.props.importErrors.duplicatedEmail[0]
      ) {
        errorNumber =
          errorNumber + this.props.importErrors.duplicatedEmail.length;
      }
    }
    this.setState({ errors: errorNumber });
  };

  getUsers = async () => {
    await this.props.getAllUsers(
      [2, 3, 4, 5],
      this.props.user.companyId,
      false
    );
    if (this.props.status) {
      this.setState({ users: this.props.users });
    }
  };

  removeFile = () => {
    this.setState({ file: [] });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  checkIfEmailExists = async (email) => {
    await this.props.checkIfEmailExists(email);
    console.log(this.props.emailExists);
    if (this.props.emailExists) {
      return true;
    }
    return false;
  };

  checkIfEmailExistsInUsers = (email) => {
    if (this.state.users.filter((user) => user.email === email).length > 1) {
      return true;
    } else {
      return false;
    }
  };

  handleSubmit = async () => {
    const userToAdd = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      position: this.state.position,
      phoneNumber: this.state.phoneNumber,
    };

    if (this.state.modify) {
      if (this.state.beforeEmail !== this.state.email) {
        if (await this.checkIfEmailExists(this.state.email)) {
          message.error(
            `${this.props.t("wizard-excel-import-emailexists")} ${this.state.email
            }`,
            2
          );
          return;
        }
      }

      let users = this.state.users;
      let newUsers = this.state.newUsers;
      users[this.state.modifyIndex] = userToAdd;
      newUsers[this.state.modifyIndex - this.props.users.length] = userToAdd;
      this.setState({
        firstName: "",
        lastName: "",
        email: "",
        position: "",
        phoneNumber: "",
        users: [...users],
        modify: false,
        modifyIndex: null,
      });
    } else {
      if (await this.checkIfEmailExists(this.state.email)) {
        message.error(
          `${this.props.t("wizard-excel-import-emailexists")} ${this.state.email
          }`,
          2
        );
        return;
      }

      if (
        (this.props.user.officeId || this.props.user.company.subscription.maxUser === null
          ? Number.MAX_SAFE_INTEGER
          : this.props.user.company.subscription.maxUser) >=
        this.state.users.length
      ) {
        this.setState({
          firstName: "",
          lastName: "",
          email: "",
          position: "",
          phoneNumber: "",
          users: [...this.state.users, userToAdd],
          newUsers: [...this.state.newUsers, userToAdd],
        });
      } else {
        message.error(this.props.t("wizard-excel-maxuser-error"));
      }
    }
    this.formRef.current.resetFields();
  };

  saveUsers = async () => {
    const saveObj = {
      users: this.state.users,
      companyId: this.state.selectedCompanyId || this.props.user.companyId,
    };
    if (this.state.users.length === 0) {
      this.props.next();
    } else {
      await this.props.saveImportedUsers(saveObj);
      this.props.emptyImportErrors();
      if (this.props.saveStatus) {
        this.props.next();
      } else {
        console.log(this.props.message);
        if (this.props.message?.code && this.props.message.code === 1003) {
          message.error(
            `${this.props.t("wizard-excel-import-emailexists")} ${this.props.message.data.email
            }!`,
            2
          );
        } else if (this.props.message?.message && this.props.message?.data) {
          message.error(this.props.message.message + " " + this.props.message.data, 5);
        } else if (this.props.message?.message) {
          message.error(this.props.message.message, 5);
        } else {
          message.error("Hiba a felhasználók mentése során!", 5);
        }
      }
    }
  };

  handleDeleteFromImported = async (record) => {
    const filtered = this.state.users.filter(
      (user) => user.email !== record.email
    );
    await this.props.deleteUserByEmail(record.email);
    this.setState({ users: filtered });
  };

  cancelModify = () => {
    this.setState({ modify: false, modifyIndex: null, beforeEmail: "" });
    this.formRef.current.resetFields();
  };

  handleModifyImportedUser = (record, index) => {
    this.setState({
      modify: true,
      beforeEmail: record.email,
      email: record.email,
      firstName: record.firstName,
      lastName: record.lastName,
      position: record.position,
      phoneNumber: record.phoneNumber,
      modifyIndex: index,
    });
    this.formRef.current.setFieldsValue({
      email: record.email,
      firstName: record.firstName,
      lastName: record.lastName,
      position: record.position,
      phoneNumber: record.phoneNumber,
    });
  };

  handleCompanySelect = async (event) => {
    this.setState({
      selectedCompanyId: event,
    });
  };

  handleClickAddUser = async () => {
    await this.formRef.current.validateFields();
    const errors = this.formRef.current.getFieldsError();
    if (
      !errors.filter((error) => {
        return error.errors.length !== 0;
      })[0]
    ) {
      this.handleSubmit();
    }
  };

  openDownloadTemplate = () => {
    this.setState({ downloadTemplateVisible: true });
  };

  closeDownloadTemplate = () => {
    this.setState({ downloadTemplateVisible: false });
  };

  openExcelImport = () => {
    this.setState({ excelImportVisible: true, downloadTemplateVisible: false });
  };

  closeExcelImport = async () => {
    this.setState({ excelImportVisible: false });
    await this.getUsers();
  };

  openErrors = () => {
    this.setState({ errorsVisible: true });
  };

  closeErrors = () => {
    this.setState({ errorsVisible: false });
  };

  render() {
    const columns = [
      {
        title: (
          <h3>
            <strong>{this.props.t("guest-register-lastname-label")}</strong>
          </h3>
        ),
        key: "lastName",
        dataIndex: "lastName",
      },
      {
        title: (
          <h3>
            <strong>{this.props.t("guest-register-firstname-label")}</strong>
          </h3>
        ),
        key: "firstName",
        dataIndex: "firstName",
      },
      {
        title: (
          <h3>
            <strong>{this.props.t("email-label")}</strong>
          </h3>
        ),
        key: "email",
        dataIndex: "email",
      },
      {
        title: (
          <h3>
            <strong>{this.props.t("profile-position-label")}</strong>
          </h3>
        ),
        key: "position",
        dataIndex: "position",
      },
      {
        title: (
          <h3>
            <strong>{this.props.t("profile-phone-label")}</strong>
          </h3>
        ),
        key: "phone",
        dataIndex: "phoneNumber",
      },
      {
        title: (
          <h3>
            <strong>
              <EditOutlined />
            </strong>
          </h3>
        ),
        key: "modify",
        render: (text, record, index) => {
          return (
            <Space size="middle">
              <Button
                onClick={() => this.handleModifyImportedUser(record, index)}
                type="primary"
                disabled={
                  record.groups && record.groups.some((group) => group.id === 2)
                }
              >
                <EditOutlined />
              </Button>
            </Space>
          );
        },
      },
      {
        title: (
          <h3>
            <strong>
              <DeleteOutlined />
            </strong>
          </h3>
        ),
        key: "delete",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Popconfirm
                onConfirm={() => this.handleDeleteFromImported(record)}
                title={this.props.t("workers-worker-delete-confirm")}
                cancelText={this.props.t("no-button-label")}
                okText={this.props.t("yes-button-label")}
              >
                <Button
                  danger
                  type="primary"
                  disabled={
                    record.groups &&
                    record.groups.some((group) => group.id === 2)
                  }
                >
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Space>
          );
        },
      },
    ];

    return (
      <div>
        <Row style={{ margin: "10px 0 10px 0" }}>
          <Col span={8} style={{ padding: "1em" }}>
            <Form
              onFinish={this.handleSubmit}
              ref={this.formRef}
              {...formItemLayout}
              name="design"
              scrollToFirstError
              layout="vertical"
            >
              <Form.Item
                name="lastName"
                label={
                  <strong>
                    {this.props.t("guest-register-lastname-label")}:
                  </strong>
                }
                rules={[
                  {
                    required: true,
                    message: this.props.t("guest-register-lastname-text"),
                  },
                ]}
              >
                <Input name="lastName" onChange={this.handleChange} />
              </Form.Item>
              <Form.Item
                name="firstName"
                label={
                  <strong>
                    {this.props.t("guest-register-firstname-label")}:
                  </strong>
                }
                rules={[
                  {
                    required: true,
                    message: this.props.t("guest-register-firstname-text"),
                  },
                ]}
              >
                <Input name="firstName" onChange={this.handleChange} />
              </Form.Item>
              <Form.Item
                name="email"
                label={
                  <div>
                    <strong>{this.props.t("email-label")}:</strong>&nbsp;
                    <Tooltip
                      title={this.props.t("wizard-import-email-tooltip")}
                      placement="right"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: this.props.t("email-empty"),
                  },
                  {
                    type: "email",
                    message: this.props.t("email-wrong-format"),
                  },
                ]}
              >
                <Input
                  name="email"
                  onChange={this.handleChange}
                  disabled={this.state.modify}
                />
              </Form.Item>
              <Form.Item
                name="phoneNumber"
                label={<strong>{this.props.t("profile-phone-label")}:</strong>}
                rules={[
                  {
                    pattern:
                      /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))/,
                    message: this.props.t("wrong-format"),
                  },
                  {
                    max: 15,
                    message: this.props.t("phone-number-max-digit"),
                  },
                ]}
              >
                <Input name="phoneNumber" onChange={this.handleChange} />
              </Form.Item>
              <Form.Item
                name="position"
                label={
                  <strong>{this.props.t("profile-position-label")}:</strong>
                }
              >
                <Input name="position" onChange={this.handleChange} />
              </Form.Item>
              {this.props.user.officeId && (
                <>
                  <Form.Item
                    name="selectedCompanyId"
                    label={this.props.t("profile-company-label")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ width: "15em" }}
                      onChange={(event) => this.handleCompanySelect(event)}
                      value={this.state.selectedCompanyId}
                    >
                      {this.props.companies.map((company) => {
                        return (
                          <Select.Option key={company.id} value={company.id}>
                            {company.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </>
              )}

              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  style={{
                    background: "#000000",
                    borderColor: "#000000",
                    margin: "1em",
                  }}
                  htmlType="submit"
                >
                  {this.state.modify
                    ? this.props.t("save-button-label")
                    : this.props.t("add-button-label")}
                </Button>
                {this.state.modify && (
                  <Button onClick={this.cancelModify} style={{ margin: "1em" }}>
                    {this.props.t("cancel-button-label")}
                  </Button>
                )}
              </Form.Item>
              <Form.Item style={{ textAlign: "center" }}>
                <Button type="dashed" onClick={this.openDownloadTemplate}>
                  {this.props.t("wizard-import-from-excel-button")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={16} style={{ padding: "0.5em" }}>
            <div>
              {((this.props.importErrors.duplicatedInSystem &&
                this.props.importErrors.duplicatedInSystem.length > 0) ||
                (this.props.importErrors.emptyFirstName &&
                  this.props.importErrors.emptyFirstName.length > 0) ||
                (this.props.importErrors.emptyLastName &&
                  this.props.importErrors.emptyLastName.length > 0) ||
                (this.props.importErrors.badEmail &&
                  this.props.importErrors.badEmail.length > 0) ||
                (this.props.importErrors.duplicatedEmail &&
                  this.props.importErrors.duplicatedEmail.length > 0)) && (
                  <Button
                    danger
                    type="primary"
                    style={{ marginBottom: "0.4em" }}
                    onClick={this.openErrors}
                  >
                    {this.props.t("import-errors-button-label")} (
                    {this.state.errors})
                  </Button>
                )}
            </div>
            <Table
              dataSource={this.state.users}
              columns={columns}
              rowKey="E-mail"
              pagination={{
                total: this.state.users.length,
                pageSize: this.state.users.length,
                hideOnSinglePage: true,
              }}
              scroll={{ y: 500 }}
            />
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <div>
              {this.props.wizard && (
                <Button
                  style={{ margin: "1em" }}
                  onClick={() => this.props.prev()}
                >
                  {this.props.t("back-button-label")}
                </Button>
              )}
              <Button
                type="primary"
                onClick={this.saveUsers}
                style={{ margin: "1em" }}
              >
                {this.props.t("next-button-label")}
              </Button>
            </div>
          </Col>
        </Row>
        <Modal
          maskClosable={false}
          closable={true}
          footer={null}
          visible={this.state.excelImportVisible}
          centered
          onCancel={this.closeExcelImport}
        >
          <WizardExcelImport
            next={this.closeExcelImport}
            nextVisible={false}
            back={this.closeExcelImport}
          />
        </Modal>
        <Modal
          maskClosable={false}
          closable={true}
          footer={null}
          visible={this.state.errorsVisible}
          centered
          onCancel={this.closeErrors}
          title={this.props.t("import-errors-button-label")}
        >
          {this.props.importErrors.duplicatedEmail &&
            this.props.importErrors.duplicatedEmail[0] && (
              <div>
                <div>
                  <strong>{this.props.t("duplicate-email-infile")}</strong>
                </div>
                <div>
                  {this.props.importErrors.duplicatedEmail?.map((email) => {
                    return email.index + ". " + email.email + ", ";
                  })}
                </div>
              </div>
            )}
          {this.props.importErrors.duplicatedInSystem &&
            this.props.importErrors.duplicatedInSystem[0] && (
              <div>
                <div>
                  <strong>{this.props.t("duplicate-email-insystem")}</strong>
                </div>
                <div>
                  {this.props.importErrors.duplicatedInSystem?.map((email) => {
                    return email.email + ", ";
                  })}
                </div>
              </div>
            )}
          {this.props.importErrors.emptyFirstName &&
            this.props.importErrors.emptyFirstName[0] && (
              <div>
                <div>
                  <strong>{this.props.t("empty-firstname-label")}</strong>
                </div>
                <div>
                  {this.props.importErrors.emptyFirstName?.map((email) => {
                    return email.email + ", ";
                  })}
                </div>
              </div>
            )}
          {this.props.importErrors.emptyLastName &&
            this.props.importErrors.emptyLastName[0] && (
              <div>
                <div>
                  <strong>{this.props.t("empty-lastname-label")}</strong>
                </div>
                <div>
                  {this.props.importErrors.emptyLastName?.map((email) => {
                    return email.email + ", ";
                  })}
                </div>
              </div>
            )}
        </Modal>
        <Modal
          maskClosable={false}
          closable={true}
          footer={null}
          visible={this.state.downloadTemplateVisible}
          centered
          onCancel={this.closeDownloadTemplate}
        >
          <WizardDownloadTemplate
            next={this.openExcelImport}
            prev={this.closeDownloadTemplate}
          />
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  saveStatus: state.usersReducer.saveStatus,
  message: state.usersReducer.message,
  status: state.usersReducer.status,
  users: state.usersReducer.users,
  importErrors: state.usersReducer.importErrors,
  emailExists: state.usersReducer.emailExists,
});
const WizardImportUsers = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedWizardImportUsers)
);
export default WizardImportUsers;
