import { MESSAGE_ACTIONS } from "../constants/action-types";

const initialState = {
  messages: [],
  status: false,
  message: "",
  saveStatus: false,
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case MESSAGE_ACTIONS.MESSAGES_LOADING:
      return Object.assign({}, state, {
        messages: [],
        status: false,
        message: "",
      });
    case MESSAGE_ACTIONS.MESSAGES_LOADED:
      return Object.assign({}, state, {
        messages: action.payload.messages,
        status: true,
        message: "",
      });
    case MESSAGE_ACTIONS.MESSAGES_ERROR:
      return Object.assign({}, state, {
        messages: [],
        status: false,
        message: action.payload,
      });
    case MESSAGE_ACTIONS.MESSAGE_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case MESSAGE_ACTIONS.MESSAGE_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case MESSAGE_ACTIONS.MESSAGE_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });

    default:
      return state;
  }
};

export default messageReducer;
