import { Col, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addUsersToAddress,
  deleteCompanyAddress,
  getAddressById,
  getAllCompaniesByCompanyId,
  modifyCompanyAddress,
  saveCompanyAddress,
} from "../../actions/company";
import { withTranslation } from "react-i18next";
import { getAllUsers, getAllUsersByCompany } from "../../actions/users";
import { getAllCompanyAddresses } from "../../actions/office";
import AddressesList from "../../components/Addresses/AddressesList/addressesList";
import CreateOrUpdateAddressModal from "../../components/Addresses/CreateOrUpdateAddressModal/createOrUpdateAddressModal";
import UpdateSetOfUsersModal from "../../components/Users/UpdateSetOfUsersModal/updateSetOfUsersModal";
import { userRoles } from "../../constants/roles-types";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCompanyAddresses: (companyId) =>
      dispatch(getAllCompanyAddresses(companyId)),
    saveCompanyAddress: (params) => dispatch(saveCompanyAddress(params)),
    modifyCompanyAddress: (params) => dispatch(modifyCompanyAddress(params)),
    deleteCompanyAddress: (id) => dispatch(deleteCompanyAddress(id)),
    getAddressById: (addressId) => dispatch(getAddressById(addressId)),
    getAllUsers: (groupIds, companyId) =>
      dispatch(getAllUsers(groupIds, companyId)),
    getAllUsersByCompany: (companyId) => dispatch(getAllUsersByCompany(companyId)),
    addUsersToAddress: (params) => dispatch(addUsersToAddress(params)),
    getAllCompaniesByCompanyId: (companyId) =>
      dispatch(getAllCompaniesByCompanyId(companyId)),
  };
};

class ConnectedCompanyAddressesPage extends Component {
  formRef = React.createRef();

  state = {
    targetKeys: [],
    selectedKeys: [],
    companyName: "",
    baseTime: null,
    addressBaseTime: null,
    anonymizationTime: 30,
    officeAnonymizationTimeLimit: 30,
    companies: [],
    companyId: null,
    addCompany: false,

    createOrUpdateModalVisible: false,
    createOrUpdateModalMode: "modify",
    selectedAddress: null,
    office: null,
    updateSetOfUsersModalVisible: false,
    addeddUsers: [],
    allUsers: [],
  };

  componentDidMount = async () => {
    const companyId = this.props.user.companyId;
    await this.props.getAllCompaniesByCompanyId(companyId);
    this.fetchOfficeAddresses();
  };

  fetchOfficeAddresses = async () => {
    this.setState({ loadingAddresses: true });
    await this.props.getAllCompanyAddresses(this.props.user.companyId);
    this.setState({ loadingAddresses: false });
  };

  handleOpenModifyAddressModal = (record) => {
    this.setState({
      createOrUpdateModalVisible: true,
      createOrUpdateModalMode: "modify",
      selectedAddress: record,
    });
  };

  handleCloseCreateOrUpdateAddressModal = () => {
    this.setState({
      createOrUpdateModalVisible: false,
      createOrUpdateModalMode: "modify",
      selectedAddress: null,
    });
  };

  handleCloseUpdateSetOfUsersModal = () => {
    this.setState({
      updateSetOfUsersModalVisible: false,
      allUsers: [],
      addeddUserIds: [],
    });
  };

  handleOpenUpdateSetOfUsersModal = async (address) => {
    if (address.companyId) {
      await this.props.getAllUsers([userRoles.USER], address.companyId);
    }
    await this.props.getAddressById(address.id);
    if (this.props.status) {
      const target = this.props.address.users.map((user) => user.id);
      this.setState({
        updateSetOfUsersModalVisible: true,
        addeddUserIds: target,
        allUsers: this.props.users,
        addressId: address.id,
      });
    }
  };

  handleDeleteAddress = async (addressId) => {
    await this.props.deleteCompanyAddress(addressId);
    if (this.props.saveStatus) {
      this.fetchOfficeAddresses();
    }
  };

  handleSaveSelectedUsers = async (params) => {
    await this.props.addUsersToAddress(params);
    this.handleCloseUpdateSetOfUsersModal();
    await this.fetchOfficeAddresses()
  };

  handleDelete = async (id) => {
    await this.props.deleteCompanyAddress(id);
    if (this.props.saveStatus) {
      await this.props.getAllCompanyAddresses(
        this.props.user.companyId
          ? this.props.user.companyId
          : this.props.companyId
      );
    }
  };

  updateAddress = async (addressData) => {
    await this.props.modifyCompanyAddress(addressData);
    await this.fetchOfficeAddresses();
  };

  render() {
    return (
      <div style={{ overflowY: "auto" }}>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <AddressesList
              loading={this.state.loadingAddresses}
              addresses={this.props.addresses}
              handleOpenUpdateUsersToAddressModal={
                this.handleOpenUpdateUsersToAddressModal
              }
              handleOpenModifyAddressModal={this.handleOpenModifyAddressModal}
              handleDeleteAddress={this.handleDeleteAddress}
              handleOpenUpdateSetOfUsersModal={
                this.handleOpenUpdateSetOfUsersModal
              }
            />
          </Col>
        </Row>
        <CreateOrUpdateAddressModal
          modalVisible={this.state.createOrUpdateModalVisible}
          modalMode={this.state.createOrUpdateModalMode}
          companies={this.props.companies}
          handleCloseCreateOrUpdateAddressModal={
            this.handleCloseCreateOrUpdateAddressModal
          }
          selectedAddress={this.state.selectedAddress}
          office={this.state.office}
          createNewAddress={() => {}}
          updateAddress={this.updateAddress}
          saveStatus={this.props.saveStatus}
        />
        <UpdateSetOfUsersModal
          modalVisible={this.state.updateSetOfUsersModalVisible}
          handleCloseUpdateSetOfUsersModal={
            this.handleCloseUpdateSetOfUsersModal
          }
          addeddUserIds={this.state.addeddUserIds}
          allUsers={this.state.allUsers}
          addressId={this.state.addressId}
          handleSaveSelectedUsers={this.handleSaveSelectedUsers}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  company: state.companyReducer.company,
  status: state.companyReducer.status,
  addresses: state.companyReducer.addresses,
  address: state.companyReducer.address,
  saveStatus: state.companyReducer.saveStatus,
  users: state.usersReducer.users,
  officeStatus: state.officeReducer.status,
  companies: state.companyReducer.companies,
});
const CompanyAdressesPage = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedCompanyAddressesPage)
);
export default CompanyAdressesPage;
