import { EMERGENCY_ACTIONS } from "../constants/action-types";

export const activateEmergency = () => {
  return (dispatch) => {
    dispatch({ type: EMERGENCY_ACTIONS.EMERGENCY_ACTIVATE });
  };
};

export const deactivateEmergency = () => {
  return (dispatch) => {
    dispatch({ type: EMERGENCY_ACTIONS.EMERGENCY_DEACTIVATE });
  };
};
