/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import { Form, Input, Button, Col, Row, message } from "antd";
import { connect } from "react-redux";
import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { modifyProfileUser, deleteProfilePic } from "../../actions/users";
import { refreshUser } from "../../actions/login";
import HttpService from "../../services/http";
import { withTranslation } from "react-i18next";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 4,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

const mapDispatchToProps = (dispatch) => {
  return {
    modifyProfileUser: (formData) => dispatch(modifyProfileUser(formData)),
    refreshUser: (userId) => dispatch(refreshUser(userId)),
    deleteProfilePicture: (userId) => dispatch(deleteProfilePic(userId)),
  };
};

class ConnectedProfile extends Component {
  formRef = React.createRef();

  state = {
    email: "",
    password: "",
    oldPassword: "",
    firstname: "",
    lastname: "",
    // position: "",
    phoneNumber: "",
    file: [],
  };

  componentDidMount = () => {
    const { user } = this.props;
    this.setState({
      firstname: user.firstName,
      lastname: user.lastName,
      email: user.email,
      // position: user.position,
      phoneNumber: user.phoneNumber,
    });

    this.formRef.current.setFieldsValue({
      firstname: user.firstName,
      lastname: user.lastName,
      email: user.email,
      // position: user.position,
      phoneNumber: user.phoneNumber,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  removeFile = () => {
    this.setState({ file: [] });
  };

  beforeUpload = (file) => {
    this.setState({ file: [file] });
    return false;
  };

  refreshFormAndUser = async () => {
    await this.props.refreshUser(this.props.user.id);
    const { user } = this.props;
    this.setState({
      firstname: user.firstName,
      lastname: user.lastName,
      email: user.email,
      // position: user.position,
      phoneNumber: user.phoneNumber,
      password: "",
      oldPassword: "",
      file: [],
    });
    HttpService.setAxiosDefaults(this.props.token);
    this.formRef.current.resetFields();
    this.formRef.current.setFieldsValue({
      firstname: user.firstName,
      lastname: user.lastName,
      email: user.email,
      // position: user.position,
      phoneNumber: user.phoneNumber,
    });
  };

  submitForm = async () => {
    const formData = new FormData();
    formData.set("id", this.props.user.id);
    formData.set("email", this.state.email);
    formData.set("firstname", this.state.firstname);
    formData.set("lastname", this.state.lastname);
    formData.set("password", this.state.password);
    formData.set("oldPassword", this.state.oldPassword);
    // formData.set("position", this.state.position);
    formData.set("phoneNumber", this.state.phoneNumber);
    formData.set("deletePicture", false);
    if (this.state.file.length > 0) {
      formData.append("file", this.state.file[0]);
    }

    await this.props.modifyProfileUser(formData);
    if (this.props.saveStatus) {
      message.success(this.props.t("save-success"), 5);
      await this.refreshFormAndUser();
    } else {
      message.error(this.props.t("save-fail"), 5);
    }
  };

  handleClickDeletePicture = async () => {
    await this.props.deleteProfilePicture(this.props.user.id);
    await this.refreshFormAndUser();
  };

  render() {
    const validateMessage = this.props.t("profile-password-confirm-text-validate")
    return (
      <div>
        <Row>
          <Col span={18} style={{ padding: "1em" }}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>{this.props.t("menu-profile-button-label")}</strong>
            </h1>
            <Form
              ref={this.formRef}
              {...formItemLayout}
              name="register"
              onFinish={this.submitForm}
              scrollToFirstError
            >
              <Form.Item
                name="email"
                label={this.props.t("email-label")}
                rules={[
                  {
                    type: "email",
                    message: this.props.t("email-wrong-format"),
                  },
                  {
                    required: true,
                    message: this.props.t("email-empty"),
                  },
                ]}
              >
                <Input
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label={this.props.t("password-label")}
                rules={[
                  {
                    required: false,
                    message: this.props.t("password-empty"),
                  },
                  {
                    pattern: /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)).{8,}$/,
                    message: this.props.t("password-wrong-format"),
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                label={this.props.t("profile-password-confirm-label")}
                name="confirm"
                hasFeedback
                dependencies={["password"]}
                rules={[
                  {
                    required: this.state.password.length > 0,
                    message: this.props.t("profile-password-confirm-text"),
                  },
                  
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        validateMessage
                      );
                    },
                  }),
                ]}
              >
                <Input.Password name="confirm" type="password" />
              </Form.Item>
              <Form.Item
                name="oldPassword"
                label={this.props.t("profile-oldpassword-label")}
                rules={[
                  {
                    required: this.state.password.length > 0,
                    message: this.props.t("profile-oldpassword-text"),
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  name="oldPassword"
                  value={this.state.oldPassword}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="lastname"
                label={this.props.t("guest-register-lastname-label")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("guest-register-lastname-text"),
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="lastname"
                  value={this.state.lastname}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="firstname"
                label={this.props.t("guest-register-firstname-label")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("guest-register-firstname-text"),
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="firstname"
                  value={this.state.firstname}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="phoneNumber"
                label={this.props.t("profile-phone-label")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("profile-phone-text"),
                    whitespace: true,
                  },
                  {
                    pattern:
                      /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/,
                    message: this.props.t("wrong-format"),
                  },
                  {
                    max: 15,
                    message: this.props.t("phone-number-max-digit")
                  },
                ]}
              >
                <Input
                  name="phoneNumber"
                  value={this.state.phoneNumber}
                  onChange={this.handleChange}
                />
              </Form.Item>
              {/* <Form.Item
                name="position"
                label={this.props.t("profile-position-label")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("profile-position-text"),
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="position"
                  value={this.state.position}
                  onChange={this.handleChange}
                />
              </Form.Item> */}
              <Form.Item
                name="file"
                label={this.props.t("profile-picture")}
                rules={[
                  {
                    required: false,
                    message: "Kérem adjon meg egy fájlt!",
                  },
                ]}
              >
                <Dragger
                  name="file"
                  multiple={false}
                  onRemove={this.removeFile}
                  accept=".png,.jpg,.jpeg,.gif"
                  beforeUpload={this.beforeUpload}
                  fileList={this.state.file}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    {this.props.t("profile-picture-upload-text")}
                  </p>
                </Dragger>
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  {this.props.t("save-button-label")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
          {this.props.user.filename !== null && (
            <Col span={6} style={{ padding: "1em" }}>
              <h1>{this.props.t("profile-picture-label")}</h1>
              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  "/api/1.0.0/users/profile/" +
                  this.props.user.filename +
                  "?token=" +
                  this.props.token.split(" ")[1]
                }
                alt="logo"
                width="100%"
              />
              <div>
                <Button onClick={this.handleClickDeletePicture}>
                  {this.props.t("delete-button-label")}
                </Button>
              </div>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  saveStatus: state.usersReducer.saveStatus,
});

const Profile = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedProfile)
);
export default Profile;
