import Axios from "axios";
import { LANGUAGE_ACTIONS } from "../constants/action-types";
import { message } from "antd";

export const getAllLanguages = () => {
  return async (dispatch) => {
    dispatch({ type: LANGUAGE_ACTIONS.LANGUAGES_LOADING });
    await Axios.get("/1.0.0/language")
      .then((response) => {
        dispatch({
          type: LANGUAGE_ACTIONS.LANGUAGES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: LANGUAGE_ACTIONS.LANGUAGES_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const activateLanguage = (companyId, languageId, officeId) => {
  return async () => {
    await Axios.get("/1.0.0/language/activate", {
      params: { companyId: companyId, languageId: languageId, officeId: officeId },
    }).catch((error) => {
      message.error("Hiba a nyelv aktiválása közben!", 5);
      console.log(error.message);
    });
  };
};

export const saveCompanyText = (params) => {
  return async (dispatch) => {
    dispatch({ type: LANGUAGE_ACTIONS.LANGUAGE_SAVE_START });
    await Axios.post("/1.0.0/language/company-text", params)
      .then(() => {
        dispatch({ type: LANGUAGE_ACTIONS.LANGUAGE_SAVE_SUCCESS });
        /* message.success("Sikeres mentés!", 5); */
      })
      .catch((err) => {
        console.log(err.message);
        dispatch({
          type: LANGUAGE_ACTIONS.LANGUAGE_SAVE_ERROR,
          payload: err.response.data,
        });
        message.error("Hiba a nyelvi szövegek mentése közben!", 5);
      });
  };
};

export const saveAgreement = (params) => {
  return async () => {
    await Axios.post("/1.0.0/agreement", params)
      .then(() => {
        message.success("Sikeres mentés!", 5);
      })
      .catch((err) => {
        console.log(err.message);
        message.error("Hiba a nyilatkozatok mentése közben!", 5);
      });
  };
};

export const modifyAgreement = (params) => {
  return async () => {
    await Axios.put("/1.0.0/agreement", params)
      .then(() => {
        message.success("Sikeres mentés!", 5);
      })
      .catch((err) => {
        console.log(err.message);
        message.error("Hiba a nyilatkozatok mentése közben!", 5);
      });
  };
};

export const deleteAgreement = (id) => {
  return async () => {
    await Axios.delete("/1.0.0/agreement/" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

export const setSelectedLanguageText = (params) => {
  return (dispatch) => dispatch({
    type: LANGUAGE_ACTIONS.LANGUAGE_SELECTED_LANGUAGE_SET,
    payload: params
  })
};
