export const LOGIN_ACTIONS = {
  TOKEN_LOADING: "TOKEN_LOADING",
  TOKEN_LOADED: "TOKEN_LOADED",
  TOKEN_ERROR: "TOKEN_ERROR",
  LOGOUT: "LOGOUT",
  API_KEY_LOADING: "API_KEY_LOADING",
  API_KEY_LOADED: "API_KEY_LOADED",
  API_KEY_ERROR: "API_KEY_ERROR",
};

export const USERS_ACTIONS = {
  USERS_LOADING: "USERS_LOADING",
  USERS_LOADED: "USERS_LOADED",
  USERS_ERROR: "USERS_ERROR",
  WORKING_USERS_LOADING: "WORKING_USERS_LOADING",
  WORKING_USERS_LOADED: "WORKING_USERS_LOADED",
  WORKING_USERS_ERROR: "WORKING_USERS_ERROR",
  USER_REGISTER_START: "USER_REGISTER_START",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_ERROR: "USER_REGISTER_ERROR",
  GROUPS_LOADING: "GROUPS_LOADING",
  GROUPS_LOADED: "GROUPS_LOADED",
  GROUPS_ERROR: "GROUPS_ERROR",
  WORKLOG_LOADING: "WORKLOG_LOADING",
  WORKLOG_LOADED: "WORKLOG_LOAED",
  WORKLOG_ERROR: "WORKLOG_ERROR",
  USER_IMPORT_ERRORS_ADD: "USER_IMPORT_ERRORS_ADD",
  USER_IMPORT_ERRORS_EMPTY: "USER_IMPORT_ERRORS_EMPTY",
  USERS_EMAIL_EXISTS_LOADING: "USERS_EMAIL_EXISTS_LOADING",
  USERS_EMAIL_EXISTS_LOADED: "USERS_EMAIL_EXISTS_LOADED",
  USERS_EMAIL_EXISTS_ERROR: "USERS_EMAIL_EXISTS_ERROR",
  HOSTS_LOADING: "HOSTS_LOADING",
  HOSTS_LOADED: "HOSTS_LOADED",
  HOSTS_ERROR: "HOSTS_ERROR",
  COLLEAGUES_LOADING: "COLLEAGUES_LOADING",
  COLLEAGUES_LOADED: "COLLEAGUES_LOADED",
  COLLEAGUES_ERROR: "COLLEAGUES_ERROR",
  USERS_VEHICLE_LOADING: "USERS_VEHICLE_LOADING",
  USERS_VEHICLE_LOADED: "USERS_VEHICLE_LOADED",
  USERS_VEHICLE_ERROR: "USERS_VEHICLE_ERROR",
  USERS_BY_OFFICE_LOADING: "USERS_BY_OFFICE_LOADING",
  USERS_BY_OFFICE_LOADED: "USERS_BY_OFFICE_LOADED",
  USERS_BY_OFFICE_ERROR: "USERS_BY_OFFICE_ERROR",
  USERS_BY_COMPANY_LOADING: "USERS_BY_COMPANY_LOADING",
  USERS_BY_COMPANY_LOADED: "USERS_BY_COMPANY_LOADED",
  USERS_BY_COMPANY_ERROR: "USERS_BY_COMPANY_ERROR",
  USERS_ADD_OR_REMOVE_COMPANY_ADMIN_ROLE_LOADING: "USERS_ADD_OR_REMOVE_COMPANY_ADMIN_ROLE_LOADING",
  USERS_ADD_OR_REMOVE_COMPANY_ADMIN_ROLE_LOADED: "USERS_ADD_OR_REMOVE_COMPANY_ADMIN_ROLE_LOADED",
  USERS_ADD_OR_REMOVE_COMPANY_ADMIN_ROLE_ERROR: "USERS_ADD_OR_REMOVE_COMPANY_ADMIN_ROLE_ERROR",
  USER_CONFIG_LOADING: "USER_CONFIG_LOADING",
  USUSER_CONFIG_LOADED: "USUSER_CONFIG_LOADED",
  USER_CONFIG_ERROR: "USER_CONFIG_ERROR",
};

export const COMPANY_ACTIONS = {
  COMPANIES_LOADING: "COMPANIES_LOADING",
  COMPANIES_LOADED: "COMPANIES_LOADED",
  COMPANIES_ERROR: "COMPANIES_ERROR",
  COMPANIES_FILTER_LOADING: "COMPANIES_FILTER_LOADING",
  COMPANIES_FILTER_LOADED: "COMPANIES_FILTER_LOADED",
  COMPANIES_FILTER_ERROR: "COMPANIES_FILTER_ERROR",
  COMPANY_LOADING: "COMPANY_LOADING",
  COMPANY_LOADED: "COMPANY_LOADED",
  COMPANY_ERROR: "COMPANY_ERROR",
  COMPANY_ANYONIMIZATION_LIMIT_LOADING: "COMPANY_ANYONIMIZATION_LIMIT_LOADING",
  COMPANY_ANYONIMIZATION_LIMIT_LOADED: "COMPANY_ANYONIMIZATION_LIMIT_LOADED",
  COMPANY_ANYONIMIZATION_LIMIT_ERROR: "COMPANY_ANYONIMIZATION_LIMIT_ERROR",
  COMPANY_SAVE_START: "COMPANY_SAVE_START",
  COMPANY_SAVE_SUCCESS: "COMPANY_SAVE_SUCCESS",
  COMPANY_SAVE_ERROR: "COMPANY_SAVE_ERROR",
  CROP_IMAGE_START: "CROP_IMAGE_START",
  CROP_IMAGE_SUCCESS: "CROP_IMAGE_SUCCESS",
  CROP_IMAGE_ERROR: "CROP_IMAGE_ERROR",
  SUBSCRIPTIONS_LOADING: "SUBSCRIPTIONS_LOADING",
  SUBSCRIPTIONS_LOADED: "SUBSCRIPTIONS_LOADED",
  SUBSCRIPTIONS_ERROR: "SUBSCRIPTIONS_ERROR",
  ADDRESSES_LOADING: "ADDRESSES_LOADING",
  ADDRESSES_LOADED: "ADDRESSES_LOADED",
  ADDRESSES_ERROR: "ADDRESSES_ERROR",
  ADDRESS_LOADING: "ADDRESS_LOADING",
  ADDRESS_LOADED: "ADDRESS_LOADED",
  ADDRESS_ERROR: "ADDRESS_ERROR",
  COMPANY_RESET: "COMPANY_RESET",
};

export const RECEPTION_ACTIONS = {
  INVITES_LOADING: "INVITES_LOADING",
  INVITES_LOADED: "INVITES_LOADED",
  INVITES_ERROR: "INVITES_ERROR",
  OFFICE_INVITES_LOADING: "OFFICE_INVITES_LOADING",
  OFFICE_INVITES_LOADED: "OFFICE_INVITES_LOADED",
  OFFICE_INVITES_ERROR: "OFFICE_INVITES_ERROR",
  WORKERS_LOADING: "WORKERS_LOADING",
  WORKERS_LOADED: "WORKERS_LOADED",
  WORKERS_ERROR: "WORKERS_ERROR",
  EVENTS_LOADING: "EVENTS_LOADING",
  EVENTS_LOADED: "EVENTS_LOADED",
  EVENTS_ERROR: "EVENTS_ERROR",
  OFFICE_EVENTS_LOADING: "OFFICE_EVENTS_LOADING",
  OFFICE_EVENTS_LOADED: "OFFICE_EVENTS_LOADED",
  OFFICE_EVENTS_ERROR: "OFFICE_EVENTS_ERROR",
  INVITE_SAVE_START: "INVITE_SAVE_START",
  INVITE_SAVE_SUCCESS: "INVITE_SAVE_SUCCESS",
  INVITE_SAVE_ERROR: "INVITE_SAVE_ERROR",
  WORKLOG_REGISTER_START: "WORKLOG_REGISTER_START",
  WORKLOG_REGISTER_SUCCESS: "WORKLOG_REGISTER_SUCCESS",
  WORKLOG_REGISTER_ERROR: "WORKLOG_REGISTER_ERROR",
  GUEST_SAVE_START: "GUEST_SAVE_START",
  GUEST_SAVE_SUCCESS: "GUEST_SAVE_SUCCESS",
  GUEST_SAVE_ERROR: "GUEST_SAVE_ERROR",
  NON_INVITED_GUESTS_LOADING: "NON_INVITED_GUESTS_LOADING",
  NON_INVITED_GUESTS_LOADED: "NON_INVITED_GUESTS_LOADED",
  NON_INVITED_GUESTS_ERROR: "NON_INVITED_GUESTS_ERRORs",
  GUESTS_LIST_LOADING: "GUESTS_LIST_LOADING",
  GUESTS_LIST_LOADED: "GUESTS_LIST_LOADED",
  GUESTS_LIST_ERROR: "GUESTS_LIST_ERROR",
  GUEST_RECEPTION_EVENTS_LOADING: "GUEST_RECEPTION_EVENTS_LOADING",
  GUEST_RECEPTION_EVENTS_LOADED: "GUEST_RECEPTION_EVENTS_LOADED",
  GUEST_RECEPTION_EVENTS_ERROR: "GUEST_RECEPTION_EVENTS_ERROR",
  GUEST_DEPARTURE_SAVE_START: "GUEST_DEPARTURE_SAVE_START",
  GUEST_DEPARTURE_SAVE_SUCCESS: "GUEST_DEPARTURE_SAVE_SUCCESS",
  GUEST_DEPARTURE_SAVE_ERROR: "GUEST_DEPARTURE_SAVE_ERROR",
};

export const EMERGENCY_ACTIONS = {
  EMERGENCY_ACTIVATE: "EMERGENCY_ACTIVATE",
  EMERGENCY_DEACTIVATE: "EMERGENCY_DEACTIVATE",
};

export const LANGUAGE_ACTIONS = {
  LANGUAGES_LOADING: "LANGUAGES_LOADING",
  LANGUAGES_LOADED: "LANGUAGES_LOADED",
  LANGUAGES_ERROR: "LANGUAGES_ERROR",
  LANGUAGE_SAVE_START: "LANGUAGE_SAVE_START",
  LANGUAGE_SAVE_SUCCESS: "LANGUAGE_SAVE_SUCCESS",
  LANGUAGE_SAVE_ERROR: "LANGUAGE_SAVE_ERROR",
  LANGUAGE_SELECTED_LANGUAGE_SET: "LANGUAGE_SELECTED_LANGUAGE_SET",
};

export const MESSAGE_ACTIONS = {
  MESSAGES_LOADING: "MESSAGES_LOADING",
  MESSAGES_LOADED: "MESSAGES_LOADED",
  MESSAGES_ERROR: "MESSAGES_ERROR",
  MESSAGE_SAVE_START: "MESSAGE_SAVE_START",
  MESSAGE_SAVE_SUCCESS: "MESSAGE_SAVE_SUCCESS",
  MESSAGE_SAVE_ERROR: "MESSAGE_SAVE_ERROR",
};

export const TAG_ACTIONS = {
  OPEN_WITH_MESSAGE: "OPEN_WITH_MESSAGE",
  CLOSE: "CLOSE",
};

export const ENTRYPOINTS_ACTIONS = {
  ENTRYPOINTS_OPEN: "ENTRYPOINTS_OPEN",
  ENTRYPOINTS_LOADING: "ENTRYPOINTS_LOADING",
  ENTRYPOINTS_LOADED: "ENTRYPOINTS_LOADED",
  ENTRYPOINTS_ERROR: "ENTRYPOINTS_ERROR",
  OFFICE_ENTRYPOINTS_LOADING: "OFFICE_ENTRYPOINTS_LOADING",
  OFFICE_ENTRYPOINTS_LOADED: "OFFICE_ENTRYPOINTS_LOADED",
  OFFICE_ENTRYPOINTS_ERROR: "OFFICE_ENTRYPOINTS_ERROR",
  ENTRYPOINTS_REGISTER_START: "ENTRYPOINTS_REGISTER_START",
  ENTRYPOINTS_REGISTER_SUCCESS: "ENTRYPOINTS_REGISTER_SUCCESS",
  ENTRYPOINTS_REGISTER_ERROR: "ENTRYPOINTS_REGISTER_ERROR",
};

export const STATUS_ACTIONS = {
  STATUS_LOADING: "STATUS_LOADING",
  STATUS_LOADED: "STATUS_LOADED",
  STATUS_ERROR: "STATUS_ERROR",
  STATUS_REGISTER_START: "STATUS_REGISTER_START",
  STATUS_REGISTER_SUCCESS: "STATUS_REGISTER_SUCCESS",
  STATUS_REGISTER_ERROR: "STATUS_REGISTER_ERROR",
};

export const OFFICE_ACTIONS = {
  OFFICES_LOADING: "OFFICES_LOADING",
  OFFICES_LOADED: "OFFICES_LOADED",
  OFFICES_ERROR: "OFFICES_ERROR",

  OFFICE_LOADING: "OFFICE_LOADING",
  OFFICE_LOADED: "OFFICE_LOADED",
  OFFICE_ERROR: "OFFICE_ERROR",

  OFFICE_SAVE_START: "OFFICE_SAVE_START",
  OFFICE_SAVE_SUCCESS: "OFFICE_SAVE_SUCCESS",
  OFFICE_SAVE_ERROR: "OFFICE_SAVE_ERROR",

  OFFICE_DELETE_START: "OFFICE_DELETE_START",
  OFFICE_DELETE_SUCCESS: "OFFICE_DELETE_SUCCESS",
  OFFICE_DELETE_ERROR: "OFFICE_DELETE_ERROR",
};

export const PROXIMITYCARD_ACTIONS = {
  PROXIMITYCARD_LOADING: "ROXIMITYCARD_LOADING",
  PROXIMITYCARD_LOADED: "PROXIMITYCARD_LOADED",
  PROXIMITYCARD_ERROR: "PROXIMITYCARD_ERROR",

  PROXIMITYCARD_SAVE_START: "PROXIMITYCARD_SAVE_START",
  PROXIMITYCARD_SAVE_SUCCESS: "PROXIMITYCARD_SAVE_SUCCESS",
  PROXIMITYCARD_SAVE_ERROR: "PROXIMITYCARD_SAVE_ERROR",
  PROXIMITYCARD_IMPORT_ERRORS_ADD: "PROXIMITYCARD_IMPORT_ERRORS_ADD",
  PROXIMITYCARD_IMPORT_ERRORS_EMPTY: "PROXIMITYCARD_IMPORT_ERRORS_EMPTY",

  PROXIMITYCARD_DELETE_START: "PROXIMITYCARD_DELETE_START",
  PROXIMITYCARD_DELETE_SUCCESS: "PROXIMITYCARD_DELETE_SUCCESS",
  PROXIMITYCARD_DELETE_ERROR: "PROXIMITYCARD_DELETE_ERROR",
};

export const PARKING_ACTIONS = {
  PARKING_LOADING: "PARKING_LOADING",
  PARKING_LOADED: "PARKING_LOADED",
  PARKING_ERROR: "PARKING_ERROR",

  PARKING_SAVE_START: "PARKING_SAVE_START",
  PARKING_SAVE_SUCCESS: "PARKING_SAVE_SUCCESS",
  PARKING_SAVE_ERROR: "PARKING_SAVE_ERROR",

  PARKING_RESERVATION_LOADING: "PARKING_RESERVATION_LOADING",
  PARKING_RESERVATION_LOADED: "PARKING_RESERVATION_LOADED",
  PARKING_RESERVATION_ERROR: "PARKING_RESERVATION_ERROR",

  PARKING_RESERVATION_SAVE_START: "PARKING_RESERVATION_SAVE_START",
  PARKING_RESERVATION_SAVE_SUCCESS: "PARKING_RESERVATION_SAVE_SUCCESS",
  PARKING_RESERVATION_SAVE_ERROR: "PARKING_RESERVATION_SAVE_ERROR",

  PARKING_GROUP_LOADING: "PARKING_GROUP_LOADING",
  PARKING_GROUP_LOADED: "PARKING_GROUP_LOADED",
  PARKING_GROUP_ERROR: "PARKING_GROUP_ERROR",

  PARKING_GROUP_SAVE_START: "PARKING_GROUP_SAVE_START",
  PARKING_GROUP_SAVE_SUCCESS: "PARKING_GROUP_SAVE_SUCCESS",
  PARKING_GROUP_SAVE_ERROR: "PARKING_GROUP_SAVE_ERROR",

  PARKING_VEHICLE_LOADING: "PARKING_VEHICLE_LOADING",
  PARKING_VEHICLE_LOADED: "PARKING_VEHICLE_LOADED",
  PARKING_VEHICLE_ERROR: "PARKING_VEHICLE_ERROR",

  PARKING_VEHICLES_LOADING: "PARKING_VEHICLES_LOADING",
  PARKING_VEHICLES_LOADED: "PARKING_VEHICLES_LOADED",
  PARKING_VEHICLES_ERROR: "PARKING_VEHICLES_ERROR",

  PARKING_VEHICLE_SAVE_START: "PARKING_VEHICLE_SAVE_START",
  PARKING_VEHICLE_SAVE_SUCCESS: "PARKING_VEHICLE_SAVE_SUCCESS",
  PARKING_VEHICLE_SAVE_ERROR: "PARKING_VEHICLE_SAVE_ERROR",

  PARKING_ENTRY_GATE_LOADING: "PARKING_ENTRY_GATE_LOADING",
  PARKING_ENTRY_GATE_LOADED: "PARKING_ENTRY_GATE_LOADED",
  PARKING_ENTRY_GATE_ERROR: "PARKING_ENTRY_GATE_ERROR",

  PARKING_ENTRY_GATE_SAVE_START: "PARKING_ENTRY_GATE_SAVE_START",
  PARKING_ENTRY_GATE_SAVE_SUCCESS: "PARKING_ENTRY_GATE_SAVE_SUCCESS",
  PARKING_ENTRY_GATE_SAVE_ERROR: "PARKING_ENTRY_GATE_SAVE_ERROR",
  
  PARKING_ENTRY_GATE_OPEN_START: "PARKING_ENTRY_GATE_OPEN_START",
  PARKING_ENTRY_GATE_OPEN_SUCCESS: "PARKING_ENTRY_GATE_OPEN_SUCCESS",
  PARKING_ENTRY_GATE_OPEN_ERROR: "PARKING_ENTRY_GATE_OPEN_ERROR",

  PARKING_PLACES_LOADING: "PARKING_PLACES_LOADING",
  PARKING_PLACES_LOADED: "PARKING_PLACES_LOADED",
  PARKING_PLACES_ERROR: "PARKING_PLACES_ERROR",

  USER_SAVE_START: "USER_SAVE_START",
  USER_SAVE_SUCCESS: "USER_SAVE_SUCCESS",
  USER_SAVE_ERROR: "USER_SAVE_ERROR",
};

export const PUSH_ACTIONS = {
  PUSH_SAVE_START: "PUSH_SAVE_START",
  PUSH_SAVE_SUCCESS: "PUSH_SAVE_SUCCESS",
  PUSH_SAVE_ERROR: "PUSH_SAVE_ERROR",
};
