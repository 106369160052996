import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Input,
  Popconfirm,
  Modal,
  Form,
  Select,
  Switch,
  Transfer,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  DeleteFilled,
  EditFilled,
  PlusOutlined,
  SearchOutlined,
  FilterOutlined,
  IdcardOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import {
  addUserToParkingGroup,
  createParkingGroup,
  deleteParkingGroup,
  getEntryGates,
  getParkingGroup,
  getParkingGroups,
  updateParkingGroup,
} from "../../actions/parking";
import { getAllCompanies } from "../../actions/company";
import { GATE_TYPES } from "../../constants/parking-group-types";
import { getAllUsers, getAllUsersByOffice } from "../../actions/users";
import { userRoles } from "../../constants/roles-types";

const mapDispatchToProps = (dispatch) => {
  return {
    getEntryGates: (officeId) => dispatch(getEntryGates(officeId)),
    getParkingGroups: (companyId, officeId) =>
      dispatch(getParkingGroups(companyId, officeId)),
    createGroup: (params) => dispatch(createParkingGroup(params)),
    getAllCompanies: () => dispatch(getAllCompanies()),
    updateParkingGroup: (id, params) =>
      dispatch(updateParkingGroup(id, params)),
    deleteGroup: (id) => dispatch(deleteParkingGroup(id)),
    addUserToParkingGroup: (id, params) =>
      dispatch(addUserToParkingGroup(id, params)),
    getAllUsers: (groupIds, companyId) =>
      dispatch(getAllUsers(groupIds, companyId)),
    getParkingGroup: (id) => dispatch(getParkingGroup(id)),
    getAllUsersByOffice: (officeId) => dispatch(getAllUsersByOffice(officeId)),
  };
};
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

class ConnectedParkingGroups extends Component {
  state = {
    loaded: false,
    parking: [],
    parkingGroups: [],
    filteredInfo: {},
    openNewGroupModal: false,
    openDetailsModal: false,
    name: "",
    active: false,
    companyId: null,
    companies: [],
    searchText: "",
    modify: false,
    selectedGroupId: 0,
    filtering: false,
    type: 0,
    gate: 0,
    userToParkingGroupVisible: false,
    targetKeys: [],
    selectedKeys: [],
  };

  formRef = React.createRef();
  componentDidMount = async () => {
    await this.getAllCompanies();
    await this.getParkingGroups();

    this.setState({ loaded: true });
  };

  getParkingGroups = async () => {
    this.setState({ loaded: false });
    if (this.hasRight([userRoles.OFFICE_ADMIN])) {
      await this.props.getParkingGroups(null, this.props.user.officeId);
      await this.props.getEntryGates(this.props.user.officeId);
    } else {
      await this.props.getParkingGroups(this.props.user.companyId, null);
    }
    if (this.props.status) {
      this.setState({
        companyId: this.props.user.companyId,
        parkingGroups: this.props.parkingGroup,
      });
    }
    this.setState({ loaded: true });
  };

  getAllCompanies = async () => {
    this.setState({ loaded: false });
    if (this.hasRight([userRoles.OFFICE_ADMIN])) {
      await this.props.getAllCompanies();
      this.setState({ companies: this.props.companies });
    }
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t("search-button-label")}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            {this.props.t("reset-button-label")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      (record[dataIndex] || '').toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  hasRight = (rights, notAllowedSubscriptions) => {
    let allowed = false;
    this.props.user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allowed = true;
      }
    });
    if (allowed === true && notAllowedSubscriptions === undefined) {
      return true;
    } else {
      if (allowed === true) {
        if (this.allowedBySubscription(notAllowedSubscriptions)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  allowedBySubscription = (notAllowedSubscriptions) => {
    if (this.props.user.companyId === null) {
      return false;
    } else {
      if (
        notAllowedSubscriptions !== undefined &&
        notAllowedSubscriptions !== null
      ) {
        if (
          notAllowedSubscriptions.includes(
            this.props.user.company.subscriptionId
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  handleOpenGroupModal = async () => {
    this.setState({
      openNewGroupModal: true,
      active: false,
      name: "",
    });
  };

  closeNewGroupModal = () => {
    this.formRef.current.resetFields();
    this.setState({
      openNewGroupModal: false,
      selectedGroupId: null,
      modify: false,
      active: false,
      name: "",
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  handleCreateNewGroup = async () => {
    const toSend = {
      name: this.state.name,
      active: this.state.active,
      companyId: this.state.companyId,
      type: this.state.type,
    };

    await this.props.createGroup(toSend);

    if (this.props.saveStatus) {
      this.setState({
        parkingGroups: this.props.parkingGroup,
        openNewGroupModal: false,
      });
      this.closeNewGroupModal();
      await this.getParkingGroups();
    }
  };

  handleChangeActivate = async () => {
    if (this.state.active === false) {
      this.setState({
        active: true,
      });
    } else {
      this.setState({
        active: false,
      });
    }
  };

  handleOpenModify = (record) => {
    this.setState({
      openNewGroupModal: true,
      modify: true,
      selectedGroupId: record.id,
      name: record.name,
      active: record.active,
      type: record.type,
    });
    this.formRef.current.setFieldsValue({
      name: record.name,
      typeName:
        record.type === 1
          ? "dedicated"
          : record.type === 2
            ? "carpool"
            : "guest",
    });
  };

  handleModify = async () => {
    this.setState({
      loaded: false,
    });
    const toSend = {
      name: this.state.name,
      active: this.state.active,
      companyId: this.state.companyId,
      type: this.state.type,
    };
    await this.props.updateParkingGroup(this.state.selectedGroupId, toSend);
    await this.getParkingGroups();
    if (this.props.saveStatus) {
      this.setState({
        parkingGroups: this.props.parkingGroup,
        openNewGroupModal: false,
      });
    }
    this.setState({ loaded: true });
  };

  handleDeleteGroup = async (record) => {
    await this.props.deleteGroup(record.id);
    await this.getParkingGroups();
  };

  handleChangeCompanySelect = async (event) => {
    this.setState({
      companyId: event,
    });
  };

  handleFilterCompanySelect = async (event) => {
    this.setState({
      companyId: event,
    });
    this.setState({ loaded: false });
    await this.props.getParkingGroups(event);
    if (this.props.status) {
      this.setState({
        parkingGroups: this.props.parkingGroup,
      });
    }
    this.setState({ loaded: true });
  };

  handleChangeTypeSelect = async (event) => {
    this.setState({
      type: event,
    });
  };

  handleFilerOpen = () => {
    this.setState({
      filtering: true,
    });
  };

  handleFilterClose = () => {
    this.setState({
      filtering: false,
    });
    this.getParkingGroups();
  };

  handleChangeEntryGateSelect = (event) => {
    this.setState({
      gate: event,
    });
  };

  addUsersToGroup = async (record) => {
    if (this.hasRight([userRoles.OFFICE_ADMIN])) {
      await this.props.getAllUsers([5], record.companyId, true);
    } else {
      if (this.props.companyId) {
        await this.props.getAllUsers([5], this.props.companyId, true);
      } else {
        await this.props.getAllUsers([5], this.props.user.companyId, true);
      }
    }
    await this.props.getParkingGroup(record.id);
    if (this.props.status) {
      const target = this.props.parkingGroup.users?.map((user) => user.id);
      this.setState({ targetKeys: target });
    }
    this.setState({ userToParkingGroupVisible: true });
  };

  closeUserAddToParkingGroup = () => {
    this.setState({
      userToParkingGroupVisible: false,
      targetKeys: [],
      selectedKeys: [],
    });
  };

  handleSaveSelectedUsers = async () => {
    const params = {
      userIds: this.state.targetKeys,
    };
    await this.props.addUserToParkingGroup(this.props.parkingGroup.id, params);
    if (this.props.userSaveStatus) {
      this.closeUserAddToParkingGroup();
    }
  };

  handleChangeTarget = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  render() {
    const columns = [
      {
        title: this.props.t("reception-events-table-header-name"),
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['ascend', 'descend'],
        filteredValue: this.state.filteredInfo.name || null,
        ...this.getColumnSearchProps(
          "name",
          this.props.t("reception-events-table-header-name")
        ),
        render: (string, record) => {
          return record.name;
        },
      },
      {
        title: this.props.t("companies-label-companyname"),
        key: "companyName",
        render: (string, record) => {
          const companyName = this.props.companies.map((c) =>
            record.companyId === c.id ? c.name : ""
          );
          return companyName;
        },
      },
      {
        title: this.props.t("entrypoints-isopened-status-title"),
        key: "active",
        render: (string, record) => {
          return (
            <Space
              size="middle"
              style={{
                display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "1.1vw",
                  height: "1.1vw",
                  borderRadius: "50%",
                  backgroundColor:
                    record.active === null
                      ? "gray"
                      : record.active === true
                        ? "green"
                        : "gray",
                }}
              />
            </Space>
          );
        },
      },
      {
        title: this.props.t("user-management-label"),
        key: "userManagement",
        render: (text, record) => {
          return (
            <Space>
              <Button onClick={() => this.addUsersToGroup(record)}>
                <IdcardOutlined />
              </Button>
            </Space>
          );
        },
      },
    ];

    const updateColumns = [
      {
        title: this.props.t("modify-button-label"),
        key: "modify",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.handleOpenModify(record)}>
                <EditFilled />
              </Button>
            </Space>
          );
        },
      },
      {
        title: this.props.t("delete-button-label"),
        key: "delete",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title={this.props.t("delete-parking-group-label")}
                okText={this.props.t("yes-button-label")}
                cancelText={this.props.t("no-button-label")}
                onConfirm={() => this.handleDeleteGroup(record)}
              >
                <Button danger type="primary">
                  <DeleteFilled />
                </Button>
              </Popconfirm>
            </Space>
          );
        },
      },
    ];

    return (
      <div>
        <Row>
          <Col span={24}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>{this.props.t("parking-group-label")}</strong>
            </h1>
          </Col>
        </Row>
        {this.hasRight([userRoles.OFFICE_ADMIN]) ? (
          <>
            <Row>
              <>
                <Col>
                  {this.state.filtering ? (
                    <>
                      {this.props.t("companies-title")}
                      <Space>
                        :
                        <Select
                          style={{ width: "15em" }}
                          onChange={(event) =>
                            this.handleFilterCompanySelect(event)
                          }
                        >
                          {this.props.companies.map((company) => {
                            return (
                              <Select.Option
                                key={company.id}
                                value={company.id}
                              >
                                {company.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                        <Button onClick={this.handleFilterClose}>
                          {this.props.t(
                            "reception-guests-resetfilters-button-label"
                          )}
                        </Button>
                      </Space>
                    </>
                  ) : (
                    <Button
                      style={{ top: "3.5em" }}
                      onClick={this.handleFilerOpen}
                    >
                      <FilterOutlined /> {this.props.t("filtration")}
                    </Button>
                  )}
                </Col>
              </>
            </Row>
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              <Col style={{ padding: "1em" }}>
                <Button onClick={this.handleOpenGroupModal} type="primary">
                  <PlusOutlined />
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          // <Row>
          //   <Col style={{ position: "absolute", right: "3em", top: "10em" }}>
          //     <Button onClick={this.handleOpenGroupModal} type="primary">
          //       <PlusOutlined />
          //     </Button>
          //   </Col>
          // </Row>
          []
        )}

        <Row>
          <Col span={24}>
            <Table
              columns={
                this.hasRight([userRoles.OFFICE_ADMIN]) ? [...columns, ...updateColumns] : columns
              }
              rowKey="id"
              dataSource={this.state.parkingGroups}
              locale={{ emptyText: this.props.t("empty-text") }}
              loading={!this.props.usersStatus && !this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: true,
              }}
              onChange={this.handleChangeTable}
            />
          </Col>
        </Row>
        <Modal
          visible={this.state.openNewGroupModal}
          onCancel={this.closeNewGroupModal}
          title={
            this.state.modify
              ? this.props.t("modify-parking-group-label")
              : this.props.t("new-parking-group-label")
          }
          maskClosable={false}
          forceRender
          centered
          footer={[
            <Space>
              <Button key="cancel" onClick={this.closeNewGroupModal}>
                {this.props.t("cancel-button-label")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={
                  this.state.modify
                    ? this.handleModify
                    : this.handleCreateNewGroup
                }
              >
                {this.state.modify
                  ? this.props.t("modify-button-label")
                  : this.props.t("add-button-label")}
              </Button>
            </Space>,
          ]}
        >
          <Form
            onFinish={
              this.state.modify ? this.handleCreateNewGroup : this.handleModify
            }
            ref={this.formRef}
            {...formItemLayout}
            name="design"
            scrollToFirstError
          >
            <Form.Item
              name="name"
              label={this.props.t("reception-events-table-header-name")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input name="name" onChange={this.handleChange} />
            </Form.Item>
            {this.hasRight([userRoles.OFFICE_ADMIN]) && !this.state.modify ? (
              <>
                <Form.Item
                  name="comapanyId"
                  label={this.props.t("companies-title")}
                  rules={[
                    {
                      required: true,
                      message: this.props.t("entrypoints-modal-must-fill"),
                    },
                  ]}
                >
                  <Select
                    style={{ width: "15em" }}
                    onChange={(event) => this.handleChangeCompanySelect(event)}
                  >
                    {this.props.companies.map((company) => {
                      return (
                        <Select.Option key={company.id} value={company.id}>
                          {company.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </>
            ) : (
              []
            )}
            <Form.Item
              name="typeName"
              label={this.props.t("reception-events-table-header-type")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Select
                style={{ width: "15em" }}
                onChange={(event) => this.handleChangeTypeSelect(event)}
              >
                {GATE_TYPES.map((type) => {
                  return (
                    <Select.Option key={type.id} value={type.id}>
                      {type.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="active" label={this.props.t("active-input-label")}>
              <Switch
                checked={this.state.active === true}
                onChange={this.handleChangeActivate}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          maskClosable={false}
          closable={true}
          footer={[
            <Button onClick={this.closeUserAddToParkingGroup}>
              {this.props.t("cancel-button-label")}
            </Button>,
            <Button onClick={this.handleSaveSelectedUsers} type="primary">
              <SaveOutlined /> {this.props.t("save-button-label")}
            </Button>,
          ]}
          visible={this.state.userToParkingGroupVisible}
          centered
          bodyStyle={{
            textAlign: "center",
            margin: "0 auto",
          }}
          title={
            <h3 style={{ padding: 0, margin: 0 }}>
              <strong>{this.props.t("add-user-to-group-label")}</strong>
            </h3>
          }
          onCancel={this.closeUserAddToParkingGroup}
          destroyOnClose
          width={800}
        >
          <div>
            <Transfer
              style={{ textAlign: "left" }}
              dataSource={this.props.users}
              render={(item) =>
                `${item.lastName} ${item.firstName} (${item.email})`
              }
              targetKeys={this.state.targetKeys}
              selectedKeys={this.state.selectedKeys}
              onChange={this.handleChangeTarget}
              onSelectChange={this.handleSelectChange}
              rowKey={(record) => record.id}
              selectAllLabels={[<div></div>, <div></div>]}
              filterOption={() => false}
              locale={{
                notFoundContent: this.props.t("empty-text"),
                selectAll: this.props.t("select-all-label"),
                selectInvert: this.props.t("select-invert-label"),
              }}
              listStyle={{
                width: 350,
                height: 300,
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  parking: state.parkingReducer.parking,
  status: state.parkingReducer.status,
  saveStatus: state.parkingReducer.saveStatus,
  userSaveStatus: state.parkingReducer.userSaveStatus,
  parkingGroup: state.parkingReducer.parkingGroup,
  companies: state.companyReducer.companies,
  entryGates: state.parkingReducer.entryGates,
  users: state.usersReducer.users,
});
const ParkingGroups = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedParkingGroups)
);
export default ParkingGroups;
