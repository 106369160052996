import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

class ConnectedProtectedRoute extends Component {
  isAuth = () => {
    if (this.props.token !== null) {
      return true;
    } else {
      return false;
    }
  };

  hasRight = () => {
    const result = this.props.user.groups.find((group) =>
      this.props.rights.includes(group.id)
    );
    if (result) {
      return this.allowedBySubscription(this.props.notAllowedSubscriptions);
    } else {
      return false;
    }
  };

  allowedBySubscription = (notAllowedSubscriptions) => {
    if (
      notAllowedSubscriptions !== undefined &&
      notAllowedSubscriptions !== null
    ) {
      if (
        notAllowedSubscriptions.includes(this.props.user.company.subscriptionId)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.isAuth() ? (
          this.hasRight() ? (
            <Route
              path={this.props.path}
              render={(props) =>
                React.createElement(this.props.component, {
                  ...props,
                  socket: this.props.socket,
                  groupsToLoad: this.props.groupsToLoad
                    ? this.props.groupsToLoad
                    : undefined,
                })
              }
            />
          ) : (
            <Redirect from="**" to="/tl/missing-right" />
          )
        ) : (
          <Redirect from="**" to="/login" />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
});

const ProtectedRoute = connect(mapStateToProps, null)(ConnectedProtectedRoute);
export default ProtectedRoute;
