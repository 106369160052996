import React, { Suspense } from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Main from "./components/skeleton/main";
import ProtectedRoute from "./components/ProtectedRoute/protected-route";
import { Helmet } from "react-helmet";
import axios from "axios";
import ForgotPassword from "./components/Login/forgotpassword";
import GuestMain from "./components/Guest/guest-main";
import GuestCode from "./components/Guest/guest-code";
import GuestRegister from "./components/Guest/guest-register";
import WizardMain from "./components/Wizard/wizardMain";
import LoginRedirect from "./components/Login/loginRedirect";
import ForgotPasswordConfirm from "./components/Login/forgotpasswordconfirm";
import LoginMain from "./components/Login/loginMain";
import { Spin } from "antd";
import "./reset.css";
import "./App.css";
import GuestAgreement from "./components/Guest/guest-agreements";
import GuestDetails from "./components/Guest/guest-details";
import GuestParking from "./components/Guest/guest-parking";
import CancelGuestParking from "./components/Guest/guest-cancel-parking";
import ParkingDisplay from "./components/Parking/parking-display";
import { userRoles } from "./constants/roles-types";

const App = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL + "/api";

  return (
    <Suspense fallback={<Spin spinning={true} />}>
      <div style={{ height: "100%" }}>
        <Helmet>
          <title>{process.env.REACT_APP_TITLE}</title>
        </Helmet>
        <Router>
          <Switch>
            <Route exact path="/login" component={LoginMain} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/guest-main/:token" component={GuestMain} />
            <Route exact path="/guest-code/:token" component={GuestCode} />
            <Route exact path="/guest-agreements" component={GuestAgreement} />
            <Route exact path="/guest-details" component={GuestDetails} />
            <Route
              exact
              path="/guest-cancel-reservation/"
              component={CancelGuestParking}
            />
            <Route
              exact
              path="/parking-display/:deviceId"
              component={ParkingDisplay}
            />
            <Route
              exact
              path="/guest-vehicle-register/"
              component={GuestParking}
            />
            <Route
              exact
              path="/guest-register/:token"
              component={GuestRegister}
            />
            <Route
              exact
              path="/forgot-password-confirm/:token"
              component={ForgotPasswordConfirm}
            />
            <Route exact path="/login-redirect" component={LoginRedirect} />
            <ProtectedRoute
              path="/tl"
              component={Main}
              rights={[
                userRoles.SYSTEM_ADMIN,
                userRoles.COMPANY_ADMIN,
                userRoles.RECEPTION_ADMIN,
                userRoles.HR_ADMIN,
                userRoles.USER,
                userRoles.OFFICE_ADMIN,
                userRoles.OFFICE_RECEPTION_ADMIN,
              ]}
            />
            <ProtectedRoute
              path="/wizard"
              component={WizardMain}
              rights={[userRoles.COMPANY_ADMIN, userRoles.OFFICE_ADMIN]}
            />
            <Redirect from="**" to="/login" />
          </Switch>
        </Router>
      </div>
    </Suspense>
  );
};

export default App;
