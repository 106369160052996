import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Input,
  Form,
  Modal,
  message,
  ConfigProvider,
  DatePicker,
  Select,
} from "antd";
import Highlighter from "react-highlight-words";
import { EditFilled, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import {
  getAllManualCreatedGuestsWithReservations,
  modifyPostCreatedGuestWithVehicle,
} from "../../actions/reception";
import { createVehicleWithGuest } from "../../actions/parking";
import { getAllCompanies } from "../../actions/company";
import moment from "moment";
import { userRoles } from "../../constants/roles-types";
import localehu from "antd/es/locale/hu_HU";
import { toNumber } from "lodash";
import lodash from "lodash";

const mapDispatchToProps = (dispatch) => {
  return {
    createVehicleWithGuest: (params) =>
      dispatch(createVehicleWithGuest(params)),
    getAllManualCreatedGuestsWithReservations: () =>
      dispatch(getAllManualCreatedGuestsWithReservations()),
    getAllCompanies: () => dispatch(getAllCompanies()),
    modifyPostCreatedGuestWithVehicle: (params) =>
      dispatch(modifyPostCreatedGuestWithVehicle(params)),
  };
};

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
class ConnectedManualEnter extends Component {
  state = {
    loaded: false,
    filteredInfo: {},
    sortedInfo: {},
    guests: [],
    newGuestRegisterModal: false,
    modify: false,
    guestId: null,
    selectedCompanyId: null,
  };

  formRef = React.createRef();
  componentDidMount = async () => {
    await this.getAllPostCreatedGuests();
    if (this.hasRight([userRoles.OFFICE_ADMIN, userRoles.PARKING_RECEPTION])) {
      await this.props.getAllCompanies();
    }
    this.setState({ loaded: true });
  };

  getAllPostCreatedGuests = async () => {
    await this.props.getAllManualCreatedGuestsWithReservations();

    this.setState({
      guests: this.props.guests,
    });
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  getColumnSearchProps = (dataIndex, searchLabel, type = "text") => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      let date = null;
      if (type === "date") {
        if (selectedKeys[0] === undefined || selectedKeys[0] === null) {
          date = new Date();
        } else {
          date = selectedKeys[0];
        }
      }
      return (
        <div style={{ padding: 8 }}>
          {type === "date" ? (
            <>
              <ConfigProvider locale={localehu} name="date">
                <DatePicker
                  ref={(node) => {
                    this.searchInput = node;
                  }}
                  placeholder={`${searchLabel}`}
                  value={moment(date, "YYYY-MM-DD")}
                  format={["YYYY-MM-DD", "DD-MM-YYYY"]}
                  locale={{ dateTimeFormat: "YYYY-MM-DD" }}
                  onChange={(e, text) => {
                    setSelectedKeys(
                      text ? [new Date(text).toLocaleDateString()] : []
                    );
                  }}
                  onPressEnter={() =>
                    this.handleSearch(selectedKeys, confirm, dataIndex)
                  }
                  style={{ width: 188, marginBottom: 8, display: "block" }}
                />
              </ConfigProvider>
            </>
          ) : (
            <>
              <Input
                ref={(node) => {
                  this.searchInput = node;
                }}
                placeholder={`${searchLabel}`}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  this.handleSearch(selectedKeys, confirm, dataIndex)
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </>
          )}

          <Space>
            <Button
              type="primary"
              onClick={() =>
                this.handleSearch(selectedKeys, confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {this.props.t("search-button-label")}
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 100 }}
            >
              {this.props.t("reset-button-label")}
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const nestedValue = lodash.get(record, dataIndex);
      if (nestedValue === undefined) {
        return false;
      }
      if (type === "text") {
        if (nestedValue !== undefined) {
          return nestedValue
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      } else {
        return new Date(nestedValue)
          .toLocaleString()
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput?.select?.());
      }
    },
    render: (text) => {
      return lodash.isEqual(this.state.searchedColumn, dataIndex) ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : type === "date" ? (
        <Space align="center">{new Date(text).toLocaleDateString()}</Space>
      ) : (
        <Space align="center">{text}</Space>
      );
    },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDatePickerChange = (date, dateString, id) => {
    const momentDate = moment(date).toDate();
    if (id === "from") {
      this.setState({ from: momentDate });
    } else {
      this.setState({ to: momentDate });
    }
  };

  hasRight = (rights, notAllowedSubscriptions) => {
    let allowed = false;
    this.props.user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allowed = true;
      }
    });
    if (allowed === true && notAllowedSubscriptions === undefined) {
      return true;
    } else {
      if (allowed === true) {
        if (this.allowedBySubscription(notAllowedSubscriptions)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  allowedBySubscription = (notAllowedSubscriptions) => {
    if (this.props.user.companyId === null) {
      return false;
    } else {
      if (
        notAllowedSubscriptions !== undefined &&
        notAllowedSubscriptions !== null
      ) {
        if (
          notAllowedSubscriptions.includes(
            this.props.user.company.subscriptionId
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  handleOpenNewGuestRegisterModal = () => {
    this.setState({
      newGuestRegisterModal: true,
    });
  };

  closeNewGuestRegisterModal = () => {
    this.formRef.current.resetFields();
    this.setState({
      newGuestRegisterModal: false,
      firstName: "",
      lastName: "",
      email: "post-created-guest-email@invalidEmail.invalid",
      representedCompany: "",
      phoneNumber: "",
      licensePlateNumber: "",
      modify: false,
      from: null,
      to: null,
      selectedCompanyId: null,
    });
  };

  changeCompany = (event) => {
    this.setState({ selectedCompanyId: toNumber(event) });
  };

  handleOpenModifyGuest = (record) => {
    this.setState({
      guestId: record.id,
      newGuestRegisterModal: true,
      modify: true,
      firstName: record.firstName,
      lastName: record.lastName,
      representedCompany: record.representedCompany,
      phoneNumber: record.comment,
      licensePlateNumber: record.vehicle?.licensePlateNumber,
      from: record.parkingReservation?.from,
      to: record.parkingReservation?.to,
      selectedCompanyId: record.companyId,
    });

    this.formRef.current.setFieldsValue({
      lastName: record.lastName,
      firstName: record.firstName,
      representedCompany: record.representedCompany,
      phoneNumber: record.comment,
      licensePlateNumber: record.vehicle?.licensePlateNumber,
      from: record.parkingReservation?.from
        ? moment(new Date(record.parkingReservation?.from))
        : "",
      to: record.parkingReservation?.to
        ? moment(new Date(record.parkingReservation?.to))
        : "",
    });
  };

  handleSave = async () => {
    const toSend = {
      guestId: this.state.guestId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: "post-created-guest-email@invalidEmail.invalid",
      representedCompany: this.state.representedCompany,
      phoneNumber: this.state.phoneNumber,
      isManualPostCreatedGuest: true,
      licensePlateNumber: this.state.licensePlateNumber,
      from: this.state.from,
      to: this.state.to,
      companyId: toNumber(this.state.selectedCompanyId),
    };
    if (this.state.modify) {
      await this.props.modifyPostCreatedGuestWithVehicle(toSend);
      if (this.props.guestSaveStatus) {
        this.closeNewGuestRegisterModal();
        this.getAllPostCreatedGuests();
      } else {
        message.error("save-error-label");
      }
    } else {
      await this.props.createVehicleWithGuest(toSend);
      if (this.props.saveStatus) {
        this.closeNewGuestRegisterModal();
        this.getAllPostCreatedGuests();
      } else {
        message.error("save-error-label");
      }
    }
  };

  render() {
    const columns = [
      {
        title: this.props.t("guest-register-lastname-label"),
        dataIndex: "lastName",
        key: "lastName",
        align: "center",
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        sortDirections: ["ascend", "descend"],
        // filteredValue: this.state.filteredInfo.lastName || null,
        ...this.getColumnSearchProps(
          "lastName",
          this.props.t("guest-register-lastname-label")
        ),
      },
      {
        title: this.props.t("guest-register-firstname-label"),
        dataIndex: "firstName",
        key: "firstName",
        align: "center",
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        sortDirections: ["ascend", "descend"],
        // filteredValue: this.state.filteredInfo.firstName || null,
        ...this.getColumnSearchProps(
          "firstName",
          this.props.t("guest-register-firstname-label")
        ),
      },
      {
        title: this.props.t("licensePlateNumber"),
        key: "licensePlateNumber",
        align: "center",
        sorter: (a, b) =>
          a.vehicle?.licensePlateNumber.localeCompare(
            b.vehicle?.licensePlateNumber
          ),
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps(
          ["vehicle", "licensePlateNumber"],
          this.props.t("licensePlateNumber")
        ),
        render: (text, record) => {
          return record.vehicle?.licensePlateNumber ?? "";
        },
      },
      {
        title: this.props.t("guest-register-company-label"),
        dataIndex: "representedCompany",
        key: "representedCompany",
        align: "center",
        sorter: (a, b) =>
          a.representedCompany.localeCompare(b.representedCompany),
        sortDirections: ["ascend", "descend"],
        //filteredValue: this.state.filteredInfo.representedCompany || null,
        ...this.getColumnSearchProps(
          "representedCompany",
          this.props.t("guest-register-company-label")
        ),
      },
      {
        title: this.props.t("profile-phone-label"),
        key: "comment",
        align: "center",
        dataIndex: "comment",
        sorter: (a, b) => a.comment.localeCompare(b.comment),
        // filteredValue: this.state.filteredInfo.comment || null,
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps(
          "comment",
          this.props.t("profile-phone-label")
        ),
      },
    ];

    if (this.hasRight([userRoles.OFFICE_ADMIN, userRoles.PARKING_RECEPTION])) {
      columns.push({
        title: this.props.t("companies-label-companyname"),
        key: "company",
        dataIndex: "companyName",
        sorter: (a, b) => a.companyName.localeCompare(b.companyName),
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps(
          "companyName",
          this.props.t("companies-label-companyname")
        ),
      });
    }

    columns.push(
      {
        title: this.props.t("manual-guest-reservation-from-label"),
        key: "from",
        align: "center",
        dataIndex: "parkingReservation.from",
        sorter: (a, b) =>
          new Date(a.parkingReservation?.from) -
          new Date(b.parkingReservation?.from),
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps(
          ["parkingReservation", "from"],
          this.props.t("manual-guest-reservation-from-label"),
          "date"
        ),
        render: (text, record) => {
          return moment(record.parkingReservation?.from).format(
            "YYYY.MM.DD. HH:mm"
          );
        },
      },
      {
        title: this.props.t("manual-guest-reservation-to-label"),
        key: "to",
        align: "center",
        dataIndex: "parkingReservation.to",
        sorter: (a, b) =>
          new Date(a.parkingReservation?.to) -
          new Date(b.parkingReservation?.to),
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps(
          ["parkingReservation", "to"],
          this.props.t("manual-guest-reservation-to-label"),
          "date"
        ),
        render: (text, record) => {
          return record.parkingReservation?.to
            ? moment(record.parkingReservation?.to).format("YYYY.MM.DD. HH:mm")
            : " - ";
        },
      },
      {
        title: this.props.t("modify-button-label"),
        key: "modify",
        align: "center",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.handleOpenModifyGuest(record)}>
                <EditFilled />
              </Button>
            </Space>
          );
        },
      }
    );

    return (
      <div>
        <Row>
          <Col span={24}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>{this.props.t("manual-enter-button-label")}</strong>
            </h1>
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col style={{ padding: "1em" }}>
            <Button
              type="primary"
              onClick={this.handleOpenNewGuestRegisterModal}
            >
              <PlusOutlined />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              rowKey="id"
              dataSource={this.state.guests}
              locale={{ emptyText: this.props.t("empty-text") }}
              loading={!this.props.usersStatus && !this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: true,
              }}
              scroll={{ x: true }}
              onChange={this.handleChangeTable}
            />
          </Col>
        </Row>
        <Modal
          visible={this.state.newGuestRegisterModal}
          onCancel={this.closeNewGuestRegisterModal}
          title={
            this.state.modify
              ? this.props.t("modify-button-label")
              : this.props.t("guest-main-register-button-label")
          }
          maskClosable={false}
          forceRender
          centered
          footer={[
            <Button key="invite" type="primary" onClick={this.handleSave}>
              {this.state.modify
                ? this.props.t("modify-button-label")
                : this.props.t("save-button-label")}
            </Button>,
            <Button key="cancel" onClick={this.closeNewGuestRegisterModal}>
              {this.props.t("cancel-button-label")}
            </Button>,
          ]}
        >
          <Form
            onFinish={this.handleSave}
            ref={this.formRef}
            {...formItemLayout}
            name="design"
            scrollToFirstError
          >
            <Form.Item
              name="lastName"
              label={this.props.t("guest-register-lastname-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input name="lastName" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item
              name="firstName"
              label={this.props.t("guest-register-firstname-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input name="firstName" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item
              name="representedCompany"
              label={this.props.t("guest-register-company-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input name="representedCompany" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={this.props.t("profile-phone-label")}
              rules={[
                {
                  pattern:
                    // eslint-disable-next-line no-useless-escape
                    /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/,
                  message: this.props.t("wrong-format"),
                },
                {
                  max: 15,
                  message: this.props.t("phone-number-max-digit"),
                },
              ]}
            >
              <Input name="phoneNumber" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item
              name={"companyId"}
              fieldKey={"companyId"}
              label={this.props.t("parking-reception-host-company-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("parking-reception-host-company-label"),
                },
              ]}
            >
              <Select
                value={this.state.selectedCompanyId}
                onChange={this.changeCompany}
              >
                {this.props.companies.map((company) => {
                  return <Option key={company.id}>{company.name}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="licensePlateNumber"
              label={this.props.t("licensePlateNumber")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input name="licensePlateNumber" onChange={this.handleChange} />
            </Form.Item>
            {this.state.modify ? (
              <>
                <ConfigProvider locale={localehu} name="from">
                  <Form.Item
                    name={"from"}
                    fieldKey={"from"}
                    label={this.props.t("manual-guest-reservation-from-label")}
                    rules={[
                      {
                        required: true,
                        message: this.props.t(
                          "manual-guest-reservation-from-label"
                        ),
                      },
                    ]}
                  >
                    <DatePicker
                      mode="date"
                      showTime
                      onChange={(date, dateString) =>
                        this.handleDatePickerChange(date, dateString, "from")
                      }
                      name="from"
                      locale={{ dateTimeFormat: "YYYY-MM-DD HH:mm" }}
                      minuteStep={5}
                      showNow={false}
                      showSecond={false}
                      format="YYYY-MM-DD HH:mm"
                      defaultPickerValue={() => {
                        const date = new Date();
                        if (date.getMinutes() !== 0) {
                          if (date.getMinutes() > 30) {
                            date.setMinutes(0);
                            date.setSeconds(0);
                            date.setHours(date.getHours() + 1);
                          } else {
                            date.setMinutes(30);
                            date.setSeconds(0);
                          }
                        }
                        return moment(date);
                      }}
                      value={moment(this.state.from || new Date()).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                    />
                  </Form.Item>
                </ConfigProvider>
                <ConfigProvider locale={localehu} name="to">
                  <Form.Item
                    name={"to"}
                    fieldKey={"to"}
                    label={this.props.t("manual-guest-reservation-to-label")}
                    rules={[
                      {
                        required: true,
                        message: this.props.t(
                          "manual-guest-reservation-to-label"
                        ),
                      },
                    ]}
                  >
                    <DatePicker
                      mode="date"
                      showTime
                      onChange={(date, dateString) =>
                        this.handleDatePickerChange(date, dateString, "to")
                      }
                      name="to"
                      locale={{ dateTimeFormat: "YYYY-MM-DD HH:mm" }}
                      minuteStep={5}
                      showNow={false}
                      showSecond={false}
                      format="YYYY-MM-DD HH:mm"
                      defaultPickerValue={() => {
                        const date = new Date();
                        if (date.getMinutes() !== 0) {
                          if (date.getMinutes() > 30) {
                            date.setMinutes(0);
                            date.setSeconds(0);
                            date.setHours(date.getHours() + 1);
                          } else {
                            date.setMinutes(30);
                            date.setSeconds(0);
                          }
                        }
                        return moment(date);
                      }}
                      value={moment(this.state.to || new Date()).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                    />
                  </Form.Item>
                </ConfigProvider>
              </>
            ) : (
              <></>
            )}

            <Form.Item hidden>
              <Button htmlType="submit" type="primary" hidden>
                {this.props.t("add-button-label")}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  guests: state.receptionReducer.guests,
  companies: state.companyReducer.companies,
  saveStatus: state.parkingReducer.saveStatus,
  guestSaveStatus: state.receptionReducer.guestSaveStatus,
});
const ManualEnter = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedManualEnter)
);
export default ManualEnter;
