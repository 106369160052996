export const timezones = [
  {
    Index: 0,
    Name: "Dateline Standard Time",
    Time: "(GMT-12:00) International Date Line West",
    momentTimezone: "Etc/GMT-12",
  },
  {
    Index: 1,
    Name: "Samoa Standard Time",
    Time: "(GMT-11:00) Midway Island, Samoa",
    momentTimezone: "Pacific/Midway",
  },
  {
    Index: 2,
    Name: "Hawaiian Standard Time",
    Time: "(GMT-10:00) Hawaii",
    momentTimezone: "US/Hawaii",
  },
  {
    Index: 3,
    Name: "Alaskan Standard Time",
    Time: "(GMT-09:00) Alaska",
    momentTimezone: "US/Alaska",
  },
  {
    Index: 4,
    Name: "Pacific Standard Time",
    Time: "(GMT-08:00) Pacific Time (US and Canada); Tijuana",
    momentTimezone: "US/Pacific",
  },
  {
    Index: 10,
    Name: "Mountain Standard Time",
    Time: "(GMT-07:00) Mountain Time (US and Canada)",
    momentTimezone: "US/Mountain",
  },
  {
    Index: 13,
    Name: "Mexico Standard Time 2",
    Time: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
    momentTimezone: "America/Chihuahua",
  },
  {
    Index: 15,
    Name: "U.S. Mountain Standard Time",
    Time: "(GMT-07:00) Arizona",
    momentTimezone: "US/Arizona",
  },
  {
    Index: 20,
    Name: "Central Standard Time",
    Time: "(GMT-06:00) Central Time (US and Canada",
    momentTimezone: "US/Central",
  },
  {
    Index: 25,
    Name: "Canada Central Standard Time",
    Time: "(GMT-06:00) Saskatchewan",
    momentTimezone: "Canada/Saskatchewan",
  },
  {
    Index: 30,
    Name: "Mexico Standard Time",
    Time: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
    momentTimezone: "America/Mexico_City",
  },
  {
    Index: 33,
    Name: "Central America Standard Time",
    Time: "(GMT-06:00) Central America",
    momentTimezone: "Etc/GMT-6",
  },
  {
    Index: 35,
    Name: "Eastern Standard Time",
    Time: "(GMT-05:00) Eastern Time (US and Canada)",
    momentTimezone: "US/Eastern",
  },
  {
    Index: 40,
    Name: "U.S. Eastern Standard Time",
    Time: "(GMT-05:00) Indiana (East)",
    momentTimezone: "US/East-Indiana",
  },
  {
    Index: 45,
    Name: "S.A. Pacific Standard Time",
    Time: "(GMT-05:00) Bogota, Lima, Quito",
    momentTimezone: "America/Bogota",
  },
  {
    Index: 50,
    Name: "Atlantic Standard Time",
    Time: "(GMT-04:00) Atlantic Time (Canada)",
    momentTimezone: "Canada/Atlantic",
  },
  {
    Index: 55,
    Name: "S.A. Western Standard Time",
    Time: "(GMT-04:00) Caracas, La Paz",
    momentTimezone: "America/Caracas",
  },
  {
    Index: 56,
    Name: "Pacific S.A. Standard Time",
    Time: "(GMT-04:00) Santiago",
    momentTimezone: "America/Santiago",
  },
  {
    Index: 60,
    Name: "Newfoundland and Labrador Standard Time",
    Time: "(GMT-03:30) Newfoundland and Labrador",
    momentTimezone: "Canada/Newfoundland",
  },
  {
    Index: 65,
    Name: "E. South America Standard Time",
    Time: "(GMT-03:00) Brasilia",
    momentTimezone: "Etc/GMT-3",
  },
  {
    Index: 70,
    Name: "S.A. Eastern Standard Time",
    Time: "(GMT-03:00) Buenos Aires, Georgetown",
    momentTimezone: "America/Argentina/Buenos_Aires",
  },
  {
    Index: 73,
    Name: "Greenland Standard Time",
    Time: "(GMT-03:00) Greenland",
    momentTimezone: "Etc/GMT-3",
  },
  {
    Index: 75,
    Name: "Mid-Atlantic Standard Time",
    Time: "(GMT-02:00) Mid-Atlantic",
    momentTimezone: "Etc/GMT-2",
  },
  {
    Index: 80,
    Name: "Azores Standard Time",
    Time: "(GMT-01:00) Azores",
    momentTimezone: "Atlantic/Azores",
  },
  {
    Index: 83,
    Name: "Cape Verde Standard Time",
    Time: "(GMT-01:00) Cape Verde Islands",
    momentTimezone: "Atlantic/Cape_Verde",
  },
  {
    Index: 85,
    Name: "GMT Standard Time",
    Time: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London",
    momentTimezone: "Europe/London",
  },
  {
    Index: 90,
    Name: "Greenwich Standard Time",
    Time: "(GMT) Casablanca, Monrovia",
    momentTimezone: "Africa/Casablanca",
  },
  {
    Index: 95,
    Name: "Central Europe Standard Time",
    Time: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    momentTimezone: "Europe/Budapest",
  },
  {
    Index: 100,
    Name: "Central European Standard Time",
    Time: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    momentTimezone: "Europe/Warsaw",
  },
  {
    Index: 105,
    Name: "Romance Standard Time",
    Time: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
    momentTimezone: "Europe/Paris",
  },
  {
    Index: 110,
    Name: "W. Europe Standard Time",
    Time: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    momentTimezone: "Europe/Berlin",
  },
  {
    Index: 113,
    Name: "W. Central Africa Standard Time",
    Time: "(GMT+01:00) West Central Africa",
    momentTimezone: "Etc/GMT+1",
  },
  {
    Index: 115,
    Name: "E. Europe Standard Time",
    Time: "(GMT+02:00) Bucharest",
    momentTimezone: "Europe/Bucharest",
  },
  {
    Index: 120,
    Name: "Egypt Standard Time",
    Time: "(GMT+02:00) Cairo",
    momentTimezone: "Africa/Cairo",
  },
  {
    Index: 125,
    Name: "FLE Standard Time",
    Time: "(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
    momentTimezone: "Europe/Riga",
  },
  {
    Index: 130,
    Name: "GTB Standard Time",
    Time: "(GMT+02:00) Athens, Istanbul, Minsk",
    momentTimezone: "Europe/Athens",
  },
  {
    Index: 135,
    Name: "Israel Standard Time",
    Time: "(GMT+02:00) Jerusalem",
    momentTimezone: "Asia/Jerusalem",
  },
  {
    Index: 140,
    Name: "South Africa Standard Time",
    Time: "(GMT+02:00) Harare, Pretoria",
    momentTimezone: "Africa/Harare",
  },
  {
    Index: 145,
    Name: "Russian Standard Time",
    Time: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
    momentTimezone: "Europe/Moscow",
  },
  {
    Index: 150,
    Name: "Arab Standard Time",
    Time: "(GMT+03:00) Kuwait, Riyadh",
    momentTimezone: "Asia/Kuwait",
  },
  {
    Index: 155,
    Name: "E. Africa Standard Time",
    Time: "(GMT+03:00) Nairobi",
    momentTimezone: "Africa/Nairobi",
  },
  {
    Index: 158,
    Name: "Arabic Standard Time",
    Time: "(GMT+03:00) Baghdad",
    momentTimezone: "Asia/Baghdad",
  },
  {
    Index: 160,
    Name: "Iran Standard Time",
    Time: "(GMT+03:30) Tehran",
    momentTimezone: "Asia/Tehran",
  },
  {
    Index: 165,
    Name: "Arabian Standard Time",
    Time: "(GMT+04:00) Abu Dhabi, Muscat",
    momentTimezone: "Asia/Muscat",
  },
  {
    Index: 170,
    Name: "Caucasus Standard Time",
    Time: "(GMT+04:00) Baku, Tbilisi, Yerevan",
    momentTimezone: "Asia/Yerevan",
  },
  {
    Index: 175,
    Name: "Transitional Islamic State of Afghanistan Standard Time",
    Time: "(GMT+04:30) Kabul",
    momentTimezone: "Asia/Kabul",
  },
  {
    Index: 180,
    Name: "Ekaterinburg Standard Time",
    Time: "(GMT+05:00) Ekaterinburg",
    momentTimezone: "Asia/Yekaterinburg",
  },
  {
    Index: 185,
    Name: "West Asia Standard Time",
    Time: "(GMT+05:00) Islamabad, Karachi, Tashkent",
    momentTimezone: "Asia/Karachi",
  },
  {
    Index: 190,
    Name: "India Standard Time",
    Time: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    momentTimezone: "Asia/Kolkata",
  },
  {
    Index: 193,
    Name: "Nepal Standard Time",
    Time: "(GMT+05:45) Kathmandu",
    momentTimezone: "Asia/Kathmandu",
  },
  {
    Index: 195,
    Name: "Central Asia Standard Time",
    Time: "(GMT+06:00) Astana, Dhaka",
    momentTimezone: "Etc/GMT+6",
  },
  {
    Index: 200,
    Name: "Sri Lanka Standard Time",
    Time: "(GMT+06:00) Sri Jayawardenepura",
    momentTimezone: "Etc/GMT+6 ",
  },
  {
    Index: 201,
    Name: "N. Central Asia Standard Time",
    Time: "(GMT+06:00) Almaty, Novosibirsk",
    momentTimezone: "Etc/GMT+6 ",
  },
  {
    Index: 203,
    Name: "Myanmar Standard Time",
    Time: "(GMT+06:30) Yangon Rangoon",
    momentTimezone: "Asia/Rangoon",
  },
  {
    Index: 205,
    Name: "S.E. Asia Standard Time",
    Time: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
    momentTimezone: "Asia/Bangkok",
  },
  {
    Index: 207,
    Name: "North Asia Standard Time",
    Time: "(GMT+07:00) Krasnoyarsk",
    momentTimezone: "Asia/Krasnoyarsk",
  },
  {
    Index: 210,
    Name: "China Standard Time",
    Time: "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi",
    momentTimezone: "Asia/Chongqing",
  },
  {
    Index: 215,
    Name: "Singapore Standard Time",
    Time: "(GMT+08:00) Kuala Lumpur, Singapore",
    momentTimezone: "Asia/Singapore",
  },
  {
    Index: 220,
    Name: "Taipei Standard Time",
    Time: "(GMT+08:00) Taipei",
    momentTimezone: "Asia/Taipei",
  },
  {
    Index: 225,
    Name: "W. Australia Standard Time",
    Time: "(GMT+08:00) Perth",
    momentTimezone: "Australia/Perth",
  },
  {
    Index: 227,
    Name: "North Asia East Standard Time",
    Time: "(GMT+08:00) Irkutsk, Ulaanbaatar",
    momentTimezone: "Asia/Ulaanbaatar",
  },
  {
    Index: 230,
    Name: "Korea Standard Time",
    Time: "(GMT+09:00) Seoul",
    momentTimezone: "Asia/Seoul",
  },
  {
    Index: 235,
    Name: "Tokyo Standard Time",
    Time: "(GMT+09:00) Osaka, Sapporo, Tokyo",
    momentTimezone: "Asia/Tokyo",
  },
  {
    Index: 240,
    Name: "Yakutsk Standard Time",
    Time: "(GMT+09:00) Yakutsk",
    momentTimezone: "Asia/Yakutsk",
  },
  {
    Index: 245,
    Name: "A.U.S. Central Standard Time",
    Time: "(GMT+09:30) Darwin",
    momentTimezone: "Australia/Darwin",
  },
  {
    Index: 250,
    Name: "Cen. Australia Standard Time",
    Time: "(GMT+09:30) Adelaide",
    momentTimezone: "Australia/Adelaide",
  },
  {
    Index: 255,
    Name: "A.U.S. Eastern Standard Time",
    Time: "(GMT+10:00) Canberra, Melbourne, Sydney",
    momentTimezone: "Australia/Sydney",
  },
  {
    Index: 260,
    Name: "E. Australia Standard Time",
    Time: "(GMT+10:00) Brisbane",
    momentTimezone: "Australia/Brisbane",
  },
  {
    Index: 265,
    Name: "Tasmania Standard Time",
    Time: "(GMT+10:00) Hobart",
    momentTimezone: "Australia/Hobart",
  },
  {
    Index: 270,
    Name: "Vladivostok Standard Time",
    Time: "(GMT+10:00) Vladivostok",
    momentTimezone: "Asia/Vladivostok",
  },
  {
    Index: 275,
    Name: "West Pacific Standard Time",
    Time: "(GMT+10:00) Guam, Port Moresby",
    momentTimezone: "Pacific/Guam",
  },
  {
    Index: 280,
    Name: "Central Pacific Standard Time",
    Time: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia",
    momentTimezone: "Asia/Magadan",
  },
  {
    Index: 285,
    Name: "Fiji Islands Standard Time",
    Time: "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands",
    momentTimezone: "Pacific/Fiji",
  },
  {
    Index: 290,
    Name: "New Zealand Standard Time",
    Time: "(GMT+12:00) Auckland, Wellington",
    momentTimezone: "Pacific/Auckland",
  },
  {
    Index: 300,
    Name: "Tonga Standard Time",
    Time: "(GMT+13:00) Nuku'alofa",
    momentTimezone: "Pacific/Tongatapu",
  },
];
