import React, { Component } from "react";
import { Form, Input, Modal, Button, Select, Checkbox } from "antd";
import { connect } from "react-redux";
import { SaveFilled } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import "./createOrUpdateRoomModal.scss";
import { ROOM_TYPES_ARRAY } from "../../../constants/room-types";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class ConnectCreateOrUpdateRoomModal extends Component {
  formRef = React.createRef();

  state = {
    modalVisible: this.props.modalVisible || false,
    modalMode: "create",
    selectedRoom: null,
    name: null,
    type: null,
    floor: 0,
    isPublic: false,
    officeId: null,
    user: null,
  };

  componentDidMount = async () => {
    this.setState({
      modalVisible: this.props.modalVisible,
      modalMode: this.props.modalMode,
    });
  };

  componentDidUpdate = () => {
    if (this.props.modalVisible !== this.state.modalVisible) {
      this.setState({
        modalVisible: this.props.modalVisible,
        modalMode: this.props.modalMode,
        user: this.props.user,
      });

      if (this.props.selectedRoom) {
        const selectedRoom = this.props.selectedRoom;
        this.setState({
          selectedRoom: selectedRoom,
          name: selectedRoom.name,
          type: selectedRoom.type,
          floor: selectedRoom.floor,
          isPublic: selectedRoom.isPublic,
        });

        this.formRef.current.setFieldsValue({
          name: selectedRoom.name,
          type: selectedRoom.type,
          floor: selectedRoom.floor,
          isPublic: selectedRoom.isPublic,
        });
      }
    }
  };

  handleCancel = () => {
    this.setState({
      name: null,
      type: null,
      floor: 0,
      isPublic: false,
      officeId: null,
    });
    this.formRef.current.resetFields();
    this.props.handleCancelModal();
  };

  handleFormFinish = async () => {
    const saveData = {
      id: this.state.selectedRoom?.id || null,
      officeId: null,
      name: this.state.name,
      type: this.state.type,
      floor: this.state.floor,
      isPublic: this.state.isPublic,
    };

    if (this.state.modalMode === "create") {
      await this.props.createRoom(saveData);
    } else {
      await this.props.updateRoom(saveData);
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeType = (event) => {
    this.setState({ type: event });
  };

  handleChangeCheckbox = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  handleOpenModal = async () => {
    this.setState({
      openSearchModal: true,
    });
  };

  render() {
    return (
      <div className="roomModal">
        <Modal
          visible={this.state.modalVisible}
          title={
            this.state.modalMode === "create"
              ? this.props.t("building-layout-room-modal-create-title")
              : this.props.t("building-layout-room-modal-update-title")
          }
          onCancel={this.handleCancel}
          footer={null}
          forceRender
        >
          <Form
            className="modal-body"
            name="modifier"
            onFinish={this.handleFormFinish}
            ref={this.formRef}
            {...formItemLayout}
            scrollToFirstError
          >
            <Form.Item
              label={this.props.t("building-layout-room-modal-name-label")}
              name="name"
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
              hasFeedback
            >
              <Input
                value={this.state.name}
                name="name"
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              label={this.props.t("building-layout-room-modal-type-label")}
              name="type"
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Select
                name="type"
                onChange={(event) => this.changeType(event)}
                value={this.state.type}
              >
                {ROOM_TYPES_ARRAY.map((roomType) => {
                  return (
                    <Select.Option key={roomType.id} value={roomType.id}>
                      {this.props.t(roomType.label)}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={this.props.t("building-layout-room-modal-floor-label")}
              name="floor"
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input
                value={this.state.floor}
                name="floor"
                type="number"
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              label={this.props.t("building-layout-room-modal-is-public-label")}
              name="isPublic"
              initialValue={this.state.isPublic}
            >
              <Checkbox
                name="isPublic"
                onChange={this.handleChangeCheckbox}
                checked={this.state.isPublic}
              />
            </Form.Item>
            <Form.Item
              {...tailFormItemLayout}
              style={{
                textAlign: "right",
                width: "100%",
              }}
            >
              <Button
                key="cancel"
                onClick={this.handleCancel}
                style={{ marginRight: "1em" }}
              >
                {this.props.t("cancel-button-label")}
              </Button>
              <Button type="primary" htmlType="submit">
                <SaveFilled />
                {this.state.modalMode === "create"
                  ? this.props.t(
                      "building-layout-room-modal-create-button-label"
                    )
                  : this.props.t(
                      "building-layout-room-modal-update-button-label"
                    )}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const CreateOrUpdateRoomModal = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectCreateOrUpdateRoomModal)
);
export default CreateOrUpdateRoomModal;
