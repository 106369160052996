import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Table, Space, Popconfirm } from "antd";
import { activateEmergency } from "../../actions/emergency";
import { getAllCompanies } from "../../actions/company";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";

const mapDispatchToProps = (dispatch) => {
  return {
    activateEmergency: () => dispatch(activateEmergency()),
    getAllCompanies: () => dispatch(getAllCompanies()),
  };
};

class ConnectedEmergency extends Component {
  state = {
    buttonString: this.props.t("emergency-emergency-on-button-label"),
    selectedCompany: null,
    companies: [],
  };

  componentDidMount = async () => {
    if (!this.props.user.companyId) {
      await this.props.getAllCompanies();
      if (this.props.status) {
        this.setState({ companies: this.props.companies });
      }
      this.props.socket.on("emergencyUpdate", async () => {
        await this.props.getAllCompanies();
        if (this.props.status) {
          this.setState({ companies: this.props.companies });
        }
      });
    }
    if (
      process.env.REACT_APP_MODE &&
      process.env.REACT_APP_MODE === "development"
    ) {
      this.setState({ buttonString: this.props.t("emergency-emergency-on-button-label") });
    }
  };

  handleEmergencyClick = () => {
    this.props.socket.emit("emergencyActivate", {
      companyId: this.props.user.companyId,
      userId: this.props.user.id,
    });
  };

  selectCompany = (event) => {
    this.setState({ selectedCompany: event });
  };

  handleActivateEmergencyAdmin = (id) => {
    this.props.socket.emit("emergencyActivate", {
      companyId: id,
      userId: this.props.user.id,
    });
  };

  handleDeactivateEmergencyAdmin = (id) => {
    this.props.socket.emit("emergencyDeactivate", {
      companyId: id,
      userId: this.props.user.id,
    });
  };

  render() {
    const columns = [
      {
        title: this.props.t("companies-label-companyname"),
        key: "name",
        render: (text, record) => {
          return (
            <Space size="middle">{`${
              record.name
            }`}</Space>
          );
        },
      },
      {
        title: this.props.t("emergency-admin-table-status"),
        key: "status",
        render: (text, record) => {
          return (
            <Space size="middle">{`${
              record.emergency
                ? this.props.t("emergency-admin-table-status-emergency")
                : this.props.t("emergency-admin-table-status-noemergency")
            }`}</Space>
          );
        },
      },
      {
        title: this.props.t("emergency-admin-table-action"),
        key: "action",
        render: (text, record) => {
          return (
            <Space size="middle">
              {record.emergency ? (
                <Button
                  type="primary"
                  onClick={() => this.handleDeactivateEmergencyAdmin(record.id)}
                  style={{ background: "green", borderColor: "green" }}
                >
                  {this.props.t("deactivate-button-label")}
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={() => this.handleActivateEmergencyAdmin(record.id)}
                  style={{ background: "red", borderColor: "red" }}
                >
                  {this.props.t("activate-button-label")}
                </Button>
              )}
            </Space>
          );
        },
      },
    ];

    return (
      <div>
        {this.props.user.companyId && (
          <div>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>{this.props.t("emergency-title")}</strong>
            </h1>
            <div>
              <Popconfirm
                okText={this.props.t("yes-button-label")}
                cancelText={this.props.t("no-button-label")}
                onConfirm={this.handleEmergencyClick}
                title={this.props.t("emergency-confirm-title")}
                placement="right"
              >
                <Button>
                  {process.env.REACT_APP_MODE &&
                  process.env.REACT_APP_MODE === "development"
                    ? this.props.t("emergency-emergency-on-button-label")
                    : this.props.t("emergency-emergency-on-button-label")}
                </Button>
              </Popconfirm>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {this.props.t("emergency-description-1")}
                <br />{this.props.t("emergency-description-2")}
              </div>
              <div>
                <img
                  alt="emergency"
                  src={`${process.env.REACT_APP_BASE_URL}/api/1.0.0/assets/emergency_${i18n.language}.png`}
                  style={{ width: "20vw" }}
                />
              </div>
            </div>
          </div>
        )}
        {!this.props.user.companyId && (
          <div>
            <h1>{this.props.t("emergency-title")}</h1>
            <div>
              <Table
                columns={columns}
                dataSource={this.state.companies}
                rowKey="id"
                pagination={{
                  position: ["bottomCenter"],
                  showSizeChanger: true,
                }}
                onRow={(record, index) => {
                  return {
                    style: {
                      background: record.emergency && "red",
                    },
                  };
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  companies: state.companyReducer.companies,
  status: state.companyReducer.status,
});
const Emergency = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedEmergency)
);
export default Emergency;
