import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Input, message, Select } from "antd";
import { SaveFilled } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { withTranslation } from "react-i18next";
import Checkbox from "antd/lib/checkbox/Checkbox";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 11,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

class ConnectedCreateOrUpdateCompanyModal extends Component {
  formRef = React.createRef();

  state = {
    modalVisible: this.props.modalVisible || false,
    modalMode: "create",
    name: "",
    email: "",
    defaultLanguageId: null,
    langCode: "",
    subscriptionId: null,
    companyId: null,
    officeId: null,
    hasParklIntegration: false,
    parklId: null,
    languages: [],
    subscriptions: [],
    selectedCompany: null,
  };

  componentDidMount = async () => {
    this.setState({
      modalVisible: this.props.modalVisible,
      modalMode: this.props.modalMode,
      languages: this.props.languages || [],
      subscriptions: this.props.subscriptions || [],
    });
  };

  componentDidUpdate = () => {
    if (this.props.modalVisible !== this.state.modalVisible) {
      this.setState({
        modalVisible: this.props.modalVisible,
        modalMode: this.props.modalMode,
        languages: this.props.languages || [],
        subscriptions: this.props.subscriptions || [],
      });

      if (this.props.selectedCompany) {
        const language = this.props.languages.find(
          (x) => x.id === this.props.selectedCompany?.defaultLanguageId
        );

        this.formRef.current.setFieldsValue({
          name: this.props.selectedCompany.name,
          langCode: language.code,
          subscriptionId: this.props.selectedCompany.subscriptionId,
          office: this.props.selectedCompany.officeId,
          hasParklIntegration: this.props.selectedCompany.hasParklIntegration,
          parklId: this.props.selectedCompany.parklId,
        });

        this.setState({
          selectedCompany: this.props.selectedCompany,
          name: this.props.selectedCompany.name,
          defaultLanguageId: this.props.selectedCompany.defaultLanguageId,
          subscriptionId: this.props.selectedCompany.subscriptionId,
          officeId: this.props.selectedCompany.officeId,
          hasParklIntegration: this.props.selectedCompany.hasParklIntegration,
          parklId: this.props.selectedCompany.parklId,
        });
      }
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheckboxChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  submitForm = async () => {
    const companyData = {
      name: this.state.name,
      langCode: this.state.langCode,
      defaultLanguageId: this.state.defaultLanguageId,
      email: this.state.email,
      subscriptionId: this.state.subscriptionId,
      officeId: !this.props.user.officeId
        ? this.props.user.officeId
        : this.state.officeId,
      hasParklIntegration: this.state.hasParklIntegration,
      parklId: this.state.parklId,
    };

    await this.props.createOrUpdateCompany(
      companyData,
      this.state.modalMode === "create"
    );

    if (this.props.saveStatus) {
      message.success(this.props.t("save-success"), 5);
      this.closeModal();
    } else {
      if (this.props.message.code === 1003) {
        message.error(
          this.props.t("save-fail-because-email-already-exists"),
          5
        );
      } else {
        message.error(this.props.t("save-fail"), 5);
      }
    }
  };

  closeModal = () => {
    this.setState({
      name: "",
      email: "",
      defaultLanguageId: null,
      langCode: "",
      subscriptionId: null,
      officeId: null,
      hasParklIntegration: false,
      parklId: null,
    });
    this.formRef.current.resetFields();
    this.props.closeModal();
  };

  changeLanguage = (event) => {
    this.setState({ langCode: event });
  };

  changeSubscription = (event) => {
    this.setState({ subscriptionId: event });
  };

  handleChangeOffice = (event) => {
    this.setState({ officeId: event });
  };

  render() {
    return (
      <div>
        <Modal
          visible={this.props.modalVisible || this.state.modalVisible}
          centered
          title={
            <strong>
              {this.props.t(
                this.state.modalMode === "create"
                  ? "companies-newcompany-title"
                  : "companies-modifycompany-title"
              )}
            </strong>
          }
          footer={null}
          width={"37em"}
          onCancel={this.closeModal}
          forceRender
        >
          <Form
            style={{
              position: "relative",
              right: "1em",
              margin: "auto",
            }}
            ref={this.formRef}
            {...formItemLayout}
            name="company"
            onFinish={this.submitForm}
            scrollToFirstError
          >
            <Form.Item
              name="name"
              label={this.props.t("companies-label-companyname")}
              rules={[
                {
                  required: true,
                  message: this.props.t("companies-companyname-text"),
                  whitespace: true,
                },
              ]}
            >
              <Input
                style={{ width: "18em" }}
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </Form.Item>
            {this.props.modalMode === "create" && (
              <Form.Item
                name="email"
                label={this.props.t("companies-companyadmin-email-label")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("email-empty"),
                    whitespace: false,
                  },
                  {
                    type: "email",
                    message: this.props.t("email-wrong-format"),
                  },
                ]}
              >
                <Input
                  style={{ width: "18em" }}
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Form.Item>
            )}
            <Form.Item
              name="langCode"
              label={this.props.t("companies-companyadmin-language-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("companies-companyadmin-language-text"),
                },
              ]}
            >
              <Select
                style={{ width: "18em" }}
                onChange={this.changeLanguage}
                value={this.state.defaultLanguageId}
              >
                {(this.props.languages || this.state.languages).map(
                  (language) => {
                    return (
                      <Select.Option
                        key={language.code}
                        value={language.cpde}
                      >{`${language.name} - ${language.code}`}</Select.Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name="subscriptionId"
              label={this.props.t("companies-companyadmin-subscribe-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t(
                    "companies-companyadmin-subscribe-text"
                  ),
                },
              ]}
            >
              <Select
                style={{ width: "18em" }}
                onChange={this.changeSubscription}
                value={this.state.subscriptionId}
              >
                {(this.props.subscriptions || this.state.subscriptions).map(
                  (subscription) => {
                    return (
                      <Option key={subscription.id} value={subscription.id}>
                        {subscription.name}
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            {(this.props.user.officeId === null ||
              this.props.user.officeId === undefined) && (
              <Form.Item name="office" label={this.props.t("office")}>
                <Select
                  style={{ width: "18em" }}
                  name="office"
                  value={this.state.officeId}
                  onChange={this.handleChangeOffice}
                >
                  {this.props.offices.map((office) => {
                    return (
                      <Option key={office.id} value={office.id}>
                        {office.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              name="hasParklIntegration"
              label={this.props.t("company-has-parkl-integration-label")}
            >
              <Checkbox
                name="hasParklIntegration"
                checked={this.state.hasParklIntegration}
                onChange={(event) => this.handleCheckboxChange(event)}
              />
            </Form.Item>
            <Form.Item
              name="parklId"
              label={this.props.t("company-parkl-id-label")}
            >
              <Input
                style={{ width: "18em" }}
                name="parklId"
                value={this.state.parklId}
                disabled={
                  !this.props.selectedCompany?.hasParklIntegration &&
                  !this.state.hasParklIntegration
                }
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                style={{ margin: "0.5em", position: "relative", left: "16em" }}
              >
                <SaveFilled />
                {this.props.t("save-button-label")}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
});

const CreateOrUpdateCompanyModal = withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConnectedCreateOrUpdateCompanyModal)
);
export default CreateOrUpdateCompanyModal;
