import React, { Component } from "react";
import { Input, Button, Space, Table, Popconfirm, message } from "antd";
import { connect } from "react-redux";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import { userRoles } from "../../../constants/roles-types";
import Highlighter from "react-highlight-words";
import isExitIcon from "../../../static/isExit.png";
import lodash from "lodash";
import { checkIfUserHasRoles } from "../../../common/auth.common";

const mapDispatchToProps = (dispatch) => {
  return {};
};

class ConnectedEntryPointsList extends Component {
  formRef = React.createRef();

  state = {
    entryPoints: [],

    openSearchModal: false,
    searchText: null,
    searchedColumn: null,
  };

  componentDidMount = async () => {
    this.setState({ entryPoints: this.props.entryPoints });
  };

  handleOpenUpdateModal = (record) => {
    console.log("**** handleOpenUpdateModal ****", record);
    this.props.openUpdateModal(record);
  };

  deletePopupConfirm = async (entryPointId) => {
    await this.props.deleteEntrypoint(entryPointId);
    message.success(this.props.t("entrypoints-popupconfirm-message"));
  };

  handleCancel = () => {
    this.setState({ visible: false, modify: false, selectedId: null });
  };

  handleOpenEntryPoint = async (entryPointId) => {
    await this.props.handleOpenEntryPoint(entryPointId);
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t("search-button-label")}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            {this.props.t("reset-button-label")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const nestedValue = lodash.get(record, dataIndex);
      if (nestedValue !== undefined) {
        return nestedValue
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
      return false;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => {
      return lodash.isEqual(this.state.searchedColumn, dataIndex) ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      );
    },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const colums = [
      {
        title: this.props.t("entrypoints-controller-name"),
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps("name", this.props.t("name-label")),
      },
      {
        title: this.props.t("device-label"),
        dataIndex: "deviceId",
        key: "deviceId",
        sorter: (a, b) => a.deviceId.localeCompare(b.deviceId),
        sortDirections: ["ascend", "descend"],
        width: "20%",
        ...this.getColumnSearchProps("deviceId", this.props.t("device-label")),
      },
      {
        title: this.props.t("entrypoints-isopened-status-title"),
        dataIndex: "opened",
        key: "opened",
        width: "5%",
        render: (text, record) => (
          <Space
            size="middle"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              style={{
                width: "1.1vw",
                height: "1.1vw",
                borderRadius: "50%",
                backgroundColor: record.opened ? "green" : "gray",
              }}
            />
          </Space>
        ),
      },
    ];

    checkIfUserHasRoles(this.props.user, [
      userRoles.COMPANY_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.OFFICE_ADMIN,
    ]) &&
      colums.push({
        title: this.props.t("entrypoints-dooropener-button-label"),
        dataIndex: "open",
        width: "10%",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button
                type="primary"
                onClick={() => this.handleOpenEntryPoint(record.id)}
              >
                {this.props.t("entrypoints-dooropener-button-label")}
              </Button>
            </Space>
          );
        },
      });

    if (checkIfUserHasRoles(this.props.user, [userRoles.OFFICE_ADMIN])) {
      colums.push(
        {
          title: this.props.t("entrypoints-address-title"),
          dataIndex: "name",
          key: "address",
          sorter: (a, b) => a.address.name.localeCompare(b.address.name),
          sortDirections: ["ascend", "descend"],
          ...this.getColumnSearchProps(
            ["address", "name"],
            this.props.t("entrypoints-address-title")
          ),
          render: (text, record) => (
            <Space size="middle">
              {record?.address?.name}
              {record.address?.isExit && (
                <img alt="isExit" src={isExitIcon} width={20} />
              )}
            </Space>
          ),
        },
        {
          title: this.props.t("entrypoints-is-exit"),
          dataIndex: "isExit",
          key: "isExit",
          width: "5%",
          align: "center",
          render: (text, record) => (
            <Space size="middle">
              {record.isExit && (
                <img alt="isExit" src={isExitIcon} width={20} />
              )}
            </Space>
          ),
        },
        {
          title: this.props.t("entrypoints-update-title"),
          dataIndex: "update",
          width: "5%",
          render: (value, record) => (
            <>
              <Space size="middle">
                <Button
                  type="primary"
                  onClick={() => this.handleOpenUpdateModal(record)}
                >
                  {this.props.t("modify-button-label")}
                </Button>
              </Space>
            </>
          ),
        },
        {
          title: this.props.t("delete-button-label"),
          dataIndex: "delete",
          width: "5%",
          render: (value, record) => (
            <>
              <Space size="middle">
                <Popconfirm
                  title={this.props.t("entrypoints-popup-delete-question")}
                  onConfirm={() => this.deletePopupConfirm(record.id)}
                  okText={this.props.t("entrypoints-popup-button-ok-label")}
                  cancelText={this.props.t(
                    "entrypoints-popup-button-cancle-label"
                  )}
                  placement="topLeft"
                >
                  <Button
                    className="button"
                    type="primary"
                    htmlType="submit"
                    danger
                  >
                    <DeleteOutlined />
                    {this.props.t("delete-button-label")}
                  </Button>
                </Popconfirm>
              </Space>
            </>
          ),
        }
      );
    }

    return (
      <div className="entrypoints">
        <Table
          bordered={false}
          columns={colums}
          loading={this.props.loading}
          dataSource={this.props.entryPoints}
          pagination={
            this.state.entryPoints.length > 10
              ? {
                  position: ["bottomCenter"],
                  showSizeChanger: true,
                }
              : false
          }
          rowKey="id"
          onChange={this.props.handleTableChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
});

const EntryPointsList = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedEntryPointsList)
);
export default EntryPointsList;
