import React, { Component } from "react";
import Draggable from "react-draggable";
import { ROOM_TYPES_ARRAY } from "../../../constants/room-types";

class RoomObject extends Component {
  state = {
    svgWidth: this.props.svgWidth || 3000,
    svgHeight: this.props.svgHeight || 3000,
    svgBoundary: this.props.svgBoundary || 20,
    isMouseOver: false,
  };

  componentDidMount = async () => {};

  handleOpenEdit = (room) => {
    this.props.openUpdateModal(room);
  };

  handleFinishConnectSensor = (selectedRoomId) => {
    this.props.handleFinishConnectSensor(selectedRoomId);
  };

  handleMouseEnter = () => {
    this.setState({ isMouseOver: true });
  };

  handleMouseLeave = () => {
    this.setState({ isMouseOver: false });
  };

  render() {
    const room = this.props.room;
    const dragDelta = this.props.dragDelta;

    return (
      <>
        <Draggable
          key={room.id}
          position={{
            x: room.x + dragDelta.x,
            y: room.y + dragDelta.y,
          }}
          handle=".dragIconHandle"
          bounds={{
            left: this.state.svgBoundary,
            right: this.state.svgWidth - this.state.svgBoundary - room.width,
            top: this.state.svgBoundary,
            bottom: this.state.svgHeight - this.state.svgBoundary - room.height,
          }}
          onDrag={(e, { deltaX, deltaY }) =>
            this.props.handleRoomDrag(
              this.props.selectedFloorIndex,
              this.props.index,
              deltaX,
              deltaY
            )
          }
        >
          <g>
            <rect
              width={room.width}
              height={room.height}
              fill={ROOM_TYPES_ARRAY[room.type - 1].color}
              style={{
                cursor: "pointer",
                filter:
                  this.props.isConnectionInProgress && this.state.isMouseOver
                    ? "drop-shadow(0 0 5px blue)"
                    : "none",
              }}
              onClick={() => this.handleFinishConnectSensor(room.id)}
              onMouseEnter={() => this.handleMouseEnter()}
              onMouseLeave={() => this.handleMouseLeave()}
            />
            <text
              x={room.width / 2}
              y={room.height / 2}
              textAnchor="middle"
              alignmentBaseline="middle"
            >
              {room.name}
            </text>
            <path
              className="updateHandle"
              d="M27.5,14h-2.731c-0.228-1.003-0.624-1.94-1.156-2.785l1.933-1.933c0.195-0.195,0.195-0.512,0-0.707 l-2.121-2.121c-0.195-0.195-0.512-0.195-0.707,0l-1.933,1.933C19.94,7.855,19.003,7.459,18,7.231V4.5C18,4.224,17.776,4,17.5,4h-3 C14.224,4,14,4.224,14,4.5v2.731c-1.003,0.228-1.94,0.624-2.785,1.156L9.282,6.454c-0.195-0.195-0.512-0.195-0.707,0L6.454,8.575 c-0.195,0.195-0.195,0.512,0,0.707l1.933,1.933C7.855,12.06,7.459,12.997,7.231,14H4.5C4.224,14,4,14.224,4,14.5v3 C4,17.776,4.224,18,4.5,18h2.731c0.228,1.003,0.624,1.94,1.156,2.785l-1.933,1.933c-0.195,0.195-0.195,0.512,0,0.707l2.121,2.121 c0.195,0.195,0.512,0.195,0.707,0l1.933-1.933c0.845,0.532,1.782,0.928,2.785,1.156V27.5c0,0.276,0.224,0.5,0.5,0.5h3 c0.276,0,0.5-0.224,0.5-0.5v-2.731c1.003-0.228,1.94-0.624,2.785-1.156l1.933,1.933c0.195,0.195,0.512,0.195,0.707,0l2.121-2.121 c0.195-0.195,0.195-0.512,0-0.707l-1.933-1.933c0.532-0.845,0.928-1.782,1.156-2.785H27.5c0.276,0,0.5-0.224,0.5-0.5v-3 C28,14.224,27.776,14,27.5,14z M16,21c-2.761,0-5-2.239-5-5s2.239-5,5-5s5,2.239,5,5S18.761,21,16,21z"
              stroke="#000000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform={`translate(${0} ${0}) scale(0.75)`}
              style={{ cursor: "pointer" }}
              onClick={() => this.handleOpenEdit(room)}
            />
            <path
              className="dragIconHandle"
              d="M12 3V9M12 3L9 6M12 3L15 6M12 15V21M12 21L15 18M12 21L9 18M3 12H9M3 12L6 15M3 12L6 9M15 12H21M21 12L18 9M21 12L18 15"
              stroke="#000000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform={`translate(${room.width - 22} ${0}) scale(1)`}
              style={{ cursor: "move" }}
            />
            <path
              className="resizeIconHandle"
              d="M25.99 6.042l-0.004 9.735-3.732-3.733-4.454 4.455-2.665-2.665 4.454-4.454-3.384-3.383 9.785 0.045zM11.494 22.805l3.238 3.182-9.722 0.017 0.004-9.68 3.815 3.815 4.925-4.924 2.665 2.665-4.925 4.925z"
              transform={`translate(${room.width - 20} ${
                room.height - 20
              }) scale(0.75)`}
              style={{ cursor: "sw-resize" }}
              onMouseDown={(event) =>
                this.props.handleRoomResizeStart(event, this.props.index)
              }
            />
          </g>
        </Draggable>
      </>
    );
  }
}

export default RoomObject;
