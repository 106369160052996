import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import hu from "../../static/hu.png";
import de from "../../static/de.png";
import en from "../../static/en.png";
import i18n from "../../i18n";
import { getCompaniesByToken } from "../../actions/company";
import "./guest-register.scss";
import axios from "axios";

const mapDispatchToProps = (dispatch) => {
  return {
    getCompaniesByToken: (token) => dispatch(getCompaniesByToken(token)),
  };
};
class ConnectedCancelGuestParking extends Component {
  state = {
    message: "",
    code: null,
  };

  changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  componentDidMount = async () => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL + "/api";
    const queryParams = new URLSearchParams(this.props.location.search);
    this.setState({
      code: queryParams.get("code"),
    });
    await axios
      .get(
        "/2.0.0/parking/parking-reservations/cancel-with-code/" +
          queryParams.get("code")
      )
      .then((response) => {
        this.setState({
          message: "cancel-reservation-success-label",
        });
      })
      .catch((err) => {
        this.setState({
          message: "cancel-reservation-error-label",
        });
      });
  };

  render() {
    return (
      <Row>
        <Col span={24}>
          <div
            style={{
              padding: "20px",
              backgroundColor: "#00aea9",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "20%", maxWidth: "75px" }}>
              <img
                src={de}
                alt="de_flag"
                width={"100%"}
                onClick={() => this.changeLanguage("de")}
                style={{
                  opacity: i18n.language === "de" ? "1" : "0.7",
                  paddingBottom: "10px",
                  borderBottom: i18n.language === "de" ? "3px solid white" : "",
                }}
              />
            </div>
            <div style={{ width: "20%", maxWidth: "75px" }}>
              <img
                src={hu}
                alt="hu_flag"
                width={"100%"}
                onClick={() => this.changeLanguage("hu")}
                style={{
                  opacity: i18n.language === "hu" ? "1" : "0.7",
                  paddingBottom: "10px",
                  borderBottom: i18n.language === "hu" ? "3px solid white" : "",
                }}
              />
            </div>
            <div style={{ width: "20%", maxWidth: "75px" }}>
              <img
                src={en}
                alt="en_flag"
                width={"100%"}
                onClick={() => this.changeLanguage("en")}
                style={{
                  opacity: i18n.language === "en" ? "1" : "0.7",
                  paddingBottom: "10px",
                  borderBottom: i18n.language === "en" ? "3px solid white" : "",
                }}
              />
            </div>
          </div>
          <div>
            <div style={{ backgroundColor: "#00aea9" }}>
              <h1
                style={{
                  textAlign: "center",
                  color: "#ffffff",
                  fontSize: "2.2rem",
                }}
              >
                {this.props.t(`${this.state.message}`)}
              </h1>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({});
const CancelGuestParking = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedCancelGuestParking)
);
export default CancelGuestParking;
