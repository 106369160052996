import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Transfer,
  // TimePicker,
  InputNumber,
  Switch,
} from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addUsersToAddress,
  deleteCompanyAddress,
  getAddressById,
  getAllAddresses,
  getOneCompanyById,
  modifyCompanyAddress,
  saveCompanyAddress,
  saveCompanyTimezone,
  saveCompanyName,
  getCompanyLimitById,
} from "../../actions/company";
import { withTranslation } from "react-i18next";
import { timezones } from "../../constants/timezones";
import {
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { getAllUsers } from "../../actions/users";

const { Panel } = Collapse;

const mapDispatchToProps = (dispatch) => {
  return {
    getAllAddresses: (companyId) => dispatch(getAllAddresses(companyId)),
    saveCompanyAddress: (params) => dispatch(saveCompanyAddress(params)),
    modifyCompanyAddress: (params) => dispatch(modifyCompanyAddress(params)),
    deleteCompanyAddress: (id) => dispatch(deleteCompanyAddress(id)),
    saveCompanyTimezone: (params) => dispatch(saveCompanyTimezone(params)),
    getAddressById: (addressId) => dispatch(getAddressById(addressId)),
    getAllUsers: (groupIds, companyId) =>
      dispatch(getAllUsers(groupIds, companyId)),
    addUsersToAddress: (params) => dispatch(addUsersToAddress(params)),
    getOneCompanyById: (companyId) => dispatch(getOneCompanyById(companyId)),
    saveCompanyName: (params) => dispatch(saveCompanyName(params)),
    getCompanyLimitById: (id) => dispatch(getCompanyLimitById(id)),
  };
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};
class ConnectedCompanyAddresses extends Component {
  formRef = React.createRef();

  state = {
    country: "",
    zip: "",
    city: "",
    address: "",
    modify: false,
    selectedId: null,
    timezone: "",
    name: "",
    isPublic: false,
    isOutsideGate: false,
    isExit: false,
    isAbleToInvite: false,
    isParkingAvailable: false,
    userToAddressVisible: false,
    maxStartTolerance: 15,
    maxEndTolerance: 0,
    targetKeys: [],
    selectedKeys: [],
    companyName: "",
    baseTime: null,
    addressBaseTime: null,
    anonymizationTime: null,
    workingHours: 8,
    companyAnonymizationLimit: 1,
    worklogModuleOn: false,
    worklogModuleDisabled: false,
    canUserEditWorklog: true,
  };

  componentDidMount = async () => {
    if (this.props.companyId) {
      await this.props.getOneCompanyById(this.props.companyId);
      await this.props.getAllAddresses(this.props.companyId);
      await this.props.getCompanyLimitById(this.props.companyId);
    } else {
      await this.props.getOneCompanyById(this.props.user.companyId);
      await this.props.getAllAddresses(this.props.user.companyId);
      await this.props.getCompanyLimitById(this.props.user.companyId);
      //
    }
    this.setState({
      timezone: this.props.company[0].timezone,
      companyName: this.props.company[0].name,
      anonymizationTime: this.props.company[0].anonymizationTime,
      workingHours: this.props.company[0].workingHours,
      companyAnonymizationLimit: this.props.companyAnonymizationLimit,
      worklogModuleOn: this.props.company[0].worklogModuleOn,
      canUserEditWorklog: this.props.company[0].canUserEditWorklog
      // worklogModuleDisabled: !this.props.company[0].office.worklogModuleOn
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChangeCheckbox = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  openUserToAddress = async (record) => {
    if (this.props.companyId) {
      await this.props.getAllUsers([5], this.props.companyId);
    } else {
      await this.props.getAllUsers([5], this.props.user.companyId);
    }
    await this.props.getAddressById(record.id);
    if (this.props.status) {
      const target = this.props.address.users.map((user) => user.id);
      this.setState({ targetKeys: target });
    }
    this.setState({ userToAddressVisible: true });
  };

  closeUserAddToAddress = () => {
    this.setState({
      userToAddressVisible: false,
      targetKeys: [],
      selectedKeys: [],
    });
  };

  handleSaveSelectedUsers = async () => {
    const params = {
      userIds: this.state.targetKeys,
      addressId: this.props.address.id,
    };
    await this.props.addUsersToAddress(params);
    this.closeUserAddToAddress();
  };

  submitForm = async () => {
    const toSave = {
      country: this.state.country,
      zip: this.state.zip,
      city: this.state.city,
      address: this.state.address,
      companyId: this.props.companyId
        ? this.props.companyId
        : this.props.user.companyId,
      officeId: this.props.user.officeId,
      name: this.state.name,
      isPublic: this.state.isPublic,
      isOutsideGate: this.state.isOutsideGate,
      isExit: this.state.isExit,
      isAbleToInvite: this.state.isAbleToInvite,
      isParkingAvailable: this.state.isParkingAvailable,
      maxStartTolerance: this.state.maxStartTolerance
        ? parseInt(this.state.maxStartTolerance)
        : undefined,
      maxEndTolerance: this.state.maxEndTolerance
        ? parseInt(this.state.maxEndTolerance)
        : undefined,
      anonymizationTime:
        this.state.anonymizationTime === null
          ? this.props.company[0].anonymizationTime
          : this.state.anonymizationTime,
      workingHours: this.state.workingHours,
      worklogModuleOn:
        this.state.worklogModuleOn === null
          ? this.props.company[0].worklogModuleOn
          : this.state.worklogModuleOn,
      canUserEditWorklog: this.state.canUserEditWorklog
    };
    if (this.state.modify === true) {
      toSave.id = this.state.selectedId;
      await this.props.modifyCompanyAddress(toSave);
      if (this.props.saveStatus) {
        message.success(this.props.t("save-success"), 5);
        this.handleCancelModify();
        if (this.props.companyId) {
          await this.props.getAllAddresses(this.props.companyId);
        } else if (this.props.user.companyId) {
          await this.props.getAllAddresses(this.props.user.companyId);
        }
      } else {
        message.error(this.props.t("save-fail"), 5);
      }
    } else {
      await this.props.saveCompanyAddress(toSave);
      if (this.props.saveStatus) {
        message.success(this.props.t("save-success"), 5);
        this.handleCancelModify();
        await this.props.getAllAddresses(
          this.props.company
            ? this.props.company[0].id
            : this.props.user.companyId
        );
      } else {
        message.error(this.props.t("save-fail"), 5);
      }
    }
  };

  handleDelete = async (id) => {
    await this.props.deleteCompanyAddress(id);
    if (this.props.saveStatus) {
      await this.props.getAllAddresses(
        this.props.user.companyId
          ? this.props.user.companyId
          : this.props.companyId
      );
    }
  };

  handleModify = (data) => {
    this.setState({
      modify: true,
      selectedId: data.id,
      country: data.country,
      zip: data.zip,
      city: data.city,
      address: data.address,
      name: data.name,
      isPublic: data.isPublic,
      isOutsideGate: data.isOutsideGate,
      isExit: data.isExit,
      isAbleToInvite: data.isAbleToInvite,
      isParkingAvailable: data.isParkingAvailable,
      maxStartTolerance: data.beforeStartToleranceMins,
      maxEndTolerance: data.beforeEndToleranceMins,
      baseTime: data.baseTime,
      anonymizationTime: data.anonymizationTime,
      workingHours: data.workingHours,
      //worklogModuleOn: data.worklogModuleOn,
    });
    this.formRef.current.setFieldsValue({
      country: data.country,
      zip: data.zip,
      city: data.city,
      address: data.address,
      name: data.name,
      isPublic: data.isPublic,
      isOutsideGate: data.isOutsideGate,
      isExit: data.isExit,
      isAbleToInvite: data.isAbleToInvite,
      isParkingAvailable: data.isParkingAvailable,
      maxStartTolerance: data.beforeStartToleranceMins,
      maxEndTolerance: data.beforeEndToleranceMins,
      baseTime: data.baseTime,
      anonymizationTime: data.anonymizationTime,
      workingHours: data.workingHours,
      //worklogModuleOn: data.worklogModuleOn,
    });
  };

  handleCancelModify = () => {
    this.setState({
      name: "",
      country: "",
      zip: "",
      city: "",
      address: "",
      selectedId: null,
      modify: false,
      isPublic: false,
      isOutsideGate: false,
      isExit: false,
      isAbleToInvite: false,
      isParkingAvailable: false,
      maxStartTolerance: 15,
      maxEndTolerance: 0,
      addressBaseTime: null,
    });
    this.formRef.current.setFieldsValue({
      name: "",
      country: "",
      zip: "",
      city: "",
      address: "",
      isPublic: false,
      isOutsideGate: false,
      isExit: false,
      isAbleToInvite: false,
      isParkingAvailable: false,
      maxStartTolerance: 15,
      maxEndTolerance: 0,
      addressBaseTime: null,
    });
  };

  handleChangeTimezone = async (event) => {
    await this.props.saveCompanyTimezone({
      companyId: this.props.companyId
        ? this.props.companyId
        : this.props.user.companyId,
      timezone: event,
    });
    if (this.props.saveStatus) {
      this.setState({ timezone: event });
      await this.props.getOneCompanyById(
        this.props.companyId ? this.props.companyId : this.props.user.companyId
      );
    }
  };

  handleChangeTarget = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  handleSaveCompanyName = async () => {
    const toSave = {
      name: this.state.companyName,
      baseTime: this.state.baseTime,
      timezone: this.state.timezone,
      anonymizationTime: this.state.anonymizationTime,
      workingHours: this.state.workingHours,
      worklogModuleOn: this.state.worklogModuleOn,
      canUserEditWorklog: this.state.canUserEditWorklog,
      companyId: this.props.companyId
        ? this.props.companyId
        : this.props.user.companyId,
      officeId:
        this.props.user.companyId === null ? this.props.user.officeId : null,
    };
    await this.props.saveCompanyName(toSave);
    if (this.props.saveStatus) {
      if (this.props.companyId) {
        await this.props.getAllAddresses(this.props.companyId);
      } else {
        await this.props.getAllAddresses(this.props.user.companyId);
      }
      message.success(this.props.t("save-success"), 5);
    } else {
      message.success(this.props.t("save-error"), 5);
    }
  };

  onOk = (value) => {
    this.setState({
      baseTime: value,
    });
  };

  onAnonymizationTimeChange = (value) => {
    this.setState({
      anonymizationTime: value,
    });
  };

  onWorkingHoursChange = (value) => {
    this.setState({
      workingHours: value,
    });
  };

  handleChangeWorklogModule = (event) => {
    this.setState({ worklogModuleOn: event });
  };

  handleChangeCanUserEditWorklog = (event) => {
    this.setState({ canUserEditWorklog: event });
  };

  render() {
    //api key AIzaSyCw2J3fhxGtamO5xi-tWa_SrgUGlxgMx0Y
    const columns = [
      {
        title: this.props.t("companyadmin-address-name-label"),
        key: "name",
        dataIndex: "name",
      },
      {
        title: this.props.t("companyadmin-address-table-header-country"),
        key: "country",
        dataIndex: "country",
      },
      {
        title: this.props.t("companyadmin-address-table-header-zip"),
        key: "zip",
        dataIndex: "zip",
      },
      {
        title: this.props.t("companyadmin-address-table-header-city"),
        key: "city",
        dataIndex: "city",
      },
      {
        title: this.props.t("companyadmin-address-table-header-address"),
        key: "address",
        dataIndex: "address",
      },
      {
        title: <UserAddOutlined />,
        key: "addUser",
        render: (text, data) => {
          return (
            <Space size="middle">
              <Button
                onClick={() => this.openUserToAddress(data)}
                disabled={data.isPublic}
              >
                <UserAddOutlined />
              </Button>
            </Space>
          );
        },
      },

      {
        title: <EditOutlined />,
        key: "modify",
        render: (text, data) => {
          return (
            <Space size="middle">
              {data.officeId !== null ? (
                <Button disabled={true}>
                  <EditOutlined />
                </Button>
              ) : (
                <Button onClick={() => this.handleModify(data)}>
                  <EditOutlined />
                </Button>
              )}
            </Space>
          );
        },
      },
      {
        title: <DeleteOutlined />,
        key: "delete",
        render: (text, data) => {
          return (
            <Space size="middle">
              <Button
                danger
                onClick={() => this.handleDelete(data.id)}
                disabled={this.props.addresses.length <= 1}
                type="primary"
              >
                <DeleteOutlined />
              </Button>
            </Space>
          );
        },
      },
    ];
    return (
      <div style={{ overflowY: "auto" }}>
        <Collapse defaultActiveKey={["1"]}>
          <Panel key="1" header={this.props.t("company-admin-basicdata-panel")}>
            <Row>
              <Col span={8} style={{ padding: "1em", display: "flex" }}>
                <label style={{ width: "10em", paddingTop: "0.5em" }}>
                  <strong>
                    {this.props.t("companyadmin-languages-companyname-label")}
                  </strong>
                  &nbsp;
                </label>
                <Input
                  value={this.state.companyName}
                  style={{ height: "2.5em" }}
                  name="companyName"
                  onChange={this.handleChange}
                />
              </Col>
              <Col span={8} style={{ padding: "1em", display: "flex" }}>
                <label style={{ width: "5em", paddingTop: "0.5em" }}>
                  <strong>
                    {this.props.t("companyadmin-address-timezone-label")}
                  </strong>
                  &nbsp;
                </label>
                <Select
                  onChange={this.handleChangeTimezone}
                  value={this.state.timezone}
                  showSearch
                  style={{ width: "16em" }}
                >
                  {timezones.map((timezone) => {
                    return (
                      <Select.Option
                        key={timezone.Index}
                        value={timezone.momentTimezone}
                      >
                        {timezone.Time}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
              <Col span={8} style={{ padding: "1em", display: "flex" }}></Col>
              <Col span={10} style={{ padding: "1em", display: "flex" }}>
                <label style={{ width: "10em", paddingTop: "0.5em" }}>
                  <strong>
                    {this.props.t("companyadmin-anonymizationTime")}
                  </strong>
                  &nbsp;
                </label>
                <InputNumber
                  min={this.state.companyAnonymizationLimit}
                  value={this.state.anonymizationTime}
                  onChange={this.onAnonymizationTimeChange}
                />
              </Col>
              <Col span={10} style={{ padding: "1em", display: "flex" }}>
                <label style={{ width: "10em", paddingTop: "0.5em" }}>
                  <strong>
                    {this.props.t("companyadmin-working-hours")}
                  </strong>
                  &nbsp;
                </label>
                <InputNumber
                  max={24}
                  value={this.state.workingHours}
                  onChange={this.onWorkingHoursChange}
                />
              </Col>
              <Col span={10} style={{ padding: "1em", display: "flex" }}>
                <label style={{ width: "10em", paddingTop: "0.5em" }}>
                  <strong>
                    {this.props.t("companyadmin-worklogmoduleSwitch-label")}
                  </strong>
                  &nbsp;
                </label>
                <Switch
                  checked={this.state.worklogModuleOn}
                  onChange={this.handleChangeWorklogModule}
                  disabled={this.state.worklogModuleDisabled}
                />
              </Col>
              <Col span={10} style={{ padding: "1em", display: "flex" }}>
                <label style={{ width: "10em", paddingTop: "0.5em" }}>
                  <strong>
                    {this.props.t("companyadmin-can-user-edit-worklog-Switch-label")}
                  </strong>
                  &nbsp;
                </label>
                <Switch
                  checked={this.state.canUserEditWorklog}
                  onChange={this.handleChangeCanUserEditWorklog}
                  disabled={!this.state.worklogModuleOn}
                />
              </Col>
              <Col span={8} style={{ padding: "1em" }}>
                <Button type="primary" onClick={this.handleSaveCompanyName}>
                  <SaveOutlined /> {this.props.t("save-button-label")}
                </Button>
              </Col>
            </Row>
          </Panel>
          {
            <Panel key="2" header={this.props.t("company-admin-sites-panel")}>
              {!this.props.company[0]?.office && (
                <Row>
                  <Col span={24} style={{ padding: "1em" }}>
                    <Form
                      ref={this.formRef}
                      {...formItemLayout}
                      name="company"
                      onFinish={this.submitForm}
                      scrollToFirstError
                      layout="vertical"
                      wrapperCol={{
                        style: { padding: "0", margin: "0 0 0 0" },
                      }}
                    >
                      <Row>
                        <Col span={12} style={{ padding: "0.5em" }}>
                          <Form.Item
                            label={
                              <strong>
                                {this.props.t(
                                  "companyadmin-address-name-label"
                                )}
                                :
                              </strong>
                            }
                            name="name"
                            initialValue={this.state.name}
                            rules={[
                              {
                                required: true,
                                message: this.props.t(
                                  "companyadmin-address-name-text"
                                ),
                              },
                            ]}
                          >
                            <Input
                              name="name"
                              onChange={this.handleChange}
                              value={this.state.name}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12} style={{ padding: "0.5em" }}>
                          <Form.Item
                            label={
                              <strong>
                                {this.props.t(
                                  "companyadmin-address-country-label"
                                )}
                              </strong>
                            }
                            name="country"
                            initialValue={this.state.country}
                            rules={[
                              {
                                required: true,
                                message: this.props.t(
                                  "companyadmin-address-country-text"
                                ),
                              },
                            ]}
                          >
                            <Input
                              name="country"
                              onChange={this.handleChange}
                              value={this.state.country}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} style={{ padding: "0.5em" }}>
                          <Form.Item
                            label={
                              <strong>
                                {this.props.t("companyadmin-address-zip-label")}
                              </strong>
                            }
                            name="zip"
                            initialValue={this.state.zip}
                            rules={[
                              {
                                required: true,
                                message: this.props.t(
                                  "companyadmin-address-zip-text"
                                ),
                              },
                            ]}
                          >
                            <Input
                              name="zip"
                              onChange={this.handleChange}
                              value={this.state.zip}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12} style={{ padding: "0.5em" }}>
                          <Form.Item
                            label={
                              <strong>
                                {this.props.t(
                                  "companyadmin-address-city-label"
                                )}
                              </strong>
                            }
                            name="city"
                            initialValue={this.state.city}
                            rules={[
                              {
                                required: true,
                                message: this.props.t(
                                  "companyadmin-address-city-text"
                                ),
                              },
                            ]}
                          >
                            <Input
                              name="city"
                              onChange={this.handleChange}
                              value={this.state.city}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} style={{ padding: "0.5em" }}>
                          <Form.Item
                            label={
                              <strong>
                                {this.props.t(
                                  "companyadmin-address-address-label"
                                )}
                              </strong>
                            }
                            name="address"
                            initialValue={this.state.address}
                            rules={[
                              {
                                required: true,
                                message: this.props.t(
                                  "companyadmin-address-address-text"
                                ),
                              },
                            ]}
                          >
                            <Input
                              name="address"
                              onChange={this.handleChange}
                              value={this.state.address}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6} style={{ padding: "0.5em" }}>
                          <Form.Item
                            label={
                              <strong>
                                {this.props.t(
                                  "companyadmin-address-starttolerance-label"
                                )}
                              </strong>
                            }
                            name="maxStartTolerance"
                            initialValue={this.state.maxStartTolerance}
                            rules={[
                              {
                                required: true,
                                message: this.props.t(
                                  "companyadmin-address-starttolerance-text"
                                ),
                              },
                            ]}
                          >
                            <Input
                              name="maxStartTolerance"
                              onChange={this.handleChange}
                              value={this.state.maxStartTolerance}
                              type="number"
                              step={1}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6} style={{ padding: "0.5em" }}>
                          <Form.Item
                            label={
                              <strong>
                                {this.props.t(
                                  "companyadmin-address-endtolerance-label"
                                )}
                              </strong>
                            }
                            name="maxEndTolerance"
                            initialValue={this.state.maxEndTolerance}
                            rules={[
                              {
                                required: true,
                                message: this.props.t(
                                  "companyadmin-address-endtolerance-text"
                                ),
                              },
                            ]}
                          >
                            <Input
                              name="maxEndTolerance"
                              onChange={this.handleChange}
                              value={this.state.maxEndTolerance}
                              type="number"
                              step={1}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6} style={{ padding: "0.5em" }}>
                          <Form.Item
                            name="isPublic"
                            initialValue={this.state.isPublic}
                          >
                            <Checkbox
                              id="isPublic"
                              name="isPublic"
                              onChange={this.handleChangeCheckbox}
                              checked={this.state.isPublic}
                            />
                            <label
                              htmlFor="isPublic"
                              style={{ marginLeft: "1em", color: "#262626" }}
                            >
                              <strong>
                                {this.props.t(
                                  "companyadmin-address-public-label"
                                )}
                              </strong>
                            </label>
                          </Form.Item>
                        </Col>
                        <Col span={6} style={{ padding: "0.5em" }}>
                          <Form.Item
                            name="isAbleToInvite"
                            initialValue={this.state.isAbleToInvite}
                          >
                            <Checkbox
                              id="isAbleToInvite"
                              name="isAbleToInvite"
                              onChange={this.handleChangeCheckbox}
                              checked={this.state.isAbleToInvite}
                            />
                            <label
                              htmlFor="isAbleToInvite"
                              style={{ marginLeft: "1em", color: "#262626" }}
                            >
                              <strong>
                                {this.props.t(
                                  "companyadmin-address-abletoinvite-label"
                                )}
                              </strong>
                            </label>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6} style={{ padding: "0.5em" }}>
                          <Form.Item
                            name="isOutsideGate"
                            initialValue={this.state.isOutsideGate}
                          >
                            <Checkbox
                              id="isOutsideGate"
                              name="isOutsideGate"
                              onChange={this.handleChangeCheckbox}
                              checked={this.state.isOutsideGate}
                            />
                            <label
                              style={{ marginLeft: "1em", color: "#262626" }}
                              htmlFor="isOutsideGate"
                            >
                              <strong>
                                {this.props.t(
                                  "companyadmin-address-outsidegate-label"
                                )}
                              </strong>
                            </label>
                          </Form.Item>
                        </Col>
                        <Col span={6} style={{ padding: "0.5em" }}>
                          <Form.Item
                            name="isExit"
                            initialValue={this.state.isExit}
                          >
                            <Checkbox
                              id="isExit"
                              name="isExit"
                              onChange={this.handleChangeCheckbox}
                              checked={this.state.isExit}
                              disabled={!this.state.isOutsideGate}
                            />
                            <label
                              style={{ marginLeft: "1em", color: "#262626" }}
                              htmlFor="isExit"
                            >
                              <strong>
                                {this.props.t(
                                  "companyadmin-address-exit-label"
                                )}
                              </strong>
                            </label>
                          </Form.Item>
                        </Col>
                        <Col span={6} style={{ padding: "0.5em" }}>
                          <Form.Item
                            name="isParkingAvailable"
                            initialValue={this.state.isParkingAvailable}
                          >
                            <Checkbox
                              id="isParkingAvailable"
                              name="isParkingAvailable"
                              onChange={this.handleChangeCheckbox}
                              checked={this.state.isParkingAvailable}
                            />
                            <label
                              style={{ marginLeft: "1em", color: "#262626" }}
                              htmlFor="isParkingAvailable"
                            >
                              <strong>
                                {this.props.t(
                                  "companyadmin-address-parkingavailable-label"
                                )}
                              </strong>
                            </label>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item style={{ textAlign: "right" }}>
                        {this.state.modify && (
                          <Button
                            style={{ marginRight: "10px" }}
                            onClick={this.handleCancelModify}
                          >
                            {this.props.t("cancel-button-label")}
                          </Button>
                        )}
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={this.props.company[0]?.officeId}
                        >
                          <SaveOutlined /> {this.props.t("save-button-label")}
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={24} style={{ padding: "1em" }}>
                  <Table
                    columns={columns}
                    dataSource={this.props.addresses}
                    rowKey="id"
                    locale={{ emptyText: "Nincs adat" }}
                    pagination={
                      this.props.addresses.length > 10
                        ? {
                          position: ["bottomCenter"],
                          showSizeChanger: true,
                        }
                        : false
                    }
                    scroll={{ x: 500 }}
                  />
                </Col>
              </Row>
            </Panel>
          }
        </Collapse>
        <Modal
          maskClosable={false}
          closable={true}
          footer={[
            <Button onClick={this.closeUserAddToAddress}>
              {this.props.t("cancel-button-label")}
            </Button>,
            <Button onClick={this.handleSaveSelectedUsers} type="primary">
              <SaveOutlined /> {this.props.t("save-button-label")}
            </Button>,
          ]}
          visible={this.state.userToAddressVisible}
          centered
          bodyStyle={{
            textAlign: "center",
            margin: "0 auto",
          }}
          title={
            <h3 style={{ padding: 0, margin: 0 }}>
              <strong>{this.props.t("add-user-to-address-title")}</strong>
            </h3>
          }
          onCancel={this.closeUserAddToAddress}
          destroyOnClose
          width={900}
        >
          <div>
            <Transfer
              style={{ textAlign: "left" }}
              dataSource={this.props.users}
              render={(item) =>
                `${item.lastName} ${item.firstName} (${item.email})`
              }
              targetKeys={this.state.targetKeys}
              selectedKeys={this.state.selectedKeys}
              onChange={this.handleChangeTarget}
              onSelectChange={this.handleSelectChange}
              rowKey={(record) => record.id}
              selectAllLabels={[<div></div>, <div></div>]}
              showSearch
              locale={{
                notFoundContent: this.props.t("empty-text"),
                selectAll: this.props.t("select-all-label"),
                selectInvert: this.props.t("select-invert-label"),
                searchPlaceholder: this.props.t("search-button-label"),
              }}
              listStyle={{
                width: 400,
                height: 300,
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  company: state.companyReducer.company,
  status: state.companyReducer.status,
  addresses: state.companyReducer.addresses,
  address: state.companyReducer.address,
  saveStatus: state.companyReducer.saveStatus,
  users: state.usersReducer.users,
  officeStatus: state.officeReducer.status,
  office: state.officeReducer.office,
  companyAnonymizationLimit: state.companyReducer.companyAnonymizationLimit,
});
const CompanyAddresses = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedCompanyAddresses)
);
export default CompanyAddresses;
