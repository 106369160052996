import React, { Component } from "react";
import Draggable from "react-draggable";
import ConnectDoorIcon from "./connectDoorIcon";

class DoorObject extends Component {
  state = {
    svgWidth: this.props.svgWidth || 3000,
    svgHeight: this.props.svgHeight || 3000,
    svgBoundary: this.props.svgBoundary || 20,
    doorColor: "grey",
  };

  componentDidMount = async () => {};

  handleOpenEdit = (door) => {
    this.props.openUpdateModal(door);
  };

  handleStartConnectSensor = (sensorId) => {
    this.props.handleStartConnectSensor(sensorId);
  };

  render() {
    const door = this.props.door;
    const dragDelta = this.props.dragDelta;
    return (
      <>
        <Draggable
          key={door.id}
          position={{
            x: door.x + dragDelta.x,
            y: door.y + dragDelta.y,
          }}
          bounds={{
            left: this.state.svgBoundary,
            right: this.state.svgWidth - this.state.svgBoundary - 40,
            top: this.state.svgBoundary,
            bottom: this.state.svgHeight - this.state.svgBoundary - 40,
          }}
          onDrag={(e, { deltaX, deltaY }) =>
            this.props.handleDoorDrag(
              this.props.selectedFloorIndex,
              this.props.index,
              deltaX,
              deltaY
            )
          }
        >
          <g>
            {/* Door shape */}
            <path
              d={this.props.doorTypes[door.type - 1]?.dValue}
              fill={this.state.doorColor}
              style={{
                cursor: "move",
                transform: `scale(${
                  this.props.doorTypes[door.type - 1]?.dScale
                })`,
              }}
            >
              <title>{door.name}</title>
            </path>
            <path
              className="updateHandle"
              d="M27.5,14h-2.731c-0.228-1.003-0.624-1.94-1.156-2.785l1.933-1.933c0.195-0.195,0.195-0.512,0-0.707 l-2.121-2.121c-0.195-0.195-0.512-0.195-0.707,0l-1.933,1.933C19.94,7.855,19.003,7.459,18,7.231V4.5C18,4.224,17.776,4,17.5,4h-3 C14.224,4,14,4.224,14,4.5v2.731c-1.003,0.228-1.94,0.624-2.785,1.156L9.282,6.454c-0.195-0.195-0.512-0.195-0.707,0L6.454,8.575 c-0.195,0.195-0.195,0.512,0,0.707l1.933,1.933C7.855,12.06,7.459,12.997,7.231,14H4.5C4.224,14,4,14.224,4,14.5v3 C4,17.776,4.224,18,4.5,18h2.731c0.228,1.003,0.624,1.94,1.156,2.785l-1.933,1.933c-0.195,0.195-0.195,0.512,0,0.707l2.121,2.121 c0.195,0.195,0.512,0.195,0.707,0l1.933-1.933c0.845,0.532,1.782,0.928,2.785,1.156V27.5c0,0.276,0.224,0.5,0.5,0.5h3 c0.276,0,0.5-0.224,0.5-0.5v-2.731c1.003-0.228,1.94-0.624,2.785-1.156l1.933,1.933c0.195,0.195,0.512,0.195,0.707,0l2.121-2.121 c0.195-0.195,0.195-0.512,0-0.707l-1.933-1.933c0.532-0.845,0.928-1.782,1.156-2.785H27.5c0.276,0,0.5-0.224,0.5-0.5v-3 C28,14.224,27.776,14,27.5,14z M16,21c-2.761,0-5-2.239-5-5s2.239-5,5-5s5,2.239,5,5S18.761,21,16,21z"
              stroke="#000000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform={`translate(${0} ${0}) scale(0.75)`}
              style={{ cursor: "pointer" }}
              onClick={() => this.handleOpenEdit(door)}
            />

            <ConnectDoorIcon
              sensor={door.sensors.find((sensor) => sensor.isSideA === true)}
              selectedSensorIdForConnect={this.props.selectedSensorIdForConnect}
              handleStartConnectSensor={this.handleStartConnectSensor}
            />
            <ConnectDoorIcon
              sensor={door.sensors.find((sensor) => sensor.isSideA === false)}
              selectedSensorIdForConnect={this.props.selectedSensorIdForConnect}
              handleStartConnectSensor={this.handleStartConnectSensor}
            />
          </g>
        </Draggable>
      </>
    );
  }
}

export default DoorObject;
