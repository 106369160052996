import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Space,
  Button,
  Table,
  message,
  Upload,
  Popconfirm,
  Menu,
  Input,
  Dropdown,
  DatePicker,
  ConfigProvider,
  Form,
  Select,
  Checkbox
} from "antd";
import {
  getAllUsers,
  getAllGroups,
  getAllUsersOffice,
} from "../../actions/users";
import {
  getAllCardsByCompany,
  importCard,
  activateCard,
  banCard,
  deleteCard,
  addImportErrors,
  emptyImportErrors,
  modifyActivationTime,
  importCardsOffice,
  getAllCardsByOffice,
  createCard,
} from "../../actions/proximitycard";
import {
  PlusOutlined,
  FilterOutlined,
  CheckOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { withTranslation } from "react-i18next";
import WizardDownloadProxyTemplate from "../Wizard/wizzardDownloadProxyTemplate";
import moment from "moment";
import "jspdf-autotable";
import XLSX from "xlsx";
import WizardImportProxy from "../Wizard/wizzardImportProxy";
import localehu from "antd/es/locale/hu_HU";
import axios from "axios";
import {
  getAllAddresses,
  getAllCompaniesByOfficeId,
} from "../../actions/company";
import { userRoles } from "../../constants/roles-types";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUsers: (groupId, companyId) =>
      dispatch(getAllUsers(groupId, companyId)),
    getAllGroups: () => dispatch(getAllGroups()),
    emptyImportErrors: () => dispatch(emptyImportErrors()),
    addImportErrors: (errors) => dispatch(addImportErrors(errors)),
    getAllCardsByCompany: (companyId) =>
      dispatch(getAllCardsByCompany(companyId)),
    getAllCardsByOffice: (officeId, companyId) =>
      dispatch(getAllCardsByOffice(officeId, companyId)),
    importCard: (params) => dispatch(importCard(params)),
    activateCard: (cardId) => dispatch(activateCard(cardId)),
    banCard: (cardId) => dispatch(banCard(cardId)),
    deleteCard: (cardId) => dispatch(deleteCard(cardId)),
    modifyActivationTime: (params) => dispatch(modifyActivationTime(params)),
    getAllAddresses: (companyId, officeId) =>
      dispatch(getAllAddresses(companyId, officeId)),
    importCardsOffice: (companyId, params) =>
      dispatch(importCardsOffice(companyId, params)),
    getAllCompaniesByOfficeId: (officeId) =>
      dispatch(getAllCompaniesByOfficeId(officeId)),
    getAllUsersOffice: (companyId, activeOnly) =>
      dispatch(getAllUsersOffice(companyId, activeOnly)),
    createCard: (params) => dispatch(createCard(params)),
  };
};
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

class ProximityCard extends Component {
  state = {
    email: "",
    rfidString: "",
    searchedColumn: "activationTime",
    searchText: new Date().toLocaleDateString(),
    active: true,
    loaded: false,
    activated: false,
    modifyVisible: false,
    newWorkerVisible: false,
    excelImportVisible: false,
    importProxyVisible: false,
    downloadTemplateVisible: false,
    activationTime: moment(),
    file: [],
    users: [],
    cards: [],
    badEmail: [],
    newCards: [],
    emptyRFID: [],
    emptyEmail: [],
    duplicatedRFID: [],
    filteredInfo: {},
    selectedId: null,
    companies: [],
    selectedFilterCompanyId: null,
    selectedCompanyId: null,
    addNewCardVisible: false,
    selectedUserId: null,
  };

  formRef = React.createRef();
  componentDidMount = async () => {
    await this.props.getAllGroups();
    await this.getCards();
    await this.getUsers();
    if (this.props.user.companyId && this.props.companyId) {
      await this.props.getAllAddresses(
        this.props.companyId || this.props.user.companyId
      );
    } else {
      await this.props.getAllAddresses(undefined, this.props.user.officeId);
      await this.props.getAllCompaniesByOfficeId(this.props.user.officeId);
    }
    this.setState({ loaded: true });
  };

  getUsers = async () => {
    this.setState({ loaded: false });
    if (this.props.user.officeId) {
      await this.props.getAllUsersOffice(null);
    } else {
      await this.props.getAllUsers([2, 3, 4, 5], this.props.user.companyId);
    }
    if (this.props.usersStatus) {
      this.setState({ users: this.props.users });
    }
    this.setState({ loaded: true });
  };

  getCards = async () => {
    this.setState({ loaded: false });
    if (this.props.user.officeId) {
      await this.props.getAllCardsByOffice(this.props.user.officeId, null);
    } else {
      await this.props.getAllCardsByCompany(this.props.user.companyId);
    }
    if (this.props.cardSaveStatus) {
      this.setState({ cards: this.props.cards });
    }
    this.setState({ loaded: true });
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  openImportCards = () => {
    this.props.emptyImportErrors();
    this.props.addImportErrors({
      duplicatedInSystem: [],
      emptyRfidString: [],
      emptyEmail: [],
      badEmail: [],
    });
    this.setState({
      downloadTemplateVisible: true,
      excelImportVisible: false,
      importProxyVisible: false,
    });
  };

  openAddNewCardModal = () => {
    this.setState({
      addNewCardVisible: true,
    });
  };

  switchToExcelImport = () => {
    this.setState({
      excelImportVisible: true,
      downloadTemplateVisible: false,
      importProxyVisible: false,
    });
  };

  handleBackFromTemplate = () => {
    this.setState({
      downloadTemplateVisible: false,
      excelImportVisible: false,
      importProxyVisible: false,
      addNewCardVisible: false,
    });
    this.removeFile();
  };

  handleBackFromExcelImport = () => {
    this.setState({
      downloadTemplateVisible: true,
      excelImportVisible: false,
      importProxyVisible: false,
    });
  };

  closeImportCards = async () => {
    this.setState({ importProxyVisible: false });
    this.removeFile();
    await this.getUsers();
    await this.getCards();
  };

  closeImportCardsWithRefresh = async () => {
    this.setState({ importProxyVisible: false });
    this.removeFile();
    await this.getUsers();
    await this.getCards();
  };

  removeFile = () => {
    this.setState({
      file: [],
      newCards: [],
      emptyRFID: [],
      emptyEmail: [],
      badEmail: [],
      duplicatedRFID: [],
    });
  };

  beforeUpload = async (file) => {
    const workbook = XLSX.read(await file.arrayBuffer(), { type: "buffer" });
    const sheet_name_list = workbook.SheetNames;
    const result = XLSX.utils.sheet_to_json(
      workbook.Sheets[sheet_name_list[0]]
    );

    let cardsToSave = [result];
    const emails = result.map((email) => {
      return (
        "E-Mail",
        {
          email,
        }
      );
    });
    const rfidString = result.map((rfidString) => {
      return (
        "Card code",
        {
          rfidString,
        }
      );
    });

    for (const user in result) {
      let cardsToSave = {};
      emails.forEach((email) => {
        if (result[user][email]) {
          cardsToSave.email = result[user][email];
        }
      });
      rfidString.forEach((rfidString) => {
        if (result[user][rfidString]) {
          cardsToSave.rfidString = result[user][rfidString];
        }
      });
      cardsToSave = [...result, cardsToSave];
    }

    this.setState({
      // cards: [...this.state.cards, ...cardsToSave],
      newCards: [...cardsToSave],
      file: [file],
    });
    return false;
  };

  saveCards = async () => {
    const saveObj = {
      cards: this.state.newCards,
    };
    if (this.state.newCards.length === 0) {
      message.error(this.props.t("companyadmin-agreements-type-empty"));
    } else {
      const param = this.state.selectedCompanyId
        ? `-proxy-cards/${this.state.selectedCompanyId}`
        : "";
      await axios
        .post("/2.0.0/entrypoint/import" + param, saveObj)
        .then(
          (response) =>
            response.status === 201 &&
            this.setState({
              excelImportVisible: false,
            })
        )
        .catch((err) => {
          message.error(this.props.t("proxy-import-error"));
          console.log(err);
        });
      this.getCards();
    }
    this.removeFile();
  };

  banCard = async (record) => {
    this.setState({ activated: false });
    await this.props.banCard(record);
    this.getCards();
  };

  activateCard = async (record) => {
    this.setState({ activated: true, modifyVisible: false });
    await this.props.activateCard(record);
    this.getCards();
  };

  deleteCard = async (record) => {
    await this.props.deleteCard(record);
    this.getCards();
  };

  hasRight = (rights, notAllowedSubscriptions) => {
    let allowed = false;
    this.props.user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allowed = true;
      }
    });
    if (allowed === true && notAllowedSubscriptions === undefined) {
      return true;
    } else {
      if (allowed === true) {
        if (this.allowedBySubscription(notAllowedSubscriptions)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  allowedBySubscription = (notAllowedSubscriptions) => {
    if (this.props.user.companyId === null) {
      return false;
    } else {
      if (
        notAllowedSubscriptions !== undefined &&
        notAllowedSubscriptions !== null
      ) {
        if (
          notAllowedSubscriptions.includes(
            this.props.user.company.subscriptionId
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  handleFilterActive = () => {
    const filteredUsers = this.props.cards.filter(
      (card) => card.active === true
    );
    this.setState({ cards: filteredUsers, filterKey: "active" });
  };

  handleResetFilter = async () => {
    this.getCards();
    this.resetFilter();
  };

  resetFilter = () => {
    this.setState({
      filteredInfo: {},
      filterKey: null,
      selectedFilterCompanyId: null,
    });
  };

  handleFilterCompanySelect = async (event) => {
    this.setState({
      selectedFilterCompanyId: event,
    });
    await this.props.getAllCardsByCompany(event);
    this.setState({ cards: this.props.cards });
  };

  handleCompanySelect = async (event) => {
    this.setState({
      selectedCompanyId: event,
    });
  };

  handleUserSelect = async (event) => {
    this.setState({
      selectedUserId: event,
    });
  };

  handleAddNewCard = async () => {
    const newCard = {
      rfidString: this.state.rfidString,
      userId: this.state.selectedUserId,
      active: this.state.active,
    };

    await this.props.createCard(newCard);
    await this.getCards();

    this.handleBackFromTemplate();
  };

  renderFilterMenu = () => {
    return (
      <Menu activeKey={this.state.filterKey}>
        <Menu.Item onClick={this.handleFilterActive} key="active">
          {this.props.t("active-filter-card-button-label")}
        </Menu.Item>
        <Menu.Item onClick={this.handleFilterInactive} key="inactive">
          {this.props.t("inactive-filter-card-button-label")}
        </Menu.Item>
      </Menu>
    );
  };

  handleFilterInactive = () => {
    const filteredUsers = this.props.cards.filter(
      (card) => card.active === false
    );
    this.setState({ cards: filteredUsers, filterKey: "inactive" });
  };

  onDateChange = async (value) => {
    this.setState({ activationTime: value });
  };

  onOk = async (record) => {
    const params = { id: record.id, activationTime: this.state.activationTime };
    await this.props.modifyActivationTime(params);
    this.getCards();
    this.setState({ modifyVisible: false, selectedId: null });
  };

  onModifyVisible = async (id) => {
    this.setState({ modifyVisible: true, selectedId: id });
  };

  getColumnSearchProps = (dataIndex, searchLabel, type = "text") => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      let date = selectedKeys[0];
      if (selectedKeys[0] === undefined || selectedKeys[0] === null) {
        date = new Date();
      }
      return (
        <div style={{ padding: 8 }}>
          {dataIndex === "activationTime" ? (
            <div>
              <ConfigProvider locale={localehu} name="activationTime">
                <DatePicker
                  ref={(node) => {
                    this.searchInput = node;
                  }}
                  placeholder={`${searchLabel}`}
                  value={moment(date, "YYYY-MM-DD")}
                  format={["YYYY-MM-DD", "DD-MM-YYYY"]}
                  locale={{ dateTimeFormat: "YYYY-MM-DD" }}
                  onChange={(e, text) => {
                    setSelectedKeys(
                      text ? [new Date(text).toLocaleDateString()] : []
                    );
                  }}
                  onPressEnter={() =>
                    this.handleSearch(selectedKeys, confirm, dataIndex)
                  }
                  style={{ width: 188, marginBottom: 8, display: "block" }}
                />
              </ConfigProvider>
            </div>
          ) : (
            <div>
              <Input
                ref={(node) => {
                  this.searchInput = node;
                }}
                placeholder={`${searchLabel}`}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  this.handleSearch(selectedKeys, confirm, dataIndex)
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </div>
          )}

          <Space>
            <Button
              type="primary"
              onClick={() =>
                this.handleSearch(selectedKeys, confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {this.props.t("search-button-label")}
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 100 }}
            >
              {this.props.t("reset-button-label")}
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (type === "text") {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      } else {
        return new Date(record[dataIndex])
          .toLocaleString()
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput?.select?.());
      }
    },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    moment.updateLocale("en", {
      week: {
        dow: 8,
      },
      weekdaysMin: [
        this.props.t("Sun"),
        this.props.t("Mon"),
        this.props.t("Tue"),
        this.props.t("Wed"),
        this.props.t("Thu"),
        this.props.t("Fri"),
        this.props.t("Sat"),
      ],
      monthsShort: [
        this.props.t("Jan"),
        this.props.t("Feb"),
        this.props.t("Mar"),
        this.props.t("Apr"),
        this.props.t("May"),
        this.props.t("Jun"),
        this.props.t("Jul"),
        this.props.t("Aug"),
        this.props.t("Sep"),
        this.props.t("Oct"),
        this.props.t("Nov"),
        this.props.t("Dec"),
      ],
    });

    const dummyRequest = ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    };

    const columns = [
      {
        title: this.props.t("companyadmin-regapp-table-header-token"),
        key: "rfidString",
        dataIndex: "rfidString",
        sorter: (a, b) => a.rfidString.localeCompare(b.rfidString),
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps(
          "rfidString",
          this.props.t("rfidString-label")
        ),
      },
      {
        title: this.props.t("proximity-card-owner"),
        key: "email",
        dataIndex: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps("email", this.props.t("email-label")),
      },
      {
        title: this.props.t("companyadmin-regapp-table-header-inuse"),
        key: "active",
        sorter: (a, b) => Number(a.active) - Number(b.active),
        sortDirections: ["ascend", "descend"],
        render: (text, record) => (
          <Space
            size="middle"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              style={{
                width: "1.1vw",
                height: "1.1vw",
                borderRadius: "50%",
                backgroundColor: record.active ? "green" : "gray",
              }}
            />
          </Space>
        ),
      },
      {
        title: this.props.t("automatic-activitation-time"),
        key: "activationTime",
        sorter: (a, b) => {
          const activationTimeA = a.activationTime || "";
          const activationTimeB = b.activationTime || "";

          return activationTimeA.localeCompare(activationTimeB);
        },
        sortDirections: ["ascend", "descend"],
        render: (text, record) =>
          record.active === false &&
          this.state.modifyVisible === true &&
          this.state.selectedId === record.id ? (
            <Space>
              <DatePicker
                value={this.state.activationTime}
                defaultValue={record.activationTime}
                format={["YYYY-MM-DD"]}
                disabled={record.active === true}
                locale={{ dateTimeFormat: "YYYY-MM-DD" }}
                showToday={false}
                placeholder={
                  record.active === false && record.activationTime !== null
                    ? moment(record.activationTime).format("YYYY-MM-DD")
                    : moment()
                }
                onChange={this.onDateChange}
              />
              <Button
                onClick={() => this.onOk(record)}
                disabled={record.active === true}
              >
                <CheckOutlined />
              </Button>
            </Space>
          ) : (
            <>
              {record.activationTime
                ? moment(record.activationTime).format("YYYY-MM-DD")
                : this.props.t("empty-text")}
            </>
          ),
        ...this.getColumnSearchProps(
          "activationTime",
          this.props.t("automatic-activitation-time"),
          "date"
        ),
      },
      {
        title: this.props.t(""),
        key: "actions",
        render: (text, record) => (
          <Space size="middle">
            <Button
              disabled={!record.active}
              onClick={() => this.banCard(record.id)}
              danger
            >
              {this.props.t("companyadmin-regapp-table-header-ban")}
            </Button>

            <Button onClick={() => this.activateCard(record.id)}>
              {this.props.t("companyadmin-languages-table-header-activate")}
            </Button>
          </Space>
        ),
      },
      {
        title: this.props.t(""),
        key: "update",
        render: (text, record) => (
          <Space size="middle">
            <Button
              disabled={record.active === true}
              onClick={() => this.onModifyVisible(record.id)}
              type="primary"
            >
              {this.props.t("modify-button-label")}
            </Button>
            <Popconfirm
              title={this.props.t("cards-card-delete-confirm")}
              okText={this.props.t("yes-button-label")}
              cancelText={this.props.t("no-button-label")}
              onConfirm={() => this.deleteCard(record.id)}
              disabled={record.active === true}
            >
              <Button
                visible={this.hasRight([
                  userRoles.COMPANY_ADMIN,
                  userRoles.RECEPTION_ADMIN,
                ])}
                disabled={record.active === true}
                danger
                type="primary"
              >
                {this.props.t("entrypoints-delete-button-label")}
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];

    return (
      <div>
        <Row>
          <Col span={24}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>
                {this.props.t("companyadmin-tabstitle-proximity-card")}
              </strong>
            </h1>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ float: "right", padding: "1em" }}>
                {this.hasRight([userRoles.OFFICE_ADMIN]) && (
                  <>
                    <Form.Item
                      name="comapanyId"
                      label={this.props.t("companies-title")}
                      rules={[]}
                    >
                      <Select
                        style={{ width: "15em" }}
                        onChange={(event) =>
                          this.handleFilterCompanySelect(event)
                        }
                        value={this.state.selectedFilterCompanyId}
                      >
                        {this.props.companies.map((company) => {
                          return (
                            <Select.Option key={company.id} value={company.id}>
                              {company.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </>
                )}
                <Dropdown
                  overlay={this.renderFilterMenu}
                  placement="bottomLeft"
                >
                  <Button icon={<FilterOutlined />} style={{ margin: "1em" }}>
                    {this.props.t("filters-button-label")}
                  </Button>
                </Dropdown>
                <Button
                  style={{ margin: "1em" }}
                  onClick={this.handleResetFilter}
                  disabled={
                    this.state.filterKey === null &&
                    Object.keys(this.state.filteredInfo).length === 0
                  }
                >
                  {this.props.t("reception-guests-resetfilters-button-label")}
                </Button>
                <Button
                  type="primary"
                  visible={this.hasRight([
                    userRoles.OFFICE_ADMIN,
                    userRoles.COMPANY_ADMIN,
                    userRoles.RECEPTION_ADMIN,
                  ])}
                  disabled={
                    !this.hasRight([
                      userRoles.OFFICE_ADMIN,
                      userRoles.COMPANY_ADMIN,
                      userRoles.RECEPTION_ADMIN,
                    ])
                  }
                  onClick={this.openAddNewCardModal}
                  style={{ margin: "1em", position: "absolute", right: "18em" }}
                >
                  <PlusOutlined />
                  {this.props.t("proximity-card-add-new-card")}
                </Button>
                <Button
                  type="primary"
                  visible={this.hasRight([
                    userRoles.OFFICE_ADMIN,
                    userRoles.COMPANY_ADMIN,
                    userRoles.RECEPTION_ADMIN,
                  ])}
                  disabled={
                    !this.hasRight([
                      userRoles.OFFICE_ADMIN,
                      userRoles.COMPANY_ADMIN,
                      userRoles.RECEPTION_ADMIN,
                    ])
                  }
                  onClick={this.openImportCards}
                  style={{ margin: "1em", position: "absolute", right: "1em" }}
                >
                  <PlusOutlined />
                  {this.props.t("proximity-card-import")}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          visible={this.state.importProxyVisible}
          onCancel={this.closeImportCardsWithRefresh}
          title={this.props.t("workers-workers-newworkersimport-button-label")}
          maskClosable={false}
          centered
          footer={null}
          width={1300}
          bodyStyle={{ overflowY: "auto", maxHeight: "800px" }}
        >
          <div>
            <WizardImportProxy
              next={this.closeImportCardsWithRefresh}
              wizard={false}
            />
          </div>
        </Modal>
        <Modal
          maskClosable={false}
          closable={true}
          footer={null}
          visible={this.state.downloadTemplateVisible}
          centered
          onCancel={this.handleBackFromTemplate}
        >
          <WizardDownloadProxyTemplate
            next={this.switchToExcelImport}
            prev={this.handleBackFromTemplate}
          />
        </Modal>

        <Modal
          title={null}
          visible={this.state.excelImportVisible}
          footer={null}
          width={"35em"}
          onCancel={this.handleBackFromTemplate}
          centered
          forceRender
          bodyStyle={{ height: this.props.user.officeId ? "350px" : "300px" }}
        >
          {this.hasRight([userRoles.OFFICE_ADMIN]) && (
            <>
              <Form.Item
                name="comapanyId"
                label={this.props.t("proximity-card-import-select-company")}
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: "15em" }}
                  onChange={(event) => this.handleCompanySelect(event)}
                  value={this.state.selectedCompanyId}
                >
                  {this.props.companies.map((company) => {
                    return (
                      <Select.Option key={company.id} value={company.id}>
                        {company.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          )}
          <div style={{ textAlign: "center" }}>
            <div style={{ margin: "1em", textAlign: "left" }}>
              <Upload.Dragger
                name="file"
                multiple={false}
                onRemove={this.removeFile}
                accept={".xlsx"}
                beforeUpload={this.beforeUpload}
                fileList={this.state.file}
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: false,
                }}
                customRequest={dummyRequest}
              >
                <p
                  className="ant-upload-drag-icon"
                  style={{ textAlign: "center" }}
                >
                  <PlusOutlined />
                </p>
                <p
                  className="ant-upload-text"
                  style={{ color: "#00b7ae", textAlign: "center" }}
                >
                  {this.props.t("wizard-excel-import")}
                </p>
              </Upload.Dragger>
            </div>
            {this.state.newCards.length === 0 &&
              this.state.file.length !== 0 && (
                <div style={{ color: "#ff4d4f" }}>
                  {this.props.t("excel-proxy-import-error")}
                </div>
              )}
            <div style={{ margin: "1em" }}>
              <Button
                onClick={this.saveCards}
                type="primary"
                disabled={
                  this.state.file.length === 0 ||
                  this.state.newCards.length === 0 ||
                  (this.props.user.officeId && !this.state.selectedCompanyId)
                }
              >
                {this.props.t("wizard-excel-import")}
              </Button>
            </div>
            <Col>
              <Button
                onClick={this.handleBackFromExcelImport}
                style={{ width: "30%" }}
              >
                {this.props.t("back-button-label")}
              </Button>
            </Col>
          </div>
        </Modal>

        <Modal
          title={this.props.t("proximity-card-add-modal-label")}
          visible={this.state.addNewCardVisible}
          onCancel={this.handleBackFromTemplate}
          maskClosable={false}
          forceRender
          centered
          footer={[
            <Space>
              <Button key="cancel" onClick={this.handleBackFromTemplate}>
                {this.props.t("cancel-button-label")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.handleAddNewCard}
              >
                {this.props.t("proximity-card-add-button-label")}
              </Button>
            </Space>,
          ]}
        >
          <Form
            onFinish={this.handleAddNewCard}
            ref={this.formRef}
            {...formItemLayout}
            name="addProxyCard"
            scrollToFirstError
          >
            <Form.Item
              name="rfidString"
              label={this.props.t("proximity-card-rfid-input-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("proximity-card-rfid-input-label"),
                },
              ]}
            >
              <Input name="rfidString" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item
              name="active"
              label={this.props.t("proximity-card-active-input-label")}
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: this.props.t("proximity-card-active-input-label"),
                },
              ]}
            >
              <Checkbox
                    checked={this.state.active} onChange={this.handleChange}>
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="userId"
              label={this.props.t("proximity-card-user-input-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("proximity-card-user-input-label"),
                },
              ]}
            >
              <Select
                showSearch
                onChange={(event) => this.handleUserSelect(event)}
                value={this.state.selectedUserId}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {this.state.users.map((user) => {
                  const fullName = `${user.firstName} ${user.lastName}`;
                  const userDisplay = `${fullName} (${user.email})`;
                  return (
                    <Select.Option key={user.id} value={user.id}>
                      {userDisplay}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              rowKey="id"
              dataSource={this.state.cards}
              locale={{ emptyText: this.props.t("empty-text") }}
              loading={!this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: true,
              }}
              onChange={this.handleChangeTable}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  users: state.usersReducer.users,
  groups: state.usersReducer.groups,
  usersStatus: state.usersReducer.status,
  saveStatus: state.usersReducer.saveStatus,
  message: state.usersReducer.message,
  staus: state.usersReducer.status,
  cards: state.cardReducer.cards,
  cardSaveStatus: state.cardReducer.saveStatus,
  addresses: state.companyReducer.addresses,
  companies: state.companyReducer.companies,
});
const UserProximityCards = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ProximityCard)
);
export default UserProximityCards;
