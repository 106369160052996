import { Col, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import hu from "../../static/hu.png";
import en from "../../static/en.png";
import de from "../../static/de.png";
import qrcode from "../../static/qr-code.png";
import reg from "../../static/reg.png";
import wlogo from "../../static/w-logo.png";
import IconTint from "react-icon-tint";
import { ReactComponent as QR } from "../../static/tablogqr.svg";
import Clock from "react-live-clock";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";

const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedAppPreview extends Component {
  hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.4)"
      );
    }
    throw new Error("Bad Hex");
  }
  hasRightBySubscription = (notAllowedSubscriptions) => {
    if (this.props.user.companyId !== null) {
      if (
        notAllowedSubscriptions.includes(this.props.user.company.subscriptionId)
      ) {
        return false;
      }
      return true;
    } else {
      return true;
    }
  };

  render() {
    return (
      <div
        style={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div
          style={{
            width: this.props.width ? this.props.width : "1050px",
            height: this.props.height ? this.props.height : "765px",
            backgroundColor: "#000000",
            justifyContent: "center",
            display: "flex",
            borderRadius: "20px",
          }}
        >
          <div
            style={{
              width: this.props.screenWidth ? this.props.screenWidth : "980px",
              height: this.props.screenHeight
                ? this.props.screenHeight
                : "710px",
              backgroundImage: `url("${this.props.background}")`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              marginTop: "27px",
            }}
          >
            <div>
              <Row>
                <Col span={5}>
                  <div
                    style={{
                      backgroundColor: this.props.backgroundColor,
                      color: "#ffffff",
                      width: "100%",
                      padding: "2em",
                      borderBottomRightRadius: "100px 80px",
                    }}
                  >
                    <img src={this.props.logo} alt="logo" width={"85%"} />
                  </div>
                </Col>
                <Col span={19} style={{ textAlign: "right" }}>
                  <div style={{ color: "#ffffff", padding: "2em 5em 0 0" }}>
                    <div>
                      <Clock format={"YYYY.MM.DD"} ticking={true} />
                    </div>
                    <div>
                      <strong style={{ fontSize: "1.3rem" }}>
                        <Clock format={"HH:mm"} ticking={true} />
                      </strong>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div
                    style={{
                      width: "75%",
                      marginLeft: "70px",
                      marginTop: "50px",
                    }}
                  >
                    <div style={{ width: "25vw", height: "5vh" }}>
                      <h1
                        style={{
                          color: "#ffffff",
                        }}
                      >
                        <strong>{this.props.welcomeTitle}</strong>
                      </h1>
                    </div>
                    <div style={{ width: "25vw", height: "5vh" }}>
                      <h3
                        style={{
                          color: "#ffffff",
                        }}
                      >
                        {this.props.welcomeText}
                      </h3>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row
                style={{
                  bottom: this.props.bottom ? this.props.bottom : "38px",
                  position: this.props.bottom ? "absolute" : "absolute",
                  width: this.props.screenWidth
                    ? this.props.screenWidth
                    : "980px",
                  height: "300px",
                }}
              >
                <Col span={11}>
                  <div
                    style={{
                      backgroundColor: this.props.backgroundColor,
                      textAlign: "center",
                      color: "#ffffff",
                      borderTopLeftRadius: "30%",
                      paddingBottom: this.props.paddingBottoms
                        ? this.props.paddingBottoms[0]
                        : "5px",
                    }}
                  >
                    <h1
                      style={{
                        paddingTop: "5px",
                        paddingBottom: "0",
                        color: "#ffffff",
                        marginBottom: "0",
                      }}
                    >
                      <strong>
                        {this.props.t("app-preview-contactless-title")}
                      </strong>
                    </h1>
                    <p style={{ fontSize: "1.2rem" }}>
                      {this.props.t("app-preview-contactless-text")}
                    </p>
                    <p style={{ width: "70%", margin: "0 auto" }}>
                      {this.props.t("app-preview-qr-text")}
                    </p>
                    <div style={{ marginTop: "5px" }}>
                      <QR
                        style={{
                          borderRadius: "20px",
                          width: this.props.paddingBottoms ? "100px" : "140px",
                          height: this.props.paddingBottoms ? "100px" : "140px",
                          fill: this.props.backgroundColor,
                        }}
                      />
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: this.props.paddingBottoms ? "48px" : "15px",
                        left: "15px",
                      }}
                    >
                      <img src={wlogo} alt="logo" width={85} />
                    </div>
                  </div>
                </Col>
                <Col span={2}>
                  <div
                    style={{
                      backgroundColor: this.hexToRgbA(
                        this.props.backgroundColor
                      ),
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        padding: this.props.flagpaddings
                          ? this.props.flagpaddings[0]
                          : "71px 15px 15px 15px",
                      }}
                    >
                      <img
                        src={hu}
                        alt="hu_flag"
                        width={50}
                        style={{
                          opacity: i18n.language === "hu" ? "1" : "0.5",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        padding: this.props.flagpaddings
                          ? this.props.flagpaddings[1]
                          : "15px",
                      }}
                    >
                      <img
                        src={en}
                        alt="en_flag"
                        width={50}
                        style={{
                          opacity: i18n.language === "en" ? "1" : "0.5",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        padding: this.props.flagpaddings
                          ? this.props.flagpaddings[2]
                          : "15px 15px 71px 15px",
                      }}
                    >
                      <img
                        src={de}
                        alt="de_flag"
                        width={50}
                        style={{
                          opacity: i18n.language === "de" ? "1" : "0.5",
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={11}>
                  <div
                    style={{
                      backgroundColor: "#ffffff",
                      textAlign: "center",
                      color: "#000000",
                      borderTopRightRadius: "30%",
                      paddingBottom: this.props.paddingBottoms
                        ? this.props.paddingBottoms[1]
                        : "31px",
                    }}
                  >
                    <div>
                      <h1
                        style={{
                          paddingTop: "5px",
                          paddingBottom: "0",
                          marginBottom: "0",
                          color: this.props.backgroundColor,
                        }}
                      >
                        <strong>
                          {this.props.t("app-preview-kiosk-title")}
                        </strong>
                      </h1>
                      <p
                        style={{
                          fontSize: "1.2rem",
                          color: this.props.backgroundColor,
                        }}
                      >
                        {this.props.t("app-preview-kiosk-text")}
                      </p>
                      {this.hasRightBySubscription([2, 5]) && (
                        <div style={{ margin: "0 auto", width: "60%" }}>
                          <div
                            style={{
                              backgroundColor: this.props.backgroundColor,
                              padding: "1.2em 1em",
                              marginTop: "1em",
                              color: "#ffffff",
                              borderRadius: "50px",
                            }}
                          >
                            <div>
                              <img src={qrcode} alt="qrcode" width={20} />
                            </div>
                            <strong>
                              {this.props.t("app-preview-kiosk-button-qr")}
                            </strong>
                          </div>
                          <div
                            style={{
                              backgroundColor: "#ffffff",
                              padding: "1.2em 1em",
                              marginTop: "1em",
                              color: "#ffffff",
                              borderRadius: "50px",
                              boxShadow: "5px 5px 2px #D3D3D3",
                            }}
                          >
                            <div style={{ margin: "0 auto" }}>
                              <IconTint
                                src={reg}
                                color={this.props.backgroundColor}
                                maxWidth={"20"}
                                maxHeight={"16"}
                              />
                            </div>
                            <strong
                              style={{ color: this.props.backgroundColor }}
                            >
                              {this.props.t("app-preview-kiosk-register")}
                            </strong>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
});
const AppPreview = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedAppPreview)
);
export default AppPreview;
