import React, { Component } from "react";
import { connect } from "react-redux";
import { Input, Form, Button, message, Row, Col } from "antd";
import Axios from "axios";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import hu from "../../static/hu.png";
import de from "../../static/de.png";
import en from "../../static/en.png";
import key from "../../static/key.png";
import lock from "../../static/lock.png";

const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedGuestCode extends Component {
  state = {
    code: "",
    token: this.props.match.params.token,
    guest: null,
    host: null,
  };

  submitForm = async () => {

    await Axios.get("/1.0.0/reception/check-guest-code", {
      params: {
        token: this.state.token,
        code: this.state.code,
      },
    })
      .then((response) => {
        this.setState({ host: response.data.host, guest: response.data.guest });
        this.props.history.push(
          "/guest-agreements?token=" + this.state.token + "&code=" + this.state.code + "&email=false"
        );
      })
      .catch((err) => {
        message.error(this.props.t("guest-checkin-code-fail"), 5);
      });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  render() {
    return (
      <Row>
        <Col span={24}>
          <div
            style={{
              padding: "20px",
              backgroundColor: "#00aea9",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "20%", maxWidth: "75px" }}>
              <img
                src={de}
                alt="de_flag"
                width={"100%"}
                onClick={() => this.changeLanguage("de")}
                style={{
                  opacity: i18n.language === "de" ? "1" : "0.7",
                  paddingBottom: "10px",
                  borderBottom: i18n.language === "de" ? "3px solid white" : "",
                }}
              />
            </div>
            <div style={{ width: "20%", maxWidth: "75px" }}>
              <img
                src={hu}
                alt="hu_flag"
                width={"100%"}
                onClick={() => this.changeLanguage("hu")}
                style={{
                  opacity: i18n.language === "hu" ? "1" : "0.7",
                  paddingBottom: "10px",
                  borderBottom: i18n.language === "hu" ? "3px solid white" : "",
                }}
              />
            </div>
            <div style={{ width: "20%", maxWidth: "75px" }}>
              <img
                src={en}
                alt="en_flag"
                width={"100%"}
                onClick={() => this.changeLanguage("en")}
                style={{
                  opacity: i18n.language === "en" ? "1" : "0.7",
                  paddingBottom: "10px",
                  borderBottom: i18n.language === "en" ? "3px solid white" : "",
                }}
              />
            </div>
          </div>
          {!this.state.guest && (
            <div>
              <div style={{ backgroundColor: "#00aea9" }}>
                <h1
                  style={{
                    textAlign: "center",
                    color: "#ffffff",
                    fontSize: "2.2rem",
                  }}
                >
                  <strong>{this.props.t("guest-checkin-code-title")}</strong>
                </h1>
              </div>
              <div style={{ textAlign: "center", marginBottom: "35px" }}>
                <img alt="lock" width={"35%"} src={lock} />
              </div>
              <Form onFinish={this.submitForm}>
                <Form.Item
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: this.props.t("guest-checkin-code-code-text"),
                    },
                  ]}
                  style={{
                    padding: "1em",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <div style={{ width: "20%", paddingRight: "10px" }}>
                      <img alt="key" width={"100%"} src={key} />
                    </div>
                    <Input
                      value={this.state.code}
                      placeholder={this.props.t(
                        "guest-checkin-code-code-label"
                      )}
                      name="code"
                      onChange={this.handleChange}
                      style={{ borderRadius: "25px", fontSize: "2rem" }}
                    />
                  </div>
                </Form.Item>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      fontSize: "2.2rem",
                      height: "5%",
                      borderRadius: "50px",
                      width: "60%",
                    }}
                  >
                    {this.props.t("next-button-label")}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({});
const GuestCode = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedGuestCode)
);
export default GuestCode;
