import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Row, Col, message } from "antd";
import { withTranslation } from "react-i18next";
import hu from "../../static/hu.png";
import de from "../../static/de.png";
import en from "../../static/en.png";
import i18n from "../../i18n";
import "./guest-register.scss";
import {
  createOrUpdateVehicleFromMessage,
  createOrUpdateVehicleFromMessageParkl,
  getParkingReservationWithCode,
  getParklParkingReservationWithCode
} from "../../actions/parking";
import axios from "axios";

const mapDispatchToProps = (dispatch) => {
  return {
    getParkingReservationWithCode: (code) => dispatch(getParkingReservationWithCode(code)),
    getParklParkingReservationWithCode: (parklCode) => dispatch(getParklParkingReservationWithCode(parklCode)),
    createOrUpdateVehicleFromMessage: (code, licensePlateNumber, licensePlateNumberCountryCode) =>
      dispatch(createOrUpdateVehicleFromMessage(code, licensePlateNumber, licensePlateNumberCountryCode)),
    createOrUpdateVehicleFromMessageParkl: (parklCode, licensePlateNumber, licensePlateNumberCountryCode) =>
      dispatch(createOrUpdateVehicleFromMessageParkl(parklCode, licensePlateNumber, licensePlateNumberCountryCode)),
  };
};
class ConnectedGuestParking extends Component {
  state = {
    licensePlateNumber: "",
    licensePlateNumberCountryCode: null,
    token: this.props.match.params.token,
    hasReservation: false,
    host: null,
    company: null,
    code: null,
    parklCode: null
  };
  formRef = React.createRef();
  componentDidMount = async () => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL + "/api";
    const queryParams = new URLSearchParams(this.props.location.search);

    const code = queryParams.get("code");
    const parklCode = queryParams.get("parklCode");

    this.setState({
      code: code,
      parklCode: parklCode,
    });

    if (code) {
      await this.props.getParkingReservationWithCode(code);
    } else {
      await this.props.getParklParkingReservationWithCode(parklCode);
    }

    if (this.props.status) {
      this.setState({
        hasReservation: true,
        licensePlateNumber: this.props.reservations.licencePlateNumber,
        licensePlateNumberCountryCode: this.props.reservations.licencePlateCountryCode
      });
    } else {
      message.error(this.props.t("guest-has-deleted-the-reservation-error-message"));
    }
    await this.setLicensePlateNumber();
  };

  setLicensePlateNumber = async () => {
    this.formRef.current.setFieldsValue({
      licensePlateNumber: this.state.licensePlateNumber,
      licensePlateNumberCountryCode: this.state.licensePlateNumberCountryCode
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  submitForm = async () => {
    if (this.state.code) {
      await this.props.createOrUpdateVehicleFromMessage(
        this.state.code,
        this.state.licensePlateNumber,
        this.state.llicensePlateNumberCountryCode
      );
    } else {
      await this.props.createOrUpdateVehicleFromMessageParkl(
        this.state.parklCode,
        this.state.licensePlateNumber,
        this.state.llicensePlateNumberCountryCode
      );
    }

    if (this.props.status) {
      message.success(this.props.t("save-success"));
    } else {
      message.error(this.props.t("save-fail"));
    }
  };

  render() {
    const companyRadios = [];
    this.props.companies.forEach((company) =>
      companyRadios.push(
        <div>
          <img
            alt={company.name}
            src={`${process.env.REACT_APP_BASE_URL}/api/1.0.0/company/logo/${company.logoName}`}
            style={{ padding: "1em", width: "100%" }}
          />
          <div style={{ textAlign: "center" }}>{company.name}</div>
          <div
            style={{
              alignItems: "center",
              backgroundColor: "#00aea9",
              borderRadius: "50%",
              display: this.state.company === company.id ? "flex" : "none",
              height: "2em",
              justifyContent: "center",
              position: "absolute",
              right: "1em",
              top: "1em",
              width: "2em",
            }}
          ></div>
        </div>
      )
    );

    return (
      <Row>
        <Col span={24}>
          <div
            style={{
              padding: "20px",
              backgroundColor: "#00aea9",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "20%", maxWidth: "75px" }}>
              <img
                src={de}
                alt="de_flag"
                width={"100%"}
                onClick={() => this.changeLanguage("de")}
                style={{
                  opacity: i18n.language === "de" ? "1" : "0.7",
                  paddingBottom: "10px",
                  borderBottom: i18n.language === "de" ? "3px solid white" : "",
                }}
              />
            </div>
            <div style={{ width: "20%", maxWidth: "75px" }}>
              <img
                src={hu}
                alt="hu_flag"
                width={"100%"}
                onClick={() => this.changeLanguage("hu")}
                style={{
                  opacity: i18n.language === "hu" ? "1" : "0.7",
                  paddingBottom: "10px",
                  borderBottom: i18n.language === "hu" ? "3px solid white" : "",
                }}
              />
            </div>
            <div style={{ width: "20%", maxWidth: "75px" }}>
              <img
                src={en}
                alt="en_flag"
                width={"100%"}
                onClick={() => this.changeLanguage("en")}
                style={{
                  opacity: i18n.language === "en" ? "1" : "0.7",
                  paddingBottom: "10px",
                  borderBottom: i18n.language === "en" ? "3px solid white" : "",
                }}
              />
            </div>
          </div>
          {this.state.hasReservation === true ? (
            <div>
              <div style={{ backgroundColor: "#00aea9" }}>
                <h1
                  style={{
                    textAlign: "center",
                    color: "#ffffff",
                    fontSize: "2.2rem",
                  }}
                >
                  {this.props.t("parking-wanted-label")}
                </h1>
              </div>
              <Form onFinish={this.submitForm} ref={this.formRef}>
                <Form.Item
                  name="licensePlateNumber"
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "guest-register-license-plate-nubmer"
                      ),
                      whitespace: false,
                    },
                  ]}
                  style={{
                    padding: "1em",
                  }}
                >
                  <Input
                    value={this.state.licensePlateNumber}
                    name="licensePlateNumber"
                    placeholder={this.props.t(
                      "guest-register-license-plate-nubmer-placeholder"
                    )}
                    style={{ borderRadius: "25px", fontSize: "1.5rem" }}
                    onChange={this.handleChange}
                  />
                </Form.Item>
                <Form.Item
                  name="licensePlateNumberCountryCode"
                  style={{
                    padding: "1em",
                  }}
                >
                  <Input
                    value={this.state.licensePlateNumberCountryCode ? this.state.licensePlateNumberCountryCode : "H"}
                    name="licensePlateNumberCountryCode"
                    placeholder={this.props.t(
                      "licensePlateNumberCountryCode"
                    )}
                    style={{ borderRadius: "25px", fontSize: "1.5rem" }}
                    onChange={this.handleChange}
                  />
                </Form.Item>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      fontSize: "2.2rem",
                      height: "5%",
                      borderRadius: "50px",
                      width: "60%",
                    }}
                  >
                    {this.props.t("save-button-label")}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
                color: "red",
                fontSize: "3rem",
                fontWeight: "bold",
              }}
            >
              {this.props.t("guest-has-deleted-the-reservation")}
            </div>
          )}
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({
  companies: state.companyReducer.companies,
  vehicle: state.parkingReducer.vehicle,
  status: state.parkingReducer.status,
  reservations: state.parkingReducer.reservations,
});
const GuestParking = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedGuestParking)
);
export default GuestParking;
