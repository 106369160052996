import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Space,
  DatePicker,
  Button,
  Input,
  Select,
  ConfigProvider,
} from "antd";
import {
  getAllWorkLogs,
  getAllEvents,
  getAllEventsByOffice,
} from "../../actions/reception";
import { withTranslation } from "react-i18next";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import Axios from "axios";
import {
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { getAllAddresses } from "../../actions/company";
import { getAllUsers } from "../../actions/users";
import {
  getAllEntrypoints,
  getAllEntrypointsByOffice,
} from "../../actions/entrypoints";
import localehu from "antd/es/locale/hu_HU";
import Highlighter from "react-highlight-words";
import { userRoles } from "../../constants/roles-types";
import cardIcon from "../../static/card.png";
import nfcMobileIcon from "../../static/nfc-mobile.png";
import questionMarkIcon from "../../static/question-mark.png";
import qrScanIcon from "../../static/qr-scan.png";
import touchscreenIcon from "../../static/touchscreen.png";
import { RECEPTION_EVENT_SOURCE_TYPES } from "../../constants/reception-event-source-types";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllWorkLogs: (companyId) => dispatch(getAllWorkLogs(companyId)),
    getAllEvents: (companyId) => dispatch(getAllEvents(companyId)),
    getAllEventsByOffice: (officeId) =>
      dispatch(getAllEventsByOffice(officeId)),
    getAllAddresses: (companyId) => dispatch(getAllAddresses(companyId)),
    getAllUsers: (groupId, companyId) =>
      dispatch(getAllUsers(groupId, companyId)),
    getAllEntrypoints: () => dispatch(getAllEntrypoints()),
    getAllEntrypointsByOffice: () => dispatch(getAllEntrypointsByOffice()),
  };
};
class ConnectedReceptionWorkers extends Component {
  state = {
    events: [],
    exportVisible: false,
    start: new Date(),
    end: new Date(),
    guestFirstName: "",
    guestLastName: "",
    selectedAddresses: [],
    selectedUsers: [],
    selectedEventTypes: [],
    homeOffice: 3,
    filteredInfo: { date: [new Date().toLocaleDateString()] },
  };

  componentDidMount = async () => {
    if (
      this.props.user.groups.some((group) =>
        [userRoles.OFFICE_ADMIN, userRoles.OFFICE_RECEPTION_ADMIN].includes(
          group.id
        )
      )
    ) {
      await this.getAllEventsByOffice();
    } else {
      await this.getAllEvents();
    }
    await this.props.getAllAddresses(this.props.user.companyId);
    if (this.props.worker === true) {
      if (
        this.props.user.groups.some((group) =>
          [userRoles.OFFICE_ADMIN, userRoles.OFFICE_RECEPTION_ADMIN].includes(
            group.id
          )
        )
      ) {
        await this.props.getAllEntrypointsByOffice();
      } else {
        await this.props.getAllEntrypoints();
      }
      await this.props.getAllUsers(
        [
          userRoles.COMPANY_ADMIN,
          userRoles.RECEPTION_ADMIN,
          userRoles.HR_ADMIN,
          userRoles.USER,
        ],
        this.props.user.companyId
      );
    }
    this.props.socket.on("updateReception", async () => {
      if (
        this.props.user.groups.some((group) =>
          [userRoles.OFFICE_ADMIN, userRoles.OFFICE_RECEPTION_ADMIN].includes(
            group.id
          )
        )
      ) {
        await this.getAllEventsByOffice();
      } else {
        await this.getAllEvents();
      }
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeStartDate = (event, string) => {
    if (event === null) {
      this.setState({ start: new Date() });
    } else {
      const date = moment(event).toDate();
      this.setState({ start: date });
    }
  };

  handleChangeEndDate = (event, string) => {
    if (event === null) {
      this.setState({ end: new Date() });
    } else {
      const date = moment(event).toDate();
      this.setState({ end: date });
    }
  };

  getAllEvents = async () => {
    await this.props.getAllEvents(this.props.user.companyId);
    if (this.props.status) {
      if (this.props.worker === true) {
        const events = this.props.events.filter(
          (event) => event.userId !== null
        );
        this.setState({ events: events });
      } else {
        const events = this.props.events.filter(
          (event) => event.userId === null
        );
        this.setState({ events: events });
      }
    }
  };

  getAllEventsByOffice = async () => {
    await this.props.getAllEventsByOffice(this.props.user.officeId);
    if (this.props.status) {
      if (this.props.worker === true) {
        const events = this.props.events.filter(
          (event) => event.userId !== null
        );
        this.setState({ events: events });
      } else {
        const events = this.props.events.filter(
          (event) => event.userId === null
        );
        this.setState({ events: events });
      }
    }
  };

  handleExport = () => {
    this.setState({ exportVisible: true });
  };

  handleCloseExport = () => {
    const start = new Date();
    start.setMinutes(0);
    const end = new Date();
    end.setHours(end.getHours() + 1);
    end.setMinutes(0);
    this.setState({ exportVisible: false, start: start, end: end });
  };

  handleDownloadExport = async () => {
    const startDate = new Date(this.state.start);
    const endDate = new Date(this.state.end);
    let toSend = {
      start: startDate.getTime(),
      end: endDate.getTime(),
      addresses: this.state.selectedAddresses,
    };
    let url = "/2.0.0/reception/export-guest";
    if (this.props.worker === true) {
      url = "/2.0.0/reception/export-worker";
      toSend.users = this.state.selectedUsers;
      switch (this.state.homeOffice) {
        case 1:
          toSend.homeOffice = true;
          break;
        case 2:
          toSend.homeOffice = false;
          break;
        case 3:
          toSend.homeOffice = null;
          break;

        default:
          toSend.homeOffice = null;
          break;
      }
      toSend.eventTypes = this.state.selectedEventTypes;
    } else {
      toSend.guestFirstName = this.state.guestFirstName;
      toSend.guestLastName = this.state.guestLastName;
    }
    await Axios.post(url, toSend, { responseType: "blob" })
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `export_${new Date().toISOString()}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  changeAddresses = (event) => {
    this.setState({ selectedAddresses: event });
  };

  changeUsers = (event) => {
    this.setState({ selectedUsers: event });
  };

  changeEventTypes = (event) => {
    this.setState({ selectedEventTypes: event });
  };

  handleChangeHomeOffice = (event) => {
    this.setState({ homeOffice: event });
  };

  getColumnSearchProps = (dataIndex, searchLabel, type = "text") => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      let date = selectedKeys[0];
      if (selectedKeys[0] === undefined || selectedKeys[0] === null) {
        date = new Date();
      }
      return (
        <div style={{ padding: 8 }}>
          {dataIndex === "date" ? (
            <div>
              <ConfigProvider locale={localehu} name="date">
                <DatePicker
                  ref={(node) => {
                    this.searchInput = node;
                  }}
                  placeholder={`${searchLabel}`}
                  value={moment(date, "YYYY-MM-DD")}
                  format={["YYYY-MM-DD", "DD-MM-YYYY"]}
                  locale={{ dateTimeFormat: "YYYY-MM-DD" }}
                  onChange={(e, text) => {
                    setSelectedKeys(
                      text ? [new Date(text).toLocaleDateString()] : []
                    );
                  }}
                  onPressEnter={() =>
                    this.handleSearch(selectedKeys, confirm, dataIndex)
                  }
                  style={{ width: 188, marginBottom: 8, display: "block" }}
                />
              </ConfigProvider>
            </div>
          ) : (
            <div>
              <Input
                ref={(node) => {
                  this.searchInput = node;
                }}
                placeholder={`${searchLabel}`}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  this.handleSearch(selectedKeys, confirm, dataIndex)
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </div>
          )}

          <Space>
            <Button
              type="primary"
              onClick={() =>
                this.handleSearch(selectedKeys, confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {this.props.t("search-button-label")}
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 100 }}
            >
              {this.props.t("reset-button-label")}
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (type === "text") {
        return (record[dataIndex] || "")
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      } else {
        return new Date(record[dataIndex])
          .toLocaleString()
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput?.select?.());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={
              type === "text"
                ? text.toString()
                : new Date(text).toLocaleDateString()
            }
          />
        </Space>
      ) : type === "date" ? (
        <Space align="center">{new Date(text).toLocaleDateString()}</Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: this.props.t("reception-events-table-header-date"),
        key: "date",
        dataIndex: "date",
        sorter: (a, b) => {
          const dateA = new Date(a.date).toISOString();
          const dateB = new Date(b.date).toISOString();

          return dateA.localeCompare(dateB);
        },
        sortDirections: ["ascend", "descend"],
        render: (text, record) => {
          return (
            <Space size="middle">
              {new Date(record.date).toLocaleDateString()}
            </Space>
          );
        },
        ...this.getColumnSearchProps(
          "date",
          this.props.t("reception-guests-date-title-label"),
          "date"
        ),
      },
      {
        title: this.props.t("reception-events-table-header-time"),
        key: "time",
        render: (text, record) => {
          const date = new Date(record.date);
          return (
            <Space size="middle">
              {`${date.getHours()}:${
                date.getMinutes() < 10 ? "0" : ""
              }${date.getMinutes()}`}
            </Space>
          );
        },
      },
      {
        title: this.props.t("reception-events-table-header-name"),
        key: "name",
        sorter: (a, b) => {
          const userNameA = `${a?.user?.lastName} ${a?.user?.firstName}`;
          const userNameB = `${b?.user?.lastName} ${b?.user?.firstName}`;
        
          return userNameA.localeCompare(userNameB);
        },
        sortDirections: ["ascend", "descend"],
        render: (text, record) => {
          return (
            <Space size="middle">
              {record.user
                ? `${record.user.lastName} ${record.user.firstName}`
                : this.props.user?.company?.anonymizationTime > 0 &&
                  record.guest.invite.date <
                    moment().add(
                      -this.props.user.company.anonymizationTime,
                      "days"
                    )
                ? []
                : this.props.user?.office?.anonymizationTime > 0 &&
                  record.guest.invite.date <
                    moment().add(
                      -this.props.user.office.anonymizationTime,
                      "days"
                    )
                ? []
                : `${record.guest.lastName} ${record.guest.firstName}`}
            </Space>
          );
        },
      },
      this.props.worker === false
        ? {
            title: this.props.t("messages-table-header-company"),
            key: "company",
            dataIndex: "representedCompany",
            render: (text, record) => {
              return (
                <Space size="middle">
                  {this.props.user?.company?.anonymizationTime > 0 &&
                  record.guest.invite.date <
                    moment().add(
                      -this.props.user.company.anonymizationTime,
                      "days"
                    )
                    ? []
                    : this.props.user?.office?.anonymizationTime > 0 &&
                      record.guest.invite.date <
                        moment().add(
                          -this.props.user.office.anonymizationTime,
                          "days"
                        )
                    ? []
                    : `${record.guest.representedCompany}`}
                </Space>
              );
            },
          }
        : [],
      {
        title: this.props.t("reception-events-table-header-type"),
        key: "type",
        render: (text, record) => {
          return (
            <Space size="middle">
              {record.userId
                ? this.props.t("workers-group-worker")
                : this.props.t("guest-label")}
            </Space>
          );
        },
      },
      {
        title: this.props.t("reception-events-table-header-eventtype"),
        key: "eventType",
        render: (text, record) => {
          let string = "";
          if (record.eventType) {
            switch (record.eventType) {
              case 1:
                string = this.props.t("arrive-event-label");
                break;
              case 2:
                string = this.props.t("leave-event-label");
                break;
              case 3:
                string = this.props.t("check-in-to-place-event-label");
                break;
              case 4:
                string = this.props.t("check-out-from-place-event-label");
                break;
              case 5:
                string = this.props.t("passthrought-event-label");
                break;
              default:
                string = record.event;
                break;
            }
          } else {
            string = record.event;
          }
          return <Space size="middle">{string}</Space>;
        },
      },
      {
        title: this.props.t("reception-events-table-header-event-source-type"),
        key: "eventSourceType",
        align: "center",
        render: (text, record) => {
          let sourceIcon = questionMarkIcon;
          switch (record.eventSourceType) {
            case RECEPTION_EVENT_SOURCE_TYPES.PROXYCARD:
              console.log("PROXYCARD");
              console.log(record);
              sourceIcon = cardIcon;
              break;
            case RECEPTION_EVENT_SOURCE_TYPES.MOBILE_NFC:
              console.log("MOBILE_NFC");
              console.log(record);
              sourceIcon = nfcMobileIcon;
              break;
            case RECEPTION_EVENT_SOURCE_TYPES.MOBILE_QR:
              console.log("MOBILE_QR");
              console.log(record);
              sourceIcon = qrScanIcon;
              break;
            case RECEPTION_EVENT_SOURCE_TYPES.MOBILE_MANUAL:
              console.log("MOBILE_MANUAL");
              console.log(record);
              sourceIcon = touchscreenIcon;
              break;
            default:
              console.log("default");
              console.log(record);
              sourceIcon = questionMarkIcon;
              break;
          }
          return (
            <Space size="middle">
              <img alt="sourceIcon" src={sourceIcon} width={25} />
            </Space>
          );
        },
      },
      {
        title: this.props.t("companyadmin-address-name-label"),
        key: "address",
        render: (text, record) => {
          return <Space size="middle">{record.address?.name}</Space>;
        },
      },
      this.props.worker === true && this.props.entryPoints.length > 0
        ? {
            title: this.props.t("entrypoint-table-label"),
            key: "entryPoint",
            render: (string, record) => {
              return (
                <Space size="middle">
                  {record.entryPointId === null
                    ? this.props.t("no-entry-point-label")
                    : record.entryPoint.name}
                </Space>
              );
            },
          }
        : {},
      this.props.worker === true
        ? {
            title: "Home office",
            key: "homeOffice",
            render: (string, record) => {
              return (
                <Space size="middle">
                  {record.addressId === null ? (
                    <CheckOutlined />
                  ) : (
                    <CloseOutlined />
                  )}
                </Space>
              );
            },
          }
        : {},
    ];
    return (
      <div>
        <Row>
          <Col span={24}>
            <div style={{ float: "right" }}>
              <Button onClick={this.handleExport}>Export</Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ padding: "0.5em" }}>
            <Table
              columns={columns}
              rowKey={"id"}
              dataSource={this.state.events}
              locale={{ emptyText: this.props.t("empty-text") }}
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: true,
              }}
              size="small"
            />
          </Col>
        </Row>
        <Modal
          visible={this.state.exportVisible}
          onCancel={this.handleCloseExport}
          title={<strong>{this.props.t("export-button-label")}</strong>}
          maskClosable={false}
          centered={true}
          width={600}
          forceRender={true}
          footer={null}
        >
          <div>
            <label>
              <strong>{this.props.t("reception-export-start-date")}</strong>
            </label>
            <DatePicker
              mode="date"
              showTime
              onChange={this.handleChangeStartDate}
              name="start"
              minuteStep={5}
              showNow={false}
              showSecond={false}
              format="YYYY-MM-DD HH:mm"
              locale={{ dateTimeFormat: "YYYY-MM-DD HH:mm" }}
              defaultPickerValue={() => {
                const date = new Date();
                if (date.getMinutes() !== 0) {
                  if (date.getMinutes() > 30) {
                    date.setMinutes(0);
                    date.setSeconds(0);
                    date.setHours(date.getHours() + 1);
                  } else {
                    date.setMinutes(30);
                    date.setSeconds(0);
                  }
                }
                return moment(date);
              }}
              defaultValue={() => {
                const date = new Date();
                if (date.getMinutes() !== 0) {
                  if (date.getMinutes() > 30) {
                    date.setMinutes(0, 0, 0);
                    date.setSeconds(0);
                    date.setHours(date.getHours() + 1);
                  } else {
                    date.setMinutes(30, 0, 0);
                    date.setSeconds(0);
                  }
                }
                return moment(date);
              }}
              value={moment(this.state.start)}
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ marginTop: "1em" }}>
            <label>
              <strong>{this.props.t("reception-export-end-date")}</strong>
            </label>
            <DatePicker
              mode="date"
              showTime
              onChange={this.handleChangeEndDate}
              name="end"
              minuteStep={5}
              showNow={false}
              showSecond={false}
              format="YYYY-MM-DD HH:mm"
              locale={{ dateTimeFormat: "YYYY-MM-DD HH:mm" }}
              defaultPickerValue={() => {
                const date = new Date();
                if (date.getMinutes() !== 0) {
                  if (date.getMinutes() > 30) {
                    date.setMinutes(0);
                    date.setSeconds(0);
                    date.setHours(date.getHours() + 1);
                  } else {
                    date.setMinutes(30);
                    date.setSeconds(0);
                  }
                }
                return moment(date);
              }}
              defaultValue={() => {
                const date = new Date();
                if (date.getMinutes() !== 0) {
                  if (date.getMinutes() > 30) {
                    date.setMinutes(0, 0, 0);
                    date.setSeconds(0);
                    date.setHours(date.getHours() + 1);
                  } else {
                    date.setMinutes(30, 0, 0);
                    date.setSeconds(0);
                  }
                }
                return moment(date);
              }}
              value={moment(this.state.end)}
              style={{ width: "100%" }}
            />
          </div>
          {this.props.worker === false && (
            <div style={{ marginTop: "1em" }}>
              <label>
                <strong>
                  {this.props.t("guest-register-lastname-label")}:
                </strong>
              </label>
              <Input
                name="guestLastName"
                value={this.state.guestLastName}
                onChange={this.handleChange}
                allowClear
              />
            </div>
          )}
          {this.props.worker === false && (
            <div style={{ marginTop: "1em" }}>
              <label>
                <strong>
                  {this.props.t("guest-register-firstname-label")}:
                </strong>
              </label>
              <Input
                name="guestFirstName"
                value={this.state.guestFirstName}
                onChange={this.handleChange}
                allowClear
              />
            </div>
          )}
          {this.props.worker === true && (
            <div style={{ marginTop: "1em" }}>
              <label>
                <strong>{this.props.t("menu-workers-button-label")}:</strong>
              </label>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                value={this.state.selectedUsers}
                onChange={this.changeUsers}
              >
                {this.props.users.map((user) => {
                  return (
                    <Select.Option key={user.id} value={user.id}>
                      {user.lastName + " " + user.firstName}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          )}
          <div style={{ marginTop: "1em" }}>
            <label>
              <strong>{this.props.t("company-admin-sites-panel")}:</strong>
            </label>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              value={this.state.selectedAddresses}
              onChange={this.changeAddresses}
            >
              {this.props.addresses.map((address) => {
                return (
                  <Select.Option key={address.id} value={address.id}>
                    {address.name}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          {this.props.worker === true && (
            <div style={{ marginTop: "1em" }}>
              <label>
                <strong>
                  {this.props.t("reception-events-table-header-eventtype")}:
                </strong>
              </label>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                value={this.state.selectedEventTypes}
                onChange={this.changeEventTypes}
              >
                <Select.Option key={1} value={1}>
                  {this.props.t("arrive-event-label")}
                </Select.Option>
                <Select.Option key={2} value={2}>
                  {this.props.t("leave-event-label")}
                </Select.Option>
                <Select.Option key={5} value={5}>
                  {this.props.t("passthrought-event-label")}
                </Select.Option>
              </Select>
            </div>
          )}
          {this.props.worker === true && (
            <div style={{ marginTop: "1em" }}>
              <label>
                <strong>Home office:</strong>
              </label>
              <Select
                style={{ width: "100%" }}
                value={this.state.homeOffice}
                onChange={this.handleChangeHomeOffice}
              >
                <Select.Option key={1} value={1}>
                  {this.props.t("reception-export-home-office")}
                </Select.Option>
                <Select.Option key={2} value={2}>
                  {this.props.t("reception-export-office")}
                </Select.Option>
                <Select.Option key={3} value={3}>
                  {this.props.t("reception-export-both")}
                </Select.Option>
              </Select>
            </div>
          )}
          <div style={{ textAlign: "right", marginTop: "2em" }}>
            <Button
              onClick={this.handleCloseExport}
              style={{ marginRight: "1em" }}
            >
              {this.props.t("back-button-label")}
            </Button>
            <Button onClick={this.handleDownloadExport} type="primary">
              {this.props.t("export-button-label")}
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  workers: state.receptionReducer.workers,
  events: state.receptionReducer.events,
  status: state.receptionReducer.status,
  addresses: state.companyReducer.addresses,
  users: state.usersReducer.users,
  entryPoints: state.companyReducer.entryPoints,
});
const ReceptionWorkers = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedReceptionWorkers)
);
export default ReceptionWorkers;
