export const USER_ROLES = {
  SYSTEM_ADMIN: { id: 1, label: "user-role-system-admin-label" },
  COMPANY_ADMIN: { id: 2, label: "user-role-company-admin-label" },
  RECEPTION_ADMIN: { id: 3, label: "user-role-reception-admin-label" },
  HR_ADMIN: { id: 4, label: "user-role-hr-admin-label" },
  USER: { id: 5, label: "user-role-user-label" },
  OFFICE_ADMIN: { id: 6, label: "user-role-office-admin-label" },
  PARKING_SYSTEM: { id: 7, label: "user-role-parking-system-label" },
  HIK_VISION_SYSTEM: { id: 8, label: "user-role-hik-vision-system-label" },
  OFFICE_RECEPTION_ADMIN: { id: 9, label: "user-role-office-reception-admin-label" },
  INVITE_USER_RECEPTION_ADMIN: { id: 10, label: "user-role-invite-user-reception-admin-label" },
  PARKING_RECEPTION: { id: 11, label: "user-role-parking-reception-label" }
};

export const USER_ROLES_ARRAY = [
  USER_ROLES.SYSTEM_ADMIN,
  USER_ROLES.COMPANY_ADMIN,
  USER_ROLES.RECEPTION_ADMIN,
  USER_ROLES.HR_ADMIN,
  USER_ROLES.USER,
  USER_ROLES.OFFICE_ADMIN,
  USER_ROLES.PARKING_SYSTEM,
  USER_ROLES.HIK_VISION_SYSTEM,
  USER_ROLES.OFFICE_RECEPTION_ADMIN,
  USER_ROLES.INVITE_USER_RECEPTION_ADMIN,
  USER_ROLES.PARKING_RECEPTION,
]