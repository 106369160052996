import { PUSH_ACTIONS } from "../constants/action-types";

const initialState = {
  message: "",
  saveStatus: false,
};

const pushReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUSH_ACTIONS.PUSH_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case PUSH_ACTIONS.PUSH_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case PUSH_ACTIONS.PUSH_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });

    default:
      return state;
  }
};

export default pushReducer;
