import { message } from "antd";
import Axios from "axios";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class ConnectedForgotPasswordConfirm extends Component {
  state = {
    loading: true,
    success: false,
  };

  componentDidMount = async () => {
    await Axios.get("/1.0.0/users/forgot-password-confirm", {
      params: { token: this.props.match.params.token },
    })
      .then((response) => {
        this.setState({ loading: false, success: true });
        message.success(this.props.t("forgot-password-confirm-success"), 3);
      })
      .catch((err) => {
        this.setState({ loading: false, success: false });
        message.error(this.props.t("forgot-password-confirm-error"), 3);
      });
  };

  render() {
    return (
      <div>
        {this.state.loading && <div>Kérem várjon...</div>}
        {!this.state.loading && this.state.success && (
          <div>{this.props.t("forgot-password-confirm-success-text")}</div>
        )}
        {!this.state.loading && !this.state.success && (
          <div>{this.props.t("forgot-password-confirm-error")}</div>
        )}
      </div>
    );
  }
}

const ForgotPasswordConfirm = withTranslation()(ConnectedForgotPasswordConfirm);

export default ForgotPasswordConfirm;
