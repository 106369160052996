import { Button, Col, Row } from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addImportErrors, emptyImportErrors } from "../../actions/users";
const mapDispatchToProps = (dispatch) => {
  return {
    emptyImportErrors: () => dispatch(emptyImportErrors()),
    addImportErrors: (errors) => dispatch(addImportErrors(errors)),
  };
};
class ConnectedWizardBeforeImport extends Component {
  handleNextPage = () => {
    this.props.emptyImportErrors();
    this.props.addImportErrors({
      duplicatedInSystem: [],
      emptyFirstName: [],
      emptyLastName: [],
      badEmail: [],
      duplicatedEmail: [],
    });
    this.props.switchToNextPage();
  };

  render() {
    return (
      <Row>
        <Col span={12}>
          <div>
            <div style={{ textAlign: "left" }}>
              <h1
                style={{
                  color: "#00AEA9",
                  marginTop: "30px",
                  fontSize: "2.5rem",
                  paddingLeft: "0.62em",
                }}
              >
                <strong>{this.props.t("wizard-looksgood-popup-title")}</strong>
              </h1>
              <p
                style={{ padding: "1em 1.5em 0.5em 1.5em", fontSize: "1.2rem" }}
              >
                {this.props.t("wizard-looksgood-popup-invite")}
              </p>
              <p
                style={{ padding: "1em 1.5em 0.5em 1.5em", fontSize: "1.2rem" }}
              >
                {this.props.t("wizard-looksgood-popup-invite-question")}
              </p>

              <div style={{ margin: "2em", textAlign: "center" }}>
                <Button
                  onClick={this.handleNextPage}
                  type="primary"
                  style={{ width: "100%" }}
                  size="large"
                >
                  {this.props.t("wizard-looksgood-popup-wizard-button")}
                </Button>
              </div>
              <div style={{ margin: "2em", textAlign: "center" }}>
                <Button
                  onClick={this.props.switchToExcelImport}
                  type="primary"
                  style={{ width: "100%" }}
                  size="large"
                >
                  {this.props.t("wizard-looksgood-popup-excel-button")}
                </Button>
              </div>
              <div style={{ margin: "2em", textAlign: "center" }}>
                <Button
                  onClick={this.props.closePopup}
                  style={{ width: "100%" }}
                  size="large"
                >
                  {this.props.t("back-button-label")}
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div
            style={{
              textAlign: "center",
              paddingLeft: "36px",
              paddingTop: "20px",
              color: "#F9F9F9",
              fontSize: "1.2rem",
            }}
          >
            <div>
              <strong>
                {this.props.t("wizard-looksgood-popup-colleagueinvite")}
              </strong>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({});
const WizardBeforeImport = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedWizardBeforeImport)
);
export default WizardBeforeImport;
