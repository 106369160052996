import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Col,
  Table,
  Row,
  Space,
  Button,
  Form,
  Input,
  message,
  Popconfirm,
  Select,
} from "antd";
import {
  saveAgreement,
  modifyAgreement,
  deleteAgreement,
  getAllLanguages,
} from "../../actions/language";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  DeleteOutlined,
  EditOutlined,
  LockFilled,
  SaveOutlined,
} from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import { getOneCompanyById } from "../../actions/company";
import Axios from "axios";
import { getOneOfficeById } from "../../actions/office";

const mapDispatchToProps = (dispatch) => {
  return {
    saveAgreement: (params) => dispatch(saveAgreement(params)),
    modifyAgreement: (params) => dispatch(modifyAgreement(params)),
    deleteAgreement: (id) => dispatch(deleteAgreement(id)),
    getAllLanguages: () => dispatch(getAllLanguages()),
    getOneCompanyById: (companyId) => dispatch(getOneCompanyById(companyId)),
    getOneOfficeById: (officeId) => dispatch(getOneOfficeById(officeId)),
  };
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

class ConnectedCompanyAgreements extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    this.state = {
      selectedLanguageAgreement: [],
      type: 1,
      checkIfDisabled: false,
    };
  }

  componentDidMount = async () => {
    await this.props.getAllLanguages();
    if (this.props.companyId || this.props.user.companyId) {
      if (this.props.companyId) {
        await this.props.getOneCompanyById(this.props.companyId);
      } else {
        await this.props.getOneCompanyById(this.props.user.companyId);
      }
      if (this.props.status) {
        const defaultAgreement = Object.assign(
          [],
          this.props.company[0].agreements.filter((text) => {
            return (
              text.language.id === this.props.company[0].defaultLanguage.id
            );
          })
        );

        let filled = [];
        this.props.company[0].companyTexts.forEach((text) => {
          if (!filled.includes(text.language.code) && text.isActive) {
            filled = [...filled, text.language.code];
          }
        });

        const languageDefault = this.props.languages.find(
          (lang) => lang.id === this.props.company[0].defaultLanguage.id
        );

        const restLanguages = this.props.languages.filter(
          (lang) => lang.id !== this.props.company[0].defaultLanguage.id
        );

        this.setState({
          selectedLanguage: languageDefault,
          selectedLanguageAgreement: defaultAgreement,
          languages: [languageDefault, ...restLanguages],
          agreement: "",
          agreementName: "",
          selectedId: null,
          modify: false,
          filled: filled,
        });
      }
    } else {
      await this.props.getOneOfficeById(this.props.user.officeId);

      if (this.props.officeStatus) {
        const defaultAgreement = Object.assign(
          [],
          this.props.office[0].agreements.filter((text) => {
            return text.language.id === this.props.office[0].defaultLanguage.id;
          })
        );

        let filled = [];
        this.props.office[0].companyTexts.forEach((text) => {
          if (!filled.includes(text.language.code) && text.isActive) {
            filled = [...filled, text.language.code];
          }
        });

        const languageDefault = this.props.languages.find(
          (lang) => lang.id === this.props.office[0].defaultLanguageId
        );

        const restLanguages = this.props.languages.filter(
          (lang) => lang.id !== this.props.office[0].defaultLanguageId
        );

        this.setState({
          selectedLanguage: languageDefault,
          selectedLanguageAgreement: defaultAgreement,
          languages: [languageDefault, ...restLanguages],
          agreement: "",
          agreementName: "",
          selectedId: null,
          modify: false,
          filled: filled,
        });
      }
    }
  };

  updateCompany = async () => {
    if (this.props.companyId || this.props.user.companyId) {
      if (this.props.companyId) {
        await this.props.getOneCompanyById(this.props.companyId);
      } else {
        await this.props.getOneCompanyById(this.props.user.companyId);
      }
      if (this.props.status) {
        const defaultAgreement = Object.assign(
          [],
          this.props.company[0].agreements.filter((text) => {
            return (
              text.language.id === this.props.company[0].defaultLanguage.id
            );
          })
        );

        let filled = [];
        this.props.company[0].companyTexts.forEach((text) => {
          if (!filled.includes(text.language.code) && text.isActive) {
            filled = [...filled, text.language.code];
          }
        });

        const languageDefault = this.props.languages.find(
          (lang) => lang.id === this.props.company[0].defaultLanguage.id
        );

        const restLanguages = this.props.languages.filter(
          (lang) => lang.id !== this.props.company[0].defaultLanguage.id
        );

        this.setState({
          selectedLanguage: languageDefault,
          selectedLanguageAgreement: defaultAgreement,
          languages: [languageDefault, ...restLanguages],
          agreement: "",
          agreementName: "",
          selectedId: null,
          modify: false,
          filled: filled,
        });
      }
    } else {
      await this.props.getOneOfficeById(this.props.user.officeId);

      if (this.props.officeStatus) {
        const defaultAgreement = Object.assign(
          [],
          this.props.office[0].agreements.filter((text) => {
            return (
              text.language.id === this.props.company[0].defaultLanguage.id
            );
          })
        );

        let filled = [];
        this.props.office[0].companyTexts.forEach((text) => {
          if (!filled.includes(text.language.code) && text.isActive) {
            filled = [...filled, text.language.code];
          }
        });

        const languageDefault = this.props.languages.find(
          (lang) => lang.id === this.props.office[0].defaultLanguage.id
        );

        const restLanguages = this.props.languages.filter(
          (lang) => lang.id !== this.props.office[0].defaultLanguage.id
        );

        this.setState({
          selectedLanguage: languageDefault,
          selectedLanguageAgreement: defaultAgreement,
          languages: [languageDefault, ...restLanguages],
          agreement: "",
          agreementName: "",
          selectedId: null,
          modify: false,
          filled: filled,
        });
      }
    }
  };

  selectLanguage = (language) => {
    let selected = [];
    if (this.props.user.companyId === null || this.props.user.companyId === undefined) {
      selected = Object.assign(
        [],
        this.props.office[0].agreements.filter((text) => {
          return text.language.id === language.id;
        })
      );
    } else {
      selected = Object.assign(
        [],
        this.props.company[0].agreements.filter((text) => {
          return text.language.id === language.id;
        })
      );
    }

    this.setState({
      selectedLanguageAgreement: selected,
      selectedLanguage: language,
    });
    this.handleCancelModify();
  };

  handleChange = (event) => {
    this.setState({ agreementName: event.target.value });
  };

  handleChangeEditor = (event) => {
    this.setState({ agreement: event });
  };

  handleModify = (agreement) => {
    this.setState({
      agreementName: agreement.name,
      agreement: agreement.description,
      modify: true,
      selectedId: agreement.id,
    });
    this.formRef.current.setFieldsValue({
      agreementName: agreement.name,
      agreement: agreement.description,
    });
  };

  handleCancelModify = () => {
    this.setState({
      agreementName: "",
      agreement: "",
      modify: false,
      selectedId: null,
    });
    this.formRef.current.resetFields();
  };

  updateAgreementsList = async () => {
    if (this.props.companyId || this.props.user.companyId) {
      if (this.props.companyId) {
        await this.props.getOneCompanyById(this.props.companyId);
      } else {
        await this.props.getOneCompanyById(this.props.user.companyId);
      }
      if (this.props.status) {
        const newAgreements = this.props.company[0].agreements.filter(
          (agreement) =>
            agreement.language.id === this.state.selectedLanguage.id
        );
        this.setState({ selectedLanguageAgreement: newAgreements });
      }
    } else {
      await this.props.getOneOfficeById(this.props.user.officeId);
      if (this.props.officeStatus) {
        const newAgreements = this.props.office[0].agreements.filter(
          (agreement) =>
            agreement.language.id === this.state.selectedLanguage.id
        );
        this.setState({ selectedLanguageAgreement: newAgreements });
      }
    }
  };

  submitForm = async () => {
    if (!this.state.modify) {
      const toSave = {
        languageId: this.state.selectedLanguage.id,
        name: this.state.agreementName,
        description: this.state.agreement,
      };

      if (this.props.companyId || this.props.user.companyId) {
        toSave.companyId = this.props.companyId
          ? this.props.companyId
          : this.props.user.companyId;
      }

      if (this.props.user.officeId) {
        toSave.officeId = this.props.user.officeId;
      }

      await this.props.saveAgreement(toSave);
      this.updateAgreementsList();
      this.handleCancelModify();
      this.delaySubmit();
    } else {
      const toSave = {
        id: this.state.selectedId,
        companyId: this.props.companyId
          ? this.props.companyId
          : this.props.user.companyId,
        name: this.state.agreementName,
        description: this.state.agreement,
      };
      await this.props.modifyAgreement(toSave);
      this.updateAgreementsList();
      this.handleCancelModify();
      this.delaySubmit();
    }
  };

  delaySubmit = async () => {
    this.setState({
      checkIfDisabled: true,
    });
    setTimeout(() => this.setState({ checkIfDisabled: false }), 3000);
  };

  handleDeleteAgreement = async (record) => {
    await this.props.deleteAgreement(record.id);
    this.updateAgreementsList();
  };

  handleSelectType = (event) => {
    this.setState({ type: event });
  };

  handleSelectTemplate = async (event) => {
    let htmlFile = null;
    switch (event) {
      case "nda":
        htmlFile = await Axios.get(
          `/1.0.0/assets/agreements/nda-${this.state.selectedLanguage?.code.toLowerCase()}.html`
        );
        this.formRef.current.setFieldsValue({ agreement: htmlFile.data });
        this.setState({ agreement: htmlFile.data });
        break;
      case "covid":
        htmlFile = await Axios.get(
          `/1.0.0/assets/agreements/COVID-19-${this.state.selectedLanguage?.code.toLowerCase()}.html`
        );
        this.formRef.current.setFieldsValue({ agreement: htmlFile.data });
        this.setState({ agreement: htmlFile.data });
        break;
      case "gdpr":
        htmlFile = await Axios.get(
          `/1.0.0/assets/agreements/GDPR-${this.state.selectedLanguage?.code.toLowerCase()}.html`
        );
        this.formRef.current.setFieldsValue({ agreement: htmlFile.data });
        this.setState({ agreement: htmlFile.data });
        break;

      default:
        break;
    }
  };

  render() {
    const languageColumns = [
      {
        title: this.props.t("companyadmin-agreements-table-header-language"),
        key: "code",
        render: (text, record) => {
          return (
            <Space size="middle">
              {!this.state.filled?.includes(record.code) && <LockFilled />}
              {record.code}
            </Space>
          );
        },
      },
    ];
    const agreementColumns = [
      {
        title: this.props.t("companyadmin-agreements-table-header-agreement"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: <EditOutlined />,
        render: (text, record) => {
          return (
            <Space>
              <Button onClick={() => this.handleModify(record)}>
                <EditOutlined />
              </Button>
            </Space>
          );
        },
      },
      {
        title: <DeleteOutlined />,
        render: (text, record) => {
          return (
            <Space>
              <Popconfirm
                title="Biztosan törölni szeretné a nyilatkozatot?"
                okText={this.props.t("yes-button-label")}
                cancelText={this.props.t("no-button-label")}
                onConfirm={() => this.handleDeleteAgreement(record)}
              >
                <Button danger type="primary">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Space>
          );
        },
      },
    ];
    const toolbar = {
      toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],
        ["clean"],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
      ],
    };
    return (
      <div>
        <Row>
          <Col span={4} style={{ padding: "0.5em" }}>
            <Table
              rowKey="id"
              columns={languageColumns}
              dataSource={this.props.languages}
              pagination={{
                total: this.props.languages.length,
                pageSize: this.props.languages.length,
                hideOnSinglePage: true,
              }}
              size="small"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    if (this.state.filled.includes(record.code)) {
                      this.selectLanguage(record);
                    } else {
                      message.error(
                        this.props.t("companyadmin-agreements-language-error"),
                        3
                      );
                    }
                  },
                  style: {
                    background:
                      record.code === this.state.selectedLanguage?.code &&
                      "lightgrey",
                    cursor: this.state.filled?.includes(record.code)
                      ? "pointer"
                      : "not-allowed",
                  },
                };
              }}
            />
          </Col>
          <Col span={8} style={{ padding: "0.5em" }}>
            <Table
              rowKey="id"
              columns={agreementColumns}
              dataSource={this.state.selectedLanguageAgreement}
              size="small"
              scroll={{ x: "100%" }}
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: true,
              }}
              locale={{ emptyText: this.props.t("empty-text") }}
            />
          </Col>
          <Col span={12} style={{ padding: "0.5em" }}>
            <Form
              ref={this.formRef}
              {...formItemLayout}
              name="agreement"
              onFinish={this.submitForm}
              scrollToFirstError
            >
              <Form.Item
                label={this.props.t("companyadmin-agreements-type-label")}
                name="agreementType"
                initialValue={this.state.type}
              >
                <Select
                  value={this.state.type}
                  onSelect={this.handleSelectType}
                >
                  <Select.Option key="1" value={1}>
                    {this.props.t("companyadmin-agreements-type-empty")}
                  </Select.Option>
                  <Select.Option key="2" value={2}>
                    {this.props.t("companyadmin-agreements-type-template")}
                  </Select.Option>
                </Select>
              </Form.Item>
              {this.state.type === 2 && (
                <Form.Item
                  label={this.props.t("companyadmin-agreements-template-label")}
                  name="agreementTemplate"
                >
                  <Select
                    value={this.state.type}
                    onSelect={this.handleSelectTemplate}
                  >
                    <Select.Option key="1" value={"covid"}>
                      {this.props.t("companyadmin-agreements-template-covid")}
                    </Select.Option>
                    <Select.Option key="2" value={"nda"}>
                      {this.props.t("companyadmin-agreements-template-nda")}
                    </Select.Option>
                    <Select.Option key="3" value={"gdpr"}>
                      {this.props.t("companyadmin-agreements-template-gdpr")}
                    </Select.Option>
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                label={this.props.t("companyadmin-agreements-title-label")}
                name="agreementName"
                rules={[
                  {
                    required: true,
                    message: this.props.t("companyadmin-agreements-title-text"),
                  },
                ]}
              >
                <Input
                  value={this.state.agreementName}
                  name="agreementName"
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                label={this.props.t("companyadmin-agreements-agreement-label")}
                name="agreement"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: this.props.t(
                      "companyadmin-agreements-agreement-text"
                    ),
                  },
                ]}
              >
                <ReactQuill
                  theme="snow"
                  style={{ height: "250px", marginBottom: "12vh" }}
                  value={this.state.agreement || ""}
                  name="agreement"
                  onChange={this.handleChangeEditor}
                  modules={toolbar}
                />
              </Form.Item>
              <Form.Item style={{ textAlign: "right", width: "100%" }}>
                {this.state.modify && (
                  <Button
                    onClick={this.handleCancelModify}
                    style={{ marginRight: "0.5em" }}
                  >
                    {this.props.t("cancel-button-label")}
                  </Button>
                )}
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={this.state.checkIfDisabled}
                >
                  <SaveOutlined />
                  {this.props.t("save-button-label")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  company: state.companyReducer.company,
  status: state.companyReducer.status,
  languages: state.languageReducer.languages,
  officeStatus: state.officeReducer.status,
  office: state.officeReducer.office,
});
const CompanyAgreements = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedCompanyAgreements)
);
export default CompanyAgreements;
