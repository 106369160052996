import React, { Component } from "react";
import { Button, Space, message, Row, Col } from "antd";
import { connect } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import {
  createEntrypoints,
  deleteEntrypoints,
  getAllEntrypoints,
  modifyEntrypoints,
  getAllEntrypointsByAddressId,
  getEntrypointById,
} from "../../actions/entrypoints";
import {
  getAllAddresses,
  getAddressById,
  getOneCompanyById,
} from "../../actions/company";
import Axios from "axios";
import { Redirect } from "react-router";
import { userRoles } from "../../constants/roles-types";
import EntryPointsList from "../../components/EntryPoints/EntryPointsList/entrypoints-list";
import CreateOrUpdateEntryPointModal from "../../components/EntryPoints/CreateOrUpdateEntryPointModal/createOrUpdateEntryPointModal";
import { checkIfUserHasRoles } from "../../common/auth.common";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllEntrypoints: () => dispatch(getAllEntrypoints()),
    getEntrypointById: (id) => dispatch(getEntrypointById(id)),
    createEntrypoints: (params) => dispatch(createEntrypoints(params)),
    deleteEntrypoints: (id) => dispatch(deleteEntrypoints(id)),
    modifyEntrypoints: (params) => dispatch(modifyEntrypoints(params)),
    getAllAddresses: (companyId, officeId) =>
      dispatch(getAllAddresses(companyId, officeId)),
    getAddressById: (addressId) => dispatch(getAddressById(addressId)),
    getOneCompanyById: (companyId) => dispatch(getOneCompanyById(companyId)),
    getAllEntrypointsByAddressId: (addressId) =>
      dispatch(getAllEntrypointsByAddressId(addressId)),
  };
};

class ConnectedEntryPointsPage extends Component {
  formRef = React.createRef();

  state = {
    modalVisible: this.props.modalVisible || false,
    modalMode: "create",
    selectedEntryPoint: null,

    data: [],
    entryPoints: [],
    loading: false,
    visible: false,
    opened: false,
    regAppTokens: [],
    subscription: {},
    name: "",
    deviceId: "",
    controllerAddress: "",
    rfidString: "",
    isExit: false,
    addressId: null,
    title: "",
    buttontitle: "",
    addressName: "",
    modify: false,
    openSearchModal: false,
    tokenName: "",
    openTime: null,
    actuatorsState: null,
    desiredState: null,
    searchText: null,
    searchedColumn: null,
  };

  componentDidMount = async () => {
    if (this.props.user.officeId) {
      await this.getAllEntrypoints();
      await this.props.getAllAddresses(null, this.props.user.officeId);
    } else if (this.props.user.company.smartLock === true) {
      await this.getAllEntrypoints();
      await this.props.getAllAddresses(this.props.user.companyId);
      this.props.socket.on("doorOpened", (data) => {
        setTimeout(() => {
          const newentrypoints = this.state.entryPoints.map((point) => {
            if (point.id === data.entryPointId) {
              point.opened = false;
            }
            return point;
          });
          this.setState({ entryPoints: newentrypoints });
        }, data.time * 1000);
        const newentrypoints = this.state.entryPoints.map((point) => {
          if (point.id === data.entryPointId) {
            point.opened = true;
          }
          return point;
        });
        this.setState({ entryPoints: newentrypoints });
      });
    }
  };

  successMessage = () => {
    message.success(this.props.t("entrypoints-modal-success-message"), 5);
  };

  openCreateModal = () => {
    this.setState({ modalVisible: true, modalMode: "create" });
  };

  handleCancelModal = () => {
    this.setState({ modalVisible: false, selectedEntryPoint: null });
  };

  openUpdateModal = (record) => {
    this.setState({
      modalVisible: true,
      modalMode: "update",
      selectedEntryPoint: record,
    });
  };

  createEntryPoint = async (entrypointData) => {
    const saveData = {
      companyId: this.props.user?.companyId ? this.props.user.companyId : null,
      officeId: this.props.user?.officeId ? this.props.user.officeId : null,
      name: entrypointData.name,
      addressId: entrypointData.addressId,
      deviceId: entrypointData.deviceId,
      controllerAddress: entrypointData.controllerAddress,
      rfidString: entrypointData.rfidString,
      openTime: parseInt(entrypointData.openTime),
      actuatorsState: parseInt(entrypointData.actuatorsState),
      desiredState: parseInt(entrypointData.desiredState),
      isExit: entrypointData.isExit,
      epId: entrypointData.epId,
    };

    await this.props.createEntrypoints(saveData);
    if (this.props.saveStatus) {
      this.successMessage();
      this.handleCancelModal();
      this.getAllEntrypoints();
    } else {
      if (this.props.message && this.props.message.code === 5404) {
        message.error(this.props.t("entrypoints-save-duplicate-error"));
      } else {
        message.error(this.props.t("entrypoints-save-error"));
      }
    }
  };

  updateEntryPoint = async (entrypointData) => {
    const saveData = {
      id: entrypointData.id,
      companyId: this.props.user?.companyId ? this.props.user.companyId : null,
      officeId: this.props.user?.officeId ? this.props.user.officeId : null,
      name: entrypointData.name,
      addressId: entrypointData.addressId,
      deviceId: entrypointData.deviceId,
      controllerAddress: entrypointData.controllerAddress,
      rfidString: entrypointData.rfidString,
      openTime: parseInt(entrypointData.openTime),
      actuatorsState: parseInt(entrypointData.actuatorsState),
      desiredState: parseInt(entrypointData.desiredState),
      isExit: entrypointData.isExit,
      epId: entrypointData.epId,
    };

    await this.props.modifyEntrypoints(saveData);
    if (this.props.saveStatus) {
      this.successMessage();
      this.handleCancelModal();
      this.getAllEntrypoints();
    } else {
      if (this.props.message && this.props.message.code === 5404) {
        message.error(this.props.t("entrypoints-save-duplicate-error"));
      } else {
        message.error(this.props.t("entrypoints-save-error"));
      }
    }
  };

  handleTableChange = () => {
    this.getAllEntrypoints();
  };

  getAllEntrypoints = async () => {
    this.setState({ loading: true });
    await this.props.getAllEntrypoints();
    this.setState({
      loading: false,
    });
  };

  deleteEntrypoint = async (entryPointId) => {
    await this.props.deleteEntrypoints(entryPointId);
    //TODO: fix deleteEntrypoints, because there is no feedback on the delete success
    this.getAllEntrypoints();
  };

  handleOpenEntryPoint = async (entryPointId) => {
    //TODO: move to reducer
    try {
      await Axios.get("/2.0.0/entrypoint/open/" + entryPointId);
    } catch (err) {
      message.error(this.props.t("entrypoints-door-open-error"));
    }
  };

  render() {
    return (
      <div className="entrypoints">
        {!this.props.user.officeId &&
          this.props.user.company.smartLock === false && (
            <Redirect from="**" to="/tl/missing-right" />
          )}
        {checkIfUserHasRoles(this.props.user, [
          userRoles.COMPANY_ADMIN,
          userRoles.RECEPTION_ADMIN,
          userRoles.OFFICE_ADMIN,
        ]) && (
          <Row style={{ padding: "0.5em", position: "relative", right: "0em" }}>
            <Col span={24}>
              <Space size={"middle"}>
                {!this.props.user.company?.officeId ||
                checkIfUserHasRoles(this.props.user, [
                  userRoles.OFFICE_ADMIN,
                ]) ? (
                  <Button
                    className="button"
                    type="primary"
                    htmlType="submit"
                    onClick={() => this.openCreateModal()}
                  >
                    <PlusOutlined />
                    {this.props.t("entrypoints-create-title")}
                  </Button>
                ) : (
                  []
                )}
              </Space>
            </Col>
          </Row>
        )}

        <Row style={{ padding: "0.5em" }}>
          <Col span={24}>
            <EntryPointsList
              loading={this.state.loading}
              entryPoints={this.props.entryPoints}
              handleTableChange={this.handleTableChange}
              handleOpenEntryPoint={this.handleOpenEntryPoint}
              deleteEntrypoint={this.deleteEntrypoint}
              openUpdateModal={this.openUpdateModal}
            />
          </Col>
        </Row>
        {(checkIfUserHasRoles(this.props.user, [userRoles.OFFICE_ADMIN]) ||
          !this.props.user.company?.officeId) && (
          <CreateOrUpdateEntryPointModal
            modalVisible={this.state.modalVisible}
            modalMode={this.state.modalMode}
            addresses={this.props.addresses}
            handleCancelModal={this.handleCancelModal}
            selectedEntryPoint={this.state.selectedEntryPoint}
            createEntryPoint={this.createEntryPoint}
            updateEntryPoint={this.updateEntryPoint}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  addresses: state.companyReducer.addresses,
  company: state.companyReducer.company,
  status: state.companyReducer.status,
  users: state.usersReducer.users,
  user: state.loginReducer.user,
  entryPoints: state.companyReducer.entryPoints,
  message: state.companyReducer.message,
  saveStatus: state.companyReducer.saveStatus,
});
const EntryPointsPage = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedEntryPointsPage)
);
export default EntryPointsPage;
