import React, { Component } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { Button } from "antd";
import { withTranslation } from "react-i18next";
import hu from "../../static/hu.png";
import de from "../../static/de.png";
import en from "../../static/en.png";
import phone from "../../static/phone.png";
import i18n from "../../i18n";

const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedGuestMain extends Component {
  state = {
    loading: true,
    tokenOk: false,
  };

  componentDidMount = async () => {
    await Axios.get("/1.0.0/token/validate", {
      params: { token: this.props.match.params.token },
    })
      .then((response) => {
        this.setState({ tokenOk: true, loading: false });
      })
      .catch(() => {
        this.setState({ tokenOk: false, loading: false });
      });
  };

  navigateToRegister = () => {
    this.props.history.push(
      `/guest-agreements?token=${this.props.match.params.token}`
    );
  };

  navigateToSignInWithCode = () => {
    this.props.history.push(`/guest-code/${this.props.match.params.token}`);
  };

  changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  render() {
    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <div
          style={{
            padding: "20px",
            backgroundColor: "#00aea9",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "20%", maxWidth: "75px" }}>
            <img
              src={de}
              alt="de_flag"
              width={"100%"}
              onClick={() => this.changeLanguage("de")}
              style={{
                opacity: i18n.language === "de" ? "1" : "0.7",
                paddingBottom: "10px",
                borderBottom: i18n.language === "de" ? "3px solid white" : "",
              }}
            />
          </div>
          <div style={{ width: "20%", maxWidth: "75px" }}>
            <img
              src={hu}
              alt="hu_flag"
              width={"100%"}
              onClick={() => this.changeLanguage("hu")}
              style={{
                opacity: i18n.language === "hu" ? "1" : "0.7",
                paddingBottom: "10px",
                borderBottom: i18n.language === "hu" ? "3px solid white" : "",
              }}
            />
          </div>
          <div style={{ width: "20%", maxWidth: "75px" }}>
            <img
              src={en}
              alt="en_flag"
              width={"100%"}
              onClick={() => this.changeLanguage("en")}
              style={{
                opacity: i18n.language === "en" ? "1" : "0.7",
                paddingBottom: "10px",
                borderBottom: i18n.language === "en" ? "3px solid white" : "",
              }}
            />
          </div>
        </div>
        <div>
          {this.state.loading && (
            <div style={{ padding: "20px", backgroundColor: "#00aea9" }}>
              <div>{this.props.t("guest-main-loading")}</div>
            </div>
          )}
          {!this.state.loading &&
            (this.state.tokenOk ? (
              <div>
                <div
                  style={{
                    padding: "20px 10px 10px 10px",
                    backgroundColor: "#00aea9",
                  }}
                >
                  <h1 style={{ color: "#ffffff", fontSize: "2.2rem" }}>
                    <strong>{this.props.t("guest-main-question")}</strong>
                  </h1>
                </div>
                <div style={{ textAlign: "center", margin: "15px 0 15px 0" }}>
                  <img src={phone} alt="phone" width={"30%"} />
                </div>
                <div
                >
                  <div style={{ margin: "15px 0 15px 0" }}>
                    <Button
                      onClick={this.navigateToSignInWithCode}
                      type="primary"
                      style={{
                        fontSize: "2.2rem",
                        height: "5%",
                        borderRadius: "50px",
                        width: "60%",
                      }}
                    >
                      {this.props.t("yes-button-label")}
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={this.navigateToRegister}
                      type="primary"
                      style={{
                        fontSize: "2.2rem",
                        height: "5%",
                        borderRadius: "50px",
                        width: "60%",
                      }}
                    >
                      {this.props.t("no-button-label")}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <h3 style={{ textAlign: "center" }}>
                  {this.props.t("guest-main-bad-token")}
                </h3>
              </div>
            ))}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const GuestMain = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedGuestMain)
);
export default GuestMain;
