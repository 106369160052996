export const userRoles = {
  SYSTEM_ADMIN: 1,
  COMPANY_ADMIN: 2,
  RECEPTION_ADMIN: 3,
  HR_ADMIN: 4,
  USER: 5,
  OFFICE_ADMIN: 6,
  PARKING_SYSTEM: 7,
  HIK_VISION_SYSTEM: 8,
  OFFICE_RECEPTION_ADMIN: 9,
  INVITE_USER_RECEPTION_ADMIN: 10,
  PARKING_RECEPTION: 11,
  DEVELOPER_USER: 12
};