import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Select, Steps } from "antd";
import { refreshUser } from "../../actions/login";
import HttpService from "../../services/http";
import WizardBasicData from "./wizardBasicData";
import WizardImportUsers from "./wizardImportUsers";
import WizardRoles from "./wizardRoles";
import logo from "../../static/logo3.png";
import WizardDesign from "./wizardDesign";
import "./wizard.scss";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { saveDefaultLanguage } from "../../actions/company";
import { getAllLanguages } from "../../actions/language";
import { NavLink } from "react-router-dom";

const { Step } = Steps;

const mapDispatchToProps = (dispatch) => {
  return {
    refreshUser: (userId) => dispatch(refreshUser(userId)),
    saveDefaultLanguage: (companyId, languageId) =>
      dispatch(saveDefaultLanguage(companyId, languageId)),
    getAllLanguages: () => dispatch(getAllLanguages()),
  };
};
class ConnectedWizardMain extends Component {
  constructor(props) {
    super(props);
    HttpService.setAxiosDefaults(props.token);

    this.state = {
      current: 0,
    };
    document.body.style = "background: #d9d9d9; !important";
  }

  componentDidMount = async () => {
    await HttpService.setAxiosDefaults(this.props.token);    
    await this.props.getAllLanguages();
    i18n.changeLanguage(
      this.props.user.company.defaultLanguage.code.toLowerCase()
    );
  };

  next = () => {
    this.setState({ current: this.state.current + 1 });
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  handleChangeLanguage = async (event) => {
    const selectedlanguage = this.props.languages.find(
      (language) => language.code === event.toUpperCase()
    );
    if (selectedlanguage) {
      await this.props.saveDefaultLanguage(
        this.props.user.companyId,
        selectedlanguage.id
      );
      await this.props.refreshUser(this.props.user.id);
    }
    i18n.changeLanguage(event);
  };

  render() {
    const { current } = this.state;

    const steps = [
      {
        title: this.props.t("wizard-main-step-profile"),
        content: (
          <WizardBasicData
            next={this.next}
            prev={this.prev}
            defaultLanguageId={this.props.user.company.defaultLanguageId}
            refresh={() => this.props.refreshUser(this.props.user.id)}
          />
        ),
      },
      {
        title: this.props.t("wizard-main-step-design"),
        content: (
          <WizardDesign
            companyId={this.props.user.company.id}
            selectedCompany={this.props.user.company}
            updateCompany={() => this.props.refreshUser(this.props.user.id)}
            next={this.next}
            prev={this.prev}
            logoName={this.props.user.company.logoName}
            background={this.props.user.company.background}
            token={this.props.token}
            wizard={true}
          />
        ),
      },
      {
        title: this.props.t("wizard-main-step-colleagues"),
        content: (
          <WizardImportUsers next={this.next} prev={this.prev} wizard={true} />
        ),
      },
      {
        title: this.props.t("wizard-main-step-roles"),
        content: (
          <WizardRoles
            next={this.next}
            prev={this.prev}
            history={this.props.history}
          />
        ),
      },
    ];
    return (
      <div
        style={{
          background: "#D9D9D9",
          maxHeight: "1200px",
          height: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "repeat",
        }}
      >
        <div style={{ background: "#151917", padding: "1.5em" }}>
          <img
            src={logo}
            alt="tablog-logo"
            className="logo-icon"
            style={{ height: "26px", width: "104px" }}
          />
          {this.props.user.completeWizard && (
            <NavLink to="/tl/help">
              <Button type="primary" style={{ marginLeft: "1em" }}>
                {this.props.t("back-button-label")}
              </Button>
            </NavLink>
          )}
        </div>
        <div className="steps-container">
          <div className="steps-content">
            <div style={{ textAlign: "right" }}>
              <Select
                style={{ width: "130px", textAlign: "left" }}
                value={i18n.language}
                onChange={this.handleChangeLanguage}
              >
                <Select.Option key="hu" value="hu">
                  Magyar
                </Select.Option>
                <Select.Option key="en" value="en">
                  English
                </Select.Option>
                <Select.Option key="de" value="de">
                  Deutsch
                </Select.Option>
              </Select>
            </div>
            <div>{steps[current].content}</div>

            <div className="steps-counter" style={{ margin: "1em" }}>
              <Steps current={current} progressDot>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  languages: state.languageReducer.languages,
});
const WizardMain = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedWizardMain)
);
export default WizardMain;
