import {
  COMPANY_ACTIONS,
  ENTRYPOINTS_ACTIONS,
} from "../constants/action-types";

const initialState = {
  companies: [],
  company: [],
  status: false,
  message: "",
  saveStatus: false,
  subscriptions: [],
  addresses: [],
  entryPoints: [],
  address: {},
  companyAnonymizationLimit: 1,
  image: ""
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_ACTIONS.COMPANIES_LOADING:
      return Object.assign({}, state, {
        companies: [],
        status: false,
        message: "",
      });
    case COMPANY_ACTIONS.COMPANIES_LOADED:
      return Object.assign({}, state, {
        companies: action.payload.companies,
        status: true,
        message: "",
      });
    case COMPANY_ACTIONS.COMPANIES_ERROR:
      return Object.assign({}, state, {
        companies: [],
        status: false,
        message: action.payload,
      });
    case COMPANY_ACTIONS.COMPANIES_FILTER_LOADING:
      return Object.assign({}, state, {
        filterCompanies: [],
        status: false,
        message: "",
      });
    case COMPANY_ACTIONS.COMPANIES_FILTER_LOADED:
      return Object.assign({}, state, {
        filterCompanies: action.payload,
        status: true,
        message: "",
      });
    case COMPANY_ACTIONS.COMPANIES_FILTER_ERROR:
      return Object.assign({}, state, {
        filterCompanies: [],
        status: false,
        message: action.payload,
      });
    case COMPANY_ACTIONS.COMPANY_LOADING:
      return Object.assign({}, state, {
        company: [],
        status: false,
        message: "",
      });
    case COMPANY_ACTIONS.COMPANY_LOADED:
      return Object.assign({}, state, {
        company: action.payload.companies,
        status: true,
        message: "",
      });
    case COMPANY_ACTIONS.COMPANY_ERROR:
      return Object.assign({}, state, {
        company: [],
        status: false,
        message: action.payload,
      });
    case COMPANY_ACTIONS.COMPANY_ANYONIMIZATION_LIMIT_LOADING:
      return Object.assign({}, state, {
        companyAnonymizationLimit: 1,
        status: false,
        message: action.payload,
      });
    case COMPANY_ACTIONS.COMPANY_ANYONIMIZATION_LIMIT_LOADED:
      return Object.assign({}, state, {
        companyAnonymizationLimit: action.payload.companyAnonymizationLimit,
        status: true,
        message: action.payload,
      });
    case COMPANY_ACTIONS.COMPANY_ANYONIMIZATION_LIMIT_ERROR:
      return Object.assign({}, state, {
        companyAnonymizationLimit: 1,
        status: false,
        message: action.payload,
      });
    case COMPANY_ACTIONS.SUBSCRIPTIONS_LOADING:
      return Object.assign({}, state, {
        subscriptions: [],
        status: false,
        message: "",
      });
    case COMPANY_ACTIONS.SUBSCRIPTIONS_LOADED:
      return Object.assign({}, state, {
        subscriptions: action.payload.subscriptions,
        status: true,
        message: "",
      });
    case COMPANY_ACTIONS.SUBSCRIPTIONS_ERROR:
      return Object.assign({}, state, {
        subscriptions: [],
        status: false,
        message: action.payload,
      });
    case COMPANY_ACTIONS.ADDRESSES_LOADING:
      return Object.assign({}, state, {
        addresses: [],
        status: false,
        message: "",
      });
    case COMPANY_ACTIONS.ADDRESSES_LOADED:
      return Object.assign({}, state, {
        addresses: action.payload.addresses,
        status: true,
        message: "",
      });
    case COMPANY_ACTIONS.ADDRESSES_ERROR:
      return Object.assign({}, state, {
        addresses: [],
        status: false,
        message: action.payload,
      });
    case COMPANY_ACTIONS.ADDRESS_LOADING:
      return Object.assign({}, state, {
        address: {},
        status: false,
        message: "",
      });
    case COMPANY_ACTIONS.ADDRESS_LOADED:
      return Object.assign({}, state, {
        address: action.payload.address,
        status: true,
        message: "",
      });
    case COMPANY_ACTIONS.ADDRESS_ERROR:
      return Object.assign({}, state, {
        address: {},
        status: false,
        message: action.payload,
      });
    case COMPANY_ACTIONS.COMPANY_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
        companyId: action.payload?.user?.companyId
      });
    case COMPANY_ACTIONS.COMPANY_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case COMPANY_ACTIONS.CROP_IMAGE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case COMPANY_ACTIONS.CROP_IMAGE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
        image: action.payload
      });
    case COMPANY_ACTIONS.CROP_IMAGE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case COMPANY_ACTIONS.COMPANY_RESET:
      return Object.assign({}, state, {
        companies: [],
        status: false,
        message: "",
        saveStatus: false,
      });
    case ENTRYPOINTS_ACTIONS.ENTRYPOINTS_LOADING:
      return Object.assign({}, state, {
        entryPoints: [],
        status: false,
        message: "",
      });
    case ENTRYPOINTS_ACTIONS.ENTRYPOINTS_LOADED:
      return Object.assign({}, state, {
        entryPoints: action.payload.entryPoints,
        status: true,
        message: "",
      });
    case ENTRYPOINTS_ACTIONS.ENTRYPOINTS_ERROR:
      return Object.assign({}, state, {
        entryPoints: [],
        status: false,
        message: action.payload,
      });
    case ENTRYPOINTS_ACTIONS.OFFICE_ENTRYPOINTS_LOADING:
      return Object.assign({}, state, {
        entryPoints: [],
        status: false,
        message: "",
      });
    case ENTRYPOINTS_ACTIONS.OFFICE_ENTRYPOINTS_LOADED:
      return Object.assign({}, state, {
        entryPoints: action.payload.entryPoints,
        status: true,
        message: "",
      });
    case ENTRYPOINTS_ACTIONS.OFFICE_ENTRYPOINTS_ERROR:
      return Object.assign({}, state, {
        entryPoints: [],
        status: false,
        message: action.payload,
      });
    case ENTRYPOINTS_ACTIONS.ENTRYPOINTS_REGISTER_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case ENTRYPOINTS_ACTIONS.ENTRYPOINTS_REGISTER_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case ENTRYPOINTS_ACTIONS.ENTRYPOINTS_REGISTER_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default companyReducer;
