import { SaveOutlined } from "@ant-design/icons";
import {
  Col,
  Row,
  Form,
  Input,
  Select,
  Button,
  message,
} from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import "react-quill/dist/quill.snow.css";
import { withTranslation } from "react-i18next";
import { getAllOffices } from "../../actions/office";
import { getAllCompaniesForFilter } from "../../actions/company"
import { USER_ROLES_ARRAY } from "../../constants/user-roles";
import { sendCustomPush } from "../../actions/push";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllOffices: () => dispatch(getAllOffices()),
    getAllCompaniesForFilter: (officeId) => dispatch(getAllCompaniesForFilter(officeId)),
    sendCustomPush: (params) => dispatch(sendCustomPush(params))
  };
};

class ConnectedCustomPushNotification extends Component {
  formRef = React.createRef();

  state = {
    selectedOfficeId: 0,
    selectedCompanyIds: [],
    selectedRoleIds: [],
    filteredOptions: [],
    officesList: [],
    pushTitle: "",
    pushBody: ""
  };

  componentDidMount = async () => {
    await this.props.getAllOffices();
    if (this.props.officeStatus) {
      this.setState({ officesList: [{ id: 0, name: "NO Office" }, ...this.props.offices] })
    }

    await this.props.getAllCompaniesForFilter(this.state.selectedOfficeId);
  };

  sendCustomPushNotification = async () => {
    const saveData = {
      title: this.state.pushTitle,
      body: this.state.pushBody,
      officeId: this.state.selectedOfficeId,
      companyIds: this.state.selectedCompanyIds,
      roleIds: this.state.selectedRoleIds
    };

    await this.props.sendCustomPush(saveData);

    if (this.props.pushStatus) {
      message.success(this.props.t("send-message-success"), 5);
      this.setState({
        selectedOfficeId: 0,
        selectedCompanyIds: [],
        selectedRoleIds: [],
        pushTitle: "",
        pushBody: ""
      });
      this.formRef.current.setFieldsValue({
        pushTitle: "",
        pushBody: "",
        pushSelectedOffice: null,
        pushSelectedCompanyIds: [],
        pushSelectedRoleIds: [],
      });
    } else {
      message.error(this.props.t("save-message-fail"), 5);
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeSelectedCompanyIds = (event) => {
    this.setState({ selectedCompanyIds: event });
  };

  handleChangeSelectedRoleIds = (event) => {
    this.setState({ selectedRoleIds: event });
  };

  handleChangeSelectedOffice = async (event) => {
    this.setState({ selectedOfficeId: event, selectedCompanyIds: [] });

    await this.props.getAllCompaniesForFilter(event);
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <Form
          onFinish={this.sendCustomPushNotification}
          ref={this.formRef}
          {...formItemLayout}
          name="design"
          scrollToFirstError
        >
          <Row span={24}>
            <Col span={14} offset={2}>
              <Form.Item
                name="pushSelectedOffice"
                label={this.props.t("push-notification-selected-office-label")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("push-notification-selected-office-label"),
                  },
                ]}
                style={{ paddingBottom: "20px" }}
              >
                <Select
                  name="pushSelectedOffice"
                  style={{ width: "18em" }}
                  onChange={this.handleChangeSelectedOffice}
                  value={this.state.selectedOfficeId}
                  defaultValue={0}
                >
                  {this.state.officesList.map((office) => {
                    return (
                      <Select.Option
                        key={office.id}
                        value={office.id}
                      >{`${office.name}`}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row span={24} >
            <Col span={14} offset={2}>
              <Form.Item
                name="pushSelectedCompanyIds"
                label={this.props.t("push-notification-selected-companies-label")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("push-notification-selected-companies-label"),
                  },
                ]}
                style={{ paddingBottom: "20px" }}
              >
                <Select
                  name="pushSelectedCompanyIds"
                  mode="multiple"
                  placeholder="Select companies"
                  value={this.state.selectedCompanyIds}
                  onChange={this.handleChangeSelectedCompanyIds}
                  style={{
                    width: '100%',
                  }}
                  options={this.props.companies?.filter((o) => !this.state.selectedCompanyIds.includes(o)).map((company) => ({
                    value: company.id,
                    label: company.name,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row span={24}>
            <Col span={14} offset={2}>
              <Form.Item
                name="pushSelectedRoleIds"
                label={this.props.t("push-notification-selected-roles-label")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("push-notification-selected-roles-label"),
                  },
                ]}
                style={{ paddingBottom: "20px" }}
              >
                <Select
                  name="pushSelectedRoleIds"
                  mode="multiple"
                  placeholder="Select roles"
                  value={this.state.selectedRoleIds}
                  onChange={this.handleChangeSelectedRoleIds}
                  style={{
                    width: '100%',
                  }}
                  options={USER_ROLES_ARRAY.filter((o) => !this.state.selectedRoleIds.includes(o)).map((role) => ({
                    value: role.id,
                    label: this.props.t(role.label),
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={14} offset={2}>
              <Form.Item
                name="pushTitle"
                label={this.props.t("push-notification-title-label")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("push-notification-title-label"),
                  },
                ]}
                style={{ paddingBottom: "20px" }}
              >
                <Input.TextArea
                  name="pushTitle"
                  onChange={this.handleChange}
                  showCount
                  maxLength={50}
                />
              </Form.Item>
              <Form.Item
                name="pushBody"
                label={this.props.t("push-notification-body-label")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("push-notification-body-label"),
                  },
                ]}
              >
                <Input.TextArea
                  name="pushBody"
                  onChange={this.handleChange}
                  showCount
                  maxLength={100}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={14} offset={2}>
              <Form.Item style={{ textAlign: "right" }}>
                <Button type="primary" htmlType="submit">
                  <SaveOutlined /> {this.props.t("send-button-label")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  offices: state.officeReducer.offices,
  officeStatus: state.officeReducer.status,
  companies: state.companyReducer.filterCompanies,
  companyStatus: state.companyReducer.status,
  pushStatus: state.pushReducer.saveStatus
});
const CustomPushNotification = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedCustomPushNotification)
);
export default CustomPushNotification;
