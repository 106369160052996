import { PlusOutlined } from "@ant-design/icons";
import {
  Upload,
  Button,
  Form,
  message,
  Select,
} from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import XLSX from "xlsx";
import {
  addImportErrors,
  getAllUsers,
  saveImportedUsers,
  emptyImportErrors,
} from "../../actions/users";
/* import parse from "html-react-parser"; */
//import Axios from "axios";
import i18n from "../../i18n";
import i18next from "i18next";

const mapDispatchToProps = (dispatch) => {
  return {
    saveImportedUsers: (saveObj) => dispatch(saveImportedUsers(saveObj)),
    getAllUsers: (groupIds, companyId) =>
      dispatch(getAllUsers(groupIds, companyId)),
    addImportErrors: (errors) => dispatch(addImportErrors(errors)),
    emptyImportErrors: () => dispatch(emptyImportErrors()),
  };
};
class ConnectedWizardExcelImport extends Component {
  state = {
    users: [],
    newUsers: [],
    file: [],
    emptyFirstName: [],
    emptyLastName: [],
    badEmail: [],
    duplicatedEmail: [],
    selectedCompanyId: null,
  };

  componentDidMount = async () => {
    await this.props.getAllUsers([2, 3, 4, 5], this.props.user.companyId);
    if (this.props.status) {
      /* const users = this.props.users.map((user) => {
        return {
          Keresztnév: user.firstName,
          Vezetéknév: user.lastName,
          "E-mail": user.email,
          Beosztás: user.position,
          Telefon: user.phoneNumber,
        };
      }); */
      this.setState({ users: this.props.users });
    }
  };

  removeFile = () => {
    this.setState({
      file: [],
      newUsers: [],
      emptyFirstName: [],
      emptyLastName: [],
      badEmail: [],
      duplicatedEmail: [],
    });
  };

  beforeUpload = async (file) => {
    const workbook = XLSX.read(await file.arrayBuffer(), { type: "buffer" });
    const sheet_name_list = workbook.SheetNames;
    const result = XLSX.utils.sheet_to_json(
      workbook.Sheets[sheet_name_list[0]]
    );

    console.log(result);

    /* if (duplicates.filter((duplicate) => duplicate > 1).length > 0) {
      message.error(this.props.t("wizard-excel-duplication-error"), 5);
      this.setState({ file: [] });
      return;
    } */
    let usersToSave = [];
    await i18next.loadLanguages(["en", "de", "hu"]);

    const languages = ["en", "de", "hu"];
    const firstnames = languages.map((lang) => {
      return i18n.t("guest-register-firstname-label", {
        lng: lang,
      });
    });
    const lastnames = languages.map((lang) => {
      return i18n.t("guest-register-lastname-label", {
        lng: lang,
      });
    });
    const emails = languages.map((lang) => {
      return i18n.t("excel-email-label", {
        lng: lang,
      });
    });
    const positions = languages.map((lang) => {
      return i18n.t("profile-position-label", {
        lng: lang,
      });
    });
    const phones = languages.map((lang) => {
      return i18n.t("profile-phone-label", {
        lng: lang,
      });
    });
    const licencePlateNumbers1 = languages.map((lang) => {
      return i18n.t("excel-license-plate-number-1-label", {
        lng: lang,
      });
    });
    const licencePlateNumbers2 = languages.map((lang) => {
      return i18n.t("excel-license-plate-number-2-label", {
        lng: lang,
      });
    });
    const duplicates = result.map((user) => {
      let email = "";
      emails.forEach((email) => {
        if (user[email]) {
          email = user[email];
        }
      });
      return {
        number: result.filter((user2) => user2[email] === user[email]).length,
        email: user[email],
      };
    });

    for (const user in result) {
      let userToSave = {
        /* firstName: result[user]["Keresztnév"],
        lastName: result[user]["Vezetéknév"],
        email: result[user]["E-mail"],
        position: result[user]["Beosztás"],
        phone: result[user]["Telefon"], */
      };
      firstnames.forEach((firstName) => {
        if (result[user][firstName]) {
          userToSave.firstName = result[user][firstName];
        }
      });
      lastnames.forEach((lastName) => {
        if (result[user][lastName]) {
          userToSave.lastName = result[user][lastName];
        }
      });
      emails.forEach((email) => {
        if (result[user][email]) {
          userToSave.email = result[user][email];
        }
      });
      positions.forEach((position) => {
        if (result[user][position]) {
          userToSave.position = result[user][position];
        }
      });
      phones.forEach((phone) => {
        if (result[user][phone]) {
          userToSave.phoneNumber = result[user][phone];
        }
      });
      licencePlateNumbers1.forEach((firstVehicle) => {
        if (result[user][firstVehicle]) {
          userToSave.firstVehicle = result[user][firstVehicle];
        }
      });
      licencePlateNumbers2.forEach((secondVehicle) => {
        if (result[user][secondVehicle]) {
          userToSave.secondVehicle = result[user][secondVehicle];
        }
      });
      if (userToSave.email !== "elek@teszt.hu") {
        // eslint-disable-next-line no-control-regex
        const exp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        if (!exp.test(userToSave.email)) {
          userToSave.index = user + 1;
          console.log("email error", userToSave);
          this.setState({
            badEmail: [...this.state.badEmail, userToSave],
          });
        } else if (
          userToSave.lastName === "" ||
          userToSave.lastName === undefined ||
          userToSave.lastName === null
        ) {
          console.log("lastName error", userToSave);
          userToSave.index = user + 1;
          this.setState({
            emptyLastName: [...this.state.emptyLastName, userToSave],
          });
        } else if (
          userToSave.firstName === "" ||
          userToSave.firstName === undefined ||
          userToSave.firstName === null
        ) {
          console.log("firstName error", userToSave);
          userToSave.index = user + 1;
          this.setState({
            emptyFirstName: [...this.state.emptyFirstName, userToSave],
          });
        } else if (
          duplicates.find(
            (duplicate) =>
              duplicate.email === userToSave.email && duplicate.number > 1
          )
        ) {
          console.log("duplicate error", userToSave);
          userToSave.index = parseInt(user) + 1;
          this.setState({
            duplicatedEmail: [...this.state.duplicatedEmail, userToSave],
          });
        } else {
          usersToSave = [...usersToSave, userToSave];
        }
      }
    }

    console.log(usersToSave);

    if (this.props.user.officeId ||
      ((this.props.user.company.subscription.maxUser === null
        ? Number.MAX_SAFE_INTEGER
        : this.props.user.company.subscription.maxUser) >=
        this.state.users.length + result.length)
    ) {
      this.setState({
        users: [...this.state.users, ...usersToSave],
        newUsers: [...usersToSave],
      });
    } else {
      const size =
        (this.props.user.company.subscription.maxUser === null
          ? Number.MAX_SAFE_INTEGER
          : this.props.user.company.subscription.maxUser) -
        this.state.users.length;
      this.setState({
        users: [...this.state.users, ...usersToSave.splice(0, size)],
        newUsers: [...usersToSave.splice(0, size)],
      });
      message.warning(this.props.t("wizard-excel-maxuser-error"), 5);
    }
    this.setState({ file: [file] });
    return false;
  };

  importData = async () => {
    await this.saveUsers();
  };

  handleCompanySelect = async (event) => {
    this.setState({
      selectedCompanyId: event,
    });
  };

  saveUsers = async () => {
    console.log(this.state.selectedCompanyId)
    const saveObj = {
      users: this.state.newUsers,
      companyId: this.state.selectedCompanyId || this.props.user.companyId,
    };
    if (this.state.users.length === 0) {
      this.props.next();
    } else {
      await this.props.saveImportedUsers(saveObj);
      this.props.emptyImportErrors();
      if (this.props.saveStatus) {
        this.props.next();
      } else {
        console.log(this.props.message);
        if (this.props.message?.code && this.props.message.code === 1003) {
          message.error(
            `${this.props.t("wizard-excel-import-emailexists")} ${this.props.message.data.email
            }!`,
            2
          );
        } else if (this.props.message?.message && this.props.message?.data) {
          message.error(this.props.message.message + " " + this.props.message.data, 5);
        } else if (this.props.message?.message) {
          message.error(this.props.message.message, 5);
        } else {
          message.error("Hiba a felhasználók mentése során!", 5);
        }
      }
      /*
      await Axios.post("/1.0.0/users/import", saveObj)
        .then((response) => {
          const duplicatedInSystem = response.data.filter(
            (user) => user.duplicate && user.duplicate === true
          );
          if (this.state.newUsers.length - duplicatedInSystem.length > 0) {
            message.success(
              this.props.t("wizard-excel-import-success", {
                name: this.state.file[0].name,
                number: this.state.newUsers.length - duplicatedInSystem.length,
              }),
              2
            );
          } else {
            message.error(this.props.t("wizard-excel-import-error"), 5);
          }
          this.props.emptyImportErrors();
          this.props.addImportErrors({
            duplicatedInSystem: duplicatedInSystem,
            emptyFirstName: this.state.emptyFirstName,
            emptyLastName: this.state.emptyLastName,
            badEmail: this.state.badEmail,
            duplicatedEmail: this.state.duplicatedEmail,
          });
          this.props.next();
        })
        .catch((err) => {
          console.log(err);
          if (this.props.message && this.props.message.code === 1003) {
            message.error(
              `${this.props.t("wizard-excel-import-emailexists")} ${this.props.message.data.email
              }!`,
              2
            );
          } else if (this.props.message && this.props.message.code === 1004) {
            message.error(this.props.t("user-import-duplicated-email-in-the-file"))
          } else if (this.props.message?.message && this.props.message?.data) {
            message.error(this.props.message.message + " " + this.props.message.data, 5);
          } else if (this.props.message?.message) {
            message.error(this.props.message.message, 5);
          } else {
            message.error(this.props.t("wizard-excel-import-error"), 5);
          }
        });
        */
    }
  };

  render() {
    const dummyRequest = ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    };
    return (
      <div>
        <div style={{ textAlign: "center" }}>
          {this.props.user.officeId && (
            <>
              <Form.Item
                name="selectedCompanyId"
                label={this.props.t("proximity-card-import-select-company")}
                rules={[]}
              >
                <Select
                  style={{ width: "15em" }}
                  onChange={(event) => this.handleCompanySelect(event)}
                  value={this.state.selectedCompanyId}
                >
                  {this.props.companies.map((company) => {
                    return (
                      <Select.Option key={company.id} value={company.id}>
                        {company.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          )}
          <div style={{ margin: "1em", textAlign: "left" }}>
            <Upload.Dragger
              name="file"
              multiple={false}
              onRemove={this.removeFile}
              accept=".xlsx"
              beforeUpload={this.beforeUpload}
              fileList={this.state.file}
              showUploadList={{
                showDownloadIcon: false,
                showPreviewIcon: false,
              }}
              customRequest={dummyRequest}
            >
              <p
                className="ant-upload-drag-icon"
                style={{ textAlign: "center" }}
              >
                <PlusOutlined />
              </p>
              <p
                className="ant-upload-text"
                style={{ color: "#00b7ae", textAlign: "center" }}
              >
                {this.props.t("wizard-excel-upload")}
              </p>
            </Upload.Dragger>
          </div>
          {this.state.newUsers.length === 0 && this.state.file.length !== 0 && (
            <div style={{ color: "#ff4d4f" }}>
              {this.props.t("all-entry-isbad")}
            </div>
          )}
          <div style={{ margin: "1em" }}>
            <Button
              onClick={this.importData}
              type="primary"
              style={{ width: "65%" }}
              disabled={
                (this.props.user.officeId && !this.state.selectedCompanyId)
                || this.state.file.length === 0 || this.state.newUsers.length === 0
              }
            >
              {this.props.t("wizard-excel-import")}
            </Button>
          </div>
          <div style={{ margin: "1em" }}>
            <Button onClick={this.props.back} style={{ width: "65%" }}>
              {this.props.t("back-button-label")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  saveStatus: state.usersReducer.saveStatus,
  message: state.usersReducer.message,
  status: state.usersReducer.status,
  users: state.usersReducer.users,
});
const WizardExcelImport = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedWizardExcelImport)
);
export default WizardExcelImport;
