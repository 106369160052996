import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import ReceptionGuests from "./receptionGuests";
import ReceptionWorkers from "./receptionWorkers";
import { withTranslation } from "react-i18next";
import { openTagWithMessage } from "../../actions/tag";
import { subscriptionIdExceptions } from "../../constants/subscriptionIdExceptions";
import { userRoles } from "../../constants/roles-types";

const { TabPane } = Tabs;
const mapDispatchToProps = (dispatch) => {
  return {
    openTagWithMessage: (messageToSend) =>
      dispatch(openTagWithMessage(messageToSend)),
  };
};
class ConnectedReceptionMain extends Component {
  state = {
    activeKey: "2",
  };

  componentDidMount() {
    this.setState({ activeKey: !this.hasRightBySubscription(subscriptionIdExceptions.inviteExceptionIds) ? "3" : "2" })
  }

  handleChangeTab = (key) => {
    switch (key) {
      case "2":
        this.props.openTagWithMessage("reception-invited-tip");
        break;
      case "3":
        this.props.openTagWithMessage("reception-guestentry-tip");
        break;
      case "1":
        this.props.openTagWithMessage("reception-workerentry-tip");
        break;
      default:
        break;
    }
    this.setState({ activeKey: key });
  };

  hasRightBySubscription = (notAllowedSubscriptions) => {
    if (
      (!this.props.user.groups.some(group => [userRoles.OFFICE_ADMIN, userRoles.OFFICE_RECEPTION_ADMIN].includes(group.id))) &&
      notAllowedSubscriptions.includes(this.props.user.company.subscriptionId)
    ) {
      return false;
    }
    return true;
  };

  hasRight = (rights) => {
    let allowed = false;
    this.props.user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allowed = true;
      }
    });

    return allowed;
  };

  render() {
    //TODO: reafactor fix numbers into constants
    return (
      <div>
        <Tabs
          defaultActiveKey={!this.hasRightBySubscription(subscriptionIdExceptions.inviteExceptionIds) ? "3" : "2"}
          onChange={this.handleChangeTab}
          activeKey={this.state.activeKey}
        >
          {this.hasRight([
            userRoles.SYSTEM_ADMIN,
            userRoles.COMPANY_ADMIN,
            userRoles.RECEPTION_ADMIN,
            userRoles.OFFICE_RECEPTION_ADMIN,
            userRoles.OFFICE_ADMIN,
            userRoles.INVITE_USER_RECEPTION_ADMIN
          ]) && this.hasRightBySubscription(subscriptionIdExceptions.inviteExceptionIds) && (
              <TabPane tab={this.props.t("reception-main-guests-label")} key="2">
                <ReceptionGuests socket={this.props.socket} />
              </TabPane>
            )}
          {this.hasRight([
            userRoles.SYSTEM_ADMIN,
            userRoles.COMPANY_ADMIN,
            userRoles.RECEPTION_ADMIN,
            userRoles.OFFICE_ADMIN,
            userRoles.OFFICE_RECEPTION_ADMIN
          ]) && this.hasRightBySubscription(subscriptionIdExceptions.inviteExceptionIds) && (
              <TabPane tab={this.props.t("reception-main-events-label")} key="1">
                <ReceptionWorkers socket={this.props.socket} worker={false} />
              </TabPane>
            )}
          {this.hasRight([
            userRoles.SYSTEM_ADMIN,
            userRoles.COMPANY_ADMIN,
            userRoles.RECEPTION_ADMIN,
            userRoles.OFFICE_ADMIN,
            userRoles.OFFICE_RECEPTION_ADMIN
          ]) && this.hasRightBySubscription(subscriptionIdExceptions.workExceptionIds) && (
              <TabPane
                tab={this.props.t("reception-main-events-worker-label")}
                key="3"
              >
                <ReceptionWorkers socket={this.props.socket} worker={true} />
              </TabPane>
            )}
        </Tabs>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
});
const ReceptionMain = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedReceptionMain)
);
export default ReceptionMain;
