import React, { Component } from 'react';
import { connect } from 'react-redux';
const mapDispatchToProps = (dispatch) => {
return {};
  };
class ConnectedUnderConstruction extends Component {
render() {
return <div><h1>Fejlesztés alatt!</h1></div>;
}
}
const mapStateToProps = (state) => ({});
const UnderConstruction = connect(mapStateToProps, mapDispatchToProps)(ConnectedUnderConstruction);
export default UnderConstruction;