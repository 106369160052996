import { LANGUAGE_ACTIONS } from "../constants/action-types";

const initialState = {
  languages: [],
  status: false,
  message: "",
  saveStatus: false,
  selectedLanguageText: {}
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE_ACTIONS.LANGUAGES_LOADING:
      return Object.assign({}, state, {
        languages: [],
        status: false,
        message: "",
      });
    case LANGUAGE_ACTIONS.LANGUAGES_LOADED:
      return Object.assign({}, state, {
        languages: action.payload.languages,
        status: true,
        message: "",
      });
    case LANGUAGE_ACTIONS.LANGUAGES_ERROR:
      return Object.assign({}, state, {
        languages: [],
        status: false,
        message: action.payload,
      });
    case LANGUAGE_ACTIONS.LANGUAGE_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case LANGUAGE_ACTIONS.LANGUAGE_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case LANGUAGE_ACTIONS.LANGUAGE_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case LANGUAGE_ACTIONS.LANGUAGE_SELECTED_LANGUAGE_SET:
      return Object.assign({}, state, {
        selectedLanguageText: action.payload,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default languageReducer;
