import React, { Component } from "react";
import {
  Input,
  Modal,
  Button,
  Space,
  Table,
  message,
  Row,
  Col,
  Select,
} from "antd";
import { connect } from "react-redux";
import {
  SearchOutlined,
  BookOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import {
  getAllGuestsList,
  getAllReceptionEventsByGuest,
  markGuestsDepartured,
} from "../../actions/reception";
import { getAllCompaniesByOfficeId } from "../../actions/company";
import Highlighter from "react-highlight-words";
import moment from "moment";
import "./guest-list.scss";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllGuestsList: (qrCode, name, email, arrived, companyId, officeId) =>
      dispatch(
        getAllGuestsList(qrCode, name, email, arrived, companyId, officeId)
      ),
    getAllReceptionEventsByGuest: (guestId) =>
      dispatch(getAllReceptionEventsByGuest(guestId)),
    markGuestsDepartured: (guestIds) =>
      dispatch(markGuestsDepartured(guestIds)),
    getAllCompaniesByOfficeId: (officeId) =>
      dispatch(getAllCompaniesByOfficeId(officeId)),
  };
};

class ConnectedGuestList extends Component {
  formRef = React.createRef();

  state = {
    data: [],
    guestsList: [],
    filteredInfo: {},
    guestReceptionEvents: [],
    popupvisible: false,
    loading: false,
    opened: false,
    openModal: false,
    openSaveModal: false,
    openTime: null,
    actuatorsState: null,
    desiredState: null,
    searchText: null,
    searchedColumn: null,
    selectedCompanyId: null,
    companies: [],
    selectedRowKeys: [],
  };

  componentDidMount = async () => {
    if (this.props.user.officeId) {
      await this.props.getAllCompaniesByOfficeId(this.props.user.officeId);
      this.setState({ companies: this.props.companies });
    }
    await this.fetch();
  };

  successMessage = () => {
    message.success(this.props.t("entrypoints-modal-success-message"), 5);
  };

  popupConfirm = (record) => {
    this.setState({ popupvisible: false });
    this.handleDelete(record.id);
    message.success(this.props.t("entrypoints-popupconfirm-message"));
  };

  popupCancel = () => {
    this.setState({ popupvisible: false });
    this.handleCancel();
  };

  handlePopupVisibleChange = (popupvisible) => {
    if (!popupvisible) {
      this.setState({ popupvisible });
      return;
    } else {
      this.setState({ popupvisible });
    }
  };

  showModal = async (record) => {
    await this.props.getAllReceptionEventsByGuest(record);
    this.setState({
      guestReceptionEvents: this.props.guestReceptionEvents,
      openModal: true,
    });
  };

  createButtonHandler = () => {
    this.showModal();
    this.setCreator();
    this.formRef.current.resetFields();
  };

  handleCreateSubmit = async () => {
    const data = {
      companyId: this.props.user?.companyId ? this.props.user.companyId : null,
      officeId: this.props.user?.officeId ? this.props.user.officeId : null,
      name: this.state.name,
      addressId: this.state.addressId,
      deviceId: this.state.deviceId,
      controllerAddress: this.state.controllerAddress,
      rfidString: this.state.rfidString,
      openTime: parseInt(this.state.openTime),
      actuatorsState: parseInt(this.state.actuatorsState),
      desiredState: parseInt(this.state.desiredState),
    };
    await this.props.createEntrypoints(data);
    if (this.props.saveStatus) {
      this.setState({
        name: "",
        addressId: "",
        deviceId: "",
        controllerAddress: "",
        rfidString: "",
        openTime: null,
        actuatorsState: null,
        desiredState: null,
      });
      this.formRef.current.resetFields();
      this.successMessage();
      await this.fetch();
    } else {
      if (this.props.message && this.props.message.code === 5404) {
        message.error(this.props.t("entrypoints-save-duplicate-error"));
      } else {
        message.error(this.props.t("entrypoints-save-error"));
      }
    }
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false, modify: false, selectedId: null });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleTableChange = async () => {
    await this.fetch();
  };

  departureSelectedGuests = async () => {
    await this.props.markGuestsDepartured(this.state.selectedRowKeys);
    if (this.props.status) {
      message.success(this.props.t("save-success"), 5);
      await this.fetch();
      this.handleCloseSaveModal();
    } else {
      message.error(this.props.t("save-fail"), 5);
    }
  };

  fetch = async () => {
    this.setState({ loading: true });
    //TODO add params
    if (this.props.user.officeId && !this.state.selectedCompanyId) {
      await this.props.getAllGuestsList(
        null,
        null,
        null,
        null,
        null,
        this.props.user.officeId
      );
    } else {
      var companyId = this.state.selectedCompanyId
        ? this.state.selectedCompanyId
        : this.props.user.companyId;
      await this.props.getAllGuestsList(
        null,
        null,
        null,
        null,
        companyId,
        null
      );
    }
    this.setState({
      loading: false,
      selectedRowKeys: [],
      openSaveModal: false,
      guestsList: this.props.guestsList,
    });
  };

  onClickEvent = async (record) => {
    if (this.state.modify === true) {
      await this.handleUpdateSubmit(record);
    } else {
      await this.handleCreateSubmit();
    }
    this.handleCancel();
  };

  resetFilter = () => {
    this.setState({ filteredInfo: {}, searchedColumn: null, searchText: "" });
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t("search-button-label")}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            {this.props.t("reset-button-label")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex] !== null) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : text}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleOpenModal = async () => {
    this.setState({
      openSearchModal: true,
    });
  };

  handleCloseModal = async () => {
    this.setState({
      openModal: false,
      guestReceptionEvents: [],
    });
  };

  handleOpenSaveModal = async (id) => {
    if (id) {
      this.setState({
        selectedRowKeys: [id],
        openSaveModal: true,
      });
    } else {
      this.setState({
        openSaveModal: true,
      });
    }
  };

  handleCloseSaveModal = async () => {
    this.setState({
      openSaveModal: false,
    });
  };

  onSelectedCompanyChange = async (companyId) => {
    this.setState({
      selectedCompanyId: companyId,
    });
    await this.fetch();
  };

  onSelectedRowKeysChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const colums = [
      {
        title: this.props.t("guest-name-label"),
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["ascend", "descend"],
        filteredValue: this.state.filteredInfo.name || null,
        ...this.getColumnSearchProps("name", this.props.t("guest-name-label")),
      },
      {
        title: this.props.t("guest-email-label"),
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortDirections: ["ascend", "descend"],
        filteredValue: this.state.filteredInfo.email || null,
        ...this.getColumnSearchProps(
          "email",
          this.props.t("guest-email-label")
        ),
      },
      {
        title: this.props.t("guest-qrcode-label"),
        dataIndex: "qrCode",
        key: "qrCode",
        filteredValue: this.state.filteredInfo.qrCode || null,
        ...this.getColumnSearchProps(
          "qrCode",
          this.props.t("guest-qrcode-label")
        ),
      },
      {
        title: this.props.t("guest-represented-company-label"),
        dataIndex: "representedCompanyName",
        key: "representedCompanyName",
        sorter: (a, b) => {
          const nameA = a.representedCompanyName || "";
          const nameB = b.representedCompanyName || "";

          return nameA.localeCompare(nameB);
        },
        sortDirections: ["ascend", "descend"],
        filteredValue: this.state.filteredInfo.representedCompanyName || null,
        ...this.getColumnSearchProps(
          "representedCompanyName",
          this.props.t("guest-represented-company-label")
        ),
      },
      {
        title: this.props.t("guest-invite-company-label"),
        dataIndex: "inviteCompanyName",
        key: "inviteCompanyName",
        sorter: (a, b) =>
          a.inviteCompanyName.localeCompare(b.inviteCompanyName),
        sortDirections: ["ascend", "descend"],
        filteredValue: this.state.filteredInfo.inviteCompanyName || null,
        ...this.getColumnSearchProps(
          "inviteCompanyName",
          this.props.t("guest-invite-company-label")
        ),
      },
      {
        title: this.props.t("guest-arrive-time-label"),
        dataIndex: "arriveTime",
        key: "arriveTime",
        align: "center",
        sorter: (a, b) => {
          const arriveTimeA = a.arriveTime || "";
          const arriveTimeB = b.arriveTime || "";

          return arriveTimeA.localeCompare(arriveTimeB);
        },
        sortDirections: ["ascend", "descend"],
        render: (text, record) => {
          return record.arriveTime
            ? moment(record.arriveTime).format("YYYY.MM.DD. HH:mm")
            : "-";
        },
      },
      {
        title: this.props.t("guest-departure-time-label"),
        dataIndex: "departureTime",
        key: "departureTime",
        align: "center",
        sorter: (a, b) => {
          const departureTimeA = a.departureTime || "";
          const departureTimeB = b.departureTime || "";

          return departureTimeA.localeCompare(departureTimeB);
        },
        sortDirections: ["ascend", "descend"],
        render: (text, record) => {
          return record.departureTime
            ? moment(record.departureTime).format("YYYY.MM.DD. HH:mm")
            : "-";
        },
      },
      {
        title: this.props.t("reception-events-button-label"),
        key: "reception-events",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.showModal(record.id)}>
                <BookOutlined />
              </Button>
            </Space>
          );
        },
      },
      {
        key: "reception-guest-delete",
        render: (text, record) => {
          return (
            <Space size="small">
              <Button
                disabled={record.departureTime ? true : false}
                onClick={() => this.handleOpenSaveModal(record.id)}
              >
                <ExportOutlined />
              </Button>
            </Space>
          );
        },
      },
    ];

    const eventsColums = [
      {
        title: this.props.t("reception-events-table-header-eventtype"),
        key: "eventType",
        render: (text, record) => {
          let string = "";
          if (record.eventType) {
            if (record.eventType === 1) {
              string = this.props.t("arrive-event-label");
            } else if (record.eventType === 2) {
              string = this.props.t("leave-event-label");
            } else if (record.eventType === null) {
              string = "";
            } else {
              string = this.props.t("passthrought-event-label");
            }
          } else {
            string = record.event;
          }
          return <Space size="middle">{string}</Space>;
        },
      },
      {
        title: this.props.t("reception-events-table-header-address-name"),
        key: "addressName",
        dataIndex: "addressName",
      },
      {
        title: this.props.t("reception-events-table-header-entrypoint-name"),
        key: "entryPointName",
        dataIndex: "entryPointName",
      },
      {
        title: this.props.t("reception-events-table-header-time"),
        key: "date",
        dataIndex: "date",
        align: "center",
        render: (text, record) => {
          return record.date
            ? moment(record.date).format("YYYY.MM.DD. HH:mm")
            : "-";
        },
      },
    ];

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
      getCheckboxProps: (record) => {
        let checkboxProps = {};
        if (record.departureTime) {
          checkboxProps.disabled = true;
        }
        return checkboxProps;
      },
    };

    return (
      <div className="guests-list">
        <Row>
          {this.props.user.officeId ? (
            <Col span={6} display={false}>
              <Select
                style={{ width: "15em" }}
                onChange={(event) => this.onSelectedCompanyChange(event)}
              >
                {this.state.companies.map((company) => {
                  return (
                    <Select.Option key={company.id} value={company.id}>
                      {company.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Col>
          ) : (
            <div />
          )}
          <Col span={4}>
            <Space size="medium">
              <Button
                disabled={this.state.selectedRowKeys.length === 0}
                onClick={() => this.handleOpenSaveModal()}
              >
                {this.props.t("guest-reception-departure-button")}
              </Button>
            </Space>
          </Col>
        </Row>
        <Row style={{ padding: "0.5em" }}>
          <Col span={24}>
            <Table
              bordered={false}
              columns={colums}
              rowKey="id"
              dataSource={this.state.guestsList}
              pagination={
                this.state.guestsList.length > 10
                  ? {
                      position: ["bottomCenter"],
                      showSizeChanger: true,
                    }
                  : false
              }
              onChange={this.handleTableChange}
              rowSelection={rowSelection}
            />
          </Col>
        </Row>

        <Modal
          visible={this.state.openModal}
          onCancel={this.handleCloseModal}
          title={this.props.t("guest-reception-events-table-header")}
          maskClosable={false}
          centered
          footer={null}
          width={1200}
          bodyStyle={{ overflowY: "auto", maxHeight: "800px" }}
        >
          <Table
            columns={eventsColums}
            rowKey="id"
            dataSource={this.state.guestReceptionEvents}
            locale={{ emptyText: this.props.t("empty-text") }}
          />
        </Modal>

        <Modal
          visible={this.state.openSaveModal}
          onCancel={this.handleCloseSaveModal}
          title={this.props.t("guest-reception-departure-table-header")}
          maskClosable={false}
          centered
          footer={null}
          width={400}
          bodyStyle={{ overflowY: "auto", maxHeight: "400px" }}
        >
          <Row>
            <Col span={8} offset={4}>
              <Space size="medium">
                <Button onClick={() => this.handleCloseSaveModal()}>
                  {this.props.t("button-cancel")}
                </Button>
              </Space>
            </Col>
            <Col span={11} offset={1}>
              <Space size="medium">
                <Button onClick={() => this.departureSelectedGuests()}>
                  {this.props.t("guest-reception-departure-button-save")}
                </Button>
              </Space>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  guestsList: state.receptionReducer.guestsList,
  guestReceptionEvents: state.receptionReducer.guestReceptionEvents,
  user: state.loginReducer.user,
  status: state.receptionReducer.status,
  companies: state.companyReducer.companies,
});
const GuestList = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedGuestList)
);
export default GuestList;
