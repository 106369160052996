import { Button, Space, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  DeleteOutlined,
  EditOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { getColumnSearchProps } from "../../../common/table.common";

const mapDispatchToProps = (dispatch) => {
  return {};
};

class ConnectedAddressesList extends Component {
  formRef = React.createRef();

  state = { searchText: "", searchedColumn: "" };

  componentDidMount = async () => {};

  handleOpenUpdateSetOfUsersModal = async (address) => {
    await this.props.handleOpenUpdateSetOfUsersModal(address);
  };

  handleOpenModifyAddressModal = (record) => {
    this.props.handleOpenModifyAddressModal(record);
  };

  handleDelete = async (addressId) => {
    await this.props.handleDeleteAddress(addressId);
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: this.props.t("companyadmin-address-name-label"),
        key: "name",
        dataIndex: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["ascend", "descend"],
        ...getColumnSearchProps(
          "name",
          this.props.t("companyadmin-address-name-label"),
          this.state.searchText,
          this.state.searchedColumn,
          this.handleSearch,
          this.handleReset,
          this.formRef,
          this.props.t
        ),
      },
      {
        title: this.props.t("companyadmin-address-table-header-country"),
        key: "country",
        dataIndex: "country",
        sorter: (a, b) => a.country.localeCompare(b.country),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: this.props.t("companyadmin-address-table-header-zip"),
        key: "zip",
        dataIndex: "zip",
        sorter: (a, b) => a.zip.localeCompare(b.zip),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: this.props.t("companyadmin-address-table-header-city"),
        key: "city",
        dataIndex: "city",
        sorter: (a, b) => a.city.localeCompare(b.city),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: this.props.t("companyadmin-address-table-header-address"),
        key: "address",
        dataIndex: "address",
        sorter: (a, b) => a.address.localeCompare(b.address),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: <UserAddOutlined />,
        key: "addUser",
        render: (text, data) => {
          return (
            <Space size="middle">
              <Button
                onClick={() => this.handleOpenUpdateSetOfUsersModal(data)}
                disabled={data.isPublic}
              >
                <UserAddOutlined />
              </Button>
            </Space>
          );
        },
      },

      {
        title: <EditOutlined />,
        key: "modify",
        render: (text, data) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.handleOpenModifyAddressModal(data)}>
                <EditOutlined />
              </Button>
            </Space>
          );
        },
      },
      {
        title: <DeleteOutlined />,
        key: "delete",
        render: (text, data) => {
          return (
            <Space size="middle">
              <Button
                danger
                onClick={() => this.handleDelete(data.id)}
                disabled={this.props.addresses.length <= 1}
                type="primary"
              >
                <DeleteOutlined />
              </Button>
            </Space>
          );
        },
      },
    ];

    return (
      <>
        <Table
          columns={columns}
          dataSource={this.props.addresses}
          loading={this.props.loading}
          rowKey="id"
          locale={{ emptyText: "Nincs adat" }}
          pagination={
            this.props.addresses.length > 10
              ? {
                  position: ["bottomCenter"],
                  showSizeChanger: true,
                }
              : false
          }
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
});

const AddressesList = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedAddressesList)
);

export default AddressesList;
