import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Button, Checkbox, message, Table } from "antd";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import i18n from "../../i18n";
import hu from "../../static/hu.png";
import de from "../../static/de.png";
import en from "../../static/en.png";
import arrowUp from "../../static/arrowUp.png";
import arrowDown from "../../static/arrowDown.png";
React.lazy(() => import("./guest.scss"));

const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedGuestAgreement extends Component {
  state = {
    code: "",
    guest: null,
    agreements: [],
    selectedAgreements: [],
    accepted: false,
    error: false,
    success: false,
    expiredInvite: false,
    arrived: false,
  };

  componentDidMount = async () => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL + "/api";
    const queryParams = new URLSearchParams(this.props.location.search);
    this.setState({
      code: queryParams.get("code"),
      token: queryParams.get("token"),
    });
    if (queryParams.get("code")) {
      await axios
        .get("/1.0.0/agreement/guest-code", {
          params: { code: queryParams.get("code") },
        })
        .then((response) => {
          this.setState({
            guest: response.data.guest,
            allAgreements: response.data.agreements,
            agreements: response.data.agreements.filter(
              (agreement) =>
                agreement.language.code.toLowerCase() === i18n.language
            ),
          });
          if (response.data.guest.acceptedStatements) {
            this.setState({
              accepted: true,
              selectedAgreements: response.data.agreements
                .filter(
                  (agreement) =>
                    agreement.language.code.toLowerCase() === i18n.language
                )
                .map((agreement) => agreement.id),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ expiredInvite: true });
        });
    } else if (queryParams.get("token")) {
      await axios
        .get("/1.0.0/agreement/token", {
          params: { token: queryParams.get("token") },
        })
        .then((response) => {
          this.setState({
            allAgreements: response.data.agreements,
            agreements: response.data.agreements.filter(
              (agreement) =>
                agreement.language.code.toLowerCase() === i18n.language
            ),
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ expiredInvite: true });
        });
    }
    if (this.state.agreements.length === 0) {
      await this.handleAcceptAgreements();
    }
  };

  handleChangeAccepted = () => {
    if (this.state.selectedAgreements.length !== this.state.agreements.length) {
      message.error(this.props.t("guest-agreements-accept-error"), 5);
    } else {
      this.setState({ accepted: !this.state.accepted });
    }
  };

  handleSelectAgreement = (event, id) => {
    if (event.target.checked) {
      this.setState({
        selectedAgreements: [...this.state.selectedAgreements, id],
      });
    } else {
      const agreements = this.state.selectedAgreements.filter(
        (agreement) => agreement !== id
      );
      this.setState({ selectedAgreements: agreements });
    }
  };

  handleSelectAll = (event) => {
    if (event.target.checked) {
      const agreements = this.state.agreements.map((agreement) => agreement.id);
      this.setState({ selectedAgreements: agreements });
    } else {
      this.setState({ selectedAgreements: [] });
    }
  };

  handleAcceptAgreements = async () => {
    const queryParams = new URLSearchParams(this.props.location.search);
    if (this.state.code) {
      await axios
        .get("/1.0.0/reception/accept", {
          params: {
            code: this.state.code,
          },
        })
        .then((response) => {
          this.setState({ success: true });
          this.props.history.push(
            "/guest-details?token="+ this.state.token +"&code=" +
              this.state.code +
              "&email=" +
              queryParams.get("email")
          );
        })
        .catch((err) => {
          console.log(err);
          this.setState({ error: true });
        });
    } else if (this.state.token) {
      this.props.history.push(`/guest-register/${this.state.token}`);
    }
  };

  changeLanguage = (language) => {
    i18n.changeLanguage(language);
    this.setState({
      agreements: this.state.allAgreements.filter(
        (agreement) => agreement.language.code.toLowerCase() === language
      ),
      selectedAgreements: [],
      accepted: false,
    });
  };

  handleClickCheckbox = () => {
    console.log("clicked");
  };

  render() {
    const columns = [
      {
        title: <h1>{this.props.t("companyadmin-agreements-title-label")}</h1>,
        key: "title",
        dataIndex: "name",
        render: (text, record) => {
          return <h2>{record.name}</h2>;
        },
      },
    ];
    return (
      <div>
        <div
          style={{
            padding: "20px",
            backgroundColor: "#00aea9",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "20%", maxWidth: "75px" }}>
            <img
              src={de}
              alt="de_flag"
              width={"100%"}
              onClick={() => this.changeLanguage("de")}
              style={{
                opacity: i18n.language === "de" ? "1" : "0.7",
                paddingBottom: "10px",
                borderBottom: i18n.language === "de" ? "3px solid white" : "",
              }}
            />
          </div>
          <div style={{ width: "20%", maxWidth: "75px" }}>
            <img
              src={hu}
              alt="hu_flag"
              width={"100%"}
              onClick={() => this.changeLanguage("hu")}
              style={{
                opacity: i18n.language === "hu" ? "1" : "0.7",
                paddingBottom: "10px",
                borderBottom: i18n.language === "hu" ? "3px solid white" : "",
              }}
            />
          </div>
          <div style={{ width: "20%", maxWidth: "75px" }}>
            <img
              src={en}
              alt="en_flag"
              width={"100%"}
              onClick={() => this.changeLanguage("en")}
              style={{
                opacity: i18n.language === "en" ? "1" : "0.7",
                paddingBottom: "10px",
                borderBottom: i18n.language === "en" ? "3px solid white" : "",
              }}
            />
          </div>
        </div>
        {this.state.expiredInvite && (
          <div>{this.props.t("guest-agreement-invitation-invalid")}</div>
        )}
        {this.state.agreements.length > 0 && (
          <div>
            <div style={{ backgroundColor: "#00aea9" }}>
              <h1
                style={{
                  textAlign: "center",
                  color: "#ffffff",
                  fontSize: "2.2rem",
                  padding: "0 0.355em 0 0.355em",
                }}
              >
                <strong>{this.props.t("guest-agreements-code-title")}</strong>
              </h1>
            </div>
            <div style={{ padding: "0.8em" }}>
              <Table
                columns={columns}
                dataSource={this.state.agreements}
                rowKey="id"
                size="small"
                showHeader={false}
                expandRowByClick
                pagination={{
                  total: this.state.agreements.length,
                  pageSize: this.state.agreements.length,
                  hideOnSinglePage: true,
                }}
                rowClassName="table-row"
                locale={{ emptyText: this.props.t("empty-text") }}
                expandable={{
                  expandIconColumnIndex: 1,
                  onExpand: (expanded, record) => {
                    if (
                      !this.state.selectedAgreements.includes(record.id) &&
                      expanded
                    ) {
                      this.setState({
                        selectedAgreements: [
                          ...this.state.selectedAgreements,
                          record.id,
                        ],
                      });
                    }
                  },
                  expandedRowRender: (record) => {
                    return (
                      <p style={{ margin: 0 }}>{parse(record.description)}</p>
                    );
                  },
                  rowExpandable: (record) => true,
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <img
                        alt="up"
                        src={arrowUp}
                        height={"12"}
                        onClick={(e) => onExpand(record, e)}
                      />
                    ) : (
                      <img
                        alt="down"
                        src={arrowDown}
                        height={"12"}
                        onClick={(e) => onExpand(record, e)}
                      />
                    ),
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                margin: "20px 0 20px 0",
              }}
            >
              <div>
                <Checkbox
                  id="accept"
                  checked={this.state.accepted}
                  style={{
                    fontSize: "1.3rem",
                    borderRadius: "20px",
                    marginLeft: "15px",
                  }}
                  onChange={this.handleChangeAccepted}
                />
              </div>
              <label
                htmlFor="accept"
                style={{
                  fontSize: "1.3rem",
                  color: "#00aea9",
                  marginLeft: "15px",
                  marginRight: "15px",
                  userSelect: "none",
                }}
              >
                <strong>{this.props.t("guest-agreement-accept-terms")}</strong>
              </label>
            </div>
            <div style={{ width: "100%", textAlign: "center" }}>
              <Button
                type="primary"
                style={{
                  fontSize: "2.2rem",
                  height: "5%",
                  borderRadius: "50px",
                  width: "60%",
                }}
                disabled={!this.state.accepted}
                onClick={this.handleAcceptAgreements}
              >
                {this.props.t("next-button-label")}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const GuestAgreement = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedGuestAgreement)
);
export default GuestAgreement;
