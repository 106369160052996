import React, { Component } from "react";
import { Row, Col, message, Select, DatePicker, Button, Spin } from "antd";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router";
import Axios from "axios";
import { getAllCompanies } from "../../actions/company";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCompanies: () => dispatch(getAllCompanies()),
  };
};

class ConnectedParkingExportPage extends Component {
  formRef = React.createRef();

  state = {
    companies: [],
    companyId: null,
    startDate: null,
    endDate: null,
    loading: false,
  };

  componentDidMount = async () => {
    await this.getAllCompanies();
  };

  getAllCompanies = async () => {
    this.setState({ loaded: false });
    await this.props.getAllCompanies();
    this.setState({ companies: this.props.companies, loaded: true });
  };

  handleFilterCompanySelect = async (event) => {
    this.setState({
      companyId: event,
    });
  };

  handleStartDateChange = (date, dateString) => {
    this.setState({ startDate: dateString });
  };

  handleEndDateChange = (date, dateString) => {
    this.setState({ endDate: dateString });
  };

  excelExport = async () => {
    const { companyId, startDate, endDate } = this.state;
    if (!companyId || !startDate || !endDate) {
      message.error("Please select a company and both start and end dates.");
      return;
    }

    this.setState({ loading: true });

    let url = `/2.0.0/parking/export/parking-reservation?companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`;
    await Axios.get(url, { responseType: "blob" })
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `parking_export_${new Date().toISOString()}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        this.setState({ loading: false });
      })
      .catch((err) => {
        message.error(this.props.t("empty-text"));
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <div className="parking-export">
        {!this.props.user.officeId &&
          this.props.user.company.smartLock === false && (
            <Redirect from="**" to="/tl/missing-right" />
          )}

        <Spin spinning={this.state.loading}>
          <Row style={{ padding: "0.5em" }}>
            <Col span={24}>
              <Select
                style={{ width: "15em" }}
                onChange={this.handleFilterCompanySelect}
                placeholder="Select a company"
              >
                {this.props.companies.map((company) => {
                  return (
                    <Select.Option key={company.id} value={company.id}>
                      {company.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Col>
          </Row>

          <Row style={{ padding: "0.5em" }}>
            <Col span={12}>
              <DatePicker
                style={{ width: "100%" }}
                onChange={this.handleStartDateChange}
                placeholder="Select start date"
              />
            </Col>
            <Col span={12}>
              <DatePicker
                style={{ width: "100%" }}
                onChange={this.handleEndDateChange}
                placeholder="Select end date"
              />
            </Col>
          </Row>

          <Row style={{ padding: "0.5em" }}>
            <Col span={24}>
              <Button type="primary" onClick={this.excelExport}>
                Export
              </Button>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companies: state.companyReducer.companies,
  status: state.companyReducer.status,
  users: state.usersReducer.users,
  user: state.loginReducer.user,
  entryPoints: state.companyReducer.entryPoints,
  message: state.companyReducer.message,
});
const ParkingExportPage = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedParkingExportPage)
);
export default ParkingExportPage;
