import { Select, Tabs } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import ForgotPassword from "./forgotpassword";
import Login from "./login";
import logo from "../../static/logo.png";
import logo2 from "../../static/logo2.png";
import "./login.scss";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";

const { TabPane } = Tabs;
const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedLoginMain extends Component {
  handleChangeLanguage = (event) => {
    i18n.changeLanguage(event);
  };
  render() {
    return (
      <div style={{ paddingTop: "100px" }}>
        <div style={{ margin: "0 auto", width: "20%" }}>
          <div className="login-page-icon-container">
            <img src={logo} alt="logo" className="login-page-icon" />
          </div>
          <Tabs defaultActiveKey="1">
            <TabPane key="1" tab={this.props.t("login-title")}>
              <Login history={this.props.history} />
            </TabPane>
            <TabPane key="2" tab={this.props.t("forgot-password-title")}>
              <ForgotPassword history={this.props.history} />
            </TabPane>
          </Tabs>
        </div>

        <div
          style={{
            margin: "0 auto",
            width: "20%",
            textAlign: "center",
            bottom: "0",
          }}
        >
          <p>{this.props.t("rightsreserved")} &#169; 2020 TabLog</p>
          <Select
            style={{ width: "130px" }}
            value={i18n.language}
            onChange={this.handleChangeLanguage}
          >
            <Select.Option key="hu" value="hu">
              Magyar
            </Select.Option>
            <Select.Option key="en" value="en">
              English
            </Select.Option>
            <Select.Option key="de" value="de">
              Deutsch
            </Select.Option>
          </Select>
        </div>
        <div
          style={{ position: "absolute", top: "0", right: "10%", width: "30%" }}
        >
          <div className="login-page-icon-container">
            <img src={logo2} alt="logo2" className="login-page-icon" />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const LoginMain = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedLoginMain)
);
export default LoginMain;
