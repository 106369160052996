import { OFFICE_ACTIONS } from "../constants/action-types";

const initialState = {
  offices: [],
  office: [],
  status: false,
  message: "",
  saveStatus: false,
  deleteStatus: false,
};

const officeReducer = (state = initialState, action) => {
  switch (action.type) {
    case OFFICE_ACTIONS.OFFICES_LOADING:
      return Object.assign({}, state, {
        offices: [],
        status: false,
        message: "",
      });
    case OFFICE_ACTIONS.OFFICES_LOADED:
      return Object.assign({}, state, {
        offices: action.payload.offices,
        status: true,
        message: "",
      });
    case OFFICE_ACTIONS.OFFICES_ERROR:
      return Object.assign({}, state, {
        offices: [],
        status: false,
        message: action.payload
      });
    case OFFICE_ACTIONS.OFFICE_LOADING:
      return Object.assign({}, state, {
        office: [],
        status: false,
        message: "",
      });
    case OFFICE_ACTIONS.OFFICE_LOADED:
      return Object.assign({}, state, {
        office: action.payload.office,
        status: true,
        message: "",
      });
    case OFFICE_ACTIONS.OFFICE_ERROR:
      return Object.assign({}, state, {
        office: [],
        status: false,
        message: action.payload,
      });
    case OFFICE_ACTIONS.OFFICE_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case OFFICE_ACTIONS.OFFICE_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case OFFICE_ACTIONS.OFFICE_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case OFFICE_ACTIONS.OFFICE_DELETE_START:
      return Object.assign({}, state, {
        deleteStatus: false,
      });
    case OFFICE_ACTIONS.OFFICE_DELETE_SUCCESS:
      return Object.assign({}, state, {
        deleteStatus: true,
      });
    case OFFICE_ACTIONS.OFFICE_DELETE_ERROR:
      return Object.assign({}, state, {
        deleteStatus: false,
      });
    default:
      return state;
  }
};

export default officeReducer;
