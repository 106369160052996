export const ROOM_TYPES = {
  STANDARD: {
    id: 1,
    label: "building-room-type-name-standard",
    color: "#ADD8E6",
  },
  RECEPTION: {
    id: 2,
    label: "building-room-type-name-reception",
    color: "#FFFF99",
  },
  STORAGE: {
    id: 3,
    label: "building-room-type-name-storage",
    color: "#D3D3D3",
  },
  RESTAURANT: {
    id: 4,
    label: "building-room-type-name-restaurant",
    color: "#98FB98",
  },
  SHOP: { id: 5, label: "building-room-type-name-shop", color: "#E6E6FA" },
  PARKING: {
    id: 6,
    label: "building-room-type-name-parking",
    color: "#FFDAB9",
  },
};

export const ROOM_TYPES_ARRAY = [
  ROOM_TYPES.STANDARD,
  ROOM_TYPES.RECEPTION,
  ROOM_TYPES.STORAGE,
  ROOM_TYPES.RESTAURANT,
  ROOM_TYPES.SHOP,
  ROOM_TYPES.PARKING,
];
