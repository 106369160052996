import { PARKING_ACTIONS } from "../constants/action-types";

const initialState = {
  parking: [],
  parkingGroup: [],
  vehicle: [],
  entryGates: [],
  reservations: [],
  userSaveStatus: false,
  places: {},
  status: false,
  message: "",
  saveStatus: false,
  gateOpenStatus: false,
  reservationSaveStatus: false
};

const parkingReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARKING_ACTIONS.PARKING_LOADING:
      return Object.assign({}, state, {
        parking: [],
        status: false,
        message: "",
      });
    case PARKING_ACTIONS.PARKING_LOADED:
      return Object.assign({}, state, {
        parking: action.payload,
        status: true,
        message: "",
      });
    case PARKING_ACTIONS.PARKING_ERROR:
      return Object.assign({}, state, {
        parking: [],
        status: false,
        message: action.payload,
      });
    case PARKING_ACTIONS.PARKING_GROUP_LOADING:
      return Object.assign({}, state, {
        parkingGroup: [],
        status: false,
        message: "",
      });
    case PARKING_ACTIONS.PARKING_GROUP_LOADED:
      return Object.assign({}, state, {
        parkingGroup: action.payload,
        status: true,
        message: "",
      });
    case PARKING_ACTIONS.PARKING_GROUP_ERROR:
      return Object.assign({}, state, {
        parkingGroup: [],
        status: false,
        message: action.payload,
      });
    case PARKING_ACTIONS.PARKING_RESERVATION_LOADING:
      return Object.assign({}, state, {
        reservations: [],
        status: false,
        message: "",
      });
    case PARKING_ACTIONS.PARKING_RESERVATION_LOADED:
      return Object.assign({}, state, {
        reservations: action.payload,
        status: true,
        message: "",
      });
    case PARKING_ACTIONS.PARKING_RESERVATION_ERROR:
      return Object.assign({}, state, {
        reservations: [],
        status: false,
        message: action.payload,
      });
    case PARKING_ACTIONS.PARKING_RESERVATION_SAVE_START:
      return Object.assign({}, state, {
        reservationSaveStatus: false,
      });
    case PARKING_ACTIONS.PARKING_RESERVATION_SAVE_SUCCESS:
      return Object.assign({}, state, {
        reservationSaveStatus: true,
      });
    case PARKING_ACTIONS.PARKING_RESERVATION_SAVE_ERROR:
      return Object.assign({}, state, {
        reservationSaveStatus: false,
        message: action.payload,
      });
    case PARKING_ACTIONS.PARKING_VEHICLE_LOADING:
      return Object.assign({}, state, {
        vehicle: [],
        status: false,
        message: "",
      });
    case PARKING_ACTIONS.PARKING_VEHICLE_LOADED:
      return Object.assign({}, state, {
        vehicle: action.payload,
        status: true,
        message: "",
      });
    case PARKING_ACTIONS.PARKING_VEHICLE_ERROR:
      return Object.assign({}, state, {
        vehicle: [],
        status: false,
        message: action.payload,
      });
    case PARKING_ACTIONS.PARKING_VEHICLES_LOADING:
      return Object.assign({}, state, {
        vehicles: [],
        vehiclesStatus: false,
        message: "",
      });
    case PARKING_ACTIONS.PARKING_VEHICLES_LOADED:
      return Object.assign({}, state, {
        vehicles: action.payload,
        vehiclesStatus: true,
        message: "",
      });
    case PARKING_ACTIONS.PARKING_VEHICLES_ERROR:
      return Object.assign({}, state, {
        vehicles: [],
        vehiclesStatus: false,
        message: action.payload,
      });
    case PARKING_ACTIONS.PARKING_ENTRY_GATE_LOADING:
      return Object.assign({}, state, {
        entryGates: [],
        status: false,
        message: "",
      });
    case PARKING_ACTIONS.PARKING_ENTRY_GATE_LOADED:
      return Object.assign({}, state, {
        entryGates: action.payload,
        status: true,
        message: "",
      });
    case PARKING_ACTIONS.PARKING_ENTRY_GATE_ERROR:
      return Object.assign({}, state, {
        entryGates: [],
        status: false,
        message: action.payload,
      });
    case PARKING_ACTIONS.PARKING_ENTRY_GATE_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case PARKING_ACTIONS.PARKING_ENTRY_GATE_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case PARKING_ACTIONS.PARKING_ENTRY_GATE_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case PARKING_ACTIONS.PARKING_ENTRY_GATE_OPEN_START:
      return Object.assign({}, state, {
        gateOpenStatus: false,
      });
    case PARKING_ACTIONS.PARKING_ENTRY_GATE_OPEN_SUCCESS:
      return Object.assign({}, state, {
        gateOpenStatus: true,
      });
    case PARKING_ACTIONS.PARKING_ENTRY_GATE_OPEN_ERROR:
      return Object.assign({}, state, {
        gateOpenStatus: false,
        message: action.payload,
      });
    case PARKING_ACTIONS.PARKING_VEHICLE_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case PARKING_ACTIONS.PARKING_VEHICLE_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case PARKING_ACTIONS.PARKING_VEHICLE_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case PARKING_ACTIONS.PARKING_PLACES_LOADING:
      return Object.assign({}, state, {
        places: {},
        status: false,
        message: "",
      });
    case PARKING_ACTIONS.PARKING_PLACES_LOADED:
      return Object.assign({}, state, {
        places: action.payload,
        status: true,
        message: "",
      });
    case PARKING_ACTIONS.PARKING_PLACES_ERROR:
      return Object.assign({}, state, {
        places: {},
        status: false,
        message: action.payload,
      });
    case PARKING_ACTIONS.USER_SAVE_START:
      return Object.assign({}, state, {
        userSaveStatus: false,
      });
    case PARKING_ACTIONS.USER_SAVE_SUCCESS:
      return Object.assign({}, state, {
        userSaveStatus: true,
        companyId: action.payload?.user
      });
    case PARKING_ACTIONS.USER_SAVE_ERROR:
      return Object.assign({}, state, {
        userSaveStatus: false,
        message: action.payload,
      });
    case PARKING_ACTIONS.PARKING_GROUP_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case PARKING_ACTIONS.PARKING_GROUP_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case PARKING_ACTIONS.PARKING_GROUP_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case PARKING_ACTIONS.PARKING_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case PARKING_ACTIONS.PARKING_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case PARKING_ACTIONS.PARKING_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default parkingReducer;
